/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

export const TFA = {  // 2fa extras-keyName   // TODO: duplicate[2fa]
  yubico: 'pkrd',
  googleAuthenticator: 'pkrd2',
  smsAuthenticator: 'pkrd3',
};

export const TFA_LABELS = {
  yubico: 'Yubico',
  googleAuthenticator: 'Google Authenticator',
  smsAuthenticator: 'SMS',
};
