import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowIdLabelInterface } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { DATA_TYPES } from '../constants';

@Component({
  selector: 'flow-extras-form',
  templateUrl: './extras.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowExtrasFormComponent implements OnInit {

  @Input() control: UntypedFormGroup;

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  get dataTypes(): FlowIdLabelInterface[] {
    return DATA_TYPES;
  }

  ngOnInit() {
    this.labels = this._translateLabels();
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'forms.label.data_type',
      'forms.label.field_name',
      'forms.label.label',
      'forms.label.value'
    ]);
  }
}
