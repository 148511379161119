import { HttpHeaders } from '@angular/common/http';

/**
 * Reducer to transform a plain object to an Angular HttpParams Class.
 */
export const toHttpHeaders = (predicate: any = {}): HttpHeaders =>
  Object
  .getOwnPropertyNames(predicate)
  .reduce((p, key) => p.set(key, predicate[key]), new HttpHeaders());

/*
export function toHttpHeaders(predicate: any = {}): HttpHeaders {
  return Object.getOwnPropertyNames(predicate)
    .reduce((p, key) => p.set(key, predicate[key]), new HttpHeaders());
}
*/
