/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { FlowHelpers } from '../../classes/class.helpers';

@Injectable({
  providedIn: 'root'
})
export class FlowRouterService {

  /**
   * Stores the latest router navigation state when NavigationEnd event occurs.
   */
  private _currentChildFragment: BehaviorSubject <string> = new BehaviorSubject<string>(null);

  private _navigationEnd$$: BehaviorSubject <ActivatedRouteSnapshot> = new BehaviorSubject(null);

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Store current fragment.
        this._currentChildFragment.next(event.urlAfterRedirects);
        this._navigationEnd$$.next(this.router.routerState.snapshot.root);
      }
    });
  }

  /**
   * Gets latest stored route navigation path
   */
  get currentFragment(): string {
    return this._currentChildFragment.value;
  }

  get navigationEnd$(): Observable <ActivatedRouteSnapshot> {
    return this._navigationEnd$$.asObservable();
  }

  /**
   * Gets the latest stored route navigation path as an Observable.
   */
  get currentFragment$(): Observable <string> {
    return this._currentChildFragment.asObservable();
  }

  get url(): string {
    return this.router.url;
  }

  /**
   * Checks if given a certain path matches the current router state.
   */
  isPath(path: string, exact = false): boolean {
    if (!exact) {
      return this._currentChildFragment.value ? this._currentChildFragment.value.indexOf(path) > -1 : false;
    }
    else {
      return this._currentChildFragment.value ? this._currentChildFragment.value === path : false;
    }
  }

  /**
   * Same function as Router.navigate
   */
  navigate(to: any[], extras?: NavigationExtras): void {
    // Force reload
    if (to.length === 1 && to.indexOf(this.router.url) > -1) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(
        () => this.router.navigate(to, extras)
      );
    }
    else {
      this.router.navigate(to, extras);
    }
  }

  /**
   * Get property from NavigationExtras.state
   */
  getPropertyFromNavigationExtras(prop: string): any {
    const state = this.router.getCurrentNavigation().extras.state;

    if (!FlowHelpers.isEmptyObject(state) && FlowHelpers.hasProperty(state, prop)) {
      return state[prop];
    }

    return null;
  }
}
