<ng-container [formGroup]="form">
  <ng-container [formArrayName]="controlName">
    <mat-accordion [displayMode]="'flat'">
      <ng-container *ngFor="let control of arrayControls.controls; let i=index">
        <div class="row no-gutters">
          <div class="col-11" [formGroupName]="i">
            <mat-expansion-panel [expanded]="true" class="mb-3">
              <mat-expansion-panel-header>{{ panelHeader }} #{{ i+1 }}</mat-expansion-panel-header>
              <ng-container *ngTemplateOutlet="projectedContent; context: { control: control, controlIndex: i }"></ng-container>
            </mat-expansion-panel>
          </div>
          <div class="col-1 text-right text-sm-center repeated_collapsible__actions">
            <button [matTooltip]="labels['general.btn.remove']"
                    (click)="removeControls(i)"
                    class="button button-icon">
              <i class="material-icons remove">remove_circle_outline</i>
            </button>
          </div>
        </div>
      </ng-container>
    </mat-accordion>
    <div class="mt-3 text-right">
      <button type="button"
              class="button button-small button-primary-outline"
              [innerHTML]="buttonLabel"
              (click)="addControls()"></button>
    </div>
  </ng-container>
</ng-container>
