<svg
  class="spinner"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 50 50"
  [style.width.px]="diameter"
  [style.height.px]="diameter"
  [ngClass]="{ completed: completed }">
  <circle class="checkmark__circle" cx="25" cy="25" r="20" fill="none"/>
  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
</svg>
