<flow-preloader *ngIf="loading else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="labels['general.label.add.yubico_key']"></h5>
  </div>
  <mat-dialog-content>
    <div class="d-block">
      <div class="text-center d-block" *ngIf="registered">
        <i class="material-icons color-primary icon-3x">check</i>
        <p [innerHTML]="labels['account_settings.label.key_registered']"></p>
      </div>
      <div class="text-center d-block" *ngIf="!registered">
        <p [innerHTML]="labels[msgLabel]"></p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="button button-close-dialogs"
            *ngIf="!saving"
            [mat-dialog-close]="registered"
            [innerHTML]="registered ? labels['general.btn.close_reload'] : labels['general.btn.close']"
    ></button>
    <button class="button button-primary ml-2"
            (click)="doRegister()"
            *ngIf="!registered"
            [disabled]="saving"
            [innerHTML]="labels['ppcca.btn.sync']"
    ></button>
  </mat-dialog-actions>
</ng-template>
