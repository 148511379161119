/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowRouterService } from '@flow/core';

import { FlowAuthService } from '../services/auth/auth.service';
import { FlowTokensService } from '../services/tokens/tokens.service';

@Injectable({
  providedIn: 'root'
})
export class FlowUserGuard  {
  constructor(
    private RouterService: FlowRouterService,
    private AuthService: FlowAuthService,
    private TokensService: FlowTokensService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable <boolean> {
    if (this.AuthService.isLoggedIn) {
      return of(true);
    }
    else {
      if (this.TokensService.getToken() === 'noop' && this.TokensService.getRefreshToken() === 'noop') {
        // If non-authenticated user calls any portal URL (e.g. from an email), the path is stored as redirectStatePath.
        // The user will be redirected to login. After successful login the user will be redirected to redirectStatePath.
        this.AuthService.redirectStatePath = state.url;
        this.RouterService.navigate(this.AuthService.logoutUrl, { state: { appRefresh: false } });
        return of(false);
      }
      else {
        return this.AuthService.getMe().pipe(
          map(res => {
            if (true !== res) {
              // If non-authenticated user calls any portal URL (e.g. from an email), the path is stored as redirectStatePath.
              // The user will be redirected to login. After successful login the user will be redirected to redirectStatePath.
              this.AuthService.redirectStatePath = state.url;
              this.RouterService.navigate(this.AuthService.logoutUrl);
              return false;
            }
            return this.AuthService.isLoggedIn;
          })
        );
      }
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable <boolean> {
    return this.canActivate(route, state);
  }
}
