/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlowComponentsModule } from '../../components/components.module';
import { FlowDirectivesModule } from '../../directives/directives.module';
import { FlowMaterialModule } from '../../material/material.module';
import { FlowModalsModule } from '../../modals/modals.module';
import { Flow2faYubicoService } from './services/2fa-yubico/2fa-yubico.service';
import { FlowYubicoRegisterComponent } from './components/yubico-register/yubico-register.component';
import { FlowModalYubicoRegisterComponent } from './modals/modal-yubico-register/modal-yubico-register.component';
import { FlowModalYubicoSignComponent } from './modals/modal-yubico-sign/modal-yubico-sign.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlowMaterialModule,
        FlowModalsModule,
        FlowComponentsModule,
        FlowDirectivesModule
    ],
    declarations: [
        FlowYubicoRegisterComponent,
        FlowModalYubicoSignComponent,
        FlowModalYubicoRegisterComponent,
    ],
    providers: [
        Flow2faYubicoService
    ],
    exports: [
        FlowYubicoRegisterComponent
    ]
})
export class Flow2FaYubicoModule {
}
