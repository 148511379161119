/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, last, map } from 'rxjs/operators';

import { toHttpParams } from '../functions/to-http-params';
import { buildRequestBody } from '../functions/request-body-builder';
import { requestEventMessageType } from '../functions/request-event-message-type';
import { toHttpHeaders } from '../functions/to-http-headers';

export abstract class FlowHttp {

  protected constructor(
    protected httpClient: HttpClient,
    protected _endpoint: string
  ) {
  }

  baseUrl(): string {
    return this._endpoint;
  }

  get<T>(fragment: string, params: any = {}, headers?: any, extras?: any): Observable <T> {
    return this.httpClient.get<T>(`${this._endpoint}/${fragment}`,
      buildRequestBody(
        toHttpParams(params),
        toHttpHeaders(headers),
        extras
      ));
  }

  getWithHeaders<T>(fragment: string, params: any = {}, headers?: any): Observable <{ total: number; data: T }> {
    return this.httpClient.get<HttpResponse<T>>
      (`${this._endpoint}/${fragment}`, buildRequestBody(toHttpParams(params), toHttpHeaders(headers), { observe: 'response' })).pipe(
      map(result => ({
        total: +result.headers.get('total') || 0,
        data: result.body
      }))
    );
  }

  streamProgress(fragment: string, data: FormData, params: any, method = 'POST'): Observable <any> {
    const request = new HttpRequest(method, `${this._endpoint}/${fragment}`, data, {
      reportProgress: true,
      params: toHttpParams(params)
    });

    return this.httpClient.request(request).pipe(
      map(event => requestEventMessageType(event)),
      last(),
      catchError(() => of(false))
    );
  }

  post<T>(fragment: string, params: any = {} , extras?: any, headers?: any): Observable <T> {
    return this.httpClient.post<T>(
      `${this._endpoint}/${fragment}`,
      params,
      buildRequestBody(null, toHttpHeaders(headers), extras)
    );
  }

  bulkPost<T>(fragment: string, params: any = {}, headers?: any, extras?: any): Observable <T> {
    return this.httpClient.post<T>(
      `${this._endpoint}/${fragment}`,
      params,
      buildRequestBody(null, toHttpHeaders(headers), extras)
    );
  }

  put<T>(fragment: string, params: any = {}, headers?: any, extras?: any): Observable <T> {
    return this.httpClient.put<T>(
      `${this._endpoint}/${fragment}`,
      params,
      buildRequestBody(null, toHttpHeaders(headers), extras)
    );
  }

  delete<T>(fragment: string, params: any = {}, headers?: any, extras?: any): Observable<T> {
    return this.httpClient.delete<T>(
      `${this._endpoint}/${fragment}`,
      buildRequestBody(toHttpParams(params), toHttpHeaders(headers), extras)
    );
  }

  bulkDelete<T>(fragment: string, params: any = {}, headers?: any, extras?: any): Observable<T> {
    return this.httpClient.delete<T>(
      `${this._endpoint}/${fragment}`,
      buildRequestBody(toHttpParams(params), toHttpHeaders(headers), extras)
    );
  }
}
