<flow-preloader *ngIf="loading else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="labels['general.label.gauth_register']"></h5>
  </div>

  <mat-dialog-content>
    <div class="d-block">
      <div class="text-center d-block" *ngIf="registered">
        <i class="material-icons color-primary icon-3x">check</i>
        <p [innerHTML]="labels['account_settings.two_factor_auth.gauth_registered']"></p>
      </div>
      <form [formGroup]="form" *ngIf="!registered">

        <p class="howto-step mb-3">
          <span class="number">1</span>
          {{ labels['account_settings.label.two_factor.google_authenticator.step_one'] }}
        </p>

        <div class="qrCode" [ngStyle]="{'background-image': 'url(' + qrCode + ')'}"></div>

        <p class="howto-step mb-3">
          <span class="number">2</span>
          {{ labels['account_settings.label.two_factor.google_authenticator.step_two'] }}
        </p>

        <mat-form-field [appearance]="'fill'">
          <mat-label>{{ labels['account_settings.label.pin_code'] }}</mat-label>
          <input matInput formControlName="pincode" required>
          <mat-error *ngIf="form.get('pincode').hasError('maxlength')">
            {{ pincodeError() }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="button button-close-dialogs"
      [mat-dialog-close]="registered"
      [innerHTML]="registered ? labels['general.btn.close_reload'] : labels['general.btn.close']"
    ></button>

    <button
      *ngIf="!registered"
      class="button button-primary ml-2"
      (click)="doRegister()"
      [disabled]="saving || form.invalid"
      [innerHTML]="saving ? labels['general.label.validating_pin'] : labels['account_settings.label.validate_pin']"
    ></button>
  </mat-dialog-actions>
</ng-template>
