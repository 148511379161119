/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  FlowCmsService,
  FlowEnvService,
  FlowModelsService,
  KinetixExtraModel,
  FlowLanguageInterface,
  FlowUtilsService,
  FlowHelpers
} from '@flow/core';
import { FlowAuthService } from '../auth/auth.service';
import { FlowUserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class FlowProjectService {

  languages: FlowLanguageInterface[];

  projectExtras: KinetixExtraModel[];
  projectLanguages: FlowLanguageInterface[];

  constructor(
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private CmsService: FlowCmsService,
    private EnvService: FlowEnvService,
    private ModelsService: FlowModelsService,
    private UtilsService: FlowUtilsService
  ) {
    this.languages = this.UtilsService.getLanguages();
  }

  /**
   * Get extras of project
   */
  getProjectExtras(): Observable<KinetixExtraModel[]> | null {
    /* const project = this.EnvService.portalDomain;
        NOTE:   // ST041029
        EnvService.portalDomain is driven by the url (project) subdomain prefix. This works only when we're on a parent
        app, e.g. mp2, but it will not work on sub apps, e.g. PDFC, EZI, which won't have a project subdomain, as the
        project would be passed down as url param from the parent in the iframe url. Therefor we need to use
        AuthService.vendor, which is updated when there's a "project" url param on the iframe. AuthService.vendor can
        NOT be used for both cases, as it's only correct when parent app is running as vendor.
     */
    let project;

    if (this.EnvService.isVendorPortal === undefined) {   // we're as SUB-app, aka NOT the parent-app
      project = this.AuthService.vendor;
    }
    else {  // we're as PARENT-app
      project = this.EnvService.portalDomain;
    }

    if (project) {
      return this.projectExtras
        ? of(this.projectExtras)
        : this.CmsService.get<any>(`${this.ModelsService.getModel('App')}/${project}`)
          .pipe(
            map(data => data.extras),
            tap(projectExtras => this.projectExtras = projectExtras),
            catchError(() => FlowHelpers.catchAsNull())
          );
    }
    else {
      return FlowHelpers.catchAsNull();
    }
  }

  /**
   * Get all languages belonging to the project.
   */
  getProjectLanguages(): Observable<FlowLanguageInterface[]> {
    const project = this.EnvService.portalDomain;

    if (project) {
      return this.projectLanguages
        ? of(this.projectLanguages)
        : this.CmsService.get<any>(`${this.ModelsService.getModel('App')}/${project}`)
          .pipe(
            map(data => this.languages.filter(language => data.selectedLanguages.includes(language.lcId))),
            tap(projectLanguages => this.projectLanguages = projectLanguages),
            catchError(() => of(this.languages)),
          );
    }
    else {
      return of(this.languages);
    }
  }

  /**
   * Get name of vendor
   */
  getVendorName(): Observable<string> | null {
    const project = this.EnvService.portalDomain;

    if (project) {
      return this.CmsService.get<any>(`${this.ModelsService.getModel('App')}/${project}`)
        .pipe(
          map(data => data.description),
          catchError(() => FlowHelpers.catchAsNull())
        );
    }
    else {
      return FlowHelpers.catchAsNull();
    }
  }
}
