<div
  id="flow-auth-background"
  flowBackgroundImage
  [imageUrl]="currentSourceBackgroundUrl"
  [fallbackImageUrl]="defaultBackgroundUrl">
</div>

<div id="flow-auth-content" [ngClass]="pageClass">
  <div id="flow-auth-content__inner">
    <div class="auth-title" [innerHTML]="authTitle$ | async" tabindex="0"></div>
    <div
      *ngIf="partnersOnBoardingData && partnersOnBoardingData.disableLogin else loginForm"
      class="py-5 px-5"
      [innerHTML]="labels['login.label.disabled_login']">
    </div>
    <ng-template #loginForm>
      <div class="pt-4 pb-0 px-4">
        <div
          *ngIf="(authError$ | async) as authError;"
          class="bg-danger-20 color-danger-40 p-2 mb-3 animated shake"
          role="alert"
          aria-live="assertive">
          {{ authError}}
        </div>
        <div
          *ngIf="originalPortalUrl && !hideLegacyLink"
          class="mb-4 text-center">
          <a
            [href]="originalPortalUrl"
            [innerHTML]="labels['login.login_form_link.original_portal']"
            class="legacy-link"
            tabindex="0">
          </a>
        </div>
        <router-outlet></router-outlet>
      </div>

      <ul
        id="flow-auth-links"
        class="p-3"
        role="navigation"
        [attr.aria-label]="labels['login.aria.region.auth_links']">
        <li *ngIf="ssoLoginLink && ssoLoginLink.url && ssoLoginLink.label">
          <a
            *ngIf="!ssoLoginLink.logo"
            [href]="ssoLoginLink.url"
            [innerHTML]="ssoLoginLink.label"
            [attr.aria-label]="ssoLoginLink.label"
            class="d-block mb-3"
            tabindex="0">
          </a>
          <a
            *ngIf="ssoLoginLink.logo"
            [href]="ssoLoginLink.url"
            class="d-inline-block"
            tabindex="0">
            <img
              [src]="ssoLoginLink.logo"
              class="sso-login-logo"
              [ngClass]="{
                'mysps': ssoLoginLink.url.includes('/sps/') || ssoLoginLink.logo.includes('mysps')
              }"
              [attr.aria-label]="ssoLoginLink.label"
              [alt]="ssoLoginLink.label"
              [title]="ssoLoginLink.label">
          </a>
        </li>
        <li *ngIf="isLoginPage && !hideRegisterLink && hasOnboardingRegisterLink" routerLinkActive="active">
          <a
            (click)="doRegister($event)"
            [href]="'#'"
            [innerHTML]="labels[
                hasInternalOnboarding
                ? 'login.login_form.link.register_user'
                : 'login.login_form.link.register_company'
            ]"
            [attr.aria-label]="labels[
                hasInternalOnboarding
                ? 'login.login_form.link.register_user'
                : 'login.login_form.link.register_company'
            ]"
            tabindex="0">
          </a>
        </li>
        <li *ngIf="isLoginPage" routerLinkActive="active">
          <a
            routerLink="/auth/forgot-password"
            [innerHTML]="labels['login.login_form.link.forgot_password']"
            [attr.aria-label]="labels['login.login_form.link.forgot_password']"
            tabindex="0">
          </a>
        </li>
        <li *ngIf="faqLink$ | async as link" routerLinkActive="active">
          <a
            [href]="link"
            [innerHTML]="labels['general.faq.link.label']"
            target="_blank"
          ></a>
        </li>
      </ul>
    </ng-template>
    <div id="flow-auth-terms" class="py-2 px-3">
      <ul
        class="list-inline"
        role="navigation"
        [attr.aria-label]="labels['login.aria.region.auth_terms']">
        <li *ngIf="!partnersOnBoardingData?.isSupplierPortal">
          <a
            (click)="openPrivacyModal()"
            [innerHTML]="labels['login.footer.link.privacy']"
            tabindex="0">
          </a>
        </li>
        <li *ngIf="!partnersOnBoardingData?.isSupplierPortal">
          <a
            (click)="openModal('general.terms_of_use.headline', 'general.terms_of_use.content')"
            [innerHTML]="labels['login.footer.link.terms']"
            tabindex="0">
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href="http://flow-status.tiekinetix.net/"
            [innerHTML]="labels['login.footer.link.system_status']"
            tabindex="0">
          </a>
        </li>
      </ul>
      <p role="contentinfo">&copy; SPS Commerce. <span [innerHTML]="labels['login.footer_legal']"></span></p>
    </div>
  </div>
</div>
