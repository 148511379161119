<mat-form-field [formGroup]="form">
  <mat-label *ngIf="!hideLabel">{{ fieldLabel }}</mat-label>
  <input
    matInput
    [formControlName]="controlName"
    [placeholder]="!hidePlaceholder ? placeholder : ''"
    [attr.aria-label]="!hidePlaceholder ? placeholder : ''"
    [required]="required ? required : false"
    [readonly]="readonly ? readonly : false">

  <button
    *ngIf="translateConfig && form.get(controlName).value"
    matSuffix
    type="button"
    class="button button-icon button-icon-primary"
    (click)="openDialog()"
    [attr.aria-label]="labels['forms.label.translate']">
    <i class="material-icons" [matTooltip]="labels['forms.label.translate']">translate</i>
  </button>

  <mat-hint
    *ngIf="hint"
    [innerHTML]="hint">
  </mat-hint>

  <mat-error
    *ngIf="required && form.get(controlName).hasError('required')"
    [innerHTML]="labels['generic.error.required']">
  </mat-error>
</mat-form-field>
