/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FlowCustomHelpSiteInterface } from '@flow/core';
import { FlowDialogsService } from '@flow/shared';

@Component({
  selector: 'flow-help-custom-site',
  templateUrl: './help-custom.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowHelpCustomSiteComponent {

  @Input() label: string;
  @Input() settings: FlowCustomHelpSiteInterface;

  constructor(
    private DialogsService: FlowDialogsService
  ) {}

  openHelpSite(): void {
    // This component was used in help area before.
    // As help area was removed this method is not necessary.
    // Keep this here until we have a final decision about the help area.
    //this.closeHelp();

    this.DialogsService.iframeModal({
      url: this.settings.url
    });
  }

  /* closeHelp(): void {
    this.store.dispatch(new ToggleHelpPanel());
  } */
}
