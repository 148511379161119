<ng-container *ngIf="!isEnforce2FaPage">
  <flow-preloader *ngIf="loading else content"></flow-preloader>
</ng-container>

<ng-container *ngIf="isEnforce2FaPage">
  <flow-abs-preloader *ngIf="loading; else content"></flow-abs-preloader>
</ng-container>

<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="labels['account_settings.two_factor_auth.verify_sms']"></h5>
  </div>

  <mat-dialog-content>
    <div class="d-block">
      <div class="text-center d-block" *ngIf="registered">
        <i class="material-icons color-primary icon-3x">check</i>
        <p [innerHTML]="labels['account_settings.two_factor_auth.sms_registered']"></p>
      </div>

      <form [formGroup]="form" *ngIf="!registered">
        <ng-container *ngIf="form.get('requestid').value; else invalidPhoneNrRef">
          <p class="mb-3">
            {{ labels['account_settings.two_factor_auth.enter_verification_sms_pin'] }}
          </p>
          <mat-form-field [appearance]="'fill'">
            <mat-label>{{ labels['account_settings.label.pin_code'] }}</mat-label>
            <input matInput formControlName="pincode" required>
            <mat-error *ngIf="form.get('pincode').hasError('maxlength')">
              {{ pincodeError() }}
            </mat-error>
          </mat-form-field>
        </ng-container>

        <ng-template #invalidPhoneNrRef>
          <ng-container *ngIf="isEnforce2FaPage">
            {{ labels['error.two_factor_auth.invalid_phone_number.enforced'] }}
          </ng-container>
          <ng-container *ngIf="!isEnforce2FaPage">
            {{ labels['error.two_factor_auth.invalid_phone_number'] }}
          </ng-container>
        </ng-template>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="button button-close-dialogs"
      [mat-dialog-close]="registered"
      [innerHTML]="registered ? labels['general.btn.close_reload'] : labels['general.btn.close']"
    ></button>

    <button
      *ngIf="!registered"
      class="button button-primary ml-2"
      (click)="doRegister()"
      [disabled]="saving || form.invalid"
      [innerHTML]="saving ? labels['general.label.validating_pin'] : labels['account_settings.label.validate_pin']"
    ></button>
  </mat-dialog-actions>
</ng-template>
