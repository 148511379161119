/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import { FlowRegexService } from '@flow/core';
import { FlowSsoService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowLayoutService } from '../../../../../services/layout/layout.service';

@Component({
  selector: 'flow-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FlowForgotPasswordComponent implements OnInit {

  form: UntypedFormGroup;

  loading$: Observable <boolean>;

  labels: FlowTranslateLabel;

  status$$: BehaviorSubject <string> = new BehaviorSubject('no');

  showRecoveryEmail = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private RegexService: FlowRegexService,
    private TranslateService: FlowTranslateService,
    private LayoutService: FlowLayoutService,
    private SsoService: FlowSsoService
  ) {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.RegexService.getRegex('email'))]),
      recaptcha: new UntypedFormControl(null, Validators.required),
      recoveryEmail: new UntypedFormControl('', [Validators.pattern(this.RegexService.getRegex('email'))]),
    });
  }

  get status$(): Observable <string> {
    return this.status$$.asObservable();
  }

  ngOnInit(): void {

    this.loading$ = this.LayoutService.loading$;

    this.labels = this.TranslateService.translateSync([
      'generic.error.required.email',
      'generic.error.invalid.email',
      'login.login_form.label.email',
      'login.login_form.label.password',
      'login.label.recovery_email',
      'generic.error.invalid_recovery_email',
      'login.password_form.email_text.forgot_password',
      'login.label.enter_recovery_mail',
      'login.password_form.headline.forgot_password'
    ]);
  }

  onSubmit(): void {
    this.form.markAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.LayoutService.setLoading(true);

    this.SsoService
      .requestPassword(this.form.value)
      .subscribe(result => {
        this.LayoutService.setLoading(false);
        if (!result) {
          this.status$$.next('yes');
        }
        else {
          if (true === this.showRecoveryEmail) {
            if (result.AskRecoveryEmail && result.AskRecoveryEmail === 'true') {
              this.form.get('recoveryEmail').setErrors(['invalidRecoveryEmail']);
              this.form.get('recoveryEmail').setErrors({ 'invalidRecoveryEmail': 'generic.error.invalid_recovery_email'});
            }
            else {
              this.status$$.next('yes');
            }
          }
          else {
            if (result.AskRecoveryEmail && result.AskRecoveryEmail === 'true') {
              this.showRecoveryEmail = true;
            }
            else {
              this.status$$.next('yes');
            }
          }
        }
    });
  }
}
