<flow-preloader *ngIf="loading else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white d-flex align-items-center">
    <h5 class="truncate" [innerHTML]="labels['general.label.two_factor_authentication']"></h5>

    <a [href]="tfaHelpLink" target="_blank" tabindex="-1">  <!-- tabindex="-1" needed, otherwise the input field looses its focus -->
      <i class="material-icons color-gray-40" [matTooltip]="labels['general.label.help']">help_outline</i>
    </a>
  </div>

  <mat-dialog-content>
    <div class="d-block">
      <form [formGroup]="form" *ngIf="!loading">
        <div class="w-50 d-block" style="margin: 0 auto">
          <mat-form-field>
            <mat-label>{{ labels['account_settings.label.pin_code'] }}</mat-label>
            <input matInput formControlName="pincode" required>
            <mat-error *ngIf="form.get('pincode').hasError('maxlength')">
              {{ pincodeError() }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-center">
    <button
      class="button button-close-dialogs"
      [mat-dialog-close]="false"
      [innerHTML]="labels['general.btn.close']"
    ></button>

    <button
      class="button button-primary ml-2"
      (click)="doLogin()"
      [disabled]="loggingIn || form.invalid"
      [innerHTML]="loggingIn ? labels['general.label.validating_pin'] : labels['account_settings.label.validate_pin']"
    ></button>
  </mat-dialog-actions>
</ng-template>
