/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

// Map browser language codes to suitable country codes.
// If a mapping is not clear, then it's left out.

export const LANG_COUNTRY_MAP = {
  'af': 'ZA',
  'am': 'ET',
  'ar-EG': 'EG',
  'ar-DZ': 'DZ',
  'ar-BH': 'BH',
  'ar-IQ': 'IQ',
  'ar-YE': 'YE',
  'ar-JO': 'JO',
  'ar-QA': 'QA',
  'ar-KW': 'QA',
  'ar-LB': 'LB',
  'ar-LY': 'LY',
  'ar-MA': 'MA',
  'ar-OM': 'OM',
  'ar-SA': 'SA',
  'ar-SY': 'SY',
  'ar-TN': 'TN',
  'ar-AE': 'AE',
  'ast': 'ES',
  'az': 'AZ',
  'be': 'BY',
  'bg': 'BG',
  'bg-BG': 'BG',
  'bn': 'BD',
  'br': 'FR',
  'bs': 'BA',
  'ca': 'AD',
  'ceb': 'PH',
  'chr': 'US',
  'co': 'FR',
  'cy': 'GB',
  'da': 'DK',
  'da-DK': 'DK',
  'de': 'DE',
  'de-AT': 'AT',
  'de-CH': 'CH',
  'de-DE': 'DE',
  'de-LI': 'LI',
  'de-LU': 'LU',
  'el': 'GR',
  'el-GR': 'GR',
  'en': 'US',
  'en-AU': 'AU',
  'en-BZ': 'BZ',
  'en-CA': 'CA',
  'en-GB': 'GB',
  'en-GB-oxendict': 'GB',
  'en-IE': 'IE',
  'en-IN': 'IN',
  'en-JM': 'JM',
  'en-NZ': 'NZ',
  'en-PH': 'PH',
  'en-TT': 'TT',
  'en-US': 'US',
  'en-ZA': 'ZA',
  'en-ZW': 'ZW',
  'es': 'ES',
  'es-AR': 'AR',
  'es-BO': 'BO',
  'es-CL': 'CL',
  'es-CO': 'CO',
  'es-CR': 'CR',
  'es-DO': 'DO',
  'es-EC': 'EC',
  'es-ES': 'ES',
  'es-GT': 'GT',
  'es-HN': 'HN',
  'es-MX': 'MX',
  'es-NI': 'NI',
  'es-PA': 'PA',
  'es-PE': 'PE',
  'es-PR': 'PR',
  'es-PY': 'PY',
  'es-SV': 'SV',
  'es-US': 'US',
  'es-UY': 'UY',
  'es-VE': 'VE',
  'et': 'EE',
  'et-EE': 'EE',
  'eu': 'ES',
  'fi': 'FI',
  'fil': 'PH',
  'fo': 'FO',
  'fr': 'FR',
  'fr-BE': 'BE',
  'fr-CA': 'CA',
  'fr-CH': 'CH',
  'fr-FR': 'FR',
  'fr-LU': 'LU',
  'fr-MC': 'MC',
  'fy': 'NL',
  'ga': 'IE',
  'gd': 'GB',
  'gl': 'ES',
  'gu': 'IN',
  'ha': 'NG',
  'haw': 'US',
  'he': 'IL',
  'he-IL': 'IL',
  'hi': 'IN',
  'hr': 'HR',
  'hr-BA': 'HR',
  'ht': 'HT',
  'hu': 'HU',
  'hu-HU': 'HU',
  'hy': 'AM',
  'id': 'ID',
  'ig': 'NG',
  'is': 'IS',
  'it': 'IT',
  'it-CH': 'CH',
  'it-IT': 'IT',
  'ja': 'JP',
  'ja-JP': 'JP',
  'jv': 'ID',
  'ka': 'GE',
  'kk': 'KZ',
  'km': 'KH',
  'kn': 'IN',
  'ko-KP': 'KP',
  'ko-KR': 'KR',
  'ky': 'KG',
  'lb': 'LU',
  'lo': 'LA',
  'lt': 'LT',
  'lt-LT': 'LT',
  'lv': 'LV',
  'lv-LV': 'LV',
  'mg': 'MG',
  'mi': 'NZ',
  'mk': 'MK',
  'mk-MK': 'MK',
  'mn': 'MN',
  'mo': 'MD',
  'mr': 'IN',
  'mt': 'MT',
  'my': 'MM',
  'nb': 'NO',
  'ne': 'NP',
  'nl': 'NL',
  'nl-BE': 'BE',
  'nl-NL': 'NL',
  'nn': 'NO',
  'nn-NO': 'NO',
  'no': 'NO',
  'pl': 'PL',
  'pl-PL': 'PL',
  'ps': 'AF',
  'pt': 'PT',
  'pt-BR': 'BR',
  'pt-PT': 'PT',
  'qu': 'PE',
  'rm': 'CH',
  'ro': 'RO',
  'ro-MD': 'MD',
  'ro-RO': 'RO',
  'ru': 'RU',
  'ru-MD': 'MD',
  'rw': 'RW',
  'sd': 'PK',
  'si': 'LK',
  'sk': 'SK',
  'sk-SK': 'SK',
  'sl': 'SI',
  'sl-SI': 'SI',
  'sm': 'AS',
  'sn': 'ZW',
  'so': 'SO',
  'sq': 'AL',
  'sr': 'CS',
  'sr-Latn-RS': 'CS',
  'su': 'ID',
  'sv': 'SE',
  'sv-FI': 'FI',
  'sv-SE': 'SE',
  'sv-SV': 'SV',
  'te': 'IN',
  'th': 'TH',
  'tk': 'TM',
  'tn': 'ZA',
  'to': 'TO',
  'tr': 'TR',
  'tr-TR': 'TR',
  'tw': 'GH',
  'uk': 'UA',
  'uk-UA': 'UA',
  'uz': 'UZ',
  'vi': 'VN',
  'wa': 'BE',
  'yi': 'IL',
  'yo': 'NG',
  'zh': 'CN',
  'zh-CN': 'CN',
  'zh-HK': 'HK',
  'zh-SG': 'SG',
  'zh-TW': 'TW'
};
