/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  FlowUtilsService,
  FlowCmsService,
  FlowEnvService,
  FlowHelpers,
  FlowModelsService,
  FlowProductModelInterface
} from '@flow/core';

import { FlowAuthService } from '../auth/auth.service';
import { FlowTokensService } from '../tokens/tokens.service';
import { FlowUserService } from '../user/user.service';
import { FlowPasswordChangeHashInterface } from '../../interfaces/password-change-hash.interface';

@Injectable({
  providedIn: 'root'
})
export class FlowSsoService {

  /** Store the request to fetch the partner on boarding based on subDomain */
  private onBoardingFormResult: any;

  constructor(
    private httpClient: HttpClient,
    private UtilsService: FlowUtilsService,
    private CmsService: FlowCmsService,
    private EnvService: FlowEnvService,
    private ModelsService: FlowModelsService,
    private AuthService: FlowAuthService,
    private TokensService: FlowTokensService,
    private UserService: FlowUserService
  ) { }

  /**
   * Create an account in FLOW
   */
  registerAccount(form: UntypedFormGroup): Observable <boolean|HttpErrorResponse> {
    const source = this.AuthService.vendor;
    let sourceParam = '';

    if (! this.AuthService.isDefaultVendor(source)) {
      sourceParam = `&source=${source}`;
    }

    return this
      .CmsService
      .post(`sso/customers?version=2${sourceParam}`, form.value)
      .pipe(
        map(() => true),
        catchError(err => of(err))
      );
  }

  /**
   * Send a request to change password
   */
  changePassword(values: any, options: any = {}): Observable <boolean|HttpErrorResponse> {
    const queryParams = `?version=2&project=${this.EnvService.portalDomain.toLowerCase()}`;

    // Changing password from profile form needs skipAuth: false (it also checks the current password)
    // Changing password from email (password recovery) needs skipAuth: true
    const skipAuth = (FlowHelpers.hasProperty(options, 'skipAuth') && options['skipAuth'] === true)
                     ? true
                     : false;

    // Changing password from email additionally needs FlowPasswordToken as extra header
    const headers = (FlowHelpers.hasProperty(options, 'recoveryToken') && options['recoveryToken'] !== '')
                    ? { 'FlowPasswordToken': options['recoveryToken'] }
                    : {};

    return this.CmsService
      .post(`sso/customers/${values.customerId}/users/${values.userId}/password${queryParams}`, {
        old: values.currentPassword,
        nw: values.repeatNewPassword,
        token: values.token,
        skipAuth
      },
      '',
      headers
      )
      .pipe(
        map(() => true),
        catchError(error => of(error))
      );
  }

  /**
   * Forgot password
   */
  requestPassword(data: {email: string; recaptcha: string; recoveryEmail?: string }): Observable <any> {
    return this.CmsService
      .delete<string>
      (`sso/customers/users/${data.email}/password`, {
        project: this.EnvService.portalDomain.toLowerCase(),
        version: 2,
        skipAuth: true,
        gtoken: data.recaptcha,
        recoveryEmail: data.recoveryEmail
      })
      .pipe(
        map(result => result),
        catchError(err => of(err))
      );
  }

  /**
   * Get the onboarding configuration for a given project.
   */
  getOnBoardingFormForPartners(project?: string, force = false, skipAuth = true): Observable <any|boolean> {
    project = project || this.EnvService.portalDomain;

    if (!force && this.onBoardingFormResult) {
      return of(this.onBoardingFormResult);
    }

    if (project) {
      return this.CmsService
      .get<any>(`${this.ModelsService.getModel('SsoCustomers')}/onboardingform/${project}`, { skipAuth })
      .pipe(
        map(data => {
          const isStrTrue = s => ((s && s.toLowerCase() === 'true') || false);
          const parsed = (data.onboardingform) ? data.onboardingform.split('|') : [];
          const result = {
            url: parsed.length ? parsed[0] : false,
            title: parsed.length ? parsed[1] : false,
            partnerRecruit: data.partnerRecruit || false,
            hideRegisterLink: (data.hideRegisterLink && 'true' === data.hideRegisterLink.toLowerCase()),
            disableLogin: isStrTrue(data.disableLogin),
            showZendeskForPartners: isStrTrue(data.showZendeskForPartners),
            strictCompanyValidation: isStrTrue(data.strictCompanyValidation),
            isSupplierPortal: isStrTrue(data.isSupplierPortal),
            ProjectType: data.ProjectType || '',
            CompanyName: data.CompanyName || '',
            hideFAQLink: isStrTrue(data.hideFAQLink),
            FAQLink: data.FAQLink || ''
          };

          this.onBoardingFormResult = result;

          return result;
        }),
        catchError(() => of(false))
      );
    }
    else {
      return of(false);
    }
  }

  /**
   * Returns a product link to be loaded in an iframe.
   */
  createProductLink(productId: string, args?: {
    redirect?: boolean;
    pid?: boolean | string;
    urlQueryParams?: any;
    project?: string;
    t?: number;
    local?: boolean;
  }): Observable <string> {
    if (! productId) {
      throw new ReferenceError(`Product ID was not supplied.`);
    }

    if (! args || ! FlowHelpers.isObject(args)) {
      args = {};
    }

    // Append timestamp.
    args.t = FlowHelpers.timestamp();

    // Check if the current user is acting as a partner.
    if (this.UserService.isActingAsPartner()) {
      args.project = this.AuthService.vendor;
    }

    // Url query Parameters.
    if (FlowHelpers.hasProperty(args, 'urlQueryParams')) {

      if (! FlowHelpers.isObject(args.urlQueryParams)) {
        throw new TypeError(`urlQueryParams must be an Object.`);
      }

      for (const z in args.urlQueryParams) {
        if (Object.prototype.hasOwnProperty.call(args, z)) {
          args[z] = args.urlQueryParams[z];
        }
      }

      delete args.urlQueryParams;
    }

    // if local ..
    if (this.EnvService.get('local')) {
      args.local = true;
    }


    return this.CmsService.get<string>
    (`${this.ModelsService.getModel('ShareLinkId')}/${productId}`, args, {}, {
      responseType: 'text'
    }).pipe(
      catchError(() => of(null))
    );
  }

  /**
   * Returns full einvo deep link based on given path
   */
  getEinvoDeepLink(path: string, args?: any): Observable<string> {
    if (!path) {
      throw new ReferenceError(`Path was not supplied.`);
    }

    const defaults = {
      isPartner: false,
      redirect: false,
      pid: false,
      urlQueryParams: {}
    };

    args = Object.assign(defaults, args);

    return this.createProductLink('einvo', args)
    .pipe(
      map(link => new URL(link)),
      switchMap(link => {
        let mode = '&mode=accept';

        if (this.EnvService.isAcceptance || this.EnvService.isPreAccept) {
          mode = '&mode=test';
        }
        else if (this.EnvService.isProduction) {
          mode = '';
        }

        const encodedLink = encodeURIComponent(
          link.protocol + '//' + link.hostname + link.pathname +
          '?accessToken={{accessToken}}&refreshToken={{refreshToken}}' +
          mode +
          '&' + path
        );

        return this.CmsService
        .get<string>(`${this.ModelsService.getModel('ShareLink')}?link=${encodedLink}`, {}, {}, {responseType: 'text'});
      }),
      catchError(() => of(null))
    );
  }

  /**
   * Get user data for reset password
   */
  getResetPasswordUserData(token: string): Observable<FlowPasswordChangeHashInterface|any> {
    const urlParams = new URLSearchParams(window.location.search);

    let endpoint = `sso/customers/users/${token}/password/userData`;

    if (urlParams.has('lang')) {
      const lang = urlParams.get('lang');

      // Check for allowed lang
      if (lang && lang === this.UtilsService.getLanguageByCode(lang)) {
        endpoint = `${endpoint}?lang=${lang}`;
      }
    }

    return this.CmsService.get<string>(endpoint)
      .pipe(
        map(result => result),
        catchError(error => of(error.error))
      );
  }

  /**
   * Get product token link from product attributes
   */
   getProductTokenLink(product: FlowProductModelInterface): string|undefined {
    const productTokenLink = product && product.attrs.find(attr => attr.id === 'productTokenLink');

    if (productTokenLink) {
      const { value } = productTokenLink;

      if (value.startsWith('https://')) {
        return value;
      }
    }

    return undefined;
  }

  /**
   * Call productTokenLink (product attribues property) endpoint
   * to init child app authorization
   */
  initChildAppAuthorization(productTokenLink: string): Observable<any> {
    const tokens = this.TokensService.getTokens();

    if (productTokenLink && tokens) {
      return this.httpClient.post(`${productTokenLink}`, {
        token: tokens.token,
        refresh: tokens.refreshToken
      }, {
        responseType: 'text',
        withCredentials: true
      })
      .pipe(
        catchError(() => FlowHelpers.catchAsNull())
      );
    }
    else {
      return FlowHelpers.catchAsNull();
    }
  }
}
