/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 *
 * @description
 * Ng wrapper component for the 3rd party {@link https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/ Cloudflare Turnstile} (Captcha alternative).
 * NOTE:
 * This ng wrapper is initially based on ngx-turnstile (which did not compile on FLOW's ng version) and
 * modified/extended to support (default) config and ngForms.
 */

import {
  Component,
  AfterViewInit,
  ElementRef,
  Input,
  NgZone,
  Output,
  EventEmitter,
  OnDestroy, forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { TurnstileOptions } from './turnstile-options.interface';
import { TURNSTILE_SITEKEY, TURNSTILE_THEME } from './turnstile.config';

declare global {
  interface Window {
    onloadTurnstileCallback: () => void;
    turnstile: {
      render: (
        idOrContainer: string | HTMLElement,
        options: TurnstileOptions
      ) => string;
      reset: (widgetIdOrContainer: string | HTMLElement) => void;
      getResponse: (
        widgetIdOrContainer: string | HTMLElement
      ) => string | undefined;
      remove: (widgetIdOrContainer: string | HTMLElement) => void;
    };
  }
}

const CALLBACK_NAME = 'onloadTurnstileCallback';
type SupportedVersion = '0';

@Component({
  selector: 'flow-turnstile',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlowTurnstileComponent),
      multi: true
    }
  ]
})
export class FlowTurnstileComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {

  @Input() siteKey: string = TURNSTILE_SITEKEY;
  @Input() action?: string;
  @Input() cData?: string;
  @Input() theme?: 'light' | 'dark' | 'auto' = TURNSTILE_THEME || 'auto';
  @Input() version: SupportedVersion = '0';
  @Input() tabIndex?: number;

  @Output() resolved = new EventEmitter<string | null>();

  private _value;
  private widgetId!: string;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private zone: NgZone,
  ) { }

  public ngAfterViewInit(): void {
    const turnstileOptions: TurnstileOptions = {
      sitekey: this.siteKey,
      theme: this.theme,
      tabindex: this.tabIndex,
      action: this.action,
      cData: this.cData,
      callback: (token: string) => {
        this.zone.run(() => this.value = token);
      },
      'expired-callback': () => {
        this.zone.run(() => this.value = null);
      },
    };

    const script = document.createElement('script');

    window[CALLBACK_NAME] = () => {
      if (!this.elementRef?.nativeElement) {
        return;
      }

      this.widgetId = window.turnstile.render(
        this.elementRef.nativeElement,
        turnstileOptions
      );
    };

    script.src = `${ this._getCloudflareTurnstileUrl() }?render=explicit&onload=${ CALLBACK_NAME }`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  }

  public ngOnDestroy(): void {
    window.turnstile.remove(this.widgetId);
  }

  public set value(value) {
    if (value !== this._value) {
      this._value = value;

      // update via form if any
      this.propagateChange(value);

      // update via event listener if any
      this.resolved.emit(value);
    }
  }

  public propagateChange: any = () => {
    //
  }
  public propagateTouched: any = () => {
    //
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public writeValue() {
    // NOTE:
    //    This method is only here to comply with ng ControlValueAccessor api, however it's not actually used by us, as
    //    we cannot set value on the 3rd party Turnstile widget, aka we can only read, not write.
  }

  private _getCloudflareTurnstileUrl(): string {
    if (this.version === '0') {
      return 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    }

    throw 'flow-turnstile: Version not supported.';
  }
}
