import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nameNotAllowedCharsValidator(): ValidatorFn {
  const INVALID_CHARS = [
    '!',
    '?',
    '~',
    '@',
    '#',
    '$',
    '%',
    '*',
    '(',
    '{',
    '}',
    ')',
    '=',
    '+',
    ':',
    ';',
    ',',
    '"',
    '&',
    '\'',
    '[',
    ']',
    '/',
    '\\',
    '|',
    '^',
    '`',
    '<',
    '>',
    '€',
  ];

  const _escapeChars = s => s.replace(/[-[\]{}()*+?.,\\^$|#\\]/g, '\\$&');

  const notAllowedCharsRegex = new RegExp(INVALID_CHARS.map(_escapeChars).join('|'), 'g');

  return (control: AbstractControl): {[key: string]: any} | null => {
    const { value } = control;

    if (!value) {
      return null;
    }

    const match = value.match(notAllowedCharsRegex);
    const valid = !match;

    return !valid
      ? {'nameNotAllowedCharacters': {value, chars: INVALID_CHARS }}
      : null;
  };
}
