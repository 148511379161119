/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { DataTableDirective } from 'angular-datatables';
import { Config } from 'datatables.net';

import { FlowTranslateService } from '@flow/translate';

import { FlowDatatableService } from '../../services/datatable.service';

@Component({
  selector: 'flow-raw-datatable',
  templateUrl: './raw-datatable.component.html',
  styleUrls: ['../../styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class FlowRawDatatableComponent implements OnInit {
  @Input() dtOptions: Config;

  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  private _defaultOptions: Config;

  constructor(
    private TranslateService: FlowTranslateService,
    private DatatableService: FlowDatatableService
  ) { }

  ngOnInit(): void {
    // Preloading the default labels for the datatable
    this.TranslateService.translate([
      'general.datatable.info',
      'general.datatable.info_empty',
      'general.datatable.info_filtered',
      'general.datatable.display_records',
      'general.datatable.loading_records',
      'general.datatable.processing',
      'general.datatable.zero_records',
      'general.datatable.clickable',
    ])
    .subscribe(
      (labels) => {
        this.DatatableService.defaultLabels = labels;

        this._defaultOptions = this.DatatableService.getDefaultOptions();

        this.dtOptions = { ...this._defaultOptions, ...this.dtOptions };
      }
    );
  }
}
