/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { FlowAuthService, FlowUserService } from '@flow/auth';
import { FlowUtilsService } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';

import {
  FlowWebsocketNotificationMessageExtrasInterface,
  FlowWebsocketNotificationMessageInterface
} from '@flow/shared';

const REGEX = {
  placeholders: /{{\s?([^{}\s]*)\s?}}/g,
  brackets: /[{{}}]/g,
};

const CUSTOMER_EXTRAS_REPLACEMENT_CODES = [
  'QuotaType',
  'QuotaValue',
  'SerialNumber',
  'CompanyID',
  'CompanyName',

  /* for DEBUG only */
  'dummy_DebugReplacementCode',
  'missingExtra',
];
const SUPPORTED_SEPARATORS = [
  '_',  // (DEFAULT)
  '.',  // (for LEGACY support)
];

@Injectable({
  providedIn: 'root'
})
export class FlowNotificationsService {

  flattenRootDefaultReplacementCodes = this._flattenRootDefaultReplacementCodes.bind(this); // must be done this way, otherwise scope issues
  getDefaultReplacementCodes = this._getDefaultReplacementCodes.bind(this); // must be done this way, otherwise scope issues

  private _baseReplacementCodes;
  private _notifications$$: Subject<FlowWebsocketNotificationMessageInterface[]> = new Subject();

  private _observable$: Observable<FlowWebsocketNotificationMessageInterface[]> = this._notifications$$
  .asObservable()
  .pipe(
    shareReplay(1)
  );

  constructor(
    private AuthService: FlowAuthService,
    private UserService: FlowUserService,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService,
  ) {
    this._baseReplacementCodes = this.DEFAULT_REPLACEMENT_CODES;
  }

  get DEFAULT_REPLACEMENT_CODES() {  // based on ST032094
    /* NOTE: "--" values r just to detect if a translation is missing one or more values */

    const codes = {
      // project id
      project:          this.AuthService.vendor || '--project',

      // full project name
      projectName: (
        this.UserService.isActingAsPartner()
          ? this.UserService.vendorDescription
          : (this.UserService.customer.Description || this.UserService.customer.Name)
        )
        || '--projectName',

      // company id
      'company.id':     this.UserService.customer.Id || '--company.id',

      // company Description with fallback to Name
      'company.name':   this.UserService.customer.Description || this.UserService.customer.Name || '--company.name',

      // user GivenName
      'user.firstname': this.UserService.getProperty('firstName') || '--user.firstname',

      // user FamilyName
      'user.lastname':  this.UserService.getProperty('lastName') || '--user.lastname',

      // user "GivenName FamilyName"
      'user.fullname':  this.UserService.fullName || '--user.fullname',

      // incl support for replacement codes that comes from the customer extra
      ...this._getCustomerExtrasReplacementCodes(),
    };

    // return this._markMissingCodeValues(codes);
    return codes;
  }

  set(value: FlowWebsocketNotificationMessageInterface[]) {
    if (!value) {
      return;
    }

    this._notifications$$.next(value);
  }

  get(): Observable<FlowWebsocketNotificationMessageInterface[]> {
    return this._observable$;
  }

  _getDefaultReplacementCodes(messageExtras: FlowWebsocketNotificationMessageExtrasInterface[]) {
    const codes = { ...this._baseReplacementCodes };

    if (messageExtras) {
      const messageExtrasCodes = {
        // incl message extras as replacement codes (if any)
        ...this._getMessageExtrasReplacementCodes(messageExtras),
      };

      this._safeExtendWithTruthyOnly(codes, messageExtrasCodes);
    }

    return this._markMissingCodeValues(codes);
  }

  /**
   * This will do a direct replacement of default replacement codes at root level of the text.
   * e.g. `Hi {{user.firstname}}, Welcome!` --> `Hi John, Welcome!`
   *
   * Any non-matches placeholders will remain untouched.
   * e.g. `Hi {{user.unsupported_code}}, Welcome!` --> `Hi {{user.unsupported_code}}, Welcome!`
   * e.g. `Hi {{user.firstname}}, {{some.translation.label}}.` --> `Hi John, {{some.translation.label}}.`
   *
   * If the root text is a placeholder with a translation label e.g.
   * `{{email.request_notification.debug.replacement_codes}}`, then it will first try to translate the label with the
   * replacements as params in order to handle the nested placeholders.
   *
   * NOTE: this method is intended to be called BEFORE passing the text to the normal translation handler
   */
  private _flattenRootDefaultReplacementCodes(text, messageExtras: FlowWebsocketNotificationMessageExtrasInterface[]) {
    const hasPlaceholders = text.match(REGEX.placeholders);
    let result = text;

    if (hasPlaceholders) {
      const codes = this._getDefaultReplacementCodes(messageExtras);

      result = text.replace(
        REGEX.placeholders,
        (match) => {
          const key = match.replace(REGEX.brackets, '');

          let output = '';
          const translationOutput = this.TranslateService.instant({ key, params: codes });

          if (translationOutput === key) {  // was not a translation key, we need try for replacement-codes
            output = codes[key];
          }
          else {  // key was translated
            // recursively call _flattenRootDefaultReplacementCodes to chk for nested placeholders
            output = this._flattenRootDefaultReplacementCodes(translationOutput, messageExtras);
          }

          return output !== undefined ? output : match;
        }
      );
    }

    return result;
  }

  private _getCustomerExtrasReplacementCodes() {
    const output = CUSTOMER_EXTRAS_REPLACEMENT_CODES.reduce((result, extraKey) => {
      SUPPORTED_SEPARATORS.forEach(separator => {
        const replacementCodeKey = `extra${ separator }${ extraKey }`;
        const replacementCodeValue = this._getDefaultReplacementCodesFromCustomerExtras(replacementCodeKey);

        result[replacementCodeKey] = replacementCodeValue;
      });

      return result;
    }, {});

    return output;
  }

  private _getMessageExtrasReplacementCodes(messageExtras: FlowWebsocketNotificationMessageExtrasInterface[]) {
    const output = messageExtras.reduce((result, { id, value }) => {
      SUPPORTED_SEPARATORS.forEach(separator => result[ `extra${ separator }${ id }` ] = value);

      return result;
    }, {});

    return output;
  }

  private _markMissingCodeValues(codes) {
    /* NOTE: "--" values r just to detect if a translation is missing one or more values */

    Object.keys(codes).forEach(key => !codes[key] && (codes[key] = `--${ key }`));

    return codes;
  }

  private _getDefaultReplacementCodesFromCustomerExtras(extra) {
    const { ExtendedProperties } = this.UserService.customer;

    return this.UtilsService.getExtraValue(ExtendedProperties, extra);
  }

  /**
   * Extend one (target) object with another (source) object (similar to angular.extend), but only source props that
   * have actual (truthy) value will overwrite the target ones.
   *
   * @param target
   * @param source
   * @private
   */
  private _safeExtendWithTruthyOnly(target, source) {
    const sourceKeys = Object.keys(source);

    sourceKeys.forEach(key => source[key] && (target[key] = source[key]));

    return target;
  }
}
