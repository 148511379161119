<ng-container [formGroup]="control">
  <div class="row">
    <mat-form-field class="col-12 col-md-3">
      <mat-label>{{ labels['forms.label.field_name'] }}</mat-label>
      <input matInput formControlName="fieldName">
    </mat-form-field>

    <mat-form-field class="col-12 col-md-3">
      <mat-label>{{ labels['forms.label.data_type'] }}</mat-label>
      <mat-select formControlName="dataType">
        <mat-option
          *ngFor="let dataType of dataTypes"
          [value]="dataType.id">
          {{ dataType.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="col-12 col-md-3">
      <mat-label>{{ labels['forms.label.label'] }}</mat-label>
      <input matInput formControlName="label">
    </mat-form-field>

    <mat-form-field class="col-12 col-md-3">
      <mat-label>{{ labels['forms.label.value'] }}</mat-label>
      <input matInput formControlName="value">
    </mat-form-field>
  </div>
</ng-container>
