import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS} from '@angular/common/http';

import { FlowCoreConfigService } from './services/env/env.service';
import { FlowRequestsInterceptor } from './interceptors/requests.interceptor';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class FlowCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: FlowCoreModule) {
    // console.log('FlowCoreModule ::: flow-ng17-core');
    if (parentModule) {
      throw new Error(
        'FlowCoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: FlowCoreConfigService): ModuleWithProviders<FlowCoreModule> {
    return {
      ngModule: FlowCoreModule,
      providers: [
        {
          provide: FlowCoreConfigService,
          useValue: config
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: FlowRequestsInterceptor,
          multi: true
        }
      ]
    };
  }
}
