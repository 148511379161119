<ng-container *ngIf="onlineUsers$ | async as onlineUsers">
  <div class="chat-wrap" [hidden]="!isVisible">
    <div class="d-flex align-items-center justify-content-between py-2 px-3 bg-primary color-white">
      <div>
        <h5 class="color-white truncate">Chat</h5>
      </div>
      <button matTooltipPosition="left"
              [matTooltip]="labels['general.btn.close']"
              (click)="close.emit()"
              class="button button-icon button-white-outline button-no-border">
        <i class="material-icons animated rotateIn">close</i>
      </button>
    </div>
    <mat-tab-group
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      [class.tabs-full-height]="true"
      [class.tabs-small-label-width]="true"
      [disableRipple]="true"
      [selectedIndex]="selectedIndex"
    >
      <mat-tab [label]="labels['chat.label.chats']">
        <flow-chat-conversations
          [onlineUsers]="onlineUsers"
          [currentConversation]="currentConversation"
          [conversations]="conversations$ | async"
          (conversationOpened)="currentConversation = $event"
        ></flow-chat-conversations>
      </mat-tab>
      <mat-tab [label]="labels['chat.label.search_companies']">
        <ng-template matTabContent>
          <flow-chat-search
            (conversationOpen)="startConversation($event)"
          ></flow-chat-search>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <flow-chat-box
    *ngIf="currentConversation"
    [isOnline]="onlineUsers.indexOf(currentConversation.to) > -1"
    [conversation]="currentConversation"
    [isChatOpen]="isVisible"
    (closeChat)="currentConversation = null"
  ></flow-chat-box>
</ng-container>
