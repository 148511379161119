import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { finalize } from 'rxjs/operators';

import { FlowAuthService } from '@flow/auth';
import { FlowRouterService } from '@flow/core';

@Component({
  selector: 'flow-authorize',
  template: `
    <p class="text-center mt-lg-5 pt-lg-5 pb-lg-4"
       [ngClass]="{'color-danger': authFailed}"
    >
      Authorizing<span *ngIf="loading">...</span><span *ngIf="authFailed"> failed!</span>
    </p>
    <flow-preloader [hidden]="!loading"></flow-preloader>
  `,
})
export class FlowAuthorizeComponent {

  channel = '';
  loading = true;
  authFailed = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private AuthService: FlowAuthService,
    private RouterService: FlowRouterService,
  ) {
    const { channel } = activatedRoute.snapshot.params; // NOTE: if "channel" route param deprecated, then use this.EnvService.portalDomain
    const { e, s, logoutUrl } = activatedRoute.snapshot.queryParams;

    this.channel = channel;

    // clear old user/tokens if any
    AuthService.logout();

    // store logoutUrl
    if (logoutUrl) {
      AuthService.logoutRedirectUrl = logoutUrl;
    }

    // authenticate user
    AuthService.authorize(channel, {e, s})
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result) => {
        // console.warn('______ngShared AuthService.authorize.subscribe result', result);

      if (result) { // auth success
        // console.warn('______ngShared auth SUCCESS', );

        // Tracking logged in user
        // TrackingService.loginUser();

        // Go to logged in route
        RouterService.navigate(AuthService.afterLoginUrl);
      }
      else {  // auth failed
        // console.warn('______ngShared auth FAILED', );

        this.authFailed = true;
      }
    });
  }
}
