/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowEnvService, FlowRouterService } from '@flow/core';
import { FlowAuthService, FlowSsoService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowPortalService } from '../../../../../services/portal/portal.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { FlowLayoutService } from '../../../../../services/layout/layout.service';
import { FlowMediaService } from '../../../../../services/media/media.service';

const DEFAULT_FAQ_LINK_LABEL_ID = 'general.faq.link.default_url';

@Component({
  selector: 'flow-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowAuthComponent implements OnInit, OnDestroy {

  authTitle$: Observable<string>;
  authError$: Observable<string>;
  faqLink$: Observable<string>;

  navSubscription$$: Subscription;

  defaultBackgroundUrl: string;

  currentSourceBackgroundUrl: string;

  labels: FlowTranslateLabel;

  partnersOnBoardingData: any;

  hideRegisterLink = false;
  showPartnerRegisterLink = false;
  hasOnboardingRegisterLink = false;
  hasInternalOnboarding = false;
  hideLegacyLink: boolean;

  originalPortalUrl: string;
  ssoLoginLink: { url: string; label: string; logo: string };

  pageClass: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private EnvService: FlowEnvService,
    private RouterService: FlowRouterService,
    private AuthService: FlowAuthService,
    private SsoService: FlowSsoService,
    private TranslateService: FlowTranslateService,
    private PortalService: FlowPortalService,
    private DialogsService: FlowDialogsService,
    private LayoutService: FlowLayoutService,
    private MediaService: FlowMediaService
  ) {
    const source = this.AuthService.vendor;

    this.defaultBackgroundUrl = this.MediaService.getVendorPortalBackground('Flow');
    this.currentSourceBackgroundUrl = this.PortalService.background;

    if (!this.EnvService.isVendorPortal) {
      if (!this.AuthService.isDefaultVendor(source)) {
        this.currentSourceBackgroundUrl = this.MediaService.getVendorPortalBackground(source);
      }
    }

    this.ssoLoginLink = this.PortalService.getSsoLoginLink();

    // BL: Remove this logic completely until no customer is using V1 anymore
    /*
    this.originalPortalUrl = this.PortalService.getPortalUrl(source);
    this.hideLegacyLink = this.PortalService.currentPortalDashboard.hideLegacyLink
                          ? !!this.PortalService.currentPortalDashboard.hideLegacyLink
                          : false;
    */
    this.hideLegacyLink = true;
  }

  get isLoginPage(): boolean {
    return this.RouterService.isPath('/login');
  }

  get isRegisterPage(): boolean {
    return this.RouterService.isPath('/register');
  }

  get isForgotPasswordPage(): boolean {
    return this.RouterService.isPath('/forgot-password');
  }

  get isChangePasswordPage(): boolean {
    return this.RouterService.isPath('/change-password');
  }

  ngOnInit(): void {
    this.navSubscription$$ = this.RouterService.currentFragment$.subscribe(path =>{
      this.pageClass = path.split('/')[2];
    });

    this.labels = this._translateLabels();

    this.partnersOnBoardingData = this.activatedRoute.snapshot.data.onBoardingForm;

    if (this.partnersOnBoardingData) {
      this.hideRegisterLink = this.partnersOnBoardingData.hideRegisterLink;
      this.showPartnerRegisterLink = this.partnersOnBoardingData.showPartnerRegisterLink;
      this.hasOnboardingRegisterLink = !!this.partnersOnBoardingData.registerLink;
      this.hasInternalOnboarding = this.partnersOnBoardingData.internalOnboarding;
    }

    this.authTitle$ = this.activatedRoute.url.pipe(
      map(() =>  {
        const authTitle = this.activatedRoute.snapshot.firstChild.data.authTitle;

        return authTitle
          ? this.labels[authTitle]
          : this.PortalService.welcomeTitle === 'login.login_form.headline'
            ? this.labels['login.login_form.headline']
            : this.PortalService.welcomeTitle;
      })
    );

    this.authError$ = this.LayoutService.lastError$.pipe(
      map(value => this.labels[value] || value)
    );

    this.faqLink$ = this.SsoService.getOnBoardingFormForPartners().pipe(map(this._getFaqLink.bind(this)));
  }

  ngOnDestroy(): void {
    this.navSubscription$$.unsubscribe();
  }

  openHelp(): void {
    const popupWidth = window.screen.width * 0.7;
    const popupHeight = window.screen.height * 0.7;

    this.DialogsService.simpleUrlPopup('https://tiekinetix.zendesk.com/knowledge/articles/360052625672', 'help', popupWidth, popupHeight);
  }

  openModal(
    title = 'general.privacy_policy.headline',
    content = 'general.privacy_policy.content'
  ): void {
    this.DialogsService.textModal({
        title,
        content,
        showOkButton: false
    });
  }

  openPrivacyModal(): void {
    if (this.AuthService.isDefaultVendor(this.AuthService.vendor)) {
      this.openModal();
    }
    else {
      this.DialogsService.textModalPrivacy();
    }
  }

  doRegister($event: Event): void {
    $event.preventDefault();

    if (this.partnersOnBoardingData) {
      if (this.partnersOnBoardingData['onBoardingError']) {
        this.DialogsService.textModal({
          title: 'general.label.warning',
          content: 'error.login.register_link_not_found',
          showOkButton: false
        });
      }
      else {
        this.router.navigate([
          this.hasInternalOnboarding
            ? '/auth/register'
            : this.partnersOnBoardingData.registerLink
        ]);

        // NOTE: there was an additional register option that relies on partnersOnBoardingData.showPartnerRegisterLink and would then link
        //        to partnersOnBoardingData.registerPartnerLink. This is deprecated now, since unclear if still used (Jv). IF needed,
        //        resurrect it from the git history.
      }
    }
    else {
      console.error('auth.component::doRegister - No partnersOnBoardingData');
    }  // we should never reach here
  }

  /**
   * ST043093: Get FAQ link based on project config
   * @param data from SsoService::getOnBoardingFormForPartners()
   */
  private _getFaqLink(data: any): string {
    const defaultFaqLink = this.labels[DEFAULT_FAQ_LINK_LABEL_ID];

    let link = '';
    let showLink = true; // default: show any existing faq link (default or project specific)

    if (data) {
      const { FAQLink, hideFAQLink} = data;

      !hideFAQLink && (link = FAQLink);
      hideFAQLink && (showLink = false); // to get access of this config outside of this block
    }

    // Set default link, if
    // - link should be shown
    // - no project specific link (FAQLink) is available (i.e. link = '')
    // - a label for the default link is available
    // A label is available, if translate service does not return the label id.

    (showLink && !link && defaultFaqLink !== DEFAULT_FAQ_LINK_LABEL_ID) && (link = defaultFaqLink);

    return link;
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labels = [
      'generic.request.failed',
      {
        key: 'login.label.customer_already_registered',
        params: {
          forgotPasswordLink: 'auth/forgot-password',
          contactSupportLink: 'public/support',
        }
      },
      'general.faq.link.label',
      'login.no_roles_assigned',
      'login.black_list',
      'login.invalid_grant',
      'login.user_locked_out',
      'login.no_credentials',
      'login.invalid.password_request',
      'login.login_form.headline',
      'login.label.recovery_email',
      'login.account_form.headline',
      'login.password_form.headline.forgot_password',
      'login.password_form.headline.set_password',
      'login.login_form.link.register_company',
      'login.login_form.link.register_user',
      'login.login_form.link.forgot_password',
      'login.login_form_link.original_portal',
      'login.footer.link.privacy',
      'login.footer.link.terms',
      'login.footer.link.system_status',
      'login.footer_legal',
      'login.login_form_link.register_partner',
      'login.error.two_factor_auth.default',
      'login.error.two_factor_auth_cancelled',
      'login.error.two_factor_auth_pin_code',
      'login.label.disabled_login',
      'login.error.two_factor_auth_cancelled',
      'login.error.customer_message_not_accepted',
      'login.aria.region.auth_links',
      'login.aria.region.auth_terms',
      'error.cms.temporary.emails'
    ];

    labels.push(DEFAULT_FAQ_LINK_LABEL_ID);

    return this.TranslateService.translateSync(labels);
  }
}
