import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FlowUserInterface } from '@flow/auth';

export function passwordNoPersonalInfoValidator(user: FlowUserInterface): ValidatorFn {
  const PERSONAL_PROP_MIN_LEN = 3;

  return (control: AbstractControl): {[key: string]: any} | null => {
    const { value } = control;

    if (!value) {
      return null;
    }

    const arrPersonalInfo = [];
    const { firstName, lastName, email, username } = user;

    if (firstName && firstName.length > PERSONAL_PROP_MIN_LEN) {
      arrPersonalInfo.push(firstName);
    }

    if (lastName && lastName.length > PERSONAL_PROP_MIN_LEN) {
      arrPersonalInfo.push(lastName);
    }

    if (email) {
      arrPersonalInfo.push(email);
    }

    if (username && username !== email) {
      arrPersonalInfo.push(username);
    }

    const personalInfoRegex = new RegExp(arrPersonalInfo.join('|'), 'gi');

    const match = value.match(personalInfoRegex);
    const valid = !match;

    return !valid ? {'passwordPersonal': {value}} : null;
  };
}
