import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnInit,
  SecurityContext,
  ViewChild
} from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlowSupportFormService } from '../../services';
import { FlowSupportForm, FlowSupportFormData, FlowSupportFormDialogData } from '../../interfaces';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

@Component({
  selector: 'flow-support-form',
  templateUrl: './support-form.component.html',
  styleUrls: ['./support-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowSupportFormComponent implements OnInit {

  @ViewChild('modalContainer') modalContainer: ElementRef;

  @HostBinding('style') style = 'position:relative; display:block';

  form: FormGroup<FlowSupportForm>;

  hasCustomHelpSite: boolean;

  formState = {
    saving: false,
    submitted: false,
    success: undefined
  };

  hideUploadErrorToggle = false;

  private _attachments: File[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FlowSupportFormDialogData,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<FlowSupportFormComponent>,
    private formBuilder: FormBuilder,
    private SupportFormService: FlowSupportFormService
  ) {
    this._createForm();
  }

  ngOnInit(): void {
    this.hasCustomHelpSite = this.SupportFormService.getIfHasCustomHelpSite(this.data.options);
  }

  hasFormError(controlName: string): boolean {
    const control = this.form.get(controlName);

    return control.invalid && (control.dirty || control.touched || this.formState.submitted);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onFiles($event: File[]): void {
    this._attachments = $event;
  }

  onRetry(): void {
    this.formState.success = undefined;
  }

  onSubmit(): void {
    // Set the form state as submitted to show the errors
    this.formState.submitted = true;

    // Reset any previous upload errors
    this.hideUploadErrorToggle = !this.hideUploadErrorToggle

    // Trim and sanitize the input fields
    this.form.controls.issue.setValue(this._sanitizeInput(this.form.controls.issue.value.trim()));
    this.form.controls.tradingPartners.setValue(this._sanitizeInput(this.form.controls.tradingPartners.value.trim()));

    // Form is valid, proceed with submission
    if (this.form.valid) {
      // Set the form state as saving to show the spinner
      this.formState.saving = true;

      this.SupportFormService.submitForm(
        this.form.value as FlowSupportFormData,
        this._attachments,
        this.data.tokens,
        this.data.config.env
      )
      .subscribe({
        next: () => {
          this.formState = {
            saving: false,
            submitted: false,
            success: true
          };

          setTimeout(() => this.changeDetectorRef.detectChanges());
        },
        error: () => {
          this.formState = {
            saving: false,
            submitted: false,
            success: false
          }

          setTimeout(() => this.changeDetectorRef.detectChanges());
        }
      });
    }
    // Form is invalid, scroll the modal to the top
    else {
      this.modalContainer.nativeElement.scrollTop = 0;
    }
  }

  openCustomHelpSite(): void {
    this.SupportFormService.openCustomHelpSite(this.data.options.customHelpSite, this.data.labels, this.dialogRef);
  }

  private _createForm(): void {
    this.form = this.formBuilder.group({
      contactName: [ this.data.config.contactName, [ Validators.required ] ],
      email: [ this.data.config.contactEmail, [ Validators.required, Validators.pattern(EMAIL_REGEX)]],
      additionalEmails: ['',  { validators: [this._validateCommaSeparatedEmails()], updateOn: "blur" }],
      issue: [ '', [ Validators.required ] ],
      tradingPartners: ['', [ Validators.required ]],
      percentage: [ '', [ Validators.required ] ],
      dailyBusiness: [ '', [ Validators.required ] ],
      criticalBusiness: [ '', [ Validators.required ] ]
    });
  }

  private _sanitizeInput(value: string): string {
    // Use the DomSanitizer to sanitize the input
    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, value);

    // FLOW-269: German umlauts and new line characters will be automatically encoded as HTML entities by the sanitizer.
    // Replace HTML entities with their corresponding characters.
    const textarea = document.createElement('textarea');

    textarea.innerHTML = sanitizedValue || '';

    return textarea.value;
  }

  private _validateCommaSeparatedEmails(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }

      const emails = control.value.split(',').map((email: string) => email.trim());
      const invalidEmails = emails.filter((email: string) => !EMAIL_REGEX.test(email));

      return invalidEmails.length > 0 ? { 'invalidAdditionalEmails': invalidEmails } : null;
    };
  }
}
