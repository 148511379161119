/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { FlowKeyValueStringInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

import { TFA, TFA_LABELS } from './constants/2fa';

const DEFAULT_TFA_LINK_LANGUAGE = 'en';
const TFA_LINK_SUPPORTED_LANGUAGES = {
  // lookup lang -> link url lang
  en: 'en-us',
  nl: 'nl',
  de: 'de',
  fr: 'fr',
};

@Injectable({
  providedIn: 'root'
})
export class Flow2faService {

  private _TFA_HELP_LINK = lang => `https://support.tiekinetix.net/hc/${ lang }/articles/15351774412692-FLOW-2-Factor-Authentication-PLACEHOLDER`;

  constructor(
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
  ) { }

  get tfaMappings(): FlowKeyValueStringInterface {
    return TFA;
  }

  get tfaLabels(): FlowKeyValueStringInterface {
    return TFA_LABELS;
  }

  get tfaHelpLink(): string {
    const arrSupportedLanguages = Object.keys(TFA_LINK_SUPPORTED_LANGUAGES);
    const currentLanguage = this.TranslateService.getCurrentLanguage().toLowerCase();
    const matchFn = lang => currentLanguage.includes(lang);

    const language = TFA_LINK_SUPPORTED_LANGUAGES[ arrSupportedLanguages.find(matchFn) || DEFAULT_TFA_LINK_LANGUAGE ];

    return this._TFA_HELP_LINK(language);
  }

  /**
   * Check if current user has 2FA activated
   */
  isUsing2Fa(): any {
    return this.UserService.user.extra.find(extra =>
      extra.PropertyName === TFA.yubico
      ||
      extra.PropertyName === TFA.googleAuthenticator
      ||
      extra.PropertyName === TFA.smsAuthenticator
    );
  }

}

