/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, HostBinding, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { tap, filter, take } from 'rxjs/operators';

import { FlowHelpers, FlowRouterService, FlowUtilsService } from '@flow/core';
import { FlowUserService, FlowSsoExtraInterface, FlowSsoUserInterface, FlowSsoCompanyInterface } from '@flow/auth';
import { FlowDialogsService, FlowCountriesService, DialogsOverlayRef, FlowExport } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import {
  CustomerLoadingStatus,
  FlowMarketplaceStateInterface,
  getCurrentCustomer,
  getIfCustomerIsLoading,
  getIfCustomerSaved,
  LoadCustomer,
  UpdateSsoUser,
  getProductById,
  LoadCompany,
  getCurrentCompany
} from '@marketplaceStore';

import { FlowProductsService } from '../../../marketplace-shared/services/products/products.service';
import { FlowMarketplaceDashboardService } from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';
import { FlowProfileFormComponent } from '../../components/profile-form/profile-form.component';


const TFA = {  // 2fa extras-keyName   // TODO: duplicate[2fa]
  yubico: 'pkrd',
  googleAuthenticator: 'pkrd2',
  smsAuthenticator: 'pkrd3',
};

@Component({
  selector: 'flow-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowProfileComponent implements OnInit, OnDestroy {

  @HostBinding('class') class = 'flow-sidenav-modal-wrap';

  @ViewChild(FlowProfileFormComponent) profileForm: FlowProfileFormComponent;

  labels: FlowTranslateLabel;

  customer$: Observable <FlowSsoUserInterface>;

  company$: Observable<FlowSsoCompanyInterface>;

  countries$: Observable<any>;

  isLoading$: Observable <boolean>;

  isSaved$: Observable <boolean>;

  isExporting$$: Subject<boolean> = new Subject<boolean>();

  disableDefaultBookmarkTemplate: boolean;

  isActingAsPartner: boolean;

  private _reloadPage: boolean | 'logout';
  private _reloadModalConfig: { title: string; content: string } | null;

  constructor(
    public dialogRef: DialogsOverlayRef,
    private UtilsService: FlowUtilsService,
    private UserService: FlowUserService,
    private RouterService: FlowRouterService,
    private DialogsService: FlowDialogsService,
    private CountriesService: FlowCountriesService,
    private TranslateService: FlowTranslateService,
    private store: Store <FlowMarketplaceStateInterface>,
    private ProductsService: FlowProductsService,
    private MarketplaceDashboard: FlowMarketplaceDashboardService
  ) {
    this.store.dispatch(new LoadCustomer());
    this.store.dispatch(new LoadCompany());
  }

  ngOnInit() {
    this.isSaved$ = this.store.pipe(
      select(getIfCustomerSaved),
      tap(status => {
        if (true === status) {
          if (this._reloadPage === 'logout') {
            this.RouterService.navigate(['/auth', 'logout']);
          }
          else if (true === this._reloadPage) {
            window.location.reload();
          }
          else if (this._reloadModalConfig) {
            this.store.dispatch(new CustomerLoadingStatus(false));

            this.DialogsService.textModal({
              ...this._reloadModalConfig,
              okButtonLabel: 'general.label.yes',
              cancelButtonLabel: 'general.label.no'
            })
            .afterClosed()
            .subscribe(result => {
              if (result) {
                window.location.reload();
              }
            });
          }
          else {
            setTimeout(() => this.store.dispatch(new CustomerLoadingStatus(false)), 1000);
          }
        }
      })
    );

    this.isLoading$ = this.store.pipe(select(getIfCustomerIsLoading));

    this.customer$ = this.store.pipe(
      select(getCurrentCustomer),
      filter(customer => FlowHelpers.isObject(customer)),
      take(1)
    );

    this.company$ = this.store.pipe(
      select(getCurrentCompany),
      filter(value => !!value)
    );

    this.countries$ = this.CountriesService.getCountries();

    this.disableDefaultBookmarkTemplate = this.MarketplaceDashboard.disableDefaultBookmarkTemplate();

    this.isActingAsPartner = this.UserService.isActingAsPartner();

    this.labels = this.TranslateService.translateSync([
      'generic.btn.close',
      'generic.btn.save',
      'account_settings.label.email',
      'account_settings.label.given_name',
      'account_settings.label.family_name',
      'account_settings.label.phone',
      'account_settings.label.current_language'
    ]);
  }

  ngOnDestroy(): void {
    this.isExporting$$.complete();
  }

  get isProfileFormInvalid(): boolean {
    if (this.profileForm) {
      const ExtendedProperties: FlowSsoExtraInterface[] = this.profileForm.form.get('ExtendedProperties').value;
      const FlowTwoFactorAuthentication = ExtendedProperties.find(extra => extra.PropertyName === 'FlowTwoFactorAuthentication');

      if (FlowTwoFactorAuthentication.Value) {
        const twoFactorTypeEnabled = ExtendedProperties
          .find(extra =>
            extra.PropertyName === TFA.yubico
            ||
            extra.PropertyName === TFA.googleAuthenticator
            ||
            extra.PropertyName === TFA.smsAuthenticator
          );

        if (!twoFactorTypeEnabled) {
          return true;
        }
      }

      if (this.isImpersonationFormInvalid) {
        return true;
      }

      return this.profileForm.form.invalid || (!this.profileForm.form.dirty && !this.profileForm.impersonationForm.dirty);
    }

    return false;
  }

  get isImpersonationFormInvalid(): boolean {
    if (this.profileForm && this.profileForm.impersonationForm) {
      const { byVendor, allowedVendorUsers } = this.profileForm.impersonationForm.value;

      if (byVendor && !allowedVendorUsers.length) {
        return true;
      }

      return this.profileForm.impersonationForm.invalid;
    }

    return false;
  }

  onSave(): void {
    const cloned = FlowHelpers.deepClone(this.profileForm.form.value);
    let hasAutoLoadProductToChange = null;

    this._mergeImpersonationValuesToProfile(cloned);

    cloned.ExtendedProperties = cloned.ExtendedProperties
      .map((extra, index, extras) => {
        if (extra.PropertyName === 'FlowRecoveryEmail') {
          extra.Value = extra.Value ? String(btoa(extra.Value)) : '';
        }
        else {
          extra.Value = String(extra.Value);
        }

        if (extra.PropertyName === 'TimezoneEnabled') {
          const timezoneValue = extras.filter(extra => extra.PropertyName === 'Timezone').map(extra => extra.Value);

          if (String(timezoneValue[0]) !== 'false') {
            extra.Value = 'true';
          }
          else {
            extra.Value = 'false';
          }
        }

        if (extra.PropertyName === 'AutoLoadProduct' && extra.Value !== 'false' && extra.Value.indexOf(',') === -1) {
          hasAutoLoadProductToChange = true;
        }

        return extra;
      })
      .filter(extra => {
        // Remove properties from model that are not used in profile form
        // or can be removed for other reasons
        if (
          extra.PropertyName === 'Manager'
          ||
          (extra.PropertyName === 'GeoProfile' && extra.Value === 'false')
          ||
          (extra.PropertyName === 'Timezone' && extra.Value === 'false')
          ||
          (extra.PropertyName === 'FlowRecoveryEmail' && extra.Value === '')
          ||
          (extra.PropertyName === 'AutoLoadProduct' && extra.Value === 'false')
          ||
          // BE does not allow empty string value, so we completely remove the extra
          (extra.PropertyName === 'AllowedImpersonationUsersShadow' && extra.Value === '')
          ||
          // BE does not allow empty string value, so we completely remove the extra
          (extra.PropertyName === 'AllowedImpersonationUsers' && extra.Value === '')
        ) {
          return false;
        }
        else {
          return true;
        }
      });

    // Store AutoLoadProduct in a V1 compliant way
    if (hasAutoLoadProductToChange) {
      const autoLoadProductIndex = cloned.ExtendedProperties.findIndex(prop => prop.PropertyName === 'AutoLoadProduct');

      if (autoLoadProductIndex !== -1) {
        this.store.pipe(
          select(getProductById, cloned.ExtendedProperties[autoLoadProductIndex].Value)
        ).subscribe((product) => {
          if (!FlowHelpers.isEmptyObject(product)) {
            const productSchema = this.ProductsService.productSchema(product);

            if (!FlowHelpers.isEmptyObject(productSchema)) {
              cloned.ExtendedProperties[autoLoadProductIndex].Value = productSchema.solution +
                                                                      ',' +
                                                                      (productSchema.module ? productSchema.module + ',' : '') +
                                                                      productSchema.id;
            }
          }
        });
      }
    }

    // Handle chat enabled
    if (cloned.ChatEnabled && cloned.ChatEnabled.writerRoleId !== '') {
      // If chat is enabled or not is reflected in the Roles property
      if (!cloned.ChatEnabled.enabled) {
        cloned.Roles = cloned.Roles.filter(role => role.RoleId !== cloned.ChatEnabled.writerRoleId);
      }
      else {
        if (!this.UtilsService.hasExtra(cloned.Roles, cloned.ChatEnabled.writerRoleId, 'RoleId')) {
          cloned.Roles.push({
            RoleId: cloned.ChatEnabled.writerRoleId,
            UserId: cloned.Id
          });
        }
      }

      // Remove ChatEnabled from form value as it is used as a container form control
      delete cloned['ChatEnabled'];
    }

    // Trigger modal for page reloading when certain extra property has changed
    this._reloadModalConfig = this._getReloadModalConfig(cloned);

    this.store.dispatch(new UpdateSsoUser(cloned));

    // Disable the save button after submit
    this.profileForm.form.markAsPristine();
  }

  doSave($event: boolean): void {
    this._reloadPage = $event;
    this.onSave();
  }

  onExport(form: UntypedFormGroup): void {
    this.isExporting$$.next(true);
    const flowExport = new FlowExport();

    flowExport.exportToCsv([
      {
        title: this.labels['account_settings.label.email'],
        field: 'email'
      },
      {
        title: this.labels['account_settings.label.given_name'],
        field: 'firstName'
      },
      {
        title: this.labels['account_settings.label.family_name'],
        field: 'lastName'
      },
      {
        title: this.labels['account_settings.label.phone'],
        field: 'phone'
      },
      {
        title: this.labels['account_settings.label.current_language'],
        field: 'language'
      }
    ], [{
      email: form.get('Email').value,
      firstName: form.get('GivenName').value,
      lastName: form.get('FamilyName').value,
      phone: form.get('PhoneNumber').value,
      language: (form.get('ExtendedProperties') as UntypedFormArray).at(1).get('Value').value
    }]).then(() => this.isExporting$$.next(false));
  }

  /**
   * Return title and content of a modal for page reloading when certain extra property has changed
   */
  _getReloadModalConfig(form: FlowSsoUserInterface): {title: string; content: string} | null {
    const changedUserLanguage = this._hasExtraPropertyChanged(form, 'UserLanguage');
    const changedBookmarkTemplate = this._hasExtraPropertyChanged(form, 'FlowBookmarksProfile');

    if (changedUserLanguage && changedBookmarkTemplate) {
      return { title: 'general.modal.changed_properties.title', content: 'general.modal.changed_properties.content' };
    }
    else if (changedUserLanguage && !changedBookmarkTemplate) {
      return { title: 'general.modal.changed_user_language.title', content: 'general.modal.changed_user_language.content' };
    }
    else if (!changedUserLanguage && changedBookmarkTemplate) {
      return { title: 'general.modal.changed_bookmarks_profile.title', content: 'general.modal.changed_bookmarks_profile.content' };
    }

    return null;
  }

  /**
   * Check if extra property has changed
   */
  private _hasExtraPropertyChanged(form: FlowSsoUserInterface, property: string): boolean {
    const formerPropertyObj = this.profileForm.model.ExtendedProperties.find(extra => extra.PropertyName === property);
    const newPropertyValueObj = form.ExtendedProperties.find(extra => extra.PropertyName === property);

    if (newPropertyValueObj && typeof formerPropertyObj === 'undefined') {
      // Prevent showing reload modals for users that save the profile form for the first time.
      // formerPropertyObj of undefined and default values in newPropertyValueObj triggered
      // the modals in any case even if other profile form values are changed.
      if (property === 'UserLanguage'
          &&
          newPropertyValueObj.Value === this.TranslateService.getDefaultLanguage()) {
        return false;
      }
      else if (property === 'FlowBookmarksProfile'
               &&
               this.isActingAsPartner
               &&
               newPropertyValueObj.Value === 'default') {
        return false;
      }

      return true;
    }

    if (FlowHelpers.hasProperty(formerPropertyObj, 'Value') &&
        FlowHelpers.hasProperty(newPropertyValueObj, 'Value') &&
        formerPropertyObj.Value !== newPropertyValueObj.Value
    ) {
      return true;
    }

    return false;
  }

  /**
   * ensure the main profile form values is updated with the values from the impersonationForm
   */
  private _mergeImpersonationValuesToProfile(mainFormValues) {
    const { ExtendedProperties } = mainFormValues;
    const getExtra = name => {
      let result = ExtendedProperties.find(extra => extra.PropertyName === name);

      if (!result) {
        result = {PropertyName: name, Value: ''}; // blank extra obj

        // add the blank to the actual ExtendedProperties array.
        ExtendedProperties.push(result);
      }

      return result;
    };
    const extras = {
      legacyProp:           getExtra('AllowImpersonation'),
      byFlow:               getExtra('AllowImpersonationByFlowSupport'),
      byVendor:             undefined,
      allowedVendorUsers:   undefined,
      allowedVendorUsersBE: undefined,
      inform:               getExtra('NotifyOnImpersonation')
    };

    const values = this.profileForm.impersonationForm.value;

    extras.byFlow.Value = values.byFlow;
    extras.inform.Value = values.inform;

    // backwards support - needs to be kept update since it's used elsewhere by the BE.
    extras.legacyProp.Value = (values.byFlow && values.inform) ? 'ask' : values.byFlow;

    if (this.UserService.isActingAsPartner()) {   // only set/update then when as a partner
      extras.byVendor =              getExtra('AllowImpersonationByVendor');
      extras.allowedVendorUsers =    getExtra('AllowedImpersonationUsersShadow');
      extras.allowedVendorUsersBE =  getExtra('AllowedImpersonationUsers');

      extras.byVendor.Value = values.byVendor;

      const allowedImpersonators = values.allowedVendorUsers.join(',');
      extras.allowedVendorUsers.Value =   allowedImpersonators;
      /*
        BE can only check one prop, and is therefore not able to check if the "byVendor" AND the "allowedVendorUsers"
        props are set. Because of this we have to set an additional "allowedVendorUsersBE" prop which is only there
        if both "byVendor" AND the "allowedVendorUsers" are set.
      */
      extras.allowedVendorUsersBE.Value = values.byVendor ? allowedImpersonators : '';  // empty extra gets removed
                                                                                        // later by onSave
    }
  }
}
