/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FlowCmsService, FlowHelpers } from '@flow/core';

import { FlowSsoUserInterface } from '../../interfaces/sso-user.interface';
import { FlowSsoCompanyInterface } from '../../interfaces/sso-company.interface';
import { FlowAuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FlowCustomerService {

  constructor(
    private CmsService: FlowCmsService,
    private AuthService: FlowAuthService,
  ) { }

  /**
   * Returns customer data
   */
  getCustomer(customerId: string): Observable<any> {
    return this.CmsService.get<any>(`sso/customers/${customerId}`)
      .pipe(
        map(data => data),
        catchError(FlowHelpers.catchAsNull)
      );
  }

  /**
   * Returns list of customers matching the query
   */
  searchCustomers(query: string): Observable<any> {
    const params = { query };

    return this.CmsService.get<any>(`sso/customers`, params)
      .pipe(
        map(data => data),
        catchError(FlowHelpers.catchAsNull)
      );
  }

  /**
   * Returns users from a customer
   */
  getUsersFromCustomer(customerId: string): Observable<FlowSsoUserInterface[]> {
    return this.CmsService.get<FlowSsoUserInterface[]>(`sso/customers/${customerId}/users`)
      .pipe(
        catchError(FlowHelpers.catchAsNull)
      );
  }

  /**
   * Returns a single user from a customer
   */
  getUserFromCustomer(username: string, customerId: string): Observable<FlowSsoUserInterface> {
    return this.CmsService.get<FlowSsoUserInterface[]>(`sso/customers/${customerId}/users/${username}`)
      .pipe(
        map(data => data[0]),
        catchError(FlowHelpers.catchAsNull)
      );
  }

   /**
    * Creates a customer in the SSO
    */
  createSsoUser(customerObject: FlowSsoUserInterface, customerId: string, sendResetPasswordEmail = false): Observable<boolean> {
    let url = `sso/customers/${ customerId }/users`;

    if (sendResetPasswordEmail) {
      const project = this.AuthService.vendor;

      url = `${ url }?project=${ project }&sendResetPasswordEmail=true`;
    }

    return this.CmsService.post<FlowSsoUserInterface>(url, customerObject)
      .pipe(
        map(() => true),
        catchError(FlowHelpers.catchAsFalse)
      );
  }

  /**
   * Updates a customer in the SSO
   */
  updateSsoUser(customerObject: FlowSsoUserInterface, customerId: string): Observable<boolean> {
    return this.CmsService.put<FlowSsoUserInterface>(`sso/customers/${customerId}/users`, customerObject)
      .pipe(
        map(() => true),
        catchError(FlowHelpers.catchAsFalse)
      );
  }

  /**
   * Updates a company in the SSO
   */
  updateSsoCustomer(companyObject: FlowSsoCompanyInterface, customerId: string): Observable<boolean> {
    return this.CmsService.put<FlowSsoUserInterface>(`sso/customers/${customerId}?version=2`, companyObject)
      .pipe(
        map(() => true),
        catchError(FlowHelpers.catchAsFalse)
      );
  }

  /**
   * Get all rolles
   */
  getAllRoles(): Observable<any[]> {
    return this.CmsService.get('sso/roles')
      .pipe(
        catchError(err => of(err))
      );
  }
}
