/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, AfterViewInit, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';
import { pluck, shareReplay, takeUntil, tap } from 'rxjs/operators';

import {FlowEnvService, FlowUtilsService, KinetixExtraModel} from '@flow/core';
import {FlowUserService, FlowProjectService, FlowSsoService} from '@flow/auth';
import { FlowLayoutService, FlowDialogsService, FlowPortalService, FlowWebsocketService } from '@flow/shared';
import {
  FlowMarketplaceStateInterface,
  getIfChatIsOpen,
  IsApplicationLoading,
  SetIfChatIsOpen,
  LoadCustomer
} from '@marketplaceStore';

import { FlowModalTermsAgreementComponent } from '../../modals/modal-terms-agreement/modal-terms-agreement.component';
import { FlowUserlaneService } from '../../../marketplace-shared/services/userlane/userlane.service';

@Component({
  selector: 'flow-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowWrapperComponent implements OnInit, OnDestroy, AfterViewInit {

  @HostBinding('class')
  get classes() {
    return this._isChatOpen ? 'chat-open' : '';
  }

  isMobile: boolean;

  _isChatOpen: boolean;

  isChatOpen$: Observable <boolean>;

  showMarketingPolicy: boolean;

  projectExtras: KinetixExtraModel[];

  private readonly _isSupplierPortal$: Observable <boolean|any>;

  private _destroy$$ = new Subject<void>();

  // Cache current URL to prevent bypassing acceptance of terms
  // as for some reason window.location.href changes sometimes to login url
  // when using it in showTermsModal()
  private _currentURL = window.location.href;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private UserService: FlowUserService,
    private SsoService: FlowSsoService,
    private ProjectService: FlowProjectService,
    private LayoutService: FlowLayoutService,
    private DialogsService: FlowDialogsService,
    private PortalService: FlowPortalService,
    private WebsocketService: FlowWebsocketService,
    private UserlaneService: FlowUserlaneService
  ) {
    this._isSupplierPortal$ = this.SsoService.getOnBoardingFormForPartners()
      .pipe(
        pluck('isSupplierPortal'),
        shareReplay(1),
      );

    // subscribe to pre-fetch and populate the buffer
    this._isSupplierPortal$.subscribe();
  }

  ngOnInit(): void {
    this.showMarketingPolicy = true;

    // Check resolution
    this.LayoutService.isMobile$.pipe(
      takeUntil(this._destroy$$)
    ).subscribe(isMobile => {
      this.isMobile = isMobile;
    });

    this.WebsocketService.connect();

    this.isChatOpen$ = this.store.pipe(
      select(getIfChatIsOpen),
      tap(value => this._isChatOpen = value)
    );

    this.ProjectService.getProjectExtras()
      .pipe(
        takeUntil(this._destroy$$) /* must be last */
      )
      .subscribe(
      response => {
        this.projectExtras = response;

        // By default the marketing policy is shown.
        // Then check an extra at project level. If it exists, then take this value.
        if (this.UtilsService.hasExtra(response, 'showMarketingPolicy', 'fieldName')) {
          this.showMarketingPolicy = this.UtilsService.getExtraValue(response, 'showMarketingPolicy', 'fieldName');
        }
        // Check the extra on company level. If it exists, then take this value
        else {
          if (this.UtilsService.hasExtra(this.UserService.companyInformation.ExtendedProperties, 'showMarketingPolicy')) {
            this.showMarketingPolicy = this.UtilsService.getExtraValue(this.UserService.companyInformation.ExtendedProperties, 'showMarketingPolicy');
          }
        }

        this.isSupplierPortal().subscribe(isSupplierPortal => {
          if (isSupplierPortal) {
            this.checkCustomerTermsAgreement();
          }
          else {
            this.checkTermsAgreement();
          }
        });
      }
    );
  }

  ngOnDestroy(): void {
    this.WebsocketService.disconnect();

    this._destroy$$.next();
    this._destroy$$.complete();

    if (!this.isMobile) {
      this.UserlaneService.hide();
    }
  }

  ngAfterViewInit(): void {
    this.store.dispatch(new IsApplicationLoading(false));
  }

  closeChat(): void {
    this.store.dispatch(new SetIfChatIsOpen());
  }

  checkTermsAgreement(): void {
    const privacyData = {
      eula: this.UserService.getExtraValue('EULA'),
      privacyPolicy: this.UserService.getExtraValue('FlowPrivacyPolicy'),
      marketingPolicy: this.UserService.getExtraValue('FlowMarketingPolicy'),
      showMarketingPolicy: this.showMarketingPolicy,
      isMarketingOptional: this.PortalService.currentPortalDashboard.optionalMarketing || false,
      isPartner: this.UserService.isActingAsPartner(),
      source: this.EnvService.portalDomain,
      currentSourceTermsAccepted: this.UserService.getExtraValue(`${this.EnvService.portalDomain}Terms`),
      currentPortalTerms: this.PortalService.portalTerms,
    };

    if (
      (
        !privacyData.eula
        ||
        !privacyData.privacyPolicy
        ||
        (privacyData.showMarketingPolicy === true && !privacyData.marketingPolicy)
      )
      ||
      (
        privacyData.isPartner
        &&
        !privacyData.currentSourceTermsAccepted
        &&
        privacyData.currentPortalTerms
        &&
        Object.keys(privacyData.currentPortalTerms.translations).length
      )
    ) {
        this._addCompanyNameAndShowTermsModal(privacyData);
    }
    else {
      if (!this.isMobile) {
        this.UserlaneService.init();
      }
    }
  }

  checkCustomerTermsAgreement(): void {
    // NOTE: business-logic should be in sync with "src/app/modules/profile/components/profile-form/profile-form.component.ts"

    /* BUSINESS-LOGIC
      - If you are a user of a supplier in a portal (like AMS/ROT) and you are the company admin.. you must agree with
        all 4.

      - If you are a normal user of a supplier in a portal (like AMS/ROT), then you only need to agree with the latest
        2 (privacy_terms_customer_acceptance + operational_communication_acceptance).

      - If you are a user of the company that has purchased the FLOW portal (like AMS/ROT)  you should only accept the
        latest 2 (privacy_terms_customer_acceptance + operational_communication_acceptance) (as the customer already
        signed the offline contract with TIE Kinetix)

      - If you are entering a TIE Kinetix service bought online.. then the current (old) clauses apply.
    */

    const project = this.EnvService.portalDomain;
    const isPartner = this.UserService.isActingAsPartner();
    const isAdmin = this.UserService.isAdmin();

    const customerPrivacy = this.UserService.getExtraValue(`CustomerPrivacy__${ project }`);
    const customerOperationalCommunication = this.UserService.getExtraValue(`CustomerOperationalCommunication__${ project }`);

    let projectSupplierTerms;
    let customerTerms;

    let showTermsModal = false;

    if (!customerPrivacy || !customerOperationalCommunication) {
      showTermsModal = true;
    }

    if (isPartner) {
      if (isAdmin) { // ADMIN user
        projectSupplierTerms = this.UserService.getExtraValue(`ProjectSupplierTerms__${ project }`);
        customerTerms = this.UserService.getExtraValue(`CustomerTerms__${ project }`);

        if (!projectSupplierTerms || !customerTerms) {
          showTermsModal = true;
        }
      }
      else {  // NORMAL user
      }
    }

    if (showTermsModal) {
      const reAcceptTermsMsg = this.UserService.getExtraValue(`reAcceptTermsMsg__${ project }`);

      const customerPrivacyData = {
        isPartner,
        isAdmin,
        source: project,
        isSupplierPortal: true,

        reAcceptTermsMsg,

        customerPrivacy,
        customerOperationalCommunication,
        projectSupplierTerms,
        customerTerms,
      };

      this._addCompanyNameAndShowTermsModal(customerPrivacyData);
    }
    else {
      if (!this.isMobile) {
        this.UserlaneService.init();
      }
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  showTermsModal(privacyData: any): void {
    this.DialogsService.open(FlowModalTermsAgreementComponent, {
      maxWidth: '600px',
      data: privacyData,
      closeOnNavigation: true,
      disableClose: true
    })
    .afterClosed()
    .subscribe((result) => {
      // Prevent bypassing acceptance of terms
      if (result !== 'terms-accepted') {
        document.location.href = this._currentURL;
      }
      else {
        if (!this.isMobile) {
          this.UserlaneService.init();
        }

        // When a newly created user logged in for the first time the save button in the profile form was disabled.
        // The reason for this are missing ids in the terms related extended properties of the customer data.
        // There is a required validator on the ids defined in FlowSsoCustomerFormModel.
        // Reloading the customer data after accepting the terms makes this work.
        this.store.dispatch(new LoadCustomer());
      }
    });
  }

  isSupplierPortal() {
    return this._isSupplierPortal$;
  }

  private _addCompanyNameAndShowTermsModal(privacyData: any): void {
    if (!privacyData.isPartner) {
      privacyData['companyName'] = this.UserService.customer.Description;
      this.showTermsModal(privacyData);
    }
    else {
      this.ProjectService.getVendorName().subscribe(
        response => {
          if (response) {
            privacyData['companyName'] = response;
          }

          this.showTermsModal(privacyData);
        }
      );
    }
  }

  /**
   * Check for project extra
   */
  private _getProjectExtra(fieldNameValue: string): boolean {
    const extra = this.UtilsService.getExtra(this.projectExtras, fieldNameValue, 'fieldName');

    if (extra) {
      const { value } = extra;

      return value.toLowerCase() === 'true';
    }

    return false;
  }
}
