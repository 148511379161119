/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowRouterService, FlowEnvService } from '@flow/core';
import { FlowUserService } from '@flow/auth';

import { FlowGatewayService } from '../modules/gateway/services/gateway/gateway.service';

@Injectable({
  providedIn: 'root'
})
export class FlowOnboardingGuard  {

  constructor(
    private router: FlowRouterService,
    private EnvService: FlowEnvService,
    private UserService: FlowUserService,
    private GatewayService: FlowGatewayService,
  ) {}

  /**
   * Check if user of a temp company needs to be redirected to the onboarding path
   * after registering a new account and setting his password.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const companyInfo = this.UserService.companyInformation;
    const { Name } = companyInfo;

    if (Name && Name.startsWith('tmp')) {
      // NOTE: GatewayService auto-subscribes in its constructor to trigger pre-fetch and populate the buffer
      return this.GatewayService.getOnboardingformUrl()
        .pipe(
          map(onboardingformUrl => {
            if (onboardingformUrl) {
              const project = this.EnvService.portalDomain.toLowerCase();

              this.router.navigate(['onboarding', project]);
              return false;
            }
            else {
              console.error('No onboardingformUrl -> logout');  // for pinpointing future unexpected logout issues

              this.router.navigate(['auth', 'logout']);
              return false;
            }
          }),
        );
    }

    return true;
  }
}
