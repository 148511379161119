import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { FlowCustomerService, FlowSsoUserInterface, FlowUserService } from '@flow/auth';

import { FlowMarketplaceStateInterface } from '@marketplaceStore';

import * as fromCustomerActions from '../actions/customer.actions';
import * as fromCustomerSelectors from '../selectors/customer.selectors';

@Injectable()
export class CustomerEffect {

  Load$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromCustomerActions.FlowCustomerActions>(fromCustomerActions.CustomerActionTypes.LoadCustomer),
      withLatestFrom(this.store.pipe(select(fromCustomerSelectors.getIfCustomerIsLoaded))),
      filter(([state, loaded]) => !loaded),
      switchMap(() => this.CustomerService.getUserFromCustomer(this.UserService.username, this.UserService.company).pipe(
        map(result => new fromCustomerActions.LoadCustomerSuccess(result)),
        catchError(err => of(new fromCustomerActions.LoadCustomerFail(err))),
      ))
    );
  });

  UpdateSsoUserInSettings$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromCustomerActions.FlowCustomerActions>(fromCustomerActions.CustomerActionTypes.UpdateSsoUser),
      withLatestFrom(this.store.pipe(select(fromCustomerSelectors.getCurrentCustomer))),
      switchMap(([state, user]: [any, FlowSsoUserInterface]) => this.CustomerService.updateSsoUser(user, this.UserService.company).pipe(
        map(() => new fromCustomerActions.UpdateSsoUserResult(true)),
        catchError(() => of(new fromCustomerActions.UpdateSsoUserResult(false)))
      ))
    );
  });

  SetPrivacyAgreement$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromCustomerActions.FlowCustomerActions>(fromCustomerActions.CustomerActionTypes.SetPrivacyAgreement),
      switchMap((state: any) => this.CustomerService.getUserFromCustomer(this.UserService.username, this.UserService.company).pipe(
        switchMap(result => {
          const payload = { ...state.payload };   // state.payload is immutable, so we clone to be able to mutate
          const payloadKeys = Object.keys(payload);
          const toBeRemovedExtras = payloadKeys.filter(key => payload[key] === '');

          if (toBeRemovedExtras.length) {
            // remove the extras
            // NOTE: this will take care of removing e.g. the "reAcceptTermsMsg__${ project }" extra
            result.ExtendedProperties = result.ExtendedProperties.filter(extra => !toBeRemovedExtras.includes(extra.PropertyName));

            // remove the props from the payload so that they don't get processed in the next logic block.
            toBeRemovedExtras.forEach(key => delete payload[key]);
          }

          const ts = new Date().toISOString();

          for (const property in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, property)) {
              const found = result.ExtendedProperties.findIndex(extra => extra.PropertyName === property);

              if (found > -1) {
                result.ExtendedProperties[found].Value = String(ts);
              }
              else {
                result.ExtendedProperties.push({
                  PropertyName: property,
                  Value: String(ts)
                });
              }
            }
          }

          return this.CustomerService.updateSsoUser(result, this.UserService.company).pipe(
            map(() =>  new fromCustomerActions.SetPrivacyAgreementResult({
              user: result,
              result: true
            })),
            catchError(() => of(new fromCustomerActions.SetPrivacyAgreementResult({
              user: null,
              result: false
            })))
          );
        })
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store <FlowMarketplaceStateInterface>,
    private CustomerService: FlowCustomerService,
    private UserService: FlowUserService
  ) {}
}
