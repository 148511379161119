/*
* Triggers a logout of subapps that do not use the normal v2/flow logout mechanism, by calling their app logout path in
* a hidden iframe. See ST035309 for ℹ️ Implementation logic.
* */

import { Injectable } from '@angular/core';

import { FlowKeyValueStringInterface, FlowStorageService } from '@flow/core';

const SUB_APPS = {
  edi:    '/SmartBridge/AngularApp/#/logout',  // Document Tracking
  einvo:  '/Authentication/Logout',            // Document Manager
};
const SUB_APPS_LOGOUT_URLS_STORAGE_KEY = 'flow.sub-apps-logout';

const SEC = 1000; // 1sec
const MAX_LOADING_TIME = 15 * SEC;  // max time before all the iframes are "forcefully" removed.
const IFRAME_LOAD_TIME = 1.5 * SEC; // time to allow the SPA to "bootup" in the iframe. Needed because the SPA need to
                                    // "bootup" first, before it can nav to its logout route.

@Injectable({
  providedIn: 'root'
})
export class FlowSubAppsLogoutService {

  private _toBeLoggedOut: FlowKeyValueStringInterface = { };

  private _iframeLoadingTimeout: ReturnType<typeof setTimeout>;

  private _iframeListeners = [];

  constructor(
    private StorageService: FlowStorageService,
  ) { }

  add({ appId, appUrl }) {
    const logoutPath = SUB_APPS[appId];

    if (logoutPath) { // only proceed if it's a supp-app we need to log-out.
      if (!this._toBeLoggedOut[appId]) { // only proceed if it's not already added.
        const url = new URL(appUrl);
        this._toBeLoggedOut[appId] = url.origin + SUB_APPS[appId];
      }
    }
  }

  onParentLogout() {
    const arrUrls = Object.values(this._toBeLoggedOut);

    if (arrUrls.length) {
      // store to localStorage
      this._storageSubAppsLogoutUrls = arrUrls;
    }
  }

  logoutSubApps() {
    // create iframe for each sub-app logout url
    this._storageSubAppsLogoutUrls.forEach((url, index) => {
      document.body.insertAdjacentHTML(
        'beforeend',
        `<iframe sub-apps-logout-iframe="${ index }" src="${ url }" tabindex="-1"></iframe>`
      );
    });

    setTimeout(this._initIframeListeners.bind(this));  // timeout to await elements to be in the DOM.

    this._iframeLoadingTimeout = setTimeout(this._removeAllLogoutIframes.bind(this), MAX_LOADING_TIME);


    // remove storage.
    this._removeStorageSubAppsLogoutUrls();
  }


  private _initIframeListeners() {
    document
      .querySelectorAll('IFRAME[sub-apps-logout-iframe]')
      .forEach((ifr: HTMLIFrameElement, index) => {
        const listenerFn = event => {
          this._onIframeLoad(
            event
            ||
            { target: ifr, skipDelay: true }   /* for when called by _removeAllLogoutIframes */
          );

          // detach the load event listener to prevent leak
          ifr.removeEventListener('load', listenerFn);

          // throw away the listener function to prevent leak
          this._iframeListeners.splice(this._iframeListeners.indexOf(listenerFn), 1);
        };

        this._iframeListeners.push(listenerFn);

        ifr.addEventListener('load', listenerFn);
      });
  }

  private _onIframeLoad(event) {
    const { target, skipDelay } = event;

    if (skipDelay) {
      this._removeLogoutIframes(target);
    }
    else {
      setTimeout(() => this._removeLogoutIframes(target), IFRAME_LOAD_TIME);
      /* timeout needed to allow the
         sub-app SPA to "bootup" and
         nav to its logout route
      */
    }
  }

  private _removeLogoutIframes(iframe) {
    document.body.removeChild(iframe);
  }

  private _removeAllLogoutIframes() {
    let maxIteration = this._iframeListeners.length;  // prevent the while from going into infinite-loop

    // NOTE: do NOT use forEach, as it does not execute in order and that might result in the next index item already
    //        being removed due to the listenFn performing a splice.
    while (this._iframeListeners.length && maxIteration--) {
      this._iframeListeners[0]();
    }
  }

  private get _storageSubAppsLogoutUrls() {
    const jsonStr = this.StorageService.get(SUB_APPS_LOGOUT_URLS_STORAGE_KEY);

    return jsonStr ? JSON.parse(jsonStr) : [];
  }

  private set _storageSubAppsLogoutUrls(arrUrls) {
    this.StorageService.set(SUB_APPS_LOGOUT_URLS_STORAGE_KEY, JSON.stringify(arrUrls));
  }

  private _removeStorageSubAppsLogoutUrls() {
    this.StorageService.remove(SUB_APPS_LOGOUT_URLS_STORAGE_KEY);
  }
}
