<flow-preloader *ngIf="loading$ | async else content"></flow-preloader>

<ng-template #content>
  <h3 mat-dialog-title [innerHTML]="labels['forms.label.select_available_language']"></h3>

  <mat-dialog-content>
    <mat-form-field>
      <mat-select [(ngModel)]="preselectedLanguage">
        <mat-option
          *ngFor="let language of languagesToSelect"
          [value]="language.lcId">
          {{ language.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="button button-close-dialogs mr-2"
      [mat-dialog-close]="false"
      [innerHTML]="labels['generic.btn.close']">
    </button>
    <button
      class="button button-primary"
      [mat-dialog-close]="preselectedLanguage"
      [innerHTML]="labels['generic.btn.add']">
    </button>
  </mat-dialog-actions>
</ng-template>
