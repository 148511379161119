/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { FlowAuthService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-login-buttons',
  templateUrl: './login-buttons.component.html',
  styleUrls: ['./login-buttons.component.scss']
})
export class FlowLoginButtonsComponent implements OnInit {

  /** Boolean Input to show the spinner or the submit arrow. */
  @Input() loading;

  /** Boolean Input to toggle visibility on the submit button */
  @Input() showSubmitButton = true;

  /** Boolean Input to show/hide the back arrow button. */
  @Input() showBackButton: boolean;

  /** Boolean Input with the form validation status. */
  @Input() validForm = false;

  /** Boolean Input checking if the form has been touched or not. */
  @Input() isTouched = false;

  @Input() ariaLabel: string;

  @HostBinding('class') get classes() {
    return 'd-block pt-3';
  }

  labels: FlowTranslateLabel;
  loginUrl: string[];

  constructor(
    private AuthService: FlowAuthService,
    private TranslateService: FlowTranslateService
  ) {
    this.loginUrl = this.AuthService.loginUrl;
  }

  ngOnInit(): void {
    this.labels = this.TranslateService.translateSync([
      'login.account_form.btn.back_to_login'
    ]);
  }

}
