/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowRouterService } from '@flow/core';
import { FlowMissingInfoService } from '../modules/missing-info/services/missing-info/missing-info.service';
import { FlowUserService } from '@flow/auth';

const SKIP_CLIENTS = [
  'flow-portal-impersonator',
  'flow-portal-external-authentication',
];

@Injectable({
  providedIn: 'root'
})
export class FlowMissingInfoGuard  {
  constructor(
    private router: FlowRouterService,
    private UserService: FlowUserService,
    private MissingInfoService: FlowMissingInfoService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this._isUserSkippable) {
      return true;
    }  // early exit (to bypass missing-info)

    if (this.MissingInfoService.hasMissingInfo) {

      // data is invalid or missing, so redirect to change missing-info page
      this.router.navigate(['missing-info']);
      return false;
    }

    return true;
  }

  /**
   * TRUE if current user is skippable, e.g., being impersonated or coming from external SSO.
   */
  private get _isUserSkippable() {
    return SKIP_CLIENTS.includes(this.UserService.user['client_id']);
  }
}
