/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { FlowHelpers, FlowCmsService } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';

import { LANG_COUNTRY_MAP } from './constants/lang-country-map';

@Injectable({
  providedIn: 'root'
})
export class FlowCountriesService {

  countries: any[];

  constructor(
    private CmsService: FlowCmsService,
    private TranslateService: FlowTranslateService
  ) { }

  getCountries(): Observable<any[]> | null {
    return this.countries
    ? of(this.countries)
    : this.CmsService.get<any>('countries')
      .pipe(
        map(countries => {
          // Create viewName property to prevent showing the same name twice
          // For Territoire des Terres australes et antarctiques françaises (French Southern and Antarctic Lands)
          // only the common name is shown
          countries.forEach(country => {
            country.viewName = country.name.native.common && country.name.native.common !== country.name.common
              ? `${country.name.native.common} (${country.name.common})`
              : country.name.common;
            });
          // Sort by viewName
          countries = countries.sort((a, b) => a.viewName.localeCompare(b.viewName));

          return countries;
        }),
        tap(countries => this.countries = countries),
        catchError(() => FlowHelpers.catchAsNull())
      );
  }

  /**
   * Try to get a country code by browser language code
   * based on mapping
   */
  getCountryByLanguage(countries: any): string|null {
    const userLang = this.TranslateService.getCurrentLanguage();
    const mappedCountry = LANG_COUNTRY_MAP[userLang];

    // Country found in mappings ?
    if (mappedCountry) {
      const foundCountry = countries && countries.find(country => country.cca2 === mappedCountry);

      // Country found in FLOW countries
      if (foundCountry) {
        return mappedCountry;
      }
    }

    return null;
  }

  /**
   * Get country view name for given country code
   */
  getCountryViewName(countries: Array<any>, countryCode: string): string {
    if (!FlowHelpers.isEmptyArray(countries)) {
      const foundCountry = countries.find(country => country.cca2.toLowerCase() === countryCode.toLowerCase());

      return foundCountry ? foundCountry.viewName : countryCode;
    }

    return countryCode;
  }
}
