/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

/* eslint no-console: 0 */

import { Injectable, Optional } from '@angular/core';
import * as Fingerprint2 from 'fingerprintjs2';

import { FlowHelpers } from '../../classes/class.helpers';

export class FlowCoreConfigService {
  env: any;
}

@Injectable({
  providedIn: 'root'
})
export class FlowEnvService {

  private _config;

  /** Our fallback company for every possible scenario */
  private _fallbackVendor = 'Flow';

  /** Current fingerprint, mainly used by 2FA. */
  private _fingerprint: string;

  private _isVendorPortal: boolean;

  private _portalDomain = 'Flow';

  private _portalUrlPrefix: string;

  constructor(
    @Optional() config: FlowCoreConfigService,
  ) {
    if (config) {
      this._config = config;
    }

    this.createFingerprint();
  }

  get defaultVendor(): string {
    return this._fallbackVendor;
  }

  /** Getter to know if is production environment or not */
  get isProduction(): boolean {
    return this._config.env.name === 'production';
  }

  get isAcceptance(): boolean {
    return this._config.env.name === 'acceptance';
  }

  get isPreAccept(): boolean {
    return this._config.env.name === 'preaccept';
  }

  get isTest(): boolean {
    return this._config.env.name === 'test';
  }

  /** Gets the finger print ID for the current device. */
  get fingerprint(): string {
    return this._fingerprint;
  }

  get isVendorPortal(): boolean {
    return this._isVendorPortal;
  }

  get portalDomain(): string {
    return this._portalDomain;
  }

  get portalPrefix(): string {
    return this._portalUrlPrefix;
  }

  /**
   * Gets a variable from the environment data.
   */
  get(variable: string): string {
    return this._config.env[ variable ];
  }

  /**
   * Sets the current application domain.
   */
  setPortalDomain(): void {
    const host = location.host;

    let urlParts;

    if (host.indexOf('.') >= 0) {
      urlParts = host.split('.');
    }

    // this._isVendorPortal = true;
    // this._portalDomain = FlowHelpers.vendorName('josetestcompany');
    // return;

    if (!FlowHelpers.isEmptyArray(urlParts)
      && (urlParts[0].indexOf('localhost') === -1 && !this.isFlowApplication(urlParts[0]))
    ) {
      // Prevent production urls.
      this._portalDomain = FlowHelpers.vendorName(urlParts[0].replace('-v2', ''));
      this._isVendorPortal = true;
    }
  }

  setPortalPrefix(): void {
    let prefix = 'flow';

    if (this.isTest) {
      prefix += `-test`;
    }
    else if (this.isAcceptance) {
      prefix += `-accept`;
    }
    else if (this.isPreAccept) {
      prefix += `-preaccept`;
    }

    this._portalUrlPrefix = prefix;
  }

  /**
   * Checks if given an application name is a FLOW recognized one.
   *
   * @todo, improve if there are more applications.
   */
  isFlowApplication(appName: string): boolean {
    const str = appName.toLowerCase();
    const apps = [
      'www',
      'staging-blackbox',
      'validatorflow-blackbox',
      'camau',
      'cms',
      'emaca',
      'flow',
      'flowv2',
      'flow-marketplace',
      'mappingmanager',
      'ezinvoices',
      'onboarding',
      'pdfconversion',
      'tpm',
      'setupsecureattachments',
      'dmcomponents',
      'leads',
      'ppcca',
      'staging-blackbox'
    ];

    return apps
      .find(app => app === str || app + '-test' === str || app + '-accept' === str  || app + '-preaccept' === str)
      ? true
      : false;
  }

  /**
   * Creates the fingerprint, mainly used by two factor authentication
   */
  createFingerprint(): Promise <void> {
    return new Promise(resolve => {
      Fingerprint2.get(components => {
        this._fingerprint = Fingerprint2.x64hash128(components.map(component => component.value).join(''), 31);
        resolve();
      });
    });
  }

  /**
   * Helper function to log messages in the console.
   * This can be used to debug scenarios without being shown on production.
   */
  log(message = '', args: any, type: 'debug' | 'log' | 'info' | 'warn' | 'error' = 'log'): void {
    if (!this.isProduction) {
      if (args) {
        console[type](message, args);
      }
      else {
        console[type](message);
      }
    }
  }

  logDebug(message: string, args?: any) {
    this.log(message, args, 'debug');
  }

  logInfo(message: string, args?: any) {
    this.log(message, args, 'info');
  }

  logWarn(message: string, args?: any) {
    this.log(message, args, 'warn');
  }
}
