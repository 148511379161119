/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FlowUserService } from '@flow/auth';

@Directive({
  selector: '[hasPermission]'
})
export class FlowHasPermissionDirective implements OnInit {

  @Input('hasPermission') permissions: string[];

  constructor(
    private UserService: FlowUserService,
    private templateRef: TemplateRef <any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    if (!this.UserService.hasPermission(this.permissions)) {
      this.viewContainer.clear();
    }
    else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
