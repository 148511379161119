/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

import { Observable } from 'rxjs';

import { FlowStatusMessageService } from '../../services/status-message.service';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel } from '@flow/translate';

@Component({
  selector: 'flow-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowStatusMessageComponent implements OnInit, OnDestroy {

  @Input() forceShow: boolean;

  labels: FlowTranslateLabel;

  statusMessage$: Observable<any>;
  customerAnnouncement$: Observable<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private UserService: FlowUserService,
    private StatusMessageService: FlowStatusMessageService,
  ) {}

  ngOnInit(): void {
    this.statusMessage$ = this.StatusMessageService.startPolling(this.forceShow);

    this.UserService.isLoggedIn$().subscribe(state => {
      if (state) {
        this.customerAnnouncement$ = this.StatusMessageService.loadCustomerMaintenanceAnnouncements();

        setTimeout(() => this.changeDetectorRef.detectChanges());
      }
    });
  }

  ngOnDestroy() {
    this.StatusMessageService.stopPolling$$.next(null);
  }
}
