<mat-form-field [formGroup]="form">
  <mat-label *ngIf="inlineLabel" [innerHTML]="labels[inlineLabel]"></mat-label>
  <mat-select
    [formControlName]="controlName"
    [required]="required">
    <mat-option
      *ngFor="let country of countries$ | async"
      [value]="country.cca2">
      {{ country.viewName }}
    </mat-option>
  </mat-select>
</mat-form-field>
