<mat-form-field
  [formGroup]="formGroup"
  [hintLabel]="hintLabel">
  <mat-label *ngIf="inlineLabel" [innerHTML]="inlineLabel"></mat-label>

  <mat-select
    *ngIf="provinces"
    [formControlName]="provinceControlName"
    [required]="!hideRequiredMarker">
    <mat-option value=""></mat-option>
    <mat-option
      *ngFor="let province of provinces"
      [value]="province">
      {{ province }}
    </mat-option>
  </mat-select>

  <input
    *ngIf="!provinces"
    matInput
    [formControlName]="provinceControlName"
    [required]="!hideRequiredMarker">
  <mat-error
    [hidden]="!formGroup.get(provinceControlName).invalid"
    [innerHTML]="formGroup.get(provinceControlName).hasError('required')
    ? requiredErrMsg
    : null">
  </mat-error>
</mat-form-field>
