import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

import { EventInterface } from '../../interfaces/event.interface';

@Injectable({
  providedIn: 'root'
})
export class FlowEventService {

  private _subject$ = new Subject<EventInterface>();

  on(name: string, listener: any) {
    return this._subject$
      .pipe(
        filter(event => event.name === name),
        pluck('data')
      )
      .subscribe(listener);
  }

  send(name: string, data?: any) {
    this._subject$.next({ name, data });
  }
}
