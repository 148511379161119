import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowDateLocalePipe } from './date-locale/date-locale.pipe';
import { FlowSafePipe } from './safe/safe.pipe';
import { FlowRangePipe } from './range/range.pipe';
import { FlowTextTruncatePipe } from './text-truncate/text-truncate.pipe';
import { FlowUppercaseToWordsPipe } from './uppercase-to-words/uppercase-to-words.pipe';

@NgModule({
  declarations: [
    FlowDateLocalePipe,
    FlowSafePipe,
    FlowRangePipe,
    FlowTextTruncatePipe,
    FlowUppercaseToWordsPipe
  ],
  exports: [
    FlowDateLocalePipe,
    FlowSafePipe,
    FlowRangePipe,
    FlowTextTruncatePipe,
    FlowUppercaseToWordsPipe
  ],
  imports: [
    CommonModule
  ]
})
export class FlowPipesModule { }
