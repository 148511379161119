/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { FlowRouterService } from '@flow/core';
import { FlowUserService, FlowTokensService } from '@flow/auth';
import { FlowGatewayService } from '../modules/gateway/services/gateway/gateway.service';
import { FlowProjectDomainService } from '../services/project-domain.service';

@Injectable({
  providedIn: 'root'
})
export class FlowGatewayGuard  {

  private _onboardingformUrl;

  constructor(
    private router: FlowRouterService,
    private UserService: FlowUserService,
    private TokensService: FlowTokensService,
    private GatewayService: FlowGatewayService,
    private ProjectDomainService: FlowProjectDomainService,
  ) {
    this.GatewayService.getOnboardingformUrl()
      .subscribe(url => {
        this._onboardingformUrl = url;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.UserService.user) {
      /* NOTE: We assume "ProjectDomainGuard" will take care of checking if user is on wrong domain; Gateway only job
              is to redirect if user only has 1 option, or otherwise show a selection list if user has multiple options.
      */

      if (this.UserService.user.platform !== 'Flow') {  // platform==Flow users will need to always go to the gateway
                                                          // for the impersonation

        if (this.GatewayService.userHasAllowedVendor) {
          if (!this.GatewayService.userHasBoughtProducts) {  /* Companies that hav NOT bought any product (is not a TIE
                                                                customer) will be sent to the gateway where they'll be
                                                                given the option to become a TIE customer by going to
                                                                the webshop */
            if (
              this.GatewayService.userHasOnlyOneAllowedVendor
              &&
              (
                this.UserService.isActingAsPartner() /* For vendor its own vendor-domain will also count as an additional
                                                      option in the list and therefore we cannot do an auto-redirect */

                ||

                !this._onboardingformUrl  /* only proceed with auto-redirect if current domain has no onboarding */
              )
            ) {
              this.redirectAfterInitVendorDomainAuthorization(this.GatewayService.allowedVendors[0]);
              return false;
            }
          }
        }
        else if (this.UserService.isActingAsVendor() && !this._onboardingformUrl) {
          this.redirectAfterInitVendorDomainAuthorization(this.UserService.user.platform);
          return false;
        }
      }
    }
    else {
      this.router.navigate(['/']);
    }

    return true;
  }

  redirectAfterInitVendorDomainAuthorization(vendor) {
    this.ProjectDomainService.initVendorDomainAuthorization()
      .subscribe(result => {
        if (result) {
          this.ProjectDomainService.redirectToVendorDomain(vendor, null, false, true);
        }
        else {
          this.TokensService.deleteTokens();
          this.router.navigate(['/']);
        }
      });
  }
}
