<ng-container *ngIf="validHash$ | async else error">
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    [attr.aria-label]="labels['login.password_form.headline.set_password']">

    <div *ngIf="errorMsg" class="error" [innerHTML]="errorMsg"></div>

    <mat-form-field class="d-block mb-2" [appearance]="'fill'">
      <mat-label [innerHTML]="labels['login.password_form.label.new_password']"></mat-label>
      <input type="password" matInput formControlName="newPassword" autocomplete="new-password" required>
      <mat-error
        [hidden]="!form.get('newPassword').invalid"
        [innerHTML]="form.get('newPassword').hasError('required')
          ? labels['generic.error.required.new_password']
          : form.get('newPassword').hasError('invalidPassword')
            ? labels['generic.error.password_strength']
            : null"
        role="alert"
        aria-live="assertive">
      </mat-error>
    </mat-form-field>

    <mat-form-field class="d-block mb-2" [appearance]="'fill'">
      <mat-label [innerHTML]="labels['login.password_form.label.repeat_password']"></mat-label>
      <input type="password" matInput formControlName="repeatNewPassword" autocomplete="repeat-new-password" required>
      <mat-error
        [hidden]="!form.get('repeatNewPassword').invalid"
        [innerHTML]="form.get('repeatNewPassword').hasError('required')
          ? labels['generic.error.required.repeat_password']
          : form.get('repeatNewPassword').hasError('passwordMatch')
            ? labels['generic.error.password_mismatch']
            : null"
        role="alert"
        aria-live="assertive">
      </mat-error>
    </mat-form-field>

    <section
      class="pl-3 pw-requirements"
      tabindex="0"
      [attr.aria-label]="labels['login.aria.region.password_requirements']"
    >
      <div><b [innerHTML]="labels['general.password_requirements.title']"></b></div>
      <ul>
        <li [ngClass]="{'color-success':passwordConformsTo('minChars')}"
            [innerHTML]="labels['general.password_requirements.rules.length']"
        ></li>
        <li [ngClass]="{'color-success':passwordConformsTo('upperAndLowerChars')}"
            [innerHTML]="labels['general.password_requirements.rules.upper_lower_chars']"
        ></li>
        <li [ngClass]="{'color-success':passwordConformsTo('numbers')}"
            [innerHTML]="labels['general.password_requirements.rules.numbers']"
        ></li>
        <li [ngClass]="{'color-success':passwordConformsTo('specialChars')}"
            [innerHTML]="labels['general.password_requirements.rules.special_chars']"
        ></li>
        <li [innerHTML]="labels['general.password_requirements.rules.no_personal_info']"></li>
      </ul>
    </section>

    <flow-login-buttons
      [showBackButton]="true"
      [validForm]="form.valid"
      [isTouched]="form.touched"
      [loading]="loading$ | async"
      [ariaLabel]="labels['login.password_form.headline.set_password']">
    </flow-login-buttons>
  </form>
</ng-container>

<ng-template #error>
  <div class="error" [innerHTML]="errorMsg"></div>
</ng-template>
