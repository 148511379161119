<footer class="d-flex justify-content-between align-items-center">
  <div class="d-flex logo align-items-center hidden-mobile">
    <flow-logo
      [width]="'70'"
      [height]="'25'"
      [viewbox]="'0 0 140 30'"
      [classes]="logoClasses">
    </flow-logo>
    <div class="brand">Partner Automation</div>
  </div>
  <div class="links">
    <ul class="list-inline">
      <li>&copy; {{ currentYear}} SPS Commerce</li>
      <ng-container *ngIf="showLinks">
        <ng-container *ngIf="isSupplierPortal$ | async else ourPrivacyLink">
          <li *ngIf="privacySettings">
            <a
              *ngIf="privacySettings.url && privacySettings.view && privacySettings.view === 'browser'"
              [href]="privacySettings.url"
              [innerHTML]="labels['general.footer.link.disclaimer']"
              target="_blank">
            </a>
            <a
              *ngIf="privacySettings.url && privacySettings.view && privacySettings.view === 'modal'"
              (click)="openCustomerPrivacyModal(privacySettings.url)"
              [innerHTML]="labels['general.footer.link.disclaimer']">
            </a>
          </li>
        </ng-container>
        <ng-template #ourPrivacyLink>
          <li>
            <a
              (click)="openPrivacyModal()"
              [innerHTML]="labels['general.footer.link.disclaimer']">
            </a>
          </li>
        </ng-template>
      </ng-container>
      <li *ngIf="showLinks">
        <a
          href="http://flow-status.tiekinetix.net/"
          [innerHTML]="labels['general.footer.link.status_page']"
          target="_blank">
        </a>
      </li>
    </ul>
  </div>
</footer>
