import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FlowAuthViewsRoutingModule } from './auth-views-routing.module';

import { Flow2FaModule } from '../../2fa/2fa.module';
import { FlowTurnstileModule } from '../../turnstile/turnstile.module';
import { FlowMaterialModule } from '../../material/material.module';
import { FlowPipesModule } from '../../pipes/pipes.module';
import { FlowStatusMessageModule } from '../../status-message/status-message.module';

import { FlowDirectivesModule } from '../../directives/directives.module';

import { FlowOnboardingFormResolver } from './resolvers/onboarding-form.resolver';

import { FlowAuthComponent } from './containers/auth/auth.component';
import { FlowLoginComponent } from './components/login/login.component';
import { FlowLogoutComponent } from './components/logout/logout.component';
import { FlowRegisterComponent } from './components/register/register.component';
import { FlowForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FlowSsoComponent } from './components/sso/sso.component';
import { FlowAuthorizeComponent } from './components/authorize/authorize.component';
import { FlowLoginButtonsComponent } from './components/login-buttons/login-buttons.component';
import { FlowChangePasswordComponent } from './components/change-password/change-password.component';
import { FlowChangePasswordComponentResolver } from './components/change-password/change-password.resolver';
import { FlowComponentsModule } from '../../components/components.module';

// Modals
import { FlowModalPrivacyComponent } from './modals/modal-privacy/modal-privacy.component';
import { FlowModalMessageAcceptComponent } from './modals/modal-message-accept/modal-message-accept.component';


@NgModule({
    declarations: [
        FlowAuthComponent,
        FlowLoginComponent,
        FlowLogoutComponent,
        FlowRegisterComponent,
        FlowForgotPasswordComponent,
        FlowChangePasswordComponent,
        FlowSsoComponent,
        FlowAuthorizeComponent,
        FlowLoginButtonsComponent,
        FlowModalPrivacyComponent,
        FlowModalMessageAcceptComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlowMaterialModule,
        FlowComponentsModule,
        FlowDirectivesModule,
        FlowPipesModule,
        FlowAuthViewsRoutingModule,
        Flow2FaModule,
        FlowStatusMessageModule,
        FlowTurnstileModule,
    ],
    providers: [
        FlowOnboardingFormResolver,
        FlowChangePasswordComponentResolver
    ]
})
export class FlowAuthViewsModule { }
