import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FlowCategoryModelInterface, FlowModelsService } from '@flow/core';

import * as fromActions from '../actions/category.actions';

@Injectable()
export class CategoryEffect {

  load$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromActions.FlowCategoriesActions>(fromActions.CategoriesActionTypes.LoadCategories),
      switchMap(() => this.ModelsService.list<FlowCategoryModelInterface>('Category', {
        platform: 'Flow'
      }).pipe(
        map(result => new fromActions.LoadCategoriesSuccess(result)),
        catchError(err => of(new fromActions.LoadCategoriesFail(err)))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private ModelsService: FlowModelsService
  ) {}
}
