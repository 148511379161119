/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { EventEmitter, Injectable, Optional } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FlowEnvService, FlowCmsService, FlowStorageService } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';

import { FlowTokensService } from '../tokens/tokens.service';
import { FlowUserService } from '../user/user.service';

import { FlowUserInterface } from '../../interfaces/user.interface';
import { TwoFactorResponseInterface } from '../../interfaces/two-factor-response.interface';

export class FlowAuthConfigService {
  env: any;
  afterLoginRedirect: string;
  isMarketplace?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FlowAuthService {

  private _config;

  /** Default source for the Storage */
  private _defaultSource = 'TIEKinetix';

  /** Browser key to store the source. */
  private _authSourceKey = 'flow.vendor';

  /** Browser key to store logout redirect url. */
  private _logoutRedirectUrlKey = 'flow.logout.url';

  /** Store Observable for the source */
  private _vendor$: BehaviorSubject <string> = new BehaviorSubject(this._defaultSource);

  /** Observable for the logout Event */
  private _logoutEvent$: EventEmitter <void> = new EventEmitter();

  /** Onboarding source passed on final submit in new onboarding */
  private _onboardingSource = null;

  /** Store path to redirect after login */
  private _redirectStatePath = null;

  constructor(
    @Optional() config: FlowAuthConfigService,
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService,
    private UserService: FlowUserService,
    private TokensService: FlowTokensService,
    private StorageService: FlowStorageService,
    private TranslateService: FlowTranslateService
  ) {
    if (config) {
      this._config = config;
    }
  }

  /** Getter for the login url */
  get loginUrl(): string[] {
    return ['/auth/login'];
  }

  /** Getter for the logout url */
  get logoutUrl(): string[] {
    return ['/auth/logout'];
  }

  /** Getter for the authentication status */
  get isLoggedIn(): boolean {
    return this.UserService.isLoggedIn();
  }

  /** Getter for the default url to redirect the user after loggin in */
  get afterLoginUrl(): string[] {
    return [`${this._config.afterLoginRedirect}`];
  }

  /** Getter to check application for being the marketplace. Used for the custom auth redirect after login. */
  get isMarketplace(): boolean {
    return (this._config.isMarketplace) ? this._config.isMarketplace : false;
  }

  /** Getter for the current source */
  get vendor(): string {
    return this._vendor$.getValue();
  }

  /** Observable for the logout event */
  get logoutEvent$(): Observable <void> {
    return this._logoutEvent$;
  }

  /** Getter for the onboarding source */
  get onboardingSource(): string {
    return this._onboardingSource;
  }

  get logoutRedirectUrl(): string {
    return this.StorageService.get(this._logoutRedirectUrlKey);
  }

  set logoutRedirectUrl(value) {
    this.StorageService.set(this._logoutRedirectUrlKey, value);
  }

  /** Getter for redirect state path */
  get redirectStatePath(): string {
    return this._redirectStatePath;
  }

  /** Setter for redirect state path */
  set redirectStatePath(value: string) {
    this._redirectStatePath = value;
  }

  removeLogoutRedirectUrl() {
    this.StorageService.remove(this._logoutRedirectUrlKey);
  }

  /**
   * Logs out a user
   *
   * 1. Remove the authentication status in the subjects.
   * 2. Remove tokens from the browser.
   */
  logout(): void {
    const tokens = this.TokensService.getTokens();  // we'll need the tokens to call BE /signOut in the _triggerServerLogout

    this.UserService.set(null, false);
    this.TokensService.deleteTokens();
    this._logoutEvent$.emit();

    // Remove also any tokens used for Document Manager widgets
    this.TokensService.deleteTokens(this.TokensService.DM_tokenKey, this.TokensService.DM_refreshTokenKey);

    setTimeout(() => this._triggerServerLogout(tokens)); // timeout so it runs AFTER the app goes in logout state
  }

  /**
   * Logs in a user.
   *
   * 1. Checks if the user has two factor authentication by checking the response status code.
   * 1.1 - If two factor is enabled, it is handled by child services.
   * 2 - Sets the user and the logged in status behaviour subjects.
   * 3 - Sets the tokens in the storage.
   * 4 - Checks the source
   * 5 - Checks the user language to properly translate the application.
   * 5.1 - If the user language is different than the language used till this point, the initial labels are loaded again.
   */
  login(form: { email: string; password: string; rememberMe?: boolean; skipAuth?: boolean }): Observable<any> {
    const source = this.vendor;
    let sourceParam = '';

    if (!this.isDefaultVendor(source)) {
      sourceParam = `?source=${source}`;
    }

    form['fid'] = this.EnvService.fingerprint;

    return this.CmsService
      .post <HttpResponse <FlowUserInterface | TwoFactorResponseInterface>>
      (`signIn${sourceParam}`, form, { observe: 'response' })
      .pipe(
        switchMap(response => {
          if (response.status === 202) {
            return of({
              twoFactor: true,
              data: response.body
            });
          }
          else {
            const user = response.body as FlowUserInterface;
            // console.log("FlowAuthService ::: login() ::: flow-ng17-auth", user);
            return this.afterLogin(user);
          }
        }),
        catchError(err => of(err))
      );
  }

  /**
   * Sets the user into the application state.
   */
  afterLogin(user: FlowUserInterface, skipTokens = false): Observable <boolean> {

    // Set the user in the subject.
    this.UserService.set(user, true);

    // Set the tokens.
    if (!skipTokens) {

      // console.warn('______ngAuth afterLogin PRE setTokens',  );

      this.TokensService.setTokens(user.token, user.refreshToken);
    }
    // commenting this because after logging in with a source in a portal domain
    // the next time the user would log in could have issues, because the source could not be the original one.
    // Set the default source in case the current one is not valid.
    // if (!this.isValidVendor()) {
    //
    //   this.setVendor();
    // }

    this.TranslateService.localizeApp(this.UserService.language, true);

    if (!this.isValidVendor()) {
      this.setVendor();

      /* Only call the getMe again if user has vendors, otherwise it will trigger infinite requests for the getMe and
      therefor potentially cash the BE */
      if (this.UserService.user.vendors.length) {
        return this.getMe();
      }
      else {
        console.error(`ERROR: BE is returning user with empty "vendors" array`);
      }
    }
    else {
      if (this.EnvService.portalDomain !== 'Flow') {
        this.setVendor(this.EnvService.portalDomain);
      }
    }

    return of(true);
  }

  /**
   * Gets the user with parameters from the localStorage.
   * Mainly used when resolving a route.
   */
  getMe(): Observable <boolean> {
    const source = this.vendor;
    const params: any = {};

    if (!this.isDefaultVendor(source)) {
      params.source = source;
    }

    return this.CmsService.get<FlowUserInterface>('me', params)
      .pipe(
        switchMap(user => this.afterLogin(user, true)),
        catchError(() => of(false))
      );
  }

  /**
   * Authorize a channel; e.g. if a 3rd party is including flow in their app and they want a user to be auto authenticated
   * */
  authorize(channel, params): Observable<boolean> {

    // console.warn('______ngAuth authorize', {channel, params} );

    const source = this.vendor;

    return this.CmsService.get<FlowUserInterface>(`authorize/${ channel }`, { ...params, source })
      .pipe(
        switchMap(user => this.afterLogin(user, false)),
        catchError(() => of(false))
      );
  }

  /**
   * Check if given a source, is the default one.
   */
  isDefaultVendor(source?: string): boolean {
    return source === this._defaultSource;
  }

  /**
   * Sets the user source in the storage.
   */
  setVendor(source?: string): void {
    if (!source) {
      source = this._defaultSource;
    }
    // If the source is the default one, remove from the localStorage.
    if (this.isDefaultVendor(source)) {
      this.StorageService.remove(this._authSourceKey);
    }
    else {
      this.StorageService.set(this._authSourceKey, source);
    }

    this._vendor$.next(source);
  }

  getInitialVendor(): string {
    return this.StorageService.get(this._authSourceKey);
  }

  /**
   * Loops over the user vendors array to determine if the current source is valid.
   * This is used when the user logs in.
   */
  isValidVendor(source?: string): boolean {
    let match = false;

    if (!source) {
      source = this.vendor;
    }

    match = this.UserService.user.vendors.some(vendor => vendor === source);

    return !!match;
  }

  /**
   * Sets the user source in the storage.
   */
  setOnboardingSource(source: string): void {
    if (source) {
      this._onboardingSource = source;
    }
  }

  private _triggerServerLogout({token, refreshToken}): void {
    if (token && token !== 'noop') {  // only call the /signOut IF we have an actual token
      const source = this.vendor;
      let sourceParam = '';

      if (!this.isDefaultVendor(source)) {
        sourceParam = `?source=${source}`;
      }

      this.CmsService.get(
        `signOut${sourceParam}`,

        // skipAuth, else the interceptor will add (possible new) tokens with the request, causing the
        // new user to be logged out on the BE, instead of the previous user.
        {skipAuth: true},

        // manually added the (old) tokens, because we're bypassing the interceptor tokens
        {
          'authorizationflow': token,
          'authorizationflow-refresh': refreshToken,
        }
      ).subscribe();
    }
  }
}
