/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FlowLayoutService {

  private _isDesktop$: Observable <boolean>;
  private _isMobile$: Observable <boolean>;

  private _isMobileDevice: boolean;

  private _resize$: Observable <number>;

  private _store$$: {
    loading: BehaviorSubject <boolean>;
    lastError: BehaviorSubject <string>;
  } = {
    loading: new BehaviorSubject(false),
    lastError: new BehaviorSubject(null),
  };

  constructor(
    private router: Router
  ) {

    this._isMobileDevice = this._checkIfMobileDevice();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._store$$.loading.next(false);
        this._store$$.lastError.next(null);
      }
    });

    this._resize$ = fromEvent(window, 'resize').pipe(
      debounceTime(300),
      map(() => window.innerWidth),
      distinctUntilChanged(),
      startWith(window.innerWidth)
    );

    this._isDesktop$ = this._resize$.pipe(
      map(width => width >= 992)
    );

    this._isMobile$ = this._resize$.pipe(
      map(width => width < 768)
    );
  }

  get loading$(): Observable <boolean> {
    return this._store$$.loading.asObservable();
  }

  get lastError$(): Observable <string> {
    return this._store$$.lastError.asObservable();
  }

  get isDesktop$(): Observable <boolean> {
    return this._isDesktop$;
  }

  get isMobile$(): Observable <boolean> {
    return this._isMobile$;
  }

  get isMobileDevice(): boolean {
    return this._isMobileDevice;
  }

  get resize$(): Observable <number> {
    return this._resize$;
  }

  setLoading(status: boolean): void {
    this._store$$.loading.next(status);
  }

  setLastError(message: string): void {
    this._store$$.lastError.next(message);
  }

  private _checkIfMobileDevice(): boolean {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection

    /*
    let hasTouchScreen = false;

    if (navigator && "maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    }
    else if (navigator && "msMaxTouchPoints" in navigator) {
      hasTouchScreen = navigator.msMaxTouchPoints > 0;
    }
    else {
      const mQ = window.matchMedia && matchMedia("(pointer:coarse)");

      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      }
      else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;

        hasTouchScreen = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA) ||
          /Mobi/.test(UA)
        );
      }
    }

    return hasTouchScreen;
    */

    // Previous implementation wasn't suitable for laptops with touch screens
    // Now just check user agent
    const UA = navigator.userAgent;

    return (
      /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
      /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA) ||
      /Mobi/.test(UA)
    );
  }
}
