import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import * as fromStore from '@marketplaceStore';

@Injectable()
export class FlowProductsLoaderGuard  {
  constructor(
    private store: Store< fromStore.FlowMarketplaceStateInterface>
  ) {}

  canActivate(): Observable <boolean> {
    return this.store
      .pipe(
        select(fromStore.getProductsLoaded),
        tap(loaded => {
          if (!loaded) {
            this.store.dispatch(new fromStore.LoadProducts());
          }
        }),
        filter(loaded => loaded),
        take(1)
      );
  }
}
