<div class="row flow-login-actions">
  <button
    *ngIf="showBackButton"
    type="button"
    class="auth-back-button"
    [matTooltip]="labels['login.account_form.btn.back_to_login']"
    [routerLink]="loginUrl"
    tabindex="0">
    <div class="arrow arrow-left">
      <div class="line-wrap">
        <div class="line top"></div>
        <div class="line center"></div>
        <div class="line bottom"></div>
      </div>
    </div>
  </button>
  <button
    *ngIf="showSubmitButton"
    type="submit"
    class="auth-submit-button"
    [disabled]="isTouched && !validForm"
    [matTooltip]="ariaLabel"
    [attr.aria-disabled]="isTouched && !validForm"
    tabindex="0">
    <div class="arrow arrow-right" [hidden]="loading">
      <div class="line-wrap">
        <div class="line top"></div>
        <div class="line center"></div>
        <div class="line bottom"></div>
      </div>
    </div>
    <flow-preloader [hidden]="!loading"></flow-preloader>
  </button>
</div>
