import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FlowSsoService, FlowPasswordChangeHashInterface } from '@flow/auth';

@Injectable()
export class FlowChangePasswordComponentResolver  {
  constructor(
    private SsoService: FlowSsoService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<FlowPasswordChangeHashInterface|any> {
    return this.SsoService.getResetPasswordUserData(route.params.hash)
      .pipe(
        map(result => result),
        catchError(error => error.error)
      );
  }
}
