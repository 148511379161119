/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit } from '@angular/core';
import { FlowAuthService } from '@flow/auth';
import { FlowEnvService, FlowRouterService, FlowUtilsService } from '@flow/core';

@Component({
  selector: 'flow-logout',
  template: ``
})
export class FlowLogoutComponent implements OnInit {

  constructor(
    private EnvService: FlowEnvService,
    private AuthService: FlowAuthService,
    private UtilsService: FlowUtilsService,
    private RouterService: FlowRouterService
  ) { }

  ngOnInit() {
    const { logoutRedirectUrl } = this.AuthService;

    const { appRefresh } = window.history.state;

    this.AuthService.logout();

    if (logoutRedirectUrl) {
      this.AuthService.removeLogoutRedirectUrl();

      document.location.href = this.UtilsService.addHttpPrefix(logoutRedirectUrl);
    }
    else {
      // No app refresh when it is forced not to do so (e.g. on base path)
      if (appRefresh === false) {
        this.RouterService.navigate(this.AuthService.loginUrl);
      }
      // ST033623 - Just switching the state causes sometimes troubles.
      // So forcing application refresh when redirecting to loginUrl.
      else {
        this.EnvService.logWarn('App refresh.');
        document.location.href = this.AuthService.loginUrl[0];
      }
    }
  }

}
