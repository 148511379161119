/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'flow-chat-box-message',
  templateUrl: './chat-box-message.component.html',
  styleUrls: ['./chat-box-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChatBoxMessageComponent {

  @Input() isOwnMessage: boolean;

  @HostBinding('class')
  get classes() {
    return this.isOwnMessage ? 'flow-my-chat-message' : 'flow-chat-message';
  }
}
