<div
  *ngIf="imageUrl && !matIconAsFallback"
  class="d-block position-relative full-height"
  [ngClass]="{'br-half': location === 'menu-top'}"
  [ngStyle]="{'width': width+'px', 'height': height+'px'}"
  flowBackgroundImage
  [imageUrl]="imageUrl"
  [matTooltip]="tooltip"
  matTooltipClass="tooltip-multi-line"
  (imageError)="showMatIconAsFallback($event)">
</div>

<em
  *ngIf="matIconAsFallback && location === 'menu-top'"
  class="material-icons large"
  [matTooltip]="tooltip"
  matTooltipClass="tooltip-multi-line">
  account_circle
</em>