/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Inject, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject, Subscription } from 'rxjs';

import {
  FlowHelpers,
  FlowModelsService,
  FlowUtilsService,
  FlowIdLabelInterface,
  FlowLanguageInterface,
  FlowTextBlockModelInterface
} from '@flow/core';

import { FlowProjectService, FlowUserService } from '@flow/auth';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

@Component({
  selector: 'flow-modal-field-translate',
  templateUrl: './modal-field-translate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowModalFieldTranslateComponent implements OnInit, OnDestroy {

  dialogData: any;

  labels: FlowTranslateLabel;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  errorOnSave$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  languagesSubscription$: Subscription;

  translateForm: UntypedFormGroup;

  defaultLanguage: string;
  selectedLanguages: FlowIdLabelInterface[];

  textBlockPage: string;
  textBlockId: string;
  textBlockType: string;

  errorMessages = {};

  private _platform: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FlowModalFieldTranslateComponent>,
    private formBuilder: UntypedFormBuilder,
    private ProjectService: FlowProjectService,
    private UserService: FlowUserService,
    private UtilsService: FlowUtilsService,
    private ModelsService: FlowModelsService,
    private TranslateService: FlowTranslateService
  ) {
    const defaults: any = {
      title: null
    };

    this.dialogData = Object.assign(defaults, this.data);

    this.loading$.next(true);
  }

  ngOnInit(): void {
    this.labels = this._translateLabels();

    this._setDialogSubtitle();

    this._platform = this.UserService.company;

    this.languagesSubscription$ = this.ProjectService.getProjectLanguages().subscribe(
      (projectLangs: FlowLanguageInterface[]) => {
        this.loading$.next(false);

        this.selectedLanguages = this._getSelectedLanguageNames(projectLangs);
        this.defaultLanguage = this._getDefaultLanguage();

        this.textBlockPage = this._getTextBlockPage(this.dialogData, this._platform);
        this.textBlockId = this._getTextBlockId(this.dialogData, this.textBlockPage);
        this.textBlockType  = this._getTextBlockType(this.dialogData);

        this._createTranslateForm();
        this._populateTranslateForm();
      }
    );
  }

  ngOnDestroy(): void {
    this.languagesSubscription$.unsubscribe();

    this.loading$.complete();
    this.errorOnSave$.complete();
  }

  /**
   * Close modal
   */
  cancel() {
    this.dialogRef.close();
  }

  /**
   * Triggering form.dirty
   */
  onContentChanged() {
    // Strange implementation of ngx-quill:
    // Without binding to the onContentChanged event the form is not set to dirty
    // after adding content.
    // But when binding to this event, it's not necessary to trigger form.dirty
    // manually.
  }

  /**
   * If no translateConfig is passed to this component,
   * then just return the translations.
   * Otherwise store the translations in the textblock model.
   */
  onSubmit() {
    const cleanedFormValue = this.UtilsService.removeEmptyObjectValues(this.translateForm.value);

    // FLOW-264: Replace &nbsp; with whitespace and add <br> tag for empty lines
    const _quillContentFix = (content: string): any => {
      const updatedContent = Object.keys(content).reduce((acc, key) => {
        acc[key] = content[key]
          .replace(/&nbsp;/g, ' ')
          .replace(/<p><\/p>/g, '<p><br></p>');

          return acc;
      }, {});

      return updatedContent;
    };

    if (!this.UtilsService.hasProperty(this.dialogData, 'translateConfig')) {
      this.dialogRef.close(cleanedFormValue);
    }
    else {
      const model = {
        id: this.textBlockId,
        page: this.textBlockPage,
        type: this.textBlockType,
        translations: this.textBlockType === 'htmlarea' ? _quillContentFix(cleanedFormValue) : cleanedFormValue
      };

      // Hide errors if any
      this.errorOnSave$.next(false);

      this.ModelsService
      .update<FlowTextBlockModelInterface>('TextBlock', this.textBlockId, model, { platform: this._platform })
      .subscribe(
        (result) => {
          if (result) {
            // TBD: Show success message
            this.dialogRef.close(result);
          }
          else {
            // TBD: Show failure message
            this.dialogRef.close();
          }
        },
        () => {
          this.errorOnSave$.next(true);
        }
      );
    }
  }

  /**
   * Create translate form
   */
  private _createTranslateForm(): void {
    const { validation, required } = this.dialogData;
    const validators = [];

    // All form fields are required by default
    if (required !== false) {
      validators.push(Validators.required);
      this.errorMessages['required'] = this.labels['generic.error.required'];
    }

    // Additional validation pattern and corresponding error message can be passed
    if (validation) {
      const { pattern, error } = validation;

      if (pattern && error) {
        validators.push(Validators.pattern(pattern));
        this.errorMessages['pattern'] = error;
      }
    }

    this.translateForm = this.formBuilder.group({});

    this.selectedLanguages.forEach(language => {
      const isDefaultLanguage = language.id === this.defaultLanguage;

      this.translateForm.addControl(
        language.id,
        this.formBuilder.control(
          isDefaultLanguage ? this.dialogData.fieldValue : '',
          validators
        )
      );
    });
  }

  /**
   * Get languages to show form fields for each of it
   */
  private _getSelectedLanguageNames(projectLangs: FlowLanguageInterface[]): FlowIdLabelInterface[] {
    const { translateConfig } = this.dialogData;
    const allLanguages = this.UtilsService.getLanguages();
    // was used before, but shows always the same languages on all envs
    const fallbackLanguageNames = this.UserService.getSelectedLanguageNames();

    // allLanguages and projectLangs use lcId property as identifier.
    // Prior implementation was based on using id property as identifier.
    // This helper variable is used to store used language objects with id property.
    let usedLanguageNames = [];

    if (translateConfig) {
      const { selectedLanguages } = translateConfig;

      // Use languages that are passed to this component
      if (selectedLanguages && selectedLanguages.length > 0) {
        usedLanguageNames = allLanguages
        .filter(lang => selectedLanguages.indexOf(lang.lcId) > -1)
        .map(lang => ({ id: lang.lcId, label: lang.description }));

        return usedLanguageNames.length > 0 ? usedLanguageNames : fallbackLanguageNames;
      }
      // Check for project languages
      else if (projectLangs && projectLangs.length > 0) {
        usedLanguageNames = projectLangs.map(lang => ({ id: lang.lcId, label: lang.description }));

        return usedLanguageNames.length > 0 ? usedLanguageNames : fallbackLanguageNames;
      }
      // Use fallback languages
      else {
        return fallbackLanguageNames;
      }
    }
    else {
      // Check for project languages
      if (projectLangs && projectLangs.length > 0) {
        usedLanguageNames = projectLangs.map(lang => ({ id: lang.lcId, label: lang.description }));

        return usedLanguageNames.length > 0 ? usedLanguageNames : fallbackLanguageNames;
      }
      // Use fallback languages
      else {
        return fallbackLanguageNames;
      }
    }
  }

  /**
   * Get default language used as required language
   */
  private _getDefaultLanguage(): string {
    const { translateConfig } = this.dialogData;

    // For now we asume default language to be en-US
    // Needs to be changed for projects not having en-US as language
    const defaultLang = this.TranslateService.getDefaultLanguage();

    //  If default language is passed to this component (e.g. from onboarding form builder),
    // then use this one
    if (translateConfig) {
      const { defaultLanguage } = translateConfig;

      return defaultLanguage ? defaultLanguage : defaultLang;
    }

    return defaultLang;
  }

  /**
   * Get text block id based on translate config
   */
  private _getTextBlockId(dialogData: any, page: string): string {
    let id: string;

    if (this.UtilsService.hasProperty(dialogData, 'translateConfig')) {
      const { tieModel, tieModelId, tieModelJsonPath } = dialogData.translateConfig;

      if (tieModel !== '' && tieModelId !== '' && tieModelJsonPath !== '') {
        id = (tieModel + '.' +  tieModelId + '.' + tieModelJsonPath).toLowerCase();
      }
      else {
        // page = 'pages';
        // id = page + 'frontend.id. ...'
      }

      return `${page}.${id}`;
    }

    return '';
  }

  /**
   * Get text block page based on translate config
   */
  private _getTextBlockPage(dialogData: any, platform: string): string {
    if (this.UtilsService.hasProperty(dialogData, 'translateConfig') &&
        this.UtilsService.hasProperty(dialogData.translateConfig, 'tieModel')) {

      return platform.toLowerCase() || 'flow';
    }

    return 'pages';
  }

  /**
   * Get text block type based on translate config
   */
  private _getTextBlockType(dialogData: any): string {
    const { translateConfig } = dialogData;

    if (translateConfig) {
      const { fieldType } = translateConfig;

      if (fieldType) {
        return fieldType;
      }
    }

    return 'input';
  }

  /**
   * Populate translate form with translations
   */
  private _populateTranslateForm() {
    if (this.textBlockId) {
      const args = {
        platform: this._platform,
        bypassCache: (this.UtilsService.hasProperty(this.dialogData, 'translateConfig') &&
                      FlowHelpers.hasProperty(this.dialogData.translateConfig, 'bypassCache'))
                      ? this.dialogData.translateConfig.bypassCache
                      : false
      };

      this.TranslateService.getTextBlockById(this.textBlockId, args)
      .subscribe((textblock: FlowTextBlockModelInterface)  => {
        if (this.UtilsService.hasProperty(textblock, 'translations') && !this.UtilsService.isEmptyObject(textblock.translations)) {
          const translations = textblock.translations;

          // Decode content if it contains encoded HTML
          if (this.textBlockType === 'htmlarea') {
            for (const prop in translations) {
              if (Object.prototype.hasOwnProperty.call(translations, prop)) {
                translations[prop] = this.UtilsService.decodeHtml(translations[prop]);
              }
            }
          }

          this.translateForm.patchValue(translations);
        }
      });
    }
    else {
      if (!this.UtilsService.isEmptyObject(this.dialogData.translations)) {
        this.translateForm.patchValue(this.dialogData.translations);
      }

      // In cases where any field was not treated as multilingual in the past
      // only one value for this field exists.
      // We use this single value (string) as translation for all project languages.
      else if (typeof this.dialogData.translations === 'string' && this.dialogData.translations !== '') {
        const translations = {};

        this.selectedLanguages.forEach(language => {
          translations[language.id] = this.dialogData.translations;
        });

        this.translateForm.patchValue(translations);
        this.translateForm.markAllAsTouched();
      }
    }
  }

  /**
   * Set the dialogs subtitle
   */
   private _setDialogSubtitle(): void {
    const { subtitle } = this.dialogData;

    this.dialogData.subtitle = subtitle
      ? subtitle
      : this.labels['forms.modal.translate.description'];
  }

  /**
   * Translate the labels
   */
   private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'forms.modal.translate.description',
      'generic.error.required',
      'generic.btn.save',
      'general.btn.remove',
      'generic.btn.cancel'
    ]);
  }
}
