/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowCmsService, FlowEnvService, FlowHelpers } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowDialogsService } from '../dialogs/dialogs.service';

@Injectable({
  providedIn: 'root'
})
export class FlowMediaService {

  constructor(
    private CmsService: FlowCmsService,
    private EnvService: FlowEnvService,
    private UserService: FlowUserService,
    private DialogsService: FlowDialogsService
  ) { }

  upload(data: FormData, params: any = {}) {

    const defaults = {
      repository: false,
      platform: this.UserService.company,
      filenames: false,
    };

    params = { ...defaults, ...params };

    for (const prop in params) {
      if (Object.prototype.hasOwnProperty.call(params, prop)) {
        if (!params[prop]) {
          delete params[prop];
        }
      }
    }

    return this.CmsService.streamProgress(`media/upload`, data, params);
  }

  getVendorLogoUrl(vendor: string): string {
    vendor = vendor.toLowerCase();
    return this._createLink([vendor, 'vendor_logos', `${vendor}.png?t=${FlowHelpers.timestamp()}`]);
  }

  getVendorPortalBackground(vendor: string): string {
    vendor = vendor.toLowerCase();
    return this._createLink([vendor.toLowerCase(), 'backgrounds', `${vendor}.jpg`]);
  }

  previewImage(url: string) {
    return url;
  }

  private _createLink(parts: string[]): string {
    return `${this.EnvService.get('googleImagesUrl')}/${parts.join('/')}`;
  }
}
