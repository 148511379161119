<flow-preloader *ngIf="loading else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white d-flex align-items-center">
    <h5 class="truncate" [innerHTML]="labels['general.label.two_factor_authentication']"></h5>

    <a [href]="tfaHelpLink" target="_blank" tabindex="-1">  <!-- tabindex="-1" needed, otherwise the input field looses its focus -->
      <i class="material-icons color-gray-40" [matTooltip]="labels['general.label.help']">help_outline</i>
    </a>
  </div>
  <mat-dialog-content>
    <div class="d-block">
      <div class="text-center d-block">
        <p [innerHTML]="labels[msgLabel]"></p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-center">
    <button class="button button-close-dialogs"
            [mat-dialog-close]="false"
            [innerHTML]="labels['general.btn.cancel']"
    ></button>
  </mat-dialog-actions>
</ng-template>
