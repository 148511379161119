/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { FlowKeyValueStringInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';

@Injectable({
  providedIn: 'root'
})
export class FlowPortalCustomHelpSiteService {

  constructor(
    private UserService: FlowUserService
  ) { }

  generateMailto(sourceObj: any): string {
    // console.log('portal-custom-help-site.service.ts::generateMailto', arguments);

    const { emailTo, emailCc, emailBcc, emailSubject, emailBody } = sourceObj;

    const params = [];

    if (emailCc || emailBcc || emailSubject || emailBody) {
      if (emailCc)      {
        params.push(`cc=${ emailCc }`);
      }
      if (emailBcc)     {
        params.push(`bcc=${ emailBcc }`);
      }
      if (emailSubject) {
        params.push(`subject=${ encodeURI(emailSubject) }`);
      }
      if (emailBody)    {
        params.push(`body=${ encodeURI(emailBody) }`);
      }
    }

    return `mailto:${ emailTo }${ params.length ? `?${ params.join('&') }` : '' }`;
  }

  getLanguageBasedUrl(urls: string|FlowKeyValueStringInterface): string {
    // backwards compatibility: url of custom help site initially was not language based
    if (typeof urls === 'string' && urls !== '') {
      return urls;
    }
    else {
      return urls[this.UserService.language];
    }
  }
}
