/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  OnInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

/**
 *
 * Lazy loads an image as background Image in the given element.
 * This should be the default way of loading the images inside the app, since it optimizes the critical rendering path in the browser.
 * The application can follow the normal loading while the images the loaded in the background asynchronously.
 *
 * Also, it prevents images from being partially loaded.
 */

@Directive({
  selector: '[flowBackgroundImage]'
})
export class FlowBackgroundImageDirective implements OnInit, OnChanges {

  /** Image URL to load. */
  @Input() imageUrl: string;

  /** Image to load in case the main one fails */
  @Input() fallbackImageUrl?: string;

  /** Background Fill type */
  @Input() backgroundSizeType: 'contain' | 'cover' | 'initial' = 'cover';

  /** To update element's background position (center top by default ) */
  @Input() backgroundPosition: string[] = ['center', 'top'];

  /** Set background image repeat */
  @Input() backgroundRepeat = 'no-repeat';

  @Input() loadEffect: 'fadeIn' | 'blur' = 'fadeIn';

  @Output() imageError = new EventEmitter<boolean>();

  constructor(
    private element: ElementRef,
    public _Renderer2: Renderer2
  ) { }

  ngOnInit() {
    this._setInitialElementClass();
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._resetElementClasses();
    if (changes.imageUrl &&
        !changes.imageUrl.firstChange) {
      this.load();
    }
  }

  /**
   * Loads an image asynchronously.
   */
  _loadImage(source: string): Promise <boolean>  {
    return new Promise(resolve => {
      if (!this.imageUrl) {
        return resolve(false);
      }

      const image = new Image();

      image.src = source;

      image.onload = () => {
        this._setBackgroundImage(image.src);
        return resolve(true);
      };

      image.onerror = () => {
        this.imageError.emit(true);
        return resolve(false);
      };
    });
  }

  load(): void {
    if (!this.imageUrl) {
      this._removeBackground();
    }
    else {
      this._loadImage(this.imageUrl).then(result => {
        if (!result) {
          if (this.fallbackImageUrl) {
            this._loadImage(this.fallbackImageUrl).then();
          }
        }
      });
    }
  }

  /** Sets an initial class to identify all the elements which use this directive */
  _setInitialElementClass(): void {
    this._Renderer2.addClass(this.element.nativeElement, 'background-image-element');
    this._Renderer2.addClass(this.element.nativeElement, `background-image-element-${this.loadEffect}`);
    this._Renderer2.addClass(this.element.nativeElement, `background-image-element-${this.backgroundSizeType}`);
  }

  /** Adds extra classes and the final background position after the image has loaded */
  _setBackgroundImage(url: string): void {
    this._Renderer2.setStyle(this.element.nativeElement, 'background-image', `url(${url})`);
    this._Renderer2.setStyle(this.element.nativeElement, 'background-position', this.backgroundPosition.join(' '));
    this._Renderer2.setStyle(this.element.nativeElement, 'background-repeat', this.backgroundRepeat);
    this._Renderer2.addClass(this.element.nativeElement, 'background-image-loaded');
  }

  _resetElementClasses(): void {
    this._Renderer2.removeClass(this.element.nativeElement, 'background-image-loaded');
  }

  _removeBackground(): void {
    this._Renderer2.removeStyle(this.element.nativeElement, 'background-image');
  }
}
