/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import { Flow2faYubicoService } from '../../services/2fa-yubico/2fa-yubico.service';

@Component({
  selector: 'flow-modal-yubico-register',
  templateUrl: './modal-yubico-register.component.html',
  styleUrls: ['./modal-yubico-register.component.scss']
})
export class FlowModalYubicoRegisterComponent implements OnInit {

  // Needed labels.
  labels: FlowTranslateLabel;

  // modal display content message
  msgLabel: string;

  // IF it's loadeing.
  loading: boolean;

  // IF it's saving.
  saving: boolean;

  // If the registration has succeeded.
  registered = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private TranslateService: FlowTranslateService,
    private Yubico2FaService: Flow2faYubicoService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.TranslateService.fetchLabels([
      'error.maxLength',
      'general.btn.close',
      'general.btn.close_reload',
      'general.label.add.yubico_key',
      'account_settings.two_factor_auth.press_yubico_key',
      'account_settings.label.key_registered',
      'account_settings.label.yubico_registration_start',
      'ppcca.btn.sync',
      'error.two_factor_auth.device_ineligible',
      'error.two_factor_auth.timeout',
      'error.two_factor_auth.request_error',
    ]).subscribe(results => {
      this.labels = results;
      this.loading = false;

      this.msgLabel = 'account_settings.label.yubico_registration_start';
    });
  }

  doRegister(): void {
    this.saving = true;

    this.msgLabel = 'account_settings.two_factor_auth.press_yubico_key';

    setTimeout(() => this.changeDetectorRef.detectChanges());

    this.Yubico2FaService.register()
      .subscribe(result => {
        console.warn('result', result);

        this.saving = false;

        if (result) {
          this.msgLabel = result;

          setTimeout(() => this.changeDetectorRef.detectChanges());
        }
        else {
          // Registration went ok.
          this.registered = true;
        }
      });
  }
}
