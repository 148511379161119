<flow-preloader *ngIf="loading$ | async else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="labels['general.label.note']"></h5>
  </div>
  <mat-dialog-content>
    <form
      [formGroup]="form"
      [attr.aria-label]="labels['general.label.note']">
      <div>
        <div
          class="mb-4 outline-none"
          [innerHTML]="data.content | safe:'html'"
          role="alert"
          aria-live="assertive"
          tabindex="0">
        </div>
        <mat-checkbox formControlName="accept">
          {{ labels['general.label.yes_i_agree'] }}
        </mat-checkbox>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="button button-primary"
      (click)="doAccept()"
      [disabled]="form.invalid"
      [innerHTML]="labels['general.btn.continue']">
    </button>
  </mat-dialog-actions>
</ng-template>
