/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'flow-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowPreloaderComponent {

  @Input() diameter = 48;

  @Input() completed: boolean;

  @Input() classes: string;

  @HostBinding('class')
  get hostClass() {
    return this.classes || '';
  }

  @HostBinding('style.width.px')
  get width() {
    return this.diameter;
  }

  @HostBinding('style.height.px')
  get height() {
    return this.diameter;
  }
}
