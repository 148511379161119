/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'flow-logo',
  templateUrl: './flow-logo.component.html',
  styleUrls: ['./flow-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowLogoComponent {
  @Input() width: string;
  @Input() height: string;
  @Input() viewbox: string;
  @Input() classes: string;
}
