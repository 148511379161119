<div
  *ngIf="portalDescription"
  class="color-text fs-13 mb-3"
  [innerHTML]="portalDescription">
</div>

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [attr.aria-label]="labels['general.tooltip.login']">

  <mat-form-field class="d-block mb-2" [appearance]="'fill'">
    <mat-label [innerHTML]="labels['login.login_form.label.email']"></mat-label>
    <input type="text" matInput formControlName="email" autocomplete="username" required>
    <mat-error
      [hidden]="!form.get('email').invalid"
      [innerHTML]="form.get('email').hasError('required')
        ? labels['generic.error.required.email']
        : form.get('email').hasError('pattern')
        ? labels['generic.error.invalid.email']
        : null"
      role="alert"
      aria-live="assertive">
    </mat-error>
  </mat-form-field>

  <mat-form-field class="d-block mb-2" [appearance]="'fill'">
    <mat-label [innerHTML]="labels['login.login_form.label.password']"></mat-label>
    <input type="password" matInput formControlName="password" autocomplete="password" required>
    <mat-error
      [hidden]="!form.get('password').hasError('required')"
      [innerHTML]="labels['generic.error.required.password']"
      role="alert"
      aria-live="assertive">
    </mat-error>
  </mat-form-field>

  <flow-login-buttons
    [validForm]="form.valid"
    [isTouched]="form.touched"
    [showBackButton]="false"
    [loading]="loading$ | async"
    [ariaLabel]="labels['general.tooltip.login']">
  </flow-login-buttons>
</form>
