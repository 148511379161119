/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FlowCmsService, FlowEnvService } from '@flow/core';
import { FlowUserInterface } from '@flow/auth';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class Flow2faGoogleAuthService {

  constructor(
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService
  ) { }

  /**
   * Start the registration by getting the needed secret.
   */
  registrationStart(): Observable <string> {
    return this.CmsService.post<{secret: string}>('authorize/register/google/start').pipe(
      map(data => data.secret),
      catchError(() => of(null))
    );
  }

  /**
   * Finish the google authenticator registration.
   */
  registrationFinish(data: {secret: string; pincode: number}): Observable <string> {
    return this.CmsService.post<{registry_successful: boolean}>('authorize/register/google/finish', data).pipe(
      map(result => true === result.registry_successful ? null : 'error.two_factor_auth.invalid_pin_code'),
      catchError(() => of('error.two_factor_auth.pin_code_validation'))
    );
  }

  sign(data: any): Observable <FlowUserInterface|string> {
    return this.CmsService.post<FlowUserInterface>('signIn/google', data).pipe(
      catchError((result) => {
        if (result.error.error && result.error.error === 'pincode invalid') {
          return of('error.two_factor_auth.invalid_pin_code');
        }
        return of('error.two_factor_auth.default');
      })
    );
  }

}
