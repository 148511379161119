/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FlowModalYubicoRegisterComponent } from '../../modals/modal-yubico-register/modal-yubico-register.component';
import { Flow2faYubicoService } from '../../services/2fa-yubico/2fa-yubico.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';

@Component({
  selector: 'flow-yubico-register',
  templateUrl: './yubico-register.component.html',
  styleUrls: ['./yubico-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowYubicoRegisterComponent {

  @Output() registrationResult: EventEmitter <boolean> = new EventEmitter<any>();

  constructor(
    private Yubico2FaAuthService: Flow2faYubicoService,
    private ModalsService: FlowDialogsService
  ) {}

  register(): void {
    this.ModalsService.open(FlowModalYubicoRegisterComponent, {
      maxWidth: '330px',
      closeOnNavigation: true,
      disableClose: true
    }).afterClosed().subscribe(result => {
      this.registrationResult.emit(result);
    });
  }
}
