/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowUserService } from '@flow/auth';
import { FlowStorageService } from '@flow/core';

const NOT_ALLOWED_FULL_NAMES = [
  'Administrator Company',
  'ModuleAdmin moduleAdmin',
];
const REGEX = {
  notAllowedFullNames: new RegExp(NOT_ALLOWED_FULL_NAMES.join('|'), 'i'),
};

@Injectable({
  providedIn: 'root'
})
export class FlowMissingInfoService {

  REGEX = REGEX;

  constructor(
    private StorageService: FlowStorageService,
    private UserService: FlowUserService,
  ) { }

  get hasMissingInfo() {
    return !!this.missingInfo.length;
  }

  get missingInfo() {
    const missing = {};

    Object.assign(missing, this.chkFirstNameAndLastName);

    return Object.keys(missing);
  }

  get chkFirstNameAndLastName() {
    const missing = {};

    const { fullName, user: { firstName, lastName } } = this.UserService;

    /* NOTE:
        Using GivenName,FamilyName instead of firstName,lastName as the JSON and endpoint uses GivenName,FamilyName so
        other components don't need to do the mapping.
    */

    if (!firstName) {
      missing['GivenName'] = true;
    }
    if (!lastName)  {
      missing['FamilyName'] = true;
    }


    if (fullName.match(REGEX.notAllowedFullNames)) {
      missing['GivenName'] = true;
      missing['FamilyName'] = true;
    }

    return missing;
  }
}
