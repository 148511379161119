
export const ROLES: any = {
  'none': 999,
  'super-admin': 100,
  'projectadmin': 90,
  'super-reader': 80,
  'admin': 60,
  'module-admin': 40,
  'writer': 20,
  'reader': 10,
  'all': 0
};
