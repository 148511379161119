/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowHttp } from '../../classes/class.http';
import { HttpClient } from '@angular/common/http';
import { FlowEnvService } from '../env/env.service';
import { Observable, of } from 'rxjs';

import { catchError, map, pluck } from 'rxjs/operators';
import { FlowCmsService } from '../cms/cms.service';
import { FlowUserDashboardInterface } from '../../interfaces/dashboard-user.interface';
import { FlowVendorDashboardInterface } from '../../interfaces/dashboard-vendor.interface';
import { FlowHelpers } from '../../classes/class.helpers';

@Injectable({
  providedIn: 'root'
})
export class FlowGoogleStorageService
  extends FlowHttp {

  /** Dashboard files prefix in Google storage for the different environments */
  private readonly _dashboardSuffix: string = '';

  constructor(
    protected _HttpClient: HttpClient,
    protected EnvService: FlowEnvService,
    private CmsService: FlowCmsService
  ) {
    super(_HttpClient, EnvService.get('googleStorageUrl'));

    if (! EnvService.isProduction) {
      this._dashboardSuffix = `_${EnvService.get('name')}`;
    }
  }

  /**
   * Adds the environment suffix to the dashboard file, to have separate files per environment.
   */
  getDashboardSuffix(): string {
    return this._dashboardSuffix;
  }

  /**
   * When getting a dashboard, check the vendor name.
   */
  getDashboard<T>(username: string, platform: string): Observable<T>|any {

    if (!platform) {
      platform = this.EnvService.defaultVendor;
    }

    if (!username) {
      platform = this.EnvService.defaultVendor;
    }

    return this.get<T>
      (`tie-flow-widgets/${FlowHelpers.vendorName(platform)}_${ encodeURIComponent(username) }/dashboard${this._dashboardSuffix}`)
      .pipe(
        pluck('data')
      );
  }

  /**
   * Gets a user dashboard
   */
  getUserDashboard(username: string, platform: string):
    Observable<FlowUserDashboardInterface> {
    return this.getDashboard<FlowUserDashboardInterface>(username, platform)
      .pipe(
        catchError(() => of(this.getEmptyUserDashboard()))
      );
  }

  /**
   * Gets a vendor dashboard
   */
  getVendorDashboard(vendorId: string): Observable<FlowVendorDashboardInterface> {
    return this.getDashboard<FlowVendorDashboardInterface>('project', vendorId)
      .pipe(
        map(result => this.checkVendorDashboard(result as FlowVendorDashboardInterface)),
        catchError(() => of(this.getEmptyVendorDashboard()))
      );
  }


  /** Updates a dashboard */
  updateDashboard(data: FlowUserDashboardInterface|FlowVendorDashboardInterface, alternate?: string):
    Observable<boolean> {

    let fragment = `widgets/dashboard${this.getDashboardSuffix()}`;

    if (alternate) {
      fragment += `?alternate=${alternate}`;
    }

    return this.CmsService
      .post(fragment, {data})
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  checkVendorDashboard(dashboard: FlowVendorDashboardInterface): FlowVendorDashboardInterface {

    if (!dashboard.partners.welcomeTitle) {
      if (dashboard.partners.portalTitle) {
        dashboard.partners.welcomeTitle = dashboard.partners.portalTitle;
        delete dashboard.partners.portalTitle;
      }
      else {
        dashboard.partners.welcomeTitle = {
          id: 'terms.welcome.title',
          page: 'terms',
          translations: {},
          type: 'input'
        };
      }
    }

    if (!dashboard.partners.browserTitle) {
      dashboard.partners.browserTitle = {
        id: 'terms.browser.title',
        page: 'terms',
        translations: {},
        type: 'input'
      };
    }

    if (!dashboard.partners.portalDescription) {
      dashboard.partners.portalDescription = {
        id: 'terms.portal.description',
        page: 'terms',
        translations: {},
        type: 'textarea'
      };
    }

    return dashboard;
  }


  getEmptyUserDashboard(): FlowUserDashboardInterface {
    return {
      widgets: [],
      bookmarks: []
    };
  }

  /**
   * Empty dashboard model for a vendor.
   */
  getEmptyVendorDashboard(): FlowVendorDashboardInterface {
    return {
      bookmarkTemplates: [],
      themes: [],
      theme: 'default',
      selectedBookmarkTemplate: 'default',
      widgets: [],
      partners: {
        widgets: [],
        selectedBookmarkTemplate: 'default',
        exclusiveMode: false,
        homepage: null,
        theme: 'default',
        welcomeTitle: {
          id: 'terms.welcome.title',
          page: 'terms',
          translations: {
            'en-US': ''
          },
          type: 'input'
        },
        browserTitle: {
          id: 'terms.browser.title',
          page: 'terms',
          translations: {
            'en-US': ''
          },
          type: 'input'
        },
        portalDescription: {
          id: 'terms.portal.description',
          page: 'terms',
          translations: {
            'en-US': ''
          },
          type: 'textarea'
        }
      }
    };
  }

}
