/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowHelpers, FlowRouterService, FlowUtilsService } from '@flow/core';
import { FlowProjectService, FlowUserService } from '@flow/auth';
import { Flow2faService } from '@flow/shared';

const SKIP_CLIENTS = [
  'flow-portal-impersonator',
  'flow-portal-external-authentication',
];

@Injectable({
  providedIn: 'root'
})
export class FlowEnforced2FaGuard  {

  constructor(
    private RouterService: FlowRouterService,
    private UserService: FlowUserService,
    private UtilsService: FlowUtilsService,
    private ProjectService: FlowProjectService,
    private TwoFaService: Flow2faService
  ) { }

  /**
   * Check if user needs to be redirected to authenticate path when project has enforced 2fa
   * and user has not activated 2fa yet.
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this._isUserSkippable) {
      return of(true);
    }  // early exit (to bypass 2fa)

    return this.ProjectService.getProjectExtras()
    .pipe(
      map(response => {
        if (response) {
          const enforce2fa = this.UtilsService.getExtra(response, 'enforce2fa', 'fieldName');
          const allowed2Fa = this.UtilsService.getExtra(response, 'allowed2fa', 'fieldName');

          if (enforce2fa) {
            const { value } = enforce2fa;

            // 2fa is enforced
            if (FlowHelpers.parseToPrimitive(value) === true) {
              // User is not using 2fa yet -> enforce it
              if (!this.TwoFaService.isUsing2Fa()) {
                this.RouterService.navigate(['authenticate'], { state: { allowed2Fa } });
                return false;
              }
            }
          }
        }

        return true;
      })
    );
  }

  /**
   * TRUE if current user is skippable, e.g., being impersonated or coming from external SSO.
   */
  private get _isUserSkippable() {
    return SKIP_CLIENTS.includes(this.UserService.user['client_id']);
  }
}
