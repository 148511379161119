/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

import { DataTableDirective } from 'angular-datatables';
import { Api, Config } from 'datatables.net';

import { FlowHelpers, FlowModelsService } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowDatatableService } from '../../services/datatable.service';

import { FlowDatatableActionsInterface, FlowDatatableCustomOptionsInterface } from '../../interfaces';


@Component({
  selector: 'flow-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['../../styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})

export class FlowDatatableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customOptions: FlowDatatableCustomOptionsInterface;
  @Output() dataTableActions = new EventEmitter<FlowDatatableActionsInterface>();
  @ViewChild(DataTableDirective) datatableElement: DataTableDirective;

  labels: FlowTranslateLabel;
  type: string;
  searchText: string;
  viewType = 'list';

  dtOptions: Config = {};

  platform: string;

  constructor(
    private renderer: Renderer2,
    private ModelsService: FlowModelsService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private DatatableService: FlowDatatableService
  ) {
    this.platform = this.UserService.getProperty('platform');
  }

  ngOnInit() {
    // Preloading the default labels for the datatable
    this.TranslateService.translate([
      'general.datatable.info',
      'general.datatable.info_empty',
      'general.datatable.info_filtered',
      'general.datatable.display_records',
      'general.datatable.loading_records',
      'general.datatable.processing',
      'general.datatable.zero_records',
      'general.datatable.clickable',
    ])
    .subscribe(
      (labels) => {
        this.labels = labels;
        this.DatatableService.defaultLabels = labels;
        this._buildDtOptions();
      }
    );

    // Filtering by type: We need to call the $.fn.dataTable like this because DataTables typings do not have the "ext" property
    $.fn['dataTable'].ext.search.push((settings, data) => {
      const type = data[2] || '';

      if ((!this.type) || type.includes(this.type)) {
        return true;
      }

      return false;
    });
  }

  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('data-datatable-action-mode') &&
         (event.target.hasAttribute('data-datatable-row-id') || event.target.hasAttribute('data-redirect-path'))) {

        const actionMode = event.target.getAttribute('data-datatable-action-mode');
        const rowId = event.target.getAttribute('data-datatable-row-id');
        const redirectPath = event.target.getAttribute('data-redirect-path');
        const modalContent = event.target.getAttribute('data-content') || '';

        event.stopPropagation();
        event.preventDefault();

        if (actionMode && (rowId || redirectPath)) {
          if ((actionMode === 'edit' || actionMode === 'copy') && redirectPath !== '') {
            this.dataTableActions.emit({
              actionMode,
              redirectPath
            });
          }

          if (actionMode && rowId) {
            this.dataTableActions.emit({
              actionMode,
              rowId,
              modalContent
            });
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    // We remove the last function in the global ext search array so we do not add the fn each time the component is drawn
    // /!\ This is not the ideal solution as other components may add other search function in this array, so be careful when
    // handling this global variable
    $.fn['dataTable'].ext.search.pop();
  }

  /**
   * Set the view type as list or tile
   */
  setViewType(type) {
    this.viewType = type;

    this.datatableElement.dtInstance.then((dtInstance: Api) => {
      if (type === 'tile') {
        dtInstance.page.len(12).draw();
      }
      else {
        dtInstance.page.len(10).draw();
      }
    });
  }

  filterByType(): void {
    this.datatableElement.dtInstance.then((dtInstance: Api) => {
      // dtInstance.column(2).search(this.type).draw();
      dtInstance.state.save().draw();
      // dtInstance.state.save();
    });
  }

  onSearch(event): void {
    const searchText = event.target.value;

    this.searchText = searchText;
    this.datatableElement.dtInstance.then((dtInstance: Api) => {
      dtInstance.search(searchText).draw();
      // dtInstance.state.save();
    });
  }

  /**
   * Ajax function that fetches the data based on a model
   */
  private _ajaxFnByModel(dataTablesParameters: any, callback) {
    this.ModelsService
      .list(this.customOptions.model, dataTablesParameters, { platform: this.platform })
      .subscribe(resp => {
        callback({
          data: resp.data
        });
      });
  }

  /**
   * Assign ajax property for loading data
   */
  private _setAjaxOption(options) {
    let fn;

    if (this.customOptions.model) {
      fn = this._ajaxFnByModel.bind(this);
    }
    else if (this.customOptions.dataMethod) {
      fn = this.customOptions.dataMethod;
    }

    options['ajax'] = fn;
  }

  /**
   * Build dtOptions based on default options and custom options
   */
  private _buildDtOptions() {
    let defaultOptions;
    let combinedOptions;

    if (
      (
        this.DatatableService.isValidCustomOptions(this.customOptions, 'model')
        ||
        this.DatatableService.isValidCustomOptions(this.customOptions, 'dataMethod')
      )
      &&
      this.DatatableService.isValidCustomOptions(this.customOptions, 'columns')
    ) {
      /**
       * Assign default options
       */
      defaultOptions = this.DatatableService.getDefaultOptions();
      combinedOptions = Object.assign({}, defaultOptions);

      /**
       * Assign ajax property for loading data
       */
      this._setAjaxOption(combinedOptions);

      /**
       * Assign serverSide option to allow server side processing
       */
      if (this.DatatableService.isValidCustomOptions(this.customOptions, 'serverSide')) {
        combinedOptions['serverSide'] = this.customOptions.serverSide;
      }

      /**
       * Assing columns property for columns to show
       */
      combinedOptions['columns'] = [];

      this.customOptions.columns.forEach(column => {
        // The "render" property of a column can be a string resolved in DatatableService
        // or a custom function defined in the calling component
        if (typeof column.hasPermission === 'undefined' || column.hasPermission === true) {
          if (column['render']) {
            // Resolves the "render" property into a function
            if (typeof column['render'] === 'string') {
              const columnRenderer = this.DatatableService.resolveColumnRenderer(column);

              if (columnRenderer === false) {
                delete column['render'];
              }
              else {
                column['render'] = columnRenderer;
              }
            }
          }
          else {
            // If no "render" property is set then render the column value in a generic way also for use in tile view.
            column['render'] = this.DatatableService.renderColumn(column);
          }

          combinedOptions['columns'].push(column);
        }
      });

      /**
       * Assign order behaviour
       */
      if (this.DatatableService.isValidCustomOptions(this.customOptions, 'ordering')) {

        // Activate general ordering
        if (FlowHelpers.hasProperty(this.customOptions.ordering, 'default')) {
          combinedOptions['ordering'] = (this.customOptions.ordering.default === true);
        }

        // Column based ordering
        if (FlowHelpers.hasProperty(this.customOptions.ordering, 'columns')) {
          combinedOptions['order'] = this.customOptions.ordering.columns;
        }
      }

      /**
       * Assign row callback
       */
      if (this.DatatableService.isValidCustomOptions(this.customOptions, 'rowCallback')) {
        combinedOptions['rowCallback'] = (row: Node, data: any[] | object, index: number) => {
          const jqElem = $('td:not(.no-click)', row);

          // normal click event
          jqElem.off('click');
          jqElem.on('click', () => {
            this.DatatableService.handleRowClick(row, data, index, this.customOptions.rowCallback, this.customOptions.itemIdKey);
          });

          // make ENTER key behave same as click (for WCAG - ST033587)
          jqElem.off('keyup.enter2click');
          jqElem.on('keyup.enter2click', event => {
            if (event.which === 13) {
              jqElem.trigger('click');
            }
          });

          // for WCAG - ST033587
          jqElem
            .attr('tabindex', 0);
            // .attr('aria-label', this.labels['general.datatable.clickable']); // removed as only VoiceOver is able to read both the
                                                                                // aria-label AND the cell content. NVDA will only read to
                                                                                // aria-label and ignore the cell content. Cell content is
                                                                                // more important, so aria-label is removed.

          return row;
        };
      }

      /**
       * Assign actions menu
       */
      if (this.DatatableService.isValidCustomOptions(this.customOptions, 'hasPermission') &&
          this.DatatableService.isValidCustomOptions(this.customOptions, 'actionsMenu')) {
            if (this.customOptions['hasPermission']) {
              combinedOptions['columns'].push({
                className: 'datatable__actions-menu dt-right no-click',
                orderable: false,
                width: '33px',
                render: (data: any, type: any, row: any) => this.DatatableService.buildActionsMenu(data, type, row, this.customOptions)
              });
            }
      }

      /**
       * Assign resulting combined options to dtOptions
       */
      this.dtOptions = Object.assign({}, combinedOptions);
    }
    else {
      throw new TypeError('No valid dtOptions object defined!');
    }
  }
}
