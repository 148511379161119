/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uppercaseToWords'
})
export class FlowUppercaseToWordsPipe implements PipeTransform {
  transform(value: any): any {
    return value.match(/[A-Z][a-z]+/g).join(' ');
  }
}
