export interface FlowProvincesInterface {
  countryCode: string;
  provinces: string[];
}

export const PROVINCES: FlowProvincesInterface[] = [
  {
    countryCode: 'NL',
    provinces: [
      'Drenthe',
      'Flevoland',
      'Friesland',
      'Gelderland',
      'Groningen',
      'Limburg',
      'Noord-Brabant',
      'Noord-Holland',
      'Overijssel',
      'Zuid-Holland',
      'Utrecht',
      'Zeeland'
    ]
  },
  {
    countryCode: 'DE',
    provinces: [
      'Baden-Württemberg',
      'Bayern',
      'Berlin',
      'Brandenburg',
      'Bremen',
      'Hamburg',
      'Hessen',
      'Niedersachsen',
      'Mecklenburg-Vorpommern',
      'Nordrhein-Westfalen',
      'Rheinland-Pfalz',
      'Saarland',
      'Sachsen',
      'Sachsen-Anhalt',
      'Schleswig-Holstein',
      'Thüringen'
    ]
  },
];
