/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { FlowUtilsService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';


@Component({
  selector: 'flow-modal-quill',
  templateUrl: './modal-quill.component.html',
  styleUrls: ['./modal-quill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowModalQuillComponent implements OnInit {

  labels: FlowTranslateLabel;

  loading$: BehaviorSubject <boolean> = new BehaviorSubject(true);

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit() {
    this.TranslateService
    .fetchLabels([
      'generic.btn.done',
      'generic.btn.close',
    ])
    .subscribe(data => {
      this.loading$.next(false);
      this.labels = data;

      this.data.html = this.UtilsService.decodeHtml(this.data.html);
    });
  }
}
