/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Flow2FaGoogleAuthModule } from './2fa-google-auth/2fa-google-auth.module';
import { Flow2FaYubicoModule } from './2fa-yubico/2fa-yubico.module';
import { Flow2faGoogleAuthService } from './2fa-google-auth/services/2fa-google-auth/2fa-google-auth.service';
import { Flow2FaSmsAuthModule } from './2fa-sms-auth/2fa-sms-auth.module';
import { Flow2faSmsAuthService } from './2fa-sms-auth/services/2fa-sms-auth/2fa-sms-auth.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    Flow2FaGoogleAuthModule,
    Flow2FaYubicoModule,
    Flow2FaSmsAuthModule,
  ],
  exports: [
    Flow2FaGoogleAuthModule,
    Flow2FaYubicoModule,
    Flow2FaSmsAuthModule,
  ],
  providers: [
    Flow2faGoogleAuthService,
    Flow2faSmsAuthService
  ]
})
export class Flow2FaModule {
}
