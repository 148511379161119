/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, of, Subject} from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { FlowCmsService, FlowEnvService } from '@flow/core';
import { FlowUserInterface } from '@flow/auth';

const CDN = 'https://flow.tiekinetix.net/flow-cdn/yubico_api.js';
const KEY_TIMEOUT = 30;

declare global {
  interface Window {
    u2f: {
      register: (
        appId: string,
        registerRequests: any,
        registeredKeys: any,
        callback: (response) => void,
        opt_timeoutSeconds: number
      ) => void;
      sign: any;
    };
  }
}

export interface YubicoChallengeData {
  appId: string;
  registerRequests: string[];
  registeredKeys: string[];
}

@Injectable()
export class Flow2faYubicoService {

  constructor(
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService
  ) {
    this._loadYubicoApiScript();
  }

  register() {
    return this._registrationStart()
      .pipe(
        switchMap(data => {
          if (data) {
            const subject = new Subject();

            console.warn('data', data);

            console.warn(`
              data.appId= ${ data.appId }
              data.registerRequests= ${ data.registerRequests }
              data.registeredKeys= ${ data.registeredKeys }
            `);

            // if we're on localhost, overwrite the appId, otherwise the yubico will throw error
            if (window.location.hostname.includes('localhost')) {
              data.appId = data.registerRequests[0]['appId'] = window.location.origin;
              console.warn('Y Overwritten appId', data.appId);
            }

            window.u2f.register(
              data.appId,
              data.registerRequests,
              data.registeredKeys,
              result => {
                if (result.errorCode) {
                  subject.next(this._yubicoErrors(result.errorCode));
                }
                else {
                  subject.pipe(
                    switchMap(this._registrationFinish)
                  );
                }

              },
              KEY_TIMEOUT
            );

            return subject as Observable<any>;
          }
          else {  // BE failed
            return of('error.two_factor_auth.default');
          }
        }),
      );
  }

  sign(signature: any): Observable <FlowUserInterface|string> {
    console.warn('Y sign signature', signature);

    const subject = new Subject();
    const { data } = signature;

    window.u2f.sign(
      data.appId,
      data.challenge,
      data.signRequests,
      result => {
        if (result.errorCode) {
          subject.next(this._yubicoErrors(result.errorCode));
        }
        else {
          subject.pipe(
            switchMap(this._verifyAuth)
          );
        }

      },
      KEY_TIMEOUT
    );

    return subject as Observable<any>;
  }

  /**
   * Starts a Yubico Key registration by getting the needed challenge data.
   */
  private _registrationStart(): Observable <YubicoChallengeData|any> {
    return this.CmsService.post('authorize/register/yubico/start')
      .pipe(
        catchError(() => of(null))
      );
  }

  /**
   * Finish the yubico registration / store challenge generated by yubico_api.js.
   */
  private _registrationFinish(data: any): Observable <any> {
    return this.CmsService.post('authorize/register/yubico/finish', data)
      .pipe(
        catchError(() => of('error.two_factor_auth.default'))
      );
  }

  /**
   * Verifies that the yubico sign in was correct and belongs to that user
   */
  private _verifyAuth(data: any): Observable <any> {
    data.fid = this.EnvService.fingerprint;

    return this.CmsService.post('signIn/yubico', data)
      .pipe(
        catchError(() => of('error.two_factor_auth.default'))
      );
  }

  private _loadYubicoApiScript(): Promise <void> {
    let script = document.querySelector(`SCRIPT[src="${ CDN }"]`) as HTMLScriptElement;

    return new Promise(resolve => {
      if (script) {
        // console.warn('_loadYubicoApiScript: available');
        resolve();
      }
      else {
        // console.warn('_loadYubicoApiScript: not available');

        script = document.createElement('script');

        script.src = CDN;
        script.async = true;

        document.head.appendChild(script);

        // Ensure that the script has loaded so the yubico command is available in the window object.
        script.addEventListener('load', () => {
          // this.UserlaneService.init();
          console.warn('_loadYubicoApiScript: loaded');
          resolve();
        });

      }
    });
  }

  /**
   *
   * 'OTHER_ERROR': 1,
   * 'BAD_REQUEST': 2,
   * 'CONFIGURATION_UNSUPPORTED': 3,
   * 'DEVICE_INELIGIBLE': 4,
   * 'TIMEOUT': 5
   *
   */
  private _yubicoErrors(errorCode) {
    console.warn('errorCode=', errorCode);

    switch (errorCode) {
      case 4:   return 'error.two_factor_auth.device_ineligible';
      case 5:   return 'error.two_factor_auth.timeout';
      default:  return 'error.two_factor_auth.request_error';
    }
  }
}




// TODO[Yubico]: search4this
