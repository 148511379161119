import { AbstractControl, ValidatorFn } from '@angular/forms';

export function passwordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (!control.value) {
      return null;
    }
    const valid = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(control.value);
    return !valid ? {'invalidPassword': {value: control.value}} : null;
  };
}

export const rulesRegex = {
  minChars: /(?=^.{8,}$)/,
  numbers: /(?=.*\d)/,
  upperAndLowerChars: /(?=.*[A-Z])(?=.*[a-z])/,
  specialChars: /(?=.*[!@#$%^&*]+)/,
};
