<flow-preloader [classes]="'p-3'" *ngIf="loading$ | async else content"></flow-preloader>
<ng-template #content>
  <div class="p-3 modal-title bg-primary color-white">
    <h5 class="truncate" [innerHTML]="dialogData.title"></h5>
  </div>
  <mat-dialog-content>
    <mat-tab-group
      *ngIf="dialogData.projectTerms else tieTermsOnly"
      mat-stretch-tabs="false"
      mat-align-tabs="start">
      <mat-tab
        [label]="dialogData.project"
        [aria-label]="dialogData.project">
        <div class="p-3" [innerHTML]="dialogData.projectTerms | safe:'html'"></div>
      </mat-tab>

      <mat-tab
        label="SPS Commerce"
        [aria-label]="'SPS Commerce'">
        <div class="p-3" [innerHTML]="dialogData.tieTerms | safe:'html'"></div>
      </mat-tab>
    </mat-tab-group>

    <ng-template #tieTermsOnly>
      <div class="p-3" [innerHTML]="dialogData.tieTerms | safe:'html'"></div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      class="button button-close-dialogs"
      [mat-dialog-close]="false"
      [innerHTML]="dialogData.cancelButtonLabel"
    ></button>
  </mat-dialog-actions>
</ng-template>