import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FlowMaterialModule } from '../material/material.module';
import { FlowPipesModule } from '../pipes/pipes.module';
import { FlowComponentsModule } from '../components/components.module';

import { FlowDeleteConfirmComponent } from './components/modal-delete-confirm/modal-delete-confirm.component';
import { FlowModalFieldTranslateComponent } from './components/modal-field-translate/modal-field-translate.component';
import { FlowModalTextComponent } from './components/modal-text/modal-text.component';
import { FlowModalLanguageSelectionComponent } from './components/modal-language-selection/modal-language-selection.component';
import { FlowModalQuillComponent } from './components/modal-quill/modal-quill.component';
import { FlowModalIframeComponent } from './components/modal-iframe/modal-iframe.component';

@NgModule({
    declarations: [
        FlowDeleteConfirmComponent,
        FlowModalFieldTranslateComponent,
        FlowModalTextComponent,
        FlowModalLanguageSelectionComponent,
        FlowModalQuillComponent,
        FlowModalIframeComponent
    ],
    exports: [
        FlowModalFieldTranslateComponent,
        FlowModalTextComponent,
        FlowModalIframeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlowMaterialModule,
        FlowPipesModule,
        FlowComponentsModule
    ]
})

export class FlowModalsModule { }
