/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FlowTranslateService } from '@flow/translate';
import { FlowHelpers } from '@flow/core';

import { FlowSsoCustomerRoleInterface, FlowSsoUserInterface } from '../interfaces/sso-user.interface';
import { FlowSsoExtraInterface } from '../interfaces/sso-extra.interface';

export interface FlowSsoCustomerFormModelInterface {
  createForm(model: FlowSsoUserInterface): UntypedFormGroup;
  addRole(role: FlowSsoCustomerRoleInterface): UntypedFormGroup;
  addExtra(extra: FlowSsoExtraInterface): UntypedFormGroup;
}

export class FlowSsoCustomerFormModel implements FlowSsoCustomerFormModelInterface {

  constructor(
    private formBuilder: UntypedFormBuilder,
    private TranslateService: FlowTranslateService,
  ) {
  }

  createForm(model: FlowSsoUserInterface): UntypedFormGroup {

    const defaultExtras: FlowSsoExtraInterface[] = [
      {
        PropertyName: 'FlowMenuHidden',
        Value: 'false'
      },
      {
        PropertyName: 'UserLanguage',
        Value: this.TranslateService.getDefaultLanguage()
      },
      {
        PropertyName: 'AllowImpersonation',
        Value: 'true'
      },
      {
        PropertyName: 'FlowRecoveryEmail',
        Value: ''
      },
      {
        PropertyName: 'FlowBookmarksProfile',
        Value: 'default'
      },
      {
        PropertyName: 'Manager',
        Value: ''
      },
      {
        PropertyName: 'trustDevices',
        Value: 'false'
      },
      {
        PropertyName: 'NotificationsByEmail',
        Value: 'true'
      },
      {
        PropertyName: 'NotificationsByEmailDaily',
        Value: 'false'
      },
      {
        PropertyName: 'FlowShowTermsAgreement',
        Value: 'false'
      },
      {
        PropertyName: 'EULA',
        Value: 'false'
      },
      {
        PropertyName: 'FlowPrivacyPolicy',
        Value: 'false'
      },
      {
        PropertyName: 'FlowMarketingPolicy',
        Value: 'false'
      },
      {
        PropertyName: 'GeoProfile',
        Value: ''
      },
      {
        PropertyName: 'AutoLoadProduct',
        Value: 'false'
      },
      {
        PropertyName: 'FlowTwoFactorAuthentication',
        Value: 'false'
      },
      {
        PropertyName: 'TimezoneEnabled',
        Value: 'false'
      },
      {
        PropertyName: 'Timezone',
        Value: 'false'
      },
      // This is the property used by the BE which if set to true
      // {
      //   PropertyName: 'FlowTwoFactorAuthentication',
      //   Value: 'false'
      // }
    ];

    const form = this.formBuilder.group({
      AccessFailedCount: new UntypedFormControl(0),
      Email: new UntypedFormControl('', [Validators.required]),
      EmailConfirmed: new UntypedFormControl(false),
      ExtendedProperties: new UntypedFormArray([]),
      FamilyName: new UntypedFormControl('', [Validators.required]),
      GivenName: new UntypedFormControl('', [Validators.required]),
      Id: new UntypedFormControl(0, [Validators.required]),
      LockoutEnabled: new UntypedFormControl(false),
      LockoutEndDateUtc: new UntypedFormControl(''),
      PhoneNumber: new UntypedFormControl('', [Validators.pattern(/^\+\d{7,14}$/)]),
      PhoneNumberConfirmed: new UntypedFormControl(false),
      Roles: new UntypedFormArray([]),
      TwoFactorEnabled: new UntypedFormControl(false),
      UserName: new UntypedFormControl('', [Validators.required])
    });

    form.patchValue(model);

    if (FlowHelpers.hasProperty(model, 'ExtendedProperties') && !FlowHelpers.isEmptyArray(model.ExtendedProperties)) {
      model.ExtendedProperties.forEach(modelExtra => {
        const foundInDefaultsIndex = defaultExtras.findIndex(defaultExtra => modelExtra.PropertyName === defaultExtra.PropertyName);
        if (foundInDefaultsIndex > -1) {
          defaultExtras[foundInDefaultsIndex].Id = modelExtra.Id;
          defaultExtras[foundInDefaultsIndex].Value = modelExtra.Value;
        }
        else {
          defaultExtras.push(JSON.parse(JSON.stringify(modelExtra)));
        }
      });
    }

    defaultExtras.forEach(defaultExtra => {
      (form.get('ExtendedProperties') as UntypedFormArray).push(this.addExtra(defaultExtra));
    });

    // Set the roles.
    if (FlowHelpers.hasProperty(model, 'Roles') && !FlowHelpers.isEmptyArray(model.Roles)) {
      model.Roles.forEach(role => (form.get('Roles') as UntypedFormArray).push(this.addRole(role)));
    }
    // Parse the extras to primitives.
    (form.get('ExtendedProperties') as UntypedFormArray).controls.forEach(extra => {

      extra.get('Value').setValue(FlowHelpers.parseToPrimitive(extra.get('Value').value));

      if (extra.get('PropertyName').value === 'FlowRecoveryEmail') {
        extra.get('Value').setValue(
          extra.get('Value').value
            ? atob(extra.get('Value').value)
            : defaultExtras.find(item => item.PropertyName === 'FlowRecoveryEmail').Value
        );
      }
      else if (extra.get('PropertyName').value === 'Manager' && extra.get('Value').value === false) {
        extra.get('Value').setValue('');
      }
    });

    return form;
  }

  addRole(role: FlowSsoCustomerRoleInterface): UntypedFormGroup {
    return new UntypedFormGroup({
      RoleId: new UntypedFormControl(role.RoleId, [Validators.required]),
      UserId: new UntypedFormControl(role.UserId, [])
    });
  }

  addExtra(data: FlowSsoExtraInterface): UntypedFormGroup {
    const group: any = {
      PropertyName: new UntypedFormControl(data.PropertyName, [Validators.required]),
      Value: new UntypedFormControl(data.Value || false)
    };

    // Initially on Id properties of the extras a Validators.required was defined.
    // This caused disabled save buttons in some cases where new extras without Id property
    // came into play.
    // As not every extra is shown on the UI of a form a user was not able to see
    // why the form can not be submitted.

    if (FlowHelpers.hasProperty(data, 'Id')) {
      group.Id = new UntypedFormControl(data.Id, []);
    }

    return new UntypedFormGroup(group);
  }

}
