/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';

import { forkJoin } from 'rxjs';

import { FlowCmsService, FlowEnvService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';
import { Flow2faGoogleAuthService } from '../../services/2fa-google-auth/2fa-google-auth.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';

@Component({
  selector: 'flow-modal-google-auth-register',
  templateUrl: './modal-google-auth-register.component.html',
  styleUrls: ['./modal-google-auth-register.component.scss']
})
export class FlowModalGoogleAuthRegisterComponent implements OnInit {

  // The form.
  form: UntypedFormGroup;

  // Needed labels.
  labels: FlowTranslateLabel;

  // IF it's loadeing.
  loading: boolean;

  // IF it's saving.
  saving: boolean;

  // The qr code to scan.
  qrCode: string;

  // If the registration has succeeded.
  registered = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private TranslateService: FlowTranslateService,
    private Google2FaService: Flow2faGoogleAuthService,
    private EnvService: FlowEnvService,
    private CmsService: FlowCmsService,
    private DialogsService: FlowDialogsService,
  ) {
    this.form = this.formBuilder.group({
      pincode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(6)]),
      secret: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.loading = true;
    forkJoin([
      this.TranslateService.fetchLabels([
        'error.maxLength',
        'general.btn.close',
        'general.btn.close_reload',
        'general.label.gauth_register',
        'account_settings.label.validate_pin',
        'general.label.validating_pin',
        'account_settings.label.pin_code',
        'account_settings.two_factor_auth.gauth_registered',
        'account_settings.label.two_factor.google_authenticator.step_one',
        'account_settings.label.two_factor.google_authenticator.step_two'
      ]),
      this.Google2FaService.registrationStart()
    ]).subscribe(results => {
      this.labels = results[0];
      this.form.get('secret').setValue(results[1]);

      const env = this.EnvService.portalPrefix.toUpperCase(); // FLOW-TEST | FLOW-ACCEPT | FLOW
      const account = `${ env }:flow@tiekinetix.com`;
      const otpauth = `otpauth://totp/${ account }`;

      const qrCodeApiPath = `io/qr?${
        [
          `chs=200x200`,
          `chld=L|0`,
          `choe=UTF-8`,
          `cht=qr`,
          `chl=${ encodeURI(otpauth) }?secret=${this.form.get('secret').value}`,
          `issuer=FLOW`,
          `algorithm=SHA1`,
          `digits=6`,
          `period=30`,
        ].join('&')
      }`;

      // NOTE:
      //    the (Flow)BE endpoint replacing the previous google QrCode 3rd-party-service, requires auth headers, and
      //    therefor we cannot follow the previous img url approach, as u cannot pass the auth headers. We can also not
      //    use the flowBackgroundImage directive, as this only supports url-based images and not raw img data.
      this.CmsService.get<HttpResponse<Blob>>(qrCodeApiPath, undefined, undefined, { responseType: 'blob' })
        .subscribe((imgBlob: any) => {
          this.qrCode = URL.createObjectURL(imgBlob);
        });

      this.loading = false;
    });
  }

  doRegister(): void {
    this.saving = true;
    this.Google2FaService
      .registrationFinish(this.form.value)
      .subscribe(result => {
      this.saving = false;
      if (result) {
        this.DialogsService.textModal({
          showOkButton: false,
          content: result
        });
      }
      else {
        // Registration went ok.
        this.registered = true;
      }
    });
  }

  pincodeError(): string {
    return this.TranslateService.instant({
      key: 'error.maxLength',
      params: {
        number: 6
      }
    });
  }
}
