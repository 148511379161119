import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateFileName'
})
export class FlowTruncateFileNamePipe implements PipeTransform {

  transform(value: string, maxLength = 20): string {
    if (!value) {
      return '';
    }

    if (value.length <= maxLength) {
      return value;
    }

    const truncated = value.substring(0, maxLength - 3);

    return `${truncated}...`;
  }

}