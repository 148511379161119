<ng-container [formGroup]="form">
  <div *ngIf="showSelectedAsChips" class="mt-3">
    <mat-chip-listbox #chipList>
      <mat-chip-option
        *ngFor="let option of selectControl.value; let i = index"
        [removable]="true"
        (removed)="removeSelectedOption(option)">
        {{ option }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <mat-form-field>
    <mat-select
      multiple
      [formControlName]="controlName"
      [errorStateMatcher]="matcher"
      (openedChange)="openedChange($event)">

      <!-- Empty mat-select-trigger avoids showing the selected options in the select field once again
           as they are already shown as chips.
      -->
      <mat-select-trigger *ngIf="showSelectedAsChips"></mat-select-trigger>

      <div class="mt-1 mb-2">
        <mat-optgroup (keydown)="$event.stopPropagation()">
          <mat-form-field>
            <input
              #searchTerm
              matInput
              [formControl]="searchTermControl"
              [placeholder]="labels['forms.label.search']"
              [attr.aria-label]="labels['forms.label.search']"
              autocomplete="off">
            <button
              *ngIf="searchTerm.value"
              matSuffix
              class="button button-icon"
              (click)="clearSearch($event)"
              [attr.aria-label]="labels['forms.label.clear_search']"
            ><i class="material-icons">close</i>
            </button>
          </mat-form-field>
        </mat-optgroup>

        <mat-optgroup *ngIf="(filteredOptions$ | async).length === 0">
          <div [innerHTML]="labels['forms.error.no_results']"></div>
        </mat-optgroup>
      </div>

      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option.id"
        (onSelectionChange)="selectionChange($event)"
      ><span [innerHTML]="option.name"></span>
      </mat-option>
    </mat-select>

    <mat-error
      *ngIf="errorMsg && errorMsg !== ''"
      [innerHTML]="errorMsg"
    ></mat-error>
  </mat-form-field>
</ng-container>
