/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
  ViewChild
} from '@angular/core';

import { Store, select } from '@ngrx/store';

import { Observable, BehaviorSubject } from 'rxjs';
import { flatMap, take, map, withLatestFrom, switchMap } from 'rxjs/operators';

import { FlowRouterService, FlowProductModelInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { FlowMarketplaceStateInterface } from '@marketplaceStore/reducers';
import { getBoughtProducts, getFlowProducts } from '@marketplaceStore';

import { FlowMenuMainBookmarksComponent } from '../../components/menu-main-bookmarks/menu-main-bookmarks.component';
import { FlowMarketplaceDashboardService } from '../../../marketplace-shared/services/marketplace-dashboard/marketplace-dashboard.service';
import { FlowProductsService } from '../../../marketplace-shared/services/products/products.service';

@Component({
  selector: 'flow-menu-main',
  templateUrl: './menu-main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowMenuMainComponent implements OnInit {

  @HostBinding('class') class = 'pt-3 d-flex container';

  @ViewChild(FlowMenuMainBookmarksComponent) bookmarksWrapper: FlowMenuMainBookmarksComponent;

  bookmarks$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  boughtProducts$: Observable<FlowProductModelInterface[]>;

  hiddenBookmarks: any = ['a'];

  hideDashboard = false;

  hideSolutionsTab: boolean;

  showShopTab: boolean;

  labels: FlowTranslateLabel;

  constructor(
    private store: Store <FlowMarketplaceStateInterface>,
    private RouterService: FlowRouterService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService,
    private MarketplaceDashboard: FlowMarketplaceDashboardService,
    private ProductsService: FlowProductsService
  ) { }

  ngOnInit(): void {
    this.boughtProducts$ = this.store.select(getBoughtProducts);

    this.labels = this._translateLabels();

    this.showShopTab = this.ProductsService.hasInternalShopPermission();

    if (this.UserService.isActingAsPartner()) {
      this.hideSolutionsTab = true;

      this._createBookmarks(this.MarketplaceDashboard.getProjectBookmarks());
      this._checkHideDashboard();
    }
    else {
      this.hideSolutionsTab = false;

      this._createBookmarks(this.MarketplaceDashboard.getUserBookmarks());
    }
  }

  bookmarksChanged($event): void {
    this.hiddenBookmarks = $event;
  }

  openSolution(bookmark: any, index: number): void {
    const bookmarks = this.bookmarks$.getValue();
    const bookmarkIndex = bookmarks.findIndex(item => item.id === bookmark.id);

    [bookmarks[0], bookmarks[bookmarkIndex]] = [bookmarks[bookmarkIndex], bookmarks[0]];
    this.bookmarks$.next([...bookmarks]);

    this.RouterService.navigate(['/solutions', bookmark.id]);
  }

  /**
   * check if dashboard tab should be hidden for the partner based on the vendor dashboard settings
   */
  private _checkHideDashboard() {
    const { homepage, hideDashboard } = this.MarketplaceDashboard.getProjectDashboard().partners;

    this.hideDashboard = !!(hideDashboard && homepage);
  }


  /**
   * Clean bookmarks from products that are not V2 products
   * and translate them
   */
  private _cleanAndTranslate(bookmarkTemplate) {
    return this.store
      .pipe(
        select(getFlowProducts),
        map(products => bookmarkTemplate.bookmarks.filter(bookmark => products.find(product => product.id === bookmark.id))),
        map(products => this._filterProductsWithoutRoles(products)),
        take(1),
        switchMap(products =>  /* verify the products in the template were actually bought, in order to prevent
                                  legacy wrongly setup templates to show un-allowed products */
          this.store.select(getBoughtProducts)
          .pipe(
            map(boughtProducts => products.filter(product => boughtProducts.find(boughtProduct => boughtProduct.id === product.id))),
          )
        ),
        flatMap(bookmarks => this.MarketplaceDashboard.fetchBookmarksLabels(bookmarkTemplate.templateId, bookmarks)
            .pipe(
              map(labels => {
                // Using translation if available
                bookmarks.forEach(bookmark => {
                  const toCheck = `.${bookmark.id.toLowerCase()}.`;

                  for (const key in labels) {
                    if (key.indexOf(toCheck) > -1 && key !== labels[key]) {
                      bookmark.name = labels[key];
                      break;
                    }
                  }
                });

                return bookmarks;
              })
            ))
      );
  }


  /**
   * Creates translated bookmarks or build them from bought products
   */
  private _createBookmarks(bookmarkTemplate): void {
    // Filter and translate bookmarks
    if (bookmarkTemplate && bookmarkTemplate.bookmarks.length > 0) {
      this._cleanAndTranslate(bookmarkTemplate).subscribe(result => {
        this.bookmarks$.next(result);
      });
    }
    // Show acquired products as default if no bookmarks are defined
    else {
      this._showBoughtProdutsAsFallback().subscribe(result => {
        this.bookmarks$.next(result);
      });
    }
  }

  /**
   * Filter out products without roles for users with portal:writer or portal:reader role.
   * Return all products for other user roles.
   */
  private _filterProductsWithoutRoles(products: any): any {
    if (this.UserService.isWriter() || this.UserService.isReader()) {
      const roles = this.UserService.roles;
      return products.filter(product => !!roles.find(role => role.startsWith(`${product.id}:`)));
    }
    else {
      return products;
    }
  }

  /**
   * In case no bookmark template is selected at all
   * show bought V2 products as fallback
   */
  private _showBoughtProdutsAsFallback() {
    return this.store.select(getBoughtProducts)
    .pipe(
      withLatestFrom(this.store.select(getFlowProducts)),
      map(([boughtProducts, flowProducts]) => boughtProducts
        .filter(boughtProduct => flowProducts.find(flowProduct => flowProduct.id === boughtProduct.id))
        .map(product => ({id: product.id, name: product.name }))),
      map(products => this._filterProductsWithoutRoles(products))
    );
  }


  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'account_settings.label.dashboard',
      'account_settings.label.solutions',
      'general.label.internal_shop',
      'general.label.more'
    ]);
  }
}
