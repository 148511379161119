import { Injectable } from '@angular/core';


import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { FlowEnvService } from '@flow/core';
import { FlowSsoService } from '@flow/auth';

const INTERNAL_ONBOARDING_REGEX = /\/\/onboarding(-test|-preaccept|-accept)?\.tiekinetix\.net/;

@Injectable()
export class FlowOnboardingFormResolver  {

  constructor(
    private SsoService: FlowSsoService,
    private EnvService: FlowEnvService
  ) {}

  resolve(): Observable<any> {
    return this.SsoService.getOnBoardingFormForPartners()
      .pipe(
        map(data => {
          const config = {};
          const project = this.EnvService.portalDomain;

          if (data.url) {
            config['registerLink'] = `/onboarding/${project}`;

            // flag to indicate if the onboard is internal or not. // TRUE for the new onboarding || FALSE for 3rd party e.g. jotforms
            config['internalOnboarding'] = INTERNAL_ONBOARDING_REGEX.test(data.url);
          }

          if (data.partnerRecruit) {
            config['registerPartnerLink'] = `/onboarding-partners/${project}`;
          }

          config['disableLogin'] = data.disableLogin;
          config['hideRegisterLink'] = data.hideRegisterLink;
          config['showPartnerRegisterLink'] = (data.partnerRecruit) ? true : false;
          config['isSupplierPortal'] = data.isSupplierPortal;
          config['CompanyName'] = data.CompanyName;

          return config;
        }),
        catchError(() => of({'onBoardingError': true}))
      );
  }
}
