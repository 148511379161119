/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';

import { FlowEnvService, FlowUtilsService } from '@flow/core';
import { FlowAuthService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-modal-privacy',
  templateUrl: './modal-privacy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowModalPrivacyComponent implements OnInit {

  dialogData: any = {};

  loading$: BehaviorSubject <boolean> = new BehaviorSubject(false);

  constructor(
    private EnvService: FlowEnvService,
    private UtilsService: FlowUtilsService,
    private AuthService: FlowAuthService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit(): void {
    this.loading$.next(true);

    forkJoin([
      this.TranslateService.fetchLabels([
        'general.privacy_policy.headline',
        'general.privacy_policy.content',
        'login.footer.link.privacy',
        'generic.btn.close'
      ]),
      this.TranslateService.getTextBlockById('terms.legal', { platform: this.EnvService.portalDomain })
    ])
    .subscribe(
      results => {
        const currentLang = this.TranslateService.getCurrentLanguage();
        const defaultLang = this.TranslateService.getDefaultLanguage();

        this.dialogData.tieTerms = results[0]['general.privacy_policy.content'];

        // Decode tieTerms
        if (this.dialogData.tieTerms) {
          this.dialogData.tieTerms = this.UtilsService.decodeHtml(this.dialogData.tieTerms);
        }

        this.dialogData.projectTerms = results[1]
                                       ? results[1].translations[currentLang]
                                         ? results[1].translations[currentLang]
                                         : results[1].translations[defaultLang]
                                           ? results[1].translations[defaultLang]
                                           : false
                                       : false;

        if (this.dialogData.projectTerms) {
          // Decode projectTerms
          this.dialogData.projectTerms = this.UtilsService.decodeHtml(this.dialogData.projectTerms);

          this.dialogData.title = results[0]['login.footer.link.privacy'];
          this.dialogData.project = this.AuthService.vendor;
        }
        else {
          this.dialogData.title = results[0]['general.privacy_policy.headline'];
        }

        this.dialogData.cancelButtonLabel = results[0]['generic.btn.close'];

        this.loading$.next(false);
      }
    );
  }
}
