/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, OnChanges, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { PROVINCES } from '../constants/provinces';

@Component({
  selector: 'flow-provinces-dropdown',
  templateUrl: './provinces-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowProvincesDropdownComponent implements OnInit, OnChanges {

  @Input() formGroup: UntypedFormGroup;

  @Input() provinceControlName: string;

  @Input() country: string;

  @Input() inlineLabel: string;

  @Input() hintLabel: string;

  @Input() required: boolean;

  // In onboarding module there's a red asterix on the form group label.
  // In company information it is on the field.
  @Input() hideRequiredMarker: boolean;

  @Input() triggerErrorMsg: boolean;

  @Input() requiredErrMsg: string;

  provinces: string[];

  constructor(
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  get provinceCountrol(): UntypedFormControl {
    return this.formGroup.get(this.provinceControlName) as UntypedFormControl;
  }

  ngOnInit() {
    // Set required validator based on input
    this.provinceCountrol.setValidators(this.required ? [Validators.required] : null);
  }

  ngOnChanges(): void {
    this.provinces = this._getProvinces(this.country);

    // Trigger error if selected province is not part of current provinces list
    if (
        this.provinceCountrol.value
        &&
        this.provinces
        &&
        this.provinces.indexOf(this.provinceCountrol.value) === -1
       ) {
      this.provinceCountrol.setValue(null);
    }

    // Special case for onboarding: Trigger error message
    if (this.triggerErrorMsg) {
      this.formGroup.markAllAsTouched();
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Get provinces for given country code
   */
  private _getProvinces(country: string): string[] | null {
    const foundProvince = PROVINCES.find(province => province.countryCode === country);

    return foundProvince ? foundProvince.provinces : null;
  }
}
