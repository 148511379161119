/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef, ChangeDetectorRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { FlowLayoutService } from '../../../services/layout/layout.service';

@Directive({
  selector: '[showOnDesktop]'
})
export class FlowShowOnDesktopDirective implements OnInit, OnDestroy {

  subscription$$: Subscription = new Subscription();

  constructor(
    private templateRef: TemplateRef <any>,
    private changeDetectorRef: ChangeDetectorRef,
    private viewContainer: ViewContainerRef,
    private LayoutService: FlowLayoutService
  ) { }

  ngOnInit(): void {
    this.subscription$$ = this.LayoutService.isDesktop$
      .pipe(
        tap(() => this.viewContainer.clear()),
        filter(isDesktop => isDesktop)
      )
      .subscribe(() => {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }

}
