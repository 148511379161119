import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

interface Node {
  key: string;
  value?: any;
  children?: Node[];
}

@Component({
  selector: 'flow-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowJsonViewerComponent implements OnInit {

  @Input() valueTermHighlightFn: (v) => typeof v;

  @Input() data: object;  // TODO: ts?

  treeControl = new NestedTreeControl<Node>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<Node>();

  hasChild = (_: number, node: Node) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    const treeData = this._formatToTree(this.data);

    this.dataSource.data = treeData;
  }

  /**
   * Format JSON obj to an array of entries suitable to be rendered by the MAT-TREE component
   */
  private _formatToTree(data) {
    const tree = [];

    Object.entries(data).forEach(([key, value]) => {
      const entry: Node = { key, };

      if (typeof value === 'object' || Array.isArray(value)) {
        entry.children = this._formatToTree(value);
      }
      else {
        entry.value = this.valueTermHighlightFn ? this.valueTermHighlightFn(value) : value;
      }

      tree.push(entry);
    });

    return tree;
  }
}
