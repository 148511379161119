<header class="chat-box-title d-flex align-items-center justify-content-between">
  <flow-gravatar
    [classNames]="['mr-2', 'user-avatar', 'bg-white', 'p-1', 'br-3']"
    [width]="24"
    [height]="24"
    [email]="conversation.to"></flow-gravatar>

  <button
    (click)="closeChat.emit()"
    [matTooltip]="labels['generic.btn.close']"
    class="button button-icon button-white-outline button-no-border">
    <i class="material-icons animated rotateIn">close</i>
  </button>
</header>
<div class="chat-box-body p-3">
  <flow-chat-box-message></flow-chat-box-message>
  <flow-chat-box-message [isOwnMessage]="true"></flow-chat-box-message>
  <flow-chat-box-message [isOwnMessage]="true"></flow-chat-box-message>
  <flow-chat-box-message [isOwnMessage]="true"></flow-chat-box-message>
  <flow-chat-box-message></flow-chat-box-message>
  <flow-chat-box-message></flow-chat-box-message>
  <flow-chat-box-message></flow-chat-box-message>
  <flow-chat-box-message></flow-chat-box-message>
</div>
<div class="chat-box-message">
  <textarea></textarea>
</div>

