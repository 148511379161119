/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';
import { FlowEnvService, FlowHelpers } from '@flow/core';

import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { Flow2faSmsAuthService } from '../../services/2fa-sms-auth/2fa-sms-auth.service';
import { Flow2faService } from '../../../../../services/2fa/2fa.service';

@Component({
  selector: 'flow-modal-sms-auth-sign',
  templateUrl: './modal-sms-auth-sign.component.html',
  styleUrls: ['./modal-sms-auth-sign.component.scss']
})
export class FlowModalSmsAuthSignComponent implements OnInit {

  form: UntypedFormGroup;

  labels: FlowTranslateLabel;

  loading = true;

  loggingIn = false;

  tfaHelpLink = this.tfaService.tfaHelpLink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef <FlowModalSmsAuthSignComponent>,
    private formBuilder: UntypedFormBuilder,
    private Sms2FaService: Flow2faSmsAuthService,
    private TranslateService: FlowTranslateService,
    private EnvService: FlowEnvService,
    private DialogsService: FlowDialogsService,
    private tfaService: Flow2faService,
  ) {
    this.form = this.formBuilder.group({
      requestid: new UntypedFormControl(this.data.requestid, [Validators.required]),
      pincode: new UntypedFormControl('', [Validators.required]),
      fid: new UntypedFormControl(this.EnvService.fingerprint, [Validators.required]),
    });
  }

  ngOnInit() {
    this.TranslateService.fetchLabels([
      'general.label.two_factor_authentication',
      'account_settings.label.pin_code',
      'general.label.validating_pin',
      'account_settings.label.validate_pin',
      'general.btn.close',
      'general.label.help',
    ]).subscribe(labels => {
      this.loading = false;
      this.labels = labels;
    });
  }

  doLogin(): void {
    this.loggingIn = true;
    this.Sms2FaService.sign(this.form.value).subscribe(result => {
      if (FlowHelpers.isObject(result)) {
        this.matDialogRef.close(result);
      }
      else {
        this.loggingIn = false;
        this.DialogsService.textModal({
          showOkButton: false,
          content: result
        });
      }
    });
  }

  pincodeError(): string {
    return this.TranslateService.instant({
      key: 'error.maxLength',
      params: {
        number: 6
      }
    });
  }
}
