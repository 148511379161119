<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [attr.aria-label]="labels['login.login_form.link.register_user']">

  <ng-container *ngIf="registerStatus$$ | async as result">

    <ng-container *ngIf="'yes' === result">
      <div class="p-2 mb-3 text-center">
        <i class="material-icons color-primary icon-3x animated bounceIn">check</i>
        <p [innerHTML]="labels['login.account_form.email_text.register_company']"></p>
      </div>
    </ng-container>

    <ng-container *ngIf="'no' === result">
      <!-- NOTE:  from now on, only companies with (internal) onboarding can register on this page and since when a
                  company has onboarding then the "name" field should not be show, we completely remove the field.
      <mat-form-field class="md-block mb-2" [appearance]="'fill'" *ngIf="!partnersOnBoardingData.hasInternalOnboarding">
        <mat-label [innerHTML]="labels['login.account_form.label.company']"></mat-label>
        <input matInput formControlName="name" autocomplete="company" required>
        <mat-error
          [hidden]="!form.get('name').hasError('required')"
          [innerHTML]="labels['generic.error.required']"></mat-error>
      </mat-form-field>-->

      <div
        class="color-text fs-13 mb-3"
        [innerHTML]="labels['login.account_form.note_for_existing_account']"
      ></div>

      <mat-form-field class="md-block mb-2" [appearance]="'fill'">
        <mat-label [innerHTML]="labels['login.account_form.label.country']"></mat-label>
        <mat-select
          formControlName="country"
          disableOptionCentering
          required
          panelClass="country-select">
          <mat-option
            *ngFor="let country of countries"
            [value]="country.cca2">
            {{ country.viewName }}
          </mat-option>
        </mat-select>
        <mat-error
          [hidden]="!form.get('country').hasError('required')"
          [innerHTML]="labels['generic.error.required']"
          role="alert"
          aria-live="assertive">
        </mat-error>
      </mat-form-field>

      <mat-form-field class="md-block mb-2" [appearance]="'fill'">
        <mat-label [innerHTML]="labels['login.login_form.label.email']"></mat-label>
        <input type="email" matInput formControlName="email" autocomplete="email" required>
        <mat-error
          [hidden]="!form.get('email').invalid"
          [innerHTML]="form.get('email').hasError('required')
            ? labels['generic.error.required.email']
            : form.get('email').hasError('pattern')
              ? labels['generic.error.invalid.email']
              : null"
          role="alert"
          aria-live="assertive">
          </mat-error>
      </mat-form-field>

      <div class="human-verification-container">
        <flow-turnstile formControlName="recaptcha" [tabIndex]="0"></flow-turnstile>
      </div>

      <div class="mat-checkbox-wrapper d-block mt-2 mb-3">
        <mat-checkbox formControlName="acceptTerms" required>
          <span class="mr-1" [innerHTML]="labels['login.label.accept_applicability']"></span>
          <span
            class="color-blue-link"
            *ngIf="!partnersOnBoardingData?.isSupplierPortal"
            (click)="openPrivacyPolicy($event)"
            [innerHTML]="labels['login.label.tie_privacy_statement']"
          ></span>
          <span
            [ngClass]="{'color-blue-link':customerPrivacyUrl}"
            *ngIf="partnersOnBoardingData?.isSupplierPortal"
            (click)="openCustomerPrivacyPolicy($event)"
            [innerHTML]="customerPrivacyLabel"
          ></span>
        </mat-checkbox>
      </div>
    </ng-container>

    <flow-login-buttons
      [showBackButton]="true"
      [showSubmitButton]="'no' === result"
      [validForm]="form.valid"
      [isTouched]="form.touched"
      [loading]="loading$ | async"
      [ariaLabel]="labels['login.login_form.link.register_user']">
    </flow-login-buttons>

  </ng-container>
</form>
