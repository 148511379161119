/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 * @see: https://developer.zendesk.com/embeddables/docs/widget/core
 *
 * Recipes to prevent CSP errors: https://csp.withgoogle.com/docs/adopting-csp.html
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, pluck, switchMap } from 'rxjs/operators';

import {
  FlowHelpers,
  FlowCmsService,
  FlowIframeMessageService,
  FlowUtilsService,
  FlowProductModelInterface,
  KinetixExtraModel
} from '@flow/core';

import { FlowUserService, FlowSsoService } from '@flow/auth';
import { FlowLayoutService, FlowDialogsService, FlowPortalService } from '@flow/shared';

import { FlowUserlaneService } from '../../../marketplace-shared/services/userlane/userlane.service';

declare global {
  interface Window {
    zEmbed: any;
    zE: any;
    zESettings: any;
    flowInitZendesk: any;
  }
}

declare function zE(widget: string, command?: string, extras?: any);
declare function zE(callback: () => void);

const LOCAL_ZENDESK_SCRIPT_PATH = '/assets/scripts/zendesk/';

/* https://support.zendesk.com/api/v2/rosetta/locales/public.json */
const ZENDESK_SUPPORTED_LOCALES = [
  'en-US',
  'es',
  'de',
  'zh-tw',
  'zh-cn',
  'pl',
  'fr',
  'pt-br',
  'it',
  'ro',
  'is',
  'vi',
  'ru',
  'he',
  'no',
  'fil',
  'ar',
  'ja',
  'ko',
  'sl',
  'hr',
  'id',
  'cs',
  'th',
  'fi',
  'tr',
  'sv',
  'el',
  'bg',
  'et',
  'da',
  'sk',
  'nl',
  'hu',
  'pt',
  'fa',
  'ca',
  'lt',
  'lv',
  'sr',
  'uk',
  'en-gb',
  'en-ca',
  'es-es',
  'fr-ca',
  'es-419',
  'en-au',
  'en-in',
  'en-ie',
  'en-sg',
  'en-nz',
  'en-za',
  'fr-be',
  'fr-ch',
  'nl-be',
  'de-at',
  'de-ch',
  'hi',
  'ms',
  'en-be',
  'es-mx',
  'fr-fr',
  'en-ph'
];

const INJECTED_CUSTOM_HELP_BTN = {
  id: 'tieCustomHelpSiteBtn',
  style: (themeColor, bottomValue) => `
      position: absolute;
      bottom: ${ bottomValue /* 16px between ifr and widget + 15px between btn and widget = 31px, 15px on mobile device */ }px;
      left: ${ 36 /* 16px between ifr and widget + 20px between btn and widget */ }px;
      z-index: 999;
      background: ${ themeColor };

      /* copy from Zendesk their own styling */
      font-size: 14px;
      color: #fff;
      padding: 0 15px;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
      height: 2.71429rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 2.57143rem;
  `,
};

const IFRAME_MSG_ACTION = 'zendesk.custom-help-site.open-modal';


@Component({
  selector: 'flow-help-zendesk',
  templateUrl: './help-zendesk.component.html'
})
export class FlowHelpZendeskComponent implements AfterViewInit, OnDestroy {

  @Input() label: string;
  @Input() customHelpSiteUrl: string;
  @Input() customHelpSiteInModal = false;
  @Input() products: FlowProductModelInterface[];
  @Input() projectExtras: KinetixExtraModel[];

  @ViewChild('zeButtonPlaceholder') zEButton: ElementRef;

  loading = true;

  displayed: boolean;

  isMobileDevice: boolean;

  private _zenDeskAlias = 'flow';
  private _zenDeskAccountAlias: string;
  private _zenDeskAccounts = {
    flow: 'tiekinetixflow.zendesk.com',
    dg:   'tiekinetixdemandgen.zendesk.com',
    us:   'tiekinetix.zendesk.com',
    nl:   'tiekinetixnl.zendesk.com',
    fr:   'tiekinetixfr.zendesk.com',
    ao:   'tiekinetixanalytics.zendesk.com'
  };

  private _showZendeskForPartners: boolean;
  private _portalThemeColor: string;

  private _zendeskUserTagsSubscription$$: Subscription = new Subscription();
  private _iframeMsgOpenModalSubscription$$: Subscription;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private CmsService: FlowCmsService,
    private IframeMessageService: FlowIframeMessageService,
    private UtilsService: FlowUtilsService,
    private UserService: FlowUserService,
    private SsoService: FlowSsoService,
    private LayoutService: FlowLayoutService,
    private DialogsService: FlowDialogsService,
    private PortalService: FlowPortalService,
    private UserlaneService: FlowUserlaneService

  ) {
    this._iframeMsgOpenModalSubscription$$ = IframeMessageService.on(
      IFRAME_MSG_ACTION,
      this._openCustomHelpSiteModal.bind(this)
    );

    this.isMobileDevice = this.LayoutService.isMobileDevice;
    this._portalThemeColor = this.PortalService.getPortalThemeColor();
  }

  ngAfterViewInit(): void {
    this._zendeskUserTagsSubscription$$ = this.tagUser()
      .pipe(
        switchMap(() => this.SsoService.getOnBoardingFormForPartners())
      )
      .subscribe(result => {
        this.unload();

        this._zenDeskAccountAlias = this._zenDeskAccounts[this._zenDeskAlias];
        this._showZendeskForPartners = result.showZendeskForPartners || false;

        this.load();
      });
  }

  ngOnDestroy(): void {
    this.unload();
    this._zendeskUserTagsSubscription$$.unsubscribe();
    this._iframeMsgOpenModalSubscription$$.unsubscribe();
  }

  tagUser() {
    return this.getZendeskUser()
      .pipe(
        switchMap(result => {
          const user = result && result.users && result.users[0];
          const company = this.UserService.company.toLowerCase();

          /* DEPRECATED: updateZendeskUser is replaced by updateZendeskUserTags
          if ( user && user.tags && !user.tags.includes(company) ) {
            return this.updateZendeskUser( user.id, { tags: [ company ] } );
          }*/

          if (user) {
            return this.updateZendeskUserTags(user.id);
          }

          return of(null);
        }),
      );
  }

  getZendeskUser(): Observable<any> {
    return this.CmsService.post(
        `com.tiekinetix.kinetix.shop.model.zendesk/api/${ this._zenDeskAlias }?version=2`,
        {
          method: 'GET',
          endpoint: '/api/v2/users/search.json',
          params: `query=${ this.UserService.username }`
        }
      )
      .pipe(
        catchError(err => of(null))
      );
  }

  /* DEPRECATED: updateZendeskUser is replaced by updateZendeskUserTags
  updateZendeskUser(id, data): Observable<any> {
    return this.CmsService.post(
        `com.tiekinetix.kinetix.shop.model.zendesk/api/${ this._zenDeskAlias }?version=2`,
        {
          method: 'POST',
          endpoint: `/api/v2/users/${ id }/tags.json`,
          data
        }
      )
      .pipe(
        switchMap( () => this.updateZendeskUserTags(id) ),
        catchError(err => of(null) )
      );
  }*/

  updateZendeskUserTags(userId): Observable<any> {
    return this.CmsService.post(`com.tiekinetix.kinetix.shop.model.zendesk/updateUser/${ userId }`, {
        tags: this.UserlaneService.getCurrentUserTags('zendesk'),
      })
      .pipe(
        catchError(err => of(null))
      );
  }

  loadZendeskJwt(): Observable<any> {
    return this.CmsService.post(`com.tiekinetix.kinetix.shop.model.zendesk/jwt`, {
      tags: this.UserlaneService.getCurrentUserTags('zendesk'),
      version: 2
    })
      .pipe(
        pluck('token')
    );
  }

  initZendesk(): void {
    window.zESettings = {
      webWidget: {
        authenticate: {jwtFn: (callback) => this.loadZendeskJwt().subscribe(result => callback(result))},
        position: {horizontal: 'right', vertical: 'top'},
        offset: {horizontal: '0px', vertical: '15px'},
        contactForm: {
          suppress: this.UserService.isActingAsPartner() && !this._showZendeskForPartners,
          fields: [
            { id: 'email', prefill: { '*': this.UserService.username } },
            { id: 'name', prefill: { '*': this.UserService.fullName } }
          ]
        },
        chat: {
          suppress: this.UserService.companyInformation.Country !== 'US'  /* chat is only enabled for US customers */
        }
      },
      color: {
        theme: this._portalThemeColor,
        launcher: this._portalThemeColor,
        button: this._portalThemeColor,
        header: this._portalThemeColor
      }
    };

    // Set the widget locale
    setTimeout(this._setWidgetLanguage.bind(this));

    // Set widget suggestions based on project extra 'zendeskContext'
    setTimeout(this._setWidgetSuggestions.bind(this));

    // Original Zendesk Web Widget code moved to Typescript.
    let n;
    let o;
    let d;
    let i;
    let s;

    const a = [];
    const r: any = document.createElement('iframe');

    window.zEmbed = function(...args) {
      a.push(args);
    },
    window.zE = window.zE || window.zEmbed,
    r.id = 'zendesk-iframe',

    // ST038543: JavaScript URLs are blocked by CSP policy.
    // r.src = 'javascript:false', (prevents showing attached document to iframe in developper tools)

    // Alternative 'about:blank' works, but shows attached document to iframe.
    // The following solves both:
    r.src = LOCAL_ZENDESK_SCRIPT_PATH + 'iframe.js',

    r.title = '',
    r.role = 'presentation',
    (r.frameElement || r).style.cssText = 'display: none',

    d = document.getElementsByTagName('script'),
    d = d[d.length - 1],
    d.parentNode.insertBefore(r, d),
    i = r.contentWindow,
    s = i.document;

    try {
      o = s;
    }
    catch (e) {
      n = document.domain, r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);', o = s;
    }

    o.open()._l = function(account) {
      const e = this.createElement('script');
      // this.do('hide');

      n && (this.domain = n);
      e.id = 'flow-zendesk';
      e.src = 'https://static.zdassets.com/ekr/snippet.js?key=862c90f8-2dd7-4d2b-bd90-7ddc5eef3ff5';
      this.t = +new Date();
      this.zendeskHost = account;
      this.zEQueue = a;
      this.body.appendChild(e);


      /* n && (this.domain = n),
        e.id = 'flow-zendesk',
        e.src = 'https://static.zdassets.com/ekr/snippet.js?key=862c90f8-2dd7-4d2b-bd90-7ddc5eef3ff5',
        this.t = +new Date(),
        this.zendeskHost = account,
        this.zEQueue = a,
        this.body.appendChild(e); */
    },

    // ST038543: Inline event handlers are blocked by CSP policy.
    // o.write('<body onload="document._l(\'' + this._zenDeskAccountAlias + '\');">'),

    // Working solution is to add event listener in separate js file.
    o.write('<body id="zendesk-iframe-body" data-zendesk-account-alias="' + this._zenDeskAccountAlias + '">');
    const onloadScript = document.createElement('script');
    onloadScript.src = LOCAL_ZENDESK_SCRIPT_PATH + 'onload.js';
    o.body.appendChild(onloadScript);

    o.close();
  }

  do(command: string, extras?: any, action = 'webWidget') {
    if (extras) {
      zE(action, command, extras);
    }
    else {
      zE(action, command);
    }
  }

  load(): void {
    this.loading = true;

    setTimeout(() => {
      this.initZendesk();

      zE(() => {
        this.do('identify', {
          name: this.UserService.fullName,
          email: this.UserService.username
        });

        // Listen for the close method in case a resize has happened to reposition the widget.
        this.do('close', () => {
          this.displayed = false;
          this.do('hide');
          this.changeDetectorRef.detectChanges();
        }, 'webWidget:on');

        this.do('open', () => {
          this.displayed = true;
          this.changeDetectorRef.detectChanges();
        }, 'webWidget:on');

        this.do('hide');

        this.loading = false;
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  unload(): void {
    try {
      window.zE = undefined;
      window.zEmbed = undefined;
      window.zESettings = undefined;
      const widget = document.querySelectorAll('[data-product="web_widget"]');

      if (widget.length) {
        widget.forEach(item => document.body.removeChild(item));
        document.body.removeChild(document.getElementById('launcher').parentNode);

        // document.body.removeChild(document.getElementById('webWidget').parentNode);
        //
        // This always has thrown an error because the <div> has already been removed by
        // the first removeChild.
        // <div>
        //  <iframe id="launcher">
        //  <iframe id="webWidget">
        // </div>

        document.body.removeChild(document.getElementById('zendesk-iframe'));
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  open(): void {
    this.do('show');
    this.do('open');

    if (this.customHelpSiteUrl) {
      /*
        NOTE:
          we NEED to trigger the help-btn injection based on a "waterfall timeout" approach, in order to prevent issue
          ST033225. This is only a problem with FF, were for some reason the zendesk content ends up overwriting the
          help-btn. Therefore we (waterfall recursive timeout) dirty-chk until a zendesk DOM-node is present, and then
          inject the help-btn.
      */
      setTimeout(this._awaitZendeskIframeReady.bind(this));
    }
  }

  getButtonPosition(): ClientRect {
    return this.zEButton && this.zEButton.nativeElement
      ? this.zEButton.nativeElement.getBoundingClientRect()
      : undefined;
  }

  /**
   * Set the widget locale
   */
  private _setWidgetLanguage(): void {
    const locale = this._mapToZendeskSupportedLocale(this.UserService.language);

    // https://developer.zendesk.com/embeddables/docs/widget/core#setlocale
    window.zE('webWidget', 'setLocale', locale);
  }

  /**
   * Set widget suggestions based on project extra 'zendeskContext' and/or 'zendeskContextLabels'
   */
  private _setWidgetSuggestions(): void {
    // https://developer.zendesk.com/api-reference/widget/help-center-api/?_ga=2.111112896.475676203.1680185014-472260589.1680081556#helpcentersetsuggestions
    if (this.projectExtras) {
      const zendeskContext = this.UtilsService.getExtra(this.projectExtras, 'zendeskContext', 'fieldName');
      const zendeskContextLabels = this.UtilsService.getExtra(this.projectExtras, 'zendeskContextLabels', 'fieldName');

      const zeSearchOptions = {};

      if (zendeskContext) {
        const { value } = zendeskContext;

        if (value) {
          zeSearchOptions['search'] = value.trim();
        }
      }

      if (zendeskContextLabels) {
        const { value } = zendeskContextLabels;

        if (value) {
          // Value needs to be a comma separated string of zendesk labels
          const labels = value.split(',');
          zeSearchOptions['labels'] = labels.map(label => label.trim());
        }
      }

      if (FlowHelpers.hasProperty(zeSearchOptions, 'search') || FlowHelpers.hasProperty(zeSearchOptions, 'labels')) {
        zE('webWidget', 'helpCenter:setSuggestions', zeSearchOptions);
      }
    }
  }

  private _mapToZendeskSupportedLocale(language) {
    let locale = language;  // 1st try standard way, e.g. nl-NL

    if (language && language !== 'en-US') {
      locale = language.toLowerCase();  // 2nd try all lowercase, e.g. nl-nl

      if (!ZENDESK_SUPPORTED_LOCALES.includes(locale)) {
        locale = language.split('-')[0];  // 3rd try 2 chars version, e.g. nl
      }
    }

    return locale;
  }

  private _injectCustomHelpBtn() {
    if (!this._zendeskIframeHasHelpBtn) {
      // ST038543: Add event listeners in separate js file to prevent CSP issues.
      const helpButtonScript = document.createElement('script');
      helpButtonScript.src = LOCAL_ZENDESK_SCRIPT_PATH + 'help-btn.js';

      this._zendeskIframeBody.appendChild(helpButtonScript);

      this._zendeskIframeBody.insertAdjacentHTML(
        'afterbegin',

        // ST038543: Inline event handlers and JavaScript URLs are blocked by CSP policy.

        /*  `<a id="${ INJECTED_CUSTOM_HELP_BTN.id }"
              href="${
                this.customHelpSiteInModal
                  ? this.hrefJsIframeMessageOpenModal( this.customHelpSiteUrl )
                  : this.customHelpSiteUrl
              }"
              ${ this.customHelpSiteInModal ? '' : 'target="_blank"' }
              style="${ INJECTED_CUSTOM_HELP_BTN.style(this._portalThemeColor, this.isMobileDevice ? 15 : 31) }"
              onMouseOver="this.style.opacity=0.95"
              onMouseOut="this.style.opacity=1"
          >${ this.label }</a>` */

        `<a id="${ INJECTED_CUSTOM_HELP_BTN.id }"
            ${ this.customHelpSiteInModal
              ? 'data-zendesk-widget-help-btn-url="' + this.customHelpSiteUrl + '"' +
                'data-zendesk-widget-help-btn-action="' + IFRAME_MSG_ACTION + '"' +
                'data-zendesk-widget-help-btn-origin="' + window.location.origin + '"'
              : 'href="' + this.customHelpSiteUrl + '" target="_blank"' }
            style="${ INJECTED_CUSTOM_HELP_BTN.style(this._portalThemeColor, this.isMobileDevice ? 15 : 31) }"
        >${ this.label }</a>`
      );
    }
  }

  private get _zendeskIframe() {
    return document.querySelector('IFRAME#webWidget') as HTMLIFrameElement;
  }

  private get _zendeskIframeDocument() {
    return this._zendeskIframe && this._zendeskIframe.contentDocument;
  }

  private get _zendeskIframeBody() {
    return this._zendeskIframeDocument && this._zendeskIframeDocument.body;
  }

  private get _zendeskIframeHasContent() {
    return this._zendeskIframeBody && !!this._zendeskIframeBody.querySelector('BODY > * > *');
  }

  private get _zendeskIframeHasHelpBtn() {
    return this._zendeskIframeBody && !!this._zendeskIframeBody.querySelector(INJECTED_CUSTOM_HELP_BTN.id);
  }

  private _awaitZendeskIframeReady() {
    const iframeDoc = this._zendeskIframeDocument;

    if (iframeDoc) {  // proceed
      const caller = () => setTimeout(this._awaitZendeskIframeContent.bind(this));

      if (iframeDoc.readyState !== 'loading') {
        caller();
      }   // already loaded, so execute caller
      else {
        iframeDoc.addEventListener('DOMContentLoaded', caller);
      }  // loading, so add listener
    }
    else {
      setTimeout(this._awaitZendeskIframeReady.bind(this));
    }  // stop/re-call self
  }

  private _awaitZendeskIframeContent() {
    if (this._zendeskIframeHasContent) {
      setTimeout(this._injectCustomHelpBtn.bind(this));
    }  // proceed
    else {
      setTimeout(this._awaitZendeskIframeContent.bind(this));
    }  // stop/re-call self
  }

  /* private hrefJsIframeMessageOpenModal(url) {
    const action = 'zendesk.custom-help-site.open-modal';

    return `javascript:window.parent.postMessage( { action: '${ action }', url: '${ url }', '127':'0' }, '*');`;
  } */

  private _openCustomHelpSiteModal(data) {
    const { url } = data;

    if (url) {
      this.DialogsService.iframeModal({ url });

      /* close the zendesk widget, otherwise it overlaps the new dialog */
      this.do('close');
    }
  }
}
