/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActivatedRouteSnapshot } from '@angular/router';
import { FlowTranslateService } from '../services/translate/translate.service';

@Injectable({
  providedIn: 'root'
})
export class FlowLabelsLoaderGuard  {

  constructor(
    private TranslateService: FlowTranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable <boolean> {
    const currentLanguage = this.TranslateService.getCurrentLanguage();
    const defaultLanguage = this.TranslateService.getDefaultLanguage();

    // ST020766: In case current language is different than default language fetch also labels in default language as fallback
    // for labels that are not translated in current language.
    // According to David two parallel calls to the textblocks endpoints is fastest (because of the cache).
    if (currentLanguage !== defaultLanguage) {
      return forkJoin([
        this.TranslateService.fetchRouteLabels(currentLanguage, route.data.labelsPaths || false , route.data.labelsAliases || {}),
        this.TranslateService.fetchRouteLabels(defaultLanguage, route.data.labelsPaths || false , route.data.labelsAliases || {})
      ]).pipe(
        map(() => true)
      );
    }
    else {
      return this.TranslateService.fetchRouteLabels(currentLanguage, route.data.labelsPaths || false , route.data.labelsAliases || {});
    }
  }
}
