/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

export const MODELS: any = {
  App: 'common.utils.model.App',
  Article: 'com.tiekinetix.kinetix.shop.model.KinetixArticle',
  Category: 'com.tiekinetix.kinetix.shop.model.KinetixCategory',
  Invoice: 'com.tiekinetix.kinetix.shop.model.KinetixInvoice',
  Notification: 'io/receive',
  OnboardingForm: 'com.tiekinetix.kinetix.onboarding.KinetixOnboardingForm',
  PageType: 'com.tiekinetix.kinetix.shop.model.KinetixPageType',
  Rule: 'com.tiekinetix.kinetix.shop.model.KinetixRule',
  ShareLink: 'sso/shareLink',
  ShareLinkId: 'sso/shareLinkID',
  SimpleOrder: 'com.tiekinetix.kinetix.shop.model.simplify.KinetixSimpleOrder',
  SimpleProduct: 'com.tiekinetix.kinetix.shop.model.simplify.KinetixSimpleProduct',
  Site: 'com.tiekinetix.kinetix.shop.model.sites.KinetixSite',
  SitesPageType: 'com.tiekinetix.kinetix.shop.model.sites.KinetixPage',
  SsoCustomers: 'sso/customers',
  TextBlock: 'com.tiekinetix.kinetix.shop.model.KinetixTextBlock',
  Type: 'com.tiekinetix.kinetix.shop.model.KinetixType',
  Widget: 'com.tiekinetix.kinetix.shop.model.KinetixWidget'
};

export const MODELS_WITH_BULK: any = {
  TextBlock: 'com.tiekinetix.kinetix.shop.model.KinetixTextBlock/bulk'
};
