/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-alert-message',
  templateUrl: './alert-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowAlertMessageComponent implements OnInit {

  @Input() messageId: string;

  // danger, info, success, warning
  // if no type is provided alert message appears in gray
  @Input() type: string;

  @Input() extraClasses: string;

  @Input() icon: string;

  label: FlowTranslateLabel;

  classes: string;

  private _defaultClasses = 'd-flex align-items-center alert';

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit(): void {
    this.label = this.TranslateService.translateSync([this.messageId]);

    this.classes = this._defaultClasses;

    if (this.type) {
      this.classes += ` ${this.type}`;
    }

    if (this.extraClasses) {
      this.classes += ` ${this.extraClasses}`;
    }

    this.icon = this.icon || 'warning';
  }
}
