/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class FlowStorageService {

  private _storageType = 'localStorage';

  get(key: string): any {
    return window[ this._storageType ].getItem(key) || false;
  }

  set(key: string, value: any): void {
    window[ this._storageType ].setItem(key, value);
  }

  remove(key: string): void {
    window[ this._storageType ].removeItem(key);
  }
}
