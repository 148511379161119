/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 *
 * @name FlowDatatableService
 * @description
 * Service to handle datatable related rendering and actions
 */

import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FlowHelpers, FlowModelsService, FlowRouterService, FlowUtilsService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import {
  FlowDatatableCustomOptionsInterface,
  FlowDatatableCustomOptionsColumnsInterface
} from '../interfaces';


@Injectable()
export class FlowDatatableService {

  private _defaultLabels: FlowTranslateLabel;

  constructor(
    private datePipe: DatePipe,
    private RouterService: FlowRouterService,
    private ModelsService: FlowModelsService,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService,
  ) {}

  set defaultLabels(labels: FlowTranslateLabel) {
    this._defaultLabels = labels;
  }

  /**
   * Builds the action menu for every datatable row.
   *
   * @param data ???
   * @param type The data operation.
   * @param row The complete row data object.
   * @param customOptions Custom options for the datatable.
   *
   * @returns The HTML string of the actions menu.
   */
  buildActionsMenu(data: any, type: any, row: any, customOptions: FlowDatatableCustomOptionsInterface): string {
    // console.log("FlowDatatableService ::: buildActionsMenu() ::: flow-ng17-datatable", customOptions.actionsMenu);

    const actionsMenu = customOptions.actionsMenu || [];
    const itemIdKey = customOptions.itemIdKey;

    let menu = '';
    let menuItems = '';

    if (!FlowHelpers.isEmptyArray(actionsMenu)) {
      menu = `<div class="actions-menu">
                <button class="button button-icon"><i class="material-icons">more_vert</i></button>
                <div class="actions-menu__content">`;

      actionsMenu.forEach((item) => {
        const pathToDetail = this._getPathToDetail(item.path, row[itemIdKey]);

        if (item.actionMode === 'edit') {
          menuItems += '<button data-datatable-action-mode="' + item.actionMode +
                       '" data-redirect-path="' + pathToDetail + '">' + item.label + '</button>';
        }
        else if (item.actionMode === 'copy') {
          menuItems += '<button data-datatable-action-mode="' + item.actionMode +
                       '" data-redirect-path="' + pathToDetail + '/copy">' + item.label + '</button>';
        }
        else if (item.actionMode === 'remove') {
          const rowId = (item.useAsRowId && item.useAsRowId !== '') ? row[item.useAsRowId] : row[itemIdKey];

          // Todo: Maybe think about returning back only 1 data-attribute
          if (rowId) {
            const returnModalContent = item.modalContent ? true : false;
            const dataAttributesObj = {
              'data-datatable-action-mode': item.actionMode,
              'data-datatable-row-id': rowId
            };

            let dataAttributes = '';

            if (returnModalContent && row[item.modalContent]) {
              if (item.translatable) {
                const foundColumn = customOptions.columns.find(column => column.data === item.modalContent);

                dataAttributesObj['data-content'] = foundColumn
                  ? this._mapCmsTranslatedArticleTitle(row[item.modalContent], type, row, foundColumn)
                  : row[item.modalContent];
              }
              else {
                dataAttributesObj['data-content'] = row[item.modalContent];
              }
            }

            for (const prop in dataAttributesObj) {
              if (Object.prototype.hasOwnProperty.call(dataAttributesObj, prop)) {
                dataAttributes += ` ${prop}="${dataAttributesObj[prop]}"`;
              }
            }

            menuItems += `<button${dataAttributes}>${item.label}</button>`;
          }
        }
        else if (item.actionMode) {
          // if it's a conditional item and the condition fails, then we do an early exit
          if (item.condition && !item.condition(row)) {
            return;
          }

          menuItems += '<button data-datatable-action-mode="' + item.actionMode +
                       '" data-datatable-row-id="' + row[itemIdKey] + '">' + item.label + '</button>';
        }
      });

      menu += menuItems + '</div></div>';
    }

    return menu;
  }

  /**
   * Gets the default options for a datatable.
   *
   * @returns An object with default options.
   */
  getDefaultOptions(): object {
    return {
      layout: {
        topStart: 'pageLength',
        topEnd: 'search',
        bottomStart: ['pageLength','info'],
        bottomEnd: 'paging'
    },
      language: {
        emptyTable: this._defaultLabels['general.datatable.zero_records']
          ? this._defaultLabels['general.datatable.zero_records']
          : 'No matching records found',
        info: this._defaultLabels['general.datatable.info']
          ? `<span class="separator"></span>${ this._defaultLabels['general.datatable.info'] }`
          : `<span class="separator"></span>Showing _START_ to _END_ of _TOTAL_ records`,
        infoEmpty: this._defaultLabels['general.datatable.info_empty']
          ? `<span class="separator"></span>${ this._defaultLabels['general.datatable.info_empty'] }`
          : `<span class="separator"></span>Showing 0 to 0 of 0 entries`,
        infoFiltered: this._defaultLabels['general.datatable.info_filtered']
          ? this._defaultLabels['general.datatable.info_filtered']
          : '(filtered from _MAX_ total entries)',
        infoPostFix: '',
        thousands: ',',
        lengthMenu: this._defaultLabels['general.datatable.display_records']
          ? this._defaultLabels['general.datatable.display_records']
          : 'Display _MENU_ records',
        loadingRecords: this._defaultLabels['general.datatable.loading_records']
          ? this._defaultLabels['general.datatable.loading_records']
          : 'Loading records...',
        processing: this._defaultLabels['general.datatable.processing']
          ? this._defaultLabels['general.datatable.processing']
          : 'Processing',
        search: '<span><i class="material-icons">search</i>_INPUT_</span>',
        searchPlaceholder: '',
        zeroRecords: this._defaultLabels['general.datatable.zero_records']
          ? this._defaultLabels['general.datatable.zero_records']
          : 'No matching records found',
        paginate: {
          first: 'first',
          last: 'last',
          next: '<i class="material-icons">chevron_right</i>',
          previous: '<i class="material-icons">chevron_left</i>'
        },
        aria: {
          sortAscending: 'sortAscending',
          sortDescending: 'sortDescending',
          paginate: {
            first: 'first',
            last: 'last',
            next: 'NEXT',
            previous: 'PREV'
          }
        }
      },
      lengthMenu: [10, 25, 50, 100],
      paging: true,
      pagingType: 'simple_numbers',
      processing: true,
      serverSide: false,
      stateSave: true
    };
  }

  /**
   * Handles the click on a datatable row.
   *
   * @param row The DOM node of the row.
   * @param data The complete row data object.
   * @param index The index number of the row.
   * @param rowCallbackOptions Custom options for handling the row click.
   */
  handleRowClick(row: Node, data: any[] | object, index: number, rowCallbackOptions: object, itemIdKey = '_id'): void {
    const pathToDetail = this._getPathToDetail(rowCallbackOptions['path'], data[itemIdKey]);

    this.RouterService.navigate([pathToDetail]);
  }

  /**
   * Checks the custom datatable options for validity.
   *
   * @param options The custom options object.
   * @param property The property in the custom options to check.
   *
   * @returns Valid or invalid custom options property.
   */
  isValidCustomOptions(options: FlowDatatableCustomOptionsInterface, property: string): boolean {
    let valid = false;

    switch (property) {
      case 'model':
        if (FlowHelpers.hasProperty(options, property) && this.ModelsService.getModel(options[property])) {
          valid = true;
        }
        break;

      case 'dataMethod':
        if (FlowHelpers.hasProperty(options, property) && (typeof options[property] === 'function')) {
          valid = true;
        }
        break;

      case 'serverSide':
        if (FlowHelpers.hasProperty(options, property) && (typeof options[property] === 'boolean')) {
          valid = true;
        }
        break;

      case 'hasPermission':
        if (FlowHelpers.hasProperty(options, property) && (typeof options[property] === 'boolean')) {
          valid = true;
        }
        break;

      case 'columns':
        if (FlowHelpers.hasProperty(options, property) && !FlowHelpers.isEmptyArray(options[property])) {
          valid = true;
        }
        break;

      case 'ordering':
        if (FlowHelpers.hasProperty(options, property)) {
          valid = true;
        }
        break;

      case 'rowCallback':
        if (FlowHelpers.hasProperty(options, property)) {
          const rowCallbackOptions = options[property];

          if (FlowHelpers.hasProperty(rowCallbackOptions, 'path') && rowCallbackOptions.path !== '') {
            valid = true;
          }
        }
        break;

      case 'actionsMenu':
        if (FlowHelpers.hasProperty(options, property) && !FlowHelpers.isEmptyArray(options[property])) {
          //// TODO: DEPRECATED  const allowedActionModes = ['edit', 'copy', 'remove'];
          let errors = 0;

          options[property].forEach((menuItem) => {
            // Is allowed action mode
            /*// TODO: DEPRECATED if (allowedActionModes.indexOf(menuItem['actionMode']) === -1) {
              errors++;
            }*/

            // Has label property
            if (!FlowHelpers.hasProperty(menuItem, 'label')) {
              errors++;
            }

            // Action modes "edit" and "copy" need a path property
            if (menuItem['actionMode'] === 'edit' || menuItem['actionMode'] === 'copy') {
              if (!FlowHelpers.hasProperty(menuItem, 'path')) {
                errors++;
              }
            }
          });

          valid = (errors === 0) ? true : false;
        }
        break;
    }

    return valid;
  }

  /**
   * Renders the column value of a datatable in a generic way also for use in tile view.
   *
   * @param column The column options.
   *
   * @returns A function for rendering the column value for use in tile view.
   */
  renderColumn(column: FlowDatatableCustomOptionsColumnsInterface): any {
    return (data: any) => {
      if (typeof data === 'undefined') {
        const defaultContent = column['defaultContent'];

        data = (defaultContent) ? defaultContent : '-';
      }

      return `<div class="tile__item">
                <span class="tile__item-header">${column.title}</span>${data}
              </div>`;
    };
  }

  /**
   * Resolves a rendering type into a function for rendering the column value of a datatable.
   *
   * @param column The column options.
   *
   * @returns A function for rendering the column value
   * or false in case of a not allowed rendering type.
   */
  resolveColumnRenderer(column: FlowDatatableCustomOptionsColumnsInterface): any {
    const allowedRenderTypes = ['date', 'status', 'widgetsWebcomponentType', 'cmsTranslatedArticleTitle', 'cmsStatus'];
    const renderType = (column['render'] as string);

    if (renderType && allowedRenderTypes.indexOf(renderType) > -1) {
      return (data: any, type: any, row: any) => {
        if (renderType === 'date') {
          return this._formatAsDate(data, type, row, column.title);
        }
        if (renderType === 'status') {
          return this._formatAsStatusIcon(data, type, row, column.title);
        }
        if (renderType === 'widgetsWebcomponentType') {
          return this._mapWebcomponentWidgetType(data, type, row);
        }
        if (renderType === 'cmsTranslatedArticleTitle') {
          return this._mapCmsTranslatedArticleTitle(data, type, row, column);
        }
        if (renderType === 'cmsStatus') {
          return this._formatAsCmsStatusIcon(data);
        }
      };
    }

    return false;
  }

  /**
   * Internal function for rendering a status value of a datatable column into a status icon
   * on the CMS.
   *
   * @param data The data string of the column value.
   * @param type The data operation.
   * @param row The complete row data object.
   * @param columnHeader The header of the datatable column.
   *
   * @returns The HTML string for the status icon.
   */
  private _formatAsCmsStatusIcon(data: any): string {
    if (data) {
      return '<i class="material-icons status_icon color-success">check_box</i>';
    }
    else {
      return '<i class="material-icons status_icon color-danger">indeterminate_check_box</i>';
    }
  }

  /**
   * Internal function for formatting a date value of a datatable column.
   *
   * @param data The data string of the column value.
   * @param type The data operation.
   * @param row The complete row data object.
   * @param columnHeader The header of the datatable column.
   *
   * @returns The formatted date value.
   */
  private _formatAsDate(data: any, type: any, row: any, columnHeader: string): string {
    // TODO: Maybe use global util method here
    if (data) {
      return `<div class="tile__item">
                <span class="tile__item-header">${columnHeader}</span>
                <span class="hidden">${data}</span>${this.datePipe.transform(data, 'mediumDate')}
              </div>`;
    }

    return '';
  }

  /**
   * Internal function for rendering a status value of a datatable column into a status icon.
   *
   * @param data The data string of the column value.
   * @param type The data operation.
   * @param row The complete row data object.
   * @param columnHeader The header of the datatable column.
   *
   * @returns The HTML string for the status icon.
   */
  private _formatAsStatusIcon(data: any, type: any, row: any, columnHeader: string): string {
    if (data) {
      return `<div class="tile__item">
                <span class="tile__item-header">${columnHeader}</span>
                <span style="color: lightgreen">${data}</span>
              </div>`;
    }
    else {
      return `<div class="tile__item">
                <span class="tile__item-header">${columnHeader}</span>
                <span style="color: red">${data}</span>
              </div>`;
    }
  }

  /**
   * Internal function for replacing the route parameter :id with its real value.
   *
   * @param path The path including the route paramter :id.
   * @param id The row id.
   *
   * @returns The path to a detail route.
   */
  private _getPathToDetail(path: string, id: string): string {
    return (path) ? path.replace(':id', id) : '';
  }

  /**
   * Internal function for mapping the article title to its translation.
   *
   * @param data The data string of the column value.
   * @param type The data operation.
   * @param row The complete row data object.
   * @param column Information of the datatable column.
   * @param alternateContent The alternative content to show in datatable column.
   *
   * @returns The translated article title.
   */
  private _mapCmsTranslatedArticleTitle(data: any, type: any, row: any, column: any) {
    if (data) {
      const usedLang = this.TranslateService.getCurrentLanguage();
      const foundTitleInTextblocks = row.textblocks.find(textblock => textblock.id === data);

      let returnValue = '';

      if (foundTitleInTextblocks) {
        const translation = foundTitleInTextblocks.translations[usedLang];

        // Translation in current language was found
        if (translation) {
          returnValue = translation.replace(/(<([^>]+)>)/ig, '');
        }
        // Use translation in any other language
        else {
          const lookupLangs = ['en-US', 'en-GB', 'nl-NL', 'de-DE', 'fr-FR', 'es-ES', 'it-IT'].filter(lang => lang !== usedLang);

          for (let i = 0; i < lookupLangs.length; i++) {
            const otherTranslation = foundTitleInTextblocks.translations[lookupLangs[i]];

            if (otherTranslation) {
              returnValue = otherTranslation.replace(/(<([^>]+)>)/ig, '');
              break;
            }
          }
        }
      }

      return (returnValue)
               ? this._truncateText(type, returnValue, 55)
               : (column.alternateContent)
                   ? column.alternateContent
                   : '';
    }

    return (column.defaultContent) ? column.defaultContent : '-';
  }

  /**
   * Internal function for mapping the former webcomponent widget type.
   *
   * @param data The data string of the column value.
   * @param type The data operation.
   * @param row The complete row data object.
   * @param columnHeader The header of the datatable column.
   *
   * @returns The transformed webcomponent widget type.
   */
  private _mapWebcomponentWidgetType(data: any, type: any, row: any): string|undefined {
    if (data) {
      if (data === 'webcomponent') {
        const foundElementName = this.UtilsService.getExtra(row.extra, 'elementName', 'fieldName').value;
        if (foundElementName) {
          if (foundElementName === 'flow-youtube-widget') {
            return 'youtube video';
          }

          if (foundElementName === 'flow-iframe-widget') {
            return 'iframe';
          }

          if (foundElementName === 'flow-logo-widget') {
            return 'logo';
          }
        }
      }
      else {
        return data;
      }
    }

    return undefined;
  }

  /**
   * Internal function for truncating a string.
   *
   * @param type The data operation.
   * @param value The value to be truncated.
   * @param length The length of the returned string
   *
   * @returns The truncated string.
   */
  private _truncateText(type: string, value: string, length: number): string {
    // This method will be only applied for type display to ensure searching
    // for the text being cut off
    return (type === 'display' && value.length > length)
             ? value.substr(0, length) + '…'
             : value;
  }
}
