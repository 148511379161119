<ng-container *ngIf="statusMessage$ | async as status">
  <div
    *ngIf="status.show"
    id="flow-status-message"
    class="status-message d-flex align-items-center justify-content-center"
    [ngClass]="status.bgColor + ' ' + status.color">
    <i class="material-icons pr-2">{{ status.icon }}</i>
    <span [innerHTML]="status.message"></span>
  </div>
</ng-container>

<div *ngFor="let announcement of customerAnnouncement$ | async"
     class="customer-maintenance-announcement status-message d-flex align-items-center justify-content-center"
>
  <i class="material-icons pr-2">info</i>
  <span>
    <span class="period mr-2">
      <span class="color-gray-200">{{ announcement.start | dateLocale:'medium' }}</span>
      <span class="mx-2">-</span>
      <span class="color-gray-200">{{ announcement.end | dateLocale:'medium' }}</span>
    </span>
    <span class="fw-400 mr-1">{{ announcement.message }}</span>
  </span>

</div>
