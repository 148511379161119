import { Injectable } from '@angular/core';

import { fromEvent } from 'rxjs';
import { filter, pluck, share } from 'rxjs/operators';

import { FlowKeyValueObjectInterface } from '../../interfaces/key-value.interface';

export interface FlowIframeMessageInterface extends FlowKeyValueObjectInterface {
  action: string;
}

@Injectable({
  providedIn: 'root'
})
export class FlowIframeMessageService {

  private _isInFrame = window.top !== window;

  private _iframeMessage$ = fromEvent(window, 'message').pipe(
    // tap( console.debug ),
    filter(
      (msg: MessageEvent) =>
        (
          msg.origin !== window.location.origin /* only non-self messages */
          ||
          msg.data['127'] === '0' /* '127':'0' to allow self message */
        )
        &&
        msg.data && msg.data.action /* we only care for messages coming in flow format */
    ),
    pluck('data'),
    share(), /* ensure all subscribers gets the msg */
  );

  on(action: string, listener: any) {
    return this._iframeMessage$
      .pipe(
        filter((data: FlowIframeMessageInterface) => data.action === action),
        share(), /* ensure all subscribers gets the msg */
      )
      .subscribe(listener);
  }

  send(message: FlowIframeMessageInterface) {
    if (this._isInFrame) {
      window.parent.postMessage(message, '*');
    }
    else {
      console.error('Needs to be in iframe to send message', message);
    }
  }
}
