/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlowTrackingService {

  readonly GTM: string = 'GTM-KGTHRT';

  // HOT FIX:
  // Everything related to Google Tag Manager needs to be removed due to privacy policy issues
  // Have to find a better solution

  start(): void {
    /* window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'gtm.start': new Date().getTime(), event: 'gtm.js'
    });

    const f = document.getElementsByTagName('head')[0], j = document.createElement('script');

    j.async = true;
    j.src = `//www.googletagmanager.com/gtm.js?id=${this.GTM}`;
    f.parentNode.insertBefore(j, f); */
  }

  addCart(): void {
    /* window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'addToCart',
      'ecommerce': {
        'currencyCode': (product.pricing.length > 0  ? product.pricing[0].currency : ''),
        'add': {
          'products': [{
            'name': product.name || '',
            'id': product.id || '',
            'price': (product.pricing.length > 0  ? product.pricing[0].price : ''),
            'brand': product.brand || '',
            'category': (product.categories.length > 0  ? product.categories[0].name : '')
          }]
        }
      }
    });
    window['dataLayer'].push({'ecommerce': {}}); */
  }

  loginUser(): void {
   /*  window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'loginUser',
      'loginUser': {
        'userId': user.uuid || '',
        'roles': user.roles || ''
      }
    }); */
  }

  measuringPurchase(): void {
    /* window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'measuringPurchase',
      'ecommerce': {
        'purchase': {
          'products': [{
            'name': product.name || '',
            'id': product.id || '',
            'price': (product.pricing.length > 0  ? product.pricing[0].price : ''),
            'brand': product.brand || '',
            'category': (product.categories.length > 0  ? product.categories[0].name : '')
          }]
        }
      }
    });
    window['dataLayer'].push({'ecommerce': {}}); */
  }

  productImpression(): void {
    /* window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'productDetail',
      'ecommerce': {
        'purchase': {
          'products': [{
            'name': product.name || '',
            'id': product.id || '',
            'price': (product.pricing.length > 0  ? product.pricing[0].price : ''),
            'brand': product.brand || '',
            'category': (product.categories.length > 0  ? product.categories[0].name : ''),
            'currencyCode': (product.pricing.length > 0  ? product.pricing[0].currency : ''),
          }]
        }
      }
    });
    window['dataLayer'].push({'ecommerce': {}}); */
  }

  productInformationRequest(): void {
    /* window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'productInformationRequest',
      'ecommerce': {
        'purchase': {
          'products': [{
            'name': product.name || '',
            'id': product.id || ''
          }]
        }
      }
    });
    window['dataLayer'].push({'ecommerce': {}}); */
  }

  pushEvent() {
    /* const obj = {
      'event': (typeof eventObj.event !== 'undefined') ? eventObj.event : 'ga-event',
      'category': (typeof eventObj.category !== 'undefined') ? eventObj.category : '',
      'name': (typeof eventObj.name !== 'undefined') ? eventObj.name : '',
      'label': (typeof eventObj.label !== 'undefined') ? eventObj.label : '',
      'value': (typeof eventObj.value !== 'undefined') ? eventObj.value : ''
    };

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(obj); */
  }

}
