/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import { FlowCountriesService } from '../../../../services/countries/countries.service';

@Component({
  selector: 'flow-countries-dropdown',
  templateUrl: './countries-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowCountriesDropdownComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() inlineLabel: string;

  @Input() required: boolean;

  @Output() pushBackCountries: EventEmitter<any>  = new EventEmitter();

  labels: FlowTranslateLabel;

  countries$: Observable<any[]>;

  constructor(
    private CountriesService: FlowCountriesService,
    private TranslateService: FlowTranslateService,
  ) { }

  ngOnInit() {
    this.labels = this._translateLabels();

    this.countries$ = this.CountriesService.getCountries()
    .pipe(
      tap(countries => this.pushBackCountries.emit(countries))
    );
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labels = [];

    if (this.inlineLabel) {
      labels.push(this.inlineLabel);

      return this.TranslateService.translateSync(labels);
    }

    return null;
  }
}
