/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { timer, Observable, Subject, forkJoin } from 'rxjs';
import { switchMap, map, retry, share, takeUntil } from 'rxjs/operators';

import { FlowEnvService, FlowGoogleStorageService, FlowHelpers, FlowTextBlockModelInterface } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';
import { FlowUserService } from '@flow/auth';

@Injectable()
export class FlowStatusMessageService {

  public stopPolling$$ = new Subject();

  private pageId: string;
  private apiKey: string;
  private intervall: number;

  constructor(
    private httpClient: HttpClient,
    private GoogleStorageService: FlowGoogleStorageService,
    private EnvService: FlowEnvService,
    private UserService: FlowUserService,
    private TranslateService: FlowTranslateService
  ) {
    // TEST STATUS PAGE ID: jj16m0kkj8bh
    // REAL STATUS PAGE ID: 75bt9j1jp9vx

    this.pageId = '75bt9j1jp9vx';
    this.apiKey = '1a16cd4e-370a-4e03-a2fd-417f4857281c';
    this.intervall = 20000;
  }

  /**
   * Start polling statuspage messages
   *
   * @param forceShow Forces showing the message in any case
   */
  startPolling(forceShow = false): Observable<any> {
    return timer(0, this.intervall).pipe(
      switchMap(() => this.statusRequest()),
      map(result => this._parseResponse(result, forceShow)),
      retry(4),
      share(),
      takeUntil(this.stopPolling$$)
    );
  }

  /**
   * fetches and return the (translated) announcements that should be shown now
   */
  loadCustomerMaintenanceAnnouncements() {
    return this.GoogleStorageService.getVendorDashboard(
      this.UserService.isActingAsPartner()
        ? this.EnvService.portalDomain
        : this.UserService.company
    ).pipe(
      map(this._getToShowAnnouncements.bind(this)),
      switchMap(this._translateAnnouncements.bind(this)),
    );
  }

  /**
   * returns array of announcements that show be shown now
   */
  private _getToShowAnnouncements(dashboard) {
    const { maintenance } = dashboard;

    if (maintenance) {
      try {
        const announcements = JSON.parse(atob(maintenance)) || [];

        if (announcements.length) {
          return announcements.filter(this._isInAnnouncementTimePeriod.bind(this));
        }
      }
      catch (error) {
        console.error(error);
      }
    }

    return [];
  }

  /**
   * returns the announcements with their messages already translated
   */
  private _translateAnnouncements(announcements) {
    const project = this.EnvService.portalDomain;

    const arrReqs = announcements.map(announcement => this.TranslateService.getTextBlockById(
      `${ project }.maintenanceannouncement.${ announcement.id }.message`,
      { platform: project }
    ));

    return forkJoin(arrReqs)
      .pipe(
        map((textBlocks: FlowTextBlockModelInterface[]) => textBlocks.map((textBlock, index) => {
          const announcement = announcements[index];
          const translation = this._extractTranslation(textBlock);

          if (translation) {
            announcement.message = translation;
          }

          return announcement;
        }))
      );
  }

  /**
   * returns the translation (if any) from the textblock; firstly the user's current language, otherwise fallback to the default language
   */
  private _extractTranslation(textBlock) {
    const currentLang = this.TranslateService.getCurrentLanguage();
    const defaultLang = this.TranslateService.getDefaultLanguage();

    return textBlock && (textBlock.translations[currentLang] || textBlock.translations[defaultLang]);
  }

  /**
   * checks if now is within the start and end period of the announcement
   */
  private _isInAnnouncementTimePeriod(announcement) {
    const now = Date.now();
    const { start, end } = announcement;

    return now > +(new Date(start)) && now < +(new Date(end));
  }

  /**
   * Request used for fetching statuspage messages
   */
  private statusRequest(): Observable<any> {
    return this.httpClient.get<any>(
      `https://${this.pageId}.statuspage.io/api/v2/summary.json`,
      { params: {api_key: this.apiKey} }
    );
  }

  /**
   * Parse the response getting back from statuspage.io
   *
   * @param data Response data from statuspage.io
   * @param forceShow Forces showing the message in any case
   */
  private _parseResponse(data: any, forceShow: boolean) {
    const response = {
      status: 'ok',
      message: '',
      icon: 'timeline',
      color: 'color-black',
      bgColor: '',
      system_status: 'Cannot fetch current status',
      components: data.components,
      show: false
    };

    let lastIncident = null;

    if (data.status) {
      if (data.status.description) {
        response.system_status = data.status.description;
      }
    }

    if (!FlowHelpers.isEmptyArray(data.scheduled_maintenances)) {
      response.status = 'maintenance';
      response.color = 'color-black';
      response.icon = 'timeline';
      response.bgColor = 'bg-warning';
      response.show = forceShow ? true : false;

      lastIncident = data.scheduled_maintenances[0] || null;

      if (lastIncident) {
        const parsedBody = this._urlify(lastIncident.incident_updates[0].body, response.color);

        response.message = this._parseDate(lastIncident.incident_updates[0].updated_at) +
          ' - <strong>' +
          lastIncident.name +
          '</strong>, ' +
          parsedBody;
      }

      return response;
    }

    if (!FlowHelpers.isEmptyArray(data.incidents)) {
      response.status = 'alert';
      response.color = 'color-white';
      response.icon = 'build';
      response.bgColor = 'bg-danger-60';
      response.show = true;
      lastIncident = data.incidents[0] || null;

      if (lastIncident) {
        response.message = this._parseDate(lastIncident.incident_updates[0].updated_at) +
          ' - <strong>' +
          lastIncident.name +
          '</strong>,' +
          lastIncident.incident_updates[0].body;
      }

      return response;
    }

    return response;
  }

  /**
   * Convert date string to locale string
   *
   * @param date Date string coming from statuspage.io
   */
  private _parseDate(date: string): string|undefined {
    if (!date) {
      return undefined;
    }
    else {
      const dateObj = new Date(date);
      const currentLang = this.TranslateService.getCurrentLanguage();
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      } as const;

      return dateObj.toLocaleString(currentLang, options);
    }
  }

  /**
   * Convert an url in a string into an HTML link and apply
   * passed class name
   *
   * @param string
   * @param color
   */
  private _urlify(string: string, color: string): string {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return string.replace(urlRegex,
      url => `&nbsp;<a href="${url}" class="${color} text-bold-500 text-underline" target="_blank">${url}</a>`);
  }
}
