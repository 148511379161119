import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowMarketplaceStateInterface } from '@marketplaceStore';
import { WidgetModelStateInterface } from '@marketplaceStore/reducers/widget.reducer';
import { FlowWidgetModelInterface } from '@flow/core';

export const getWidgetsSelector = createFeatureSelector<FlowMarketplaceStateInterface, WidgetModelStateInterface>('widgets');

export const getWidgetsLoaded = createSelector(
  getWidgetsSelector,
  (state: WidgetModelStateInterface) => state.loaded
);

export const getFlowWidgets = createSelector(
  getWidgetsSelector,
  (state: WidgetModelStateInterface) => state.flowWidgets
);

export const getCustomerWidgets = createSelector(
  getWidgetsSelector,
  (state: WidgetModelStateInterface) => state.customerWidgets
);

export const getAllWidgets = createSelector(
  getWidgetsSelector,
  (state: WidgetModelStateInterface) => state.data
);

export const getWidget = createSelector(
  getAllWidgets,
  (state: FlowWidgetModelInterface[], widgetId: string) => state.find((widget: any) => widget._id === widgetId)
);
