/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowTokensInterface } from '../../interfaces/tokens.interface';
import { FlowStorageService } from '@flow/core';

@Injectable({
  providedIn: 'root'
})
export class FlowTokensService {

  // FLOW token key for the storage
  private _tokenKey = 'flow.token';

  // FLOW refresh token key for the storage
  private _refreshTokenKey = 'flow.refresh.token';

  // Token Authorization header Strategy
  private _tokenStrategy = 'Bearer ';

  // Document Manager token key for the storage (used for DM widgets)
  private _DM_tokenKey = 'dm.token';

  // Document Manager refresh token key for the storage (used for DM widgets)
  private _DM_refreshTokenKey = 'dm.refresh.token';

  get DM_tokenKey() {
    return this._DM_tokenKey;
  }

  get DM_refreshTokenKey() {
    return this._DM_refreshTokenKey;
  }

  constructor(
    private StorageService: FlowStorageService
  ) {}

  /**
   * Getter for the token strategy
   */
  get tokenStrategy(): string {
    return this._tokenStrategy;
  }

  /**
   * Return stored tokens of given keys (default: flow.token and flow.refresh.token) from the storage.
   */
  getTokens(tokenKey = this._tokenKey, refreshTokenKey = this._refreshTokenKey): FlowTokensInterface {
    return {
      token: this.StorageService.get(tokenKey) || 'noop',
      refreshToken: this.StorageService.get(refreshTokenKey) || 'noop'
    };
  }

  /**
   * Get token of given token key (default: flow.token) from the storage.
   */
  getToken(tokenKey = this._tokenKey): string {
    return this.StorageService.get(tokenKey) || 'noop';
  }

  /**
   * Get refresh token of given refresh token key (default: flow.refresh.token) from the storage.
   */
  getRefreshToken(refreshTokenKey = this._refreshTokenKey): string {
    return this.StorageService.get(refreshTokenKey) || 'noop';
  }

  /**
   * Set token and/or refreshToken of given token key (default: flow.token)
   * and refresh token key (default: flow.refresh.token)
   * in the storage.
   */
  setTokens(
    token?: string,
    refreshToken?: string,
    tokenKey = this._tokenKey,
    refreshTokenKey = this._refreshTokenKey): void {

    if (token && token !== this.getToken(tokenKey)) {
      this.StorageService.set(tokenKey, token);
    }

    if (refreshToken && refreshToken !== this.getRefreshToken(refreshTokenKey)) {
      this.StorageService.set(refreshTokenKey, refreshToken);
    }
  }

  /**
   * Delete token of given token key (default: flow.token) from the storage.
   * Useful when e.g. need to trigger a refresh of the auth token/session, like in the case of newly added customer extra
   */
  deleteToken(tokenKey = this._tokenKey): void {
    this.StorageService.remove(tokenKey);
  }

  /**
   * Delete refresh token of given refresh token key (default: flow.refresh.token) from the storage.
   */
  deleteRefreshToken(refreshTokenKey = this._refreshTokenKey): void {
    this.StorageService.remove(refreshTokenKey);
  }

  /**
   * Delete tokens of given keys (default: flow.token and flow.refresh.token) from the storage.
   */
  deleteTokens(tokenKey = this._tokenKey, refreshTokenKey = this._refreshTokenKey): void {
    this.deleteToken(tokenKey);
    this.deleteRefreshToken(refreshTokenKey);
  }

}
