import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FlowAuthRequestInterceptor } from './interceptors/auth-request.interceptor';

import { FlowAuthConfigService } from './services/auth/auth.service';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [ReactiveFormsModule]
})
export class FlowAuthModule {

  constructor(@Optional() @SkipSelf() parentModule: FlowAuthModule) {
    if (parentModule) {
      throw new Error(
        'FlowAuthModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(config: FlowAuthConfigService): ModuleWithProviders<FlowAuthModule> {
    return {
      ngModule: FlowAuthModule,
      providers: [
        {
          provide: FlowAuthConfigService,
          useValue: config
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: FlowAuthRequestInterceptor,
          multi: true
        }
      ]
    };
  }
}
