import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FlowMarketplaceStateInterface } from '@marketplaceStore';
import { CategoryModelStateInterface } from '@marketplaceStore/reducers/category.reducer';

export const getCategoriesSelector = createFeatureSelector<FlowMarketplaceStateInterface, CategoryModelStateInterface>('categories');

export const getCategoriesLoaded = createSelector(
  getCategoriesSelector,
  (state: CategoryModelStateInterface) => state.loaded
);

export const getFlowCategories = createSelector(
  getCategoriesSelector,
  (state: CategoryModelStateInterface) => state.data
);

export const getRootCategory = createSelector(
  getFlowCategories,
  (state: any) => state['_ROOT'] || {}
);
