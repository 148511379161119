import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowBackgroundImageDirective } from './background-image/background-image.directive';
import { FlowShowOnDesktopDirective } from './show-on-desktop/show-on-desktop.directive';
import { FlowShowOnMobileDirective } from './show-on-mobile/show-on-mobile.directive';
import { FlowHasPermissionDirective } from './has-permission/has-permission.directive';
import { FlowNumericFieldDirective } from './numeric-field/numeric-field.directive';

@NgModule({
  declarations: [
    FlowBackgroundImageDirective,
    FlowShowOnDesktopDirective,
    FlowShowOnMobileDirective,
    FlowHasPermissionDirective,
    FlowNumericFieldDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FlowBackgroundImageDirective,
    FlowShowOnDesktopDirective,
    FlowShowOnMobileDirective,
    FlowHasPermissionDirective,
    FlowNumericFieldDirective
  ]
})
export class FlowDirectivesModule { }
