import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FlowLoginGuard } from '@flow/auth';
import { FlowLabelsLoaderGuard } from '@flow/translate';
import { FlowAuthComponent } from './containers/auth/auth.component';
import { FlowLoginComponent } from './components/login/login.component';
import { FlowLogoutComponent } from './components/logout/logout.component';
import { FlowRegisterComponent } from './components/register/register.component';
import { FlowForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FlowChangePasswordComponent } from './components/change-password/change-password.component';
import { FlowChangePasswordComponentResolver } from './components/change-password/change-password.resolver';
import { FlowSsoComponent } from './components/sso/sso.component';
import { FlowAuthorizeComponent } from './components/authorize/authorize.component';
import { FlowOnboardingFormResolver } from './resolvers/onboarding-form.resolver';

export const routes: Routes = [
  {
    path: 'auth',
    component: FlowAuthComponent,
    canActivate: [
      FlowLoginGuard,
      FlowLabelsLoaderGuard
    ],
    data: {
      animation: 'AuthState',
      labelsPaths: ['generic', 'general', 'login', 'error']
    },
    children: [
      {
        path: 'login',
        component: FlowLoginComponent
      },
      {
        path: 'logout',
        component: FlowLogoutComponent
      },
      {
        path: 'register',
        component: FlowRegisterComponent,
        resolve: {
          onBoardingForm: FlowOnboardingFormResolver
        }
      },
      {
        path: 'forgot-password',
        component: FlowForgotPasswordComponent,
        data: {
          authTitle: 'login.password_form.headline.forgot_password'
        }
      },
      {
        path: 'change-password/:hash',
        component: FlowChangePasswordComponent,
        data: {
          authTitle: 'login.password_form.headline.set_password'
        },
        resolve: {
          hash: FlowChangePasswordComponentResolver
        }
      },
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      }
    ],
    resolve: {
      onBoardingForm: FlowOnboardingFormResolver
    }
  },
  {
    path: 'sso',
    component: FlowSsoComponent,
  },
  {
    path: 'authorize/:channel',
    component: FlowAuthorizeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FlowAuthViewsRoutingModule { }
