/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, OnInit, Input } from '@angular/core';

import { FlowLoadingWrapperInterface } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-loading-or-error',
  templateUrl: './loading-or-error.component.html',
  styleUrls: ['./loading-or-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowLoadingOrErrorComponent implements OnInit {

  @Input() loadingWrapper: FlowLoadingWrapperInterface<any>|null = null;
  @Input() errorMessage;

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) {}

  ngOnInit() {
    this.labels = this.TranslateService.translateSync(['error.general.error_loading']);

    if (!this.errorMessage) {
      this.errorMessage = this.labels['error.general.error_loading'];
    }
  }
}
