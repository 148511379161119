<ng-container [formGroup]="form">
  <ng-container [formArrayName]="controlName">
    <section [ngClass]="{'no-border': !sectionTitle}">
      <ng-container *ngIf="buttonPosition === 'top' else noTopButton">
        <div class="row">
          <div *ngIf="sectionTitle"
               [innerHTML]="sectionTitle"
               class="col-7">
          </div>
          <div [ngClass]="sectionTitle ? 'col-5 text-right' : 'col-12 text-right'">
            <button type="button"
                    class="button button-small button-primary-outline"
                    [innerHTML]="buttonLabel"
                    (click)="addControls()"></button>
          </div>
        </div>
      </ng-container>
      <ng-template #noTopButton>
        <p *ngIf="sectionTitle" class="color-black" [innerHTML]="sectionTitle"></p>
      </ng-template>
      <ng-container *ngFor="let control of arrayControls.controls; let i=index">
        <div class="row no-gutters">
          <div class="col-11" [formGroupName]="i">
            <ng-container *ngTemplateOutlet="projectedContent; context: { control: control, controlIndex: i }"></ng-container>
          </div>
          <div class="col-1 text-right text-sm-center repeated__actions">
            <button [matTooltip]="labels['general.btn.remove']"
                    (click)="removeControls(i)"
                    class="button button-icon">
              <i class="material-icons remove">remove_circle_outline</i>
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf="buttonPosition !== 'top'" class="mt-3 text-right">
        <button type="button"
                class="button button-small button-primary-outline"
                [innerHTML]="buttonLabel"
                (click)="addControls()"></button>
      </div>
    </section>
  </ng-container>
</ng-container>
