import { HttpParams } from '@angular/common/http';

/**
 * Reducer to transform a plain object to an Angular HttpParams Class.
 */
export const toHttpParams = (predicate: any = {}): HttpParams =>
  Object
  .getOwnPropertyNames(predicate)
  .reduce((p, key) => p.set(key, predicate[key]), new HttpParams());

/*
export function toHttpParams(predicate: any = {}): HttpParams {
  return Object.getOwnPropertyNames(predicate)
    .reduce((p, key) => p.set(key, predicate[key]), new HttpParams());
}
 */
