/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FlowModalGoogleAuthRegisterComponent } from '../../modals/modal-google-auth-register/modal-google-auth-register.component';
import { Flow2faGoogleAuthService } from '../../services/2fa-google-auth/2fa-google-auth.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';

@Component({
  selector: 'flow-google-auth-register',
  templateUrl: './google-auth-register.component.html',
  styleUrls: ['./google-auth-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowGoogleAuthRegisterComponent {

  @Output() registrationResult: EventEmitter <boolean> = new EventEmitter<any>();

  constructor(
    private Google2FaAuthService: Flow2faGoogleAuthService,
    private ModalsService: FlowDialogsService
  ) {}

  register(): void {
    this.ModalsService.open(FlowModalGoogleAuthRegisterComponent, {
      maxWidth: '330px',
      closeOnNavigation: true,
      disableClose: true
    }).afterClosed().subscribe(result => {
      this.registrationResult.emit(result);
    });
  }
}
