<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [attr.aria-label]="labels['login.password_form.headline.forgot_password']">

  <ng-container *ngIf="status$ | async as result">

    <ng-container *ngIf="'yes' === result">
      <div class="p-2 mb-3 text-center">
        <i class="material-icons color-primary icon-3x animated bounceIn">check</i>
        <p
          [innerHTML]="labels['login.password_form.email_text.forgot_password']"
          role="alert"
          aria-live="assertive">
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="'no' === result">
      <ng-container *ngIf="!showRecoveryEmail">
        <mat-form-field  class="md-block" [appearance]="'fill'" role="alert" aria-live="assertive">
          <mat-label [innerHTML]="labels['login.login_form.label.email']"></mat-label>
          <input matInput formControlName="email">
          <mat-error
            [hidden]="!form.get('email').invalid"
            [innerHTML]="form.get('email').hasError('required')
              ? labels['generic.error.required.email']
              : form.get('email').hasError('pattern')
                ? labels['generic.error.invalid.email']
                : null"
            role="alert"
            aria-live="assertive">
          </mat-error>
        </mat-form-field>

        <div class="human-verification-container">
          <flow-turnstile formControlName="recaptcha" [tabIndex]="0"></flow-turnstile>
        </div>
      </ng-container>

      <ng-container *ngIf="showRecoveryEmail">
        <div class="d-block text-center pb-3" style="max-width: 80%; margin: 0 auto">
          <i class="material-icons icon-3x animated rotateIn">security</i>
          <p
            class="py-1"
            [innerHTML]="labels['login.label.enter_recovery_mail']"
            role="alert"
            aria-live="assertive">
          </p>
        </div>

        <mat-form-field  class="md-block" [appearance]="'fill'" role="alert" aria-live="assertive">
          <mat-label [innerHTML]="labels['login.label.recovery_email']"></mat-label>
          <input matInput formControlName="recoveryEmail">
          <mat-error
            [hidden]="!form.get('recoveryEmail').invalid"
            [innerHTML]="form.get('recoveryEmail').hasError('required')
              ? labels['generic.error.required.email']
              : form.get('recoveryEmail').hasError('pattern')
                ? labels['generic.error.invalid.email']
                : form.get('recoveryEmail').hasError('invalidRecoveryEmail')
                  ? labels['generic.error.invalid_recovery_email']
                  : null"
              role="alert"
              aria-live="assertive">
            </mat-error>
        </mat-form-field>
      </ng-container>
    </ng-container>

  <flow-login-buttons
    [showBackButton]="true"
    [showSubmitButton]="'no' === result"
    [validForm]="form.valid"
    [isTouched]="form.touched"
    [loading]="loading$ | async"
    [ariaLabel]="labels['login.password_form.headline.forgot_password']">
  </flow-login-buttons>

  </ng-container>

</form>
