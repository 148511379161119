import { FlowIdLabelInterface } from '@flow/core';

export const DATA_TYPES: FlowIdLabelInterface[] = [
  { id: 'boolean', label: 'boolean' },
  { id: 'string', label: 'string' },
  { id: 'int', label: 'int' },
  { id: 'float', label: 'float' },
  { id: 'timestamp', label: 'timestamp' },
  { id: 'url', label: 'url' },
  { id: 'text', label: 'text' },
  { id: 'html', label: 'html' }
];
