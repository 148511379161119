import { HttpHeaders, HttpParams } from '@angular/common/http';

export const buildRequestBody = (params?: HttpParams, headers?: HttpHeaders, extras?: any):
  {params?: HttpParams; observe?: any} => {

  const requestBody: {params?: HttpParams; observe?: string; headers?: HttpHeaders} = {};

  if (params) {
    requestBody.params = params;
  }

  if (headers) {
    requestBody.headers = headers;
  }

  if (extras) {
    for (const extra in extras) {
      if (Object.prototype.hasOwnProperty.call(extras, extra)) {
        requestBody[extra] = extras[extra];
      }
    }
  }

  return requestBody;
};
