/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlowAuthService, FlowSsoService } from '@flow/auth';
import { FlowRegexService } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowCountriesService } from '../../../../../services/countries/countries.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { FlowLayoutService } from '../../../../../services/layout/layout.service';
import { FlowPortalService } from '../../../../../services/portal/portal.service';

@Component({
  selector: 'flow-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FlowRegisterComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;

  loading$: Observable <boolean>;

  registerStatus$$: BehaviorSubject <string> = new BehaviorSubject('no');

  countries: any[] = [];
  countriesSubscription$$: Subscription;

  labels: FlowTranslateLabel;

  partnersOnBoardingData: any;

  customerPrivacyLabel: string;
  customerPrivacyUrl: string;
  customerPrivacyView: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private _FormBuilder: UntypedFormBuilder,
    private AuthService: FlowAuthService,
    private PortalService: FlowPortalService,
    private SsoService: FlowSsoService,
    private RegexService: FlowRegexService,
    private TranslateService: FlowTranslateService,
    private CountriesService: FlowCountriesService,
    private DialogsService: FlowDialogsService,
    private LayoutService: FlowLayoutService
  ) {
    this.form = this._FormBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      country: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.RegexService.getRegex('email'))]),
      language: new UntypedFormControl('', [Validators.required]),
      acceptTerms: new UntypedFormControl(false, [Validators.requiredTrue]),
      skipAuth: new UntypedFormControl(true, [Validators.requiredTrue]),
      recaptcha: new UntypedFormControl(null, Validators.required)
    });

    this.form.get('language').setValue(this.TranslateService.getCurrentLanguage());
  }

  get registerStatus$(): Observable <string> {
    return this.registerStatus$$.asObservable();
  }

  ngOnInit(): void {
    this.loading$ = this.LayoutService.loading$;

    this.labels = this._translateLabels();

    this.partnersOnBoardingData = this.activatedRoute.snapshot.data.onBoardingForm;

    if (this.partnersOnBoardingData) {
      this._setCustomerPrivacy();

      if (this.partnersOnBoardingData.internalOnboarding) {
        const project = this.AuthService.vendor.toLowerCase();
        const tmpName = `tmp_${ project }_${ Date.now() }`;

        this.form.patchValue({name: tmpName});
      }
      else {
        console.error('register.component::ngOnInit - No internalOnboarding');
      } // we should never reach here
    }

    this.countriesSubscription$$ = this.CountriesService.getCountries()
    .pipe(
      tap(countries => {
        // Set the countries
        this.countries = countries;

        // Pre-select country based on user language
        this.form.patchValue({
          country: this.CountriesService.getCountryByLanguage(this.countries) || ''
        });
      })
    )
    .subscribe();
  }

  ngOnDestroy(): void {
    this.countriesSubscription$$.unsubscribe();
  }

  onSubmit(): void {
    this.form.markAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.LayoutService.setLoading(true);
    this.LayoutService.setLastError(null);
    this.registerStatus$$.next('no');

    this.SsoService.registerAccount(this.form)
    .subscribe(result => {
      this.LayoutService.setLoading(false);

      if (true !== result) {
        const errResponse = result as HttpErrorResponse;

        if (errResponse.status === 500) {
          // In case a customer already exists show a success message for security reasons
          if (
            errResponse.error === 'java.lang.Exception: Customer already exists'
            ||
            (errResponse.error && errResponse.error.status === 400)
          ) {
            this.registerStatus$$.next('yes');
          }
        }
        else {
          // In case there's a specific error message show it otherwise show a generic error message
          if (errResponse.error.error !== '') {
            const errorResponseMsg = errResponse.error.error;
            const foundTranslationId = errorResponseMsg.toLowerCase().match(/{{(.*?)}}/);

            this.LayoutService.setLastError(foundTranslationId ? foundTranslationId[1] : errorResponseMsg);
          }
          else {
            this.LayoutService.setLastError('generic.request.failed');
          }
        }
      }
      else {
        this.registerStatus$$.next('yes');
      }
    });
  }

  openPrivacyPolicy($event): void {
    $event.preventDefault();

    this.DialogsService.textModal({
      title: 'general.privacy_policy.headline',
      content: 'general.privacy_policy.content',
      showOkButton: false
    });
  }

  openCustomerPrivacyPolicy($event): void {
    if (this.customerPrivacyUrl) {
      $event.preventDefault();

      if (this.customerPrivacyUrl) {
        if (this.customerPrivacyView === 'modal') {
          this.DialogsService.iframeModal({ url: this.customerPrivacyUrl });
        }
        else {
          window.open(this.customerPrivacyUrl, '_blank');
        }
      }
    }
  }

  private _setCustomerPrivacy() {
    if (this.partnersOnBoardingData.isSupplierPortal) {
      const privacy = this.PortalService.getCustomerPrivacySettings();

      if (privacy) {
        this.customerPrivacyUrl = privacy.url as string;
        this.customerPrivacyView = privacy.view;
      }

      // Show privacy label in any case (even if no privacy url is set up )
      this.customerPrivacyLabel = this.TranslateService.instant({
        key: 'login.label.customer_privacy_statement',
        params: { customer: this.partnersOnBoardingData.CompanyName }
      });
    }
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'generic.error.required',
      'generic.error.invalid.email',
      'generic.privacy_policy.title',
      'login.account_form.label.company',
      'login.account_form.label.country',
      'login.login_form.label.email',
      'login.account_form.email_text.register_company',
      'login.label.accept_applicability',
      'login.label.tie_privacy_statement',
      'login.label.customer_privacy_statement',
      'login.login_form.link.register_user',
      'login.account_form.note_for_existing_account',
      'generic.error.required.email'
    ]);
  }
}
