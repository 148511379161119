import { Injectable } from '@angular/core';

import { InterpolationOptionsInterface } from './interpolationOptions.interface';

@Injectable({
  providedIn: 'root'
})
export class FlowInterpolateHtmlService {

  URL_PARAM = 'url=';

  parseLabel(text: string, interpolationOptions: InterpolationOptionsInterface = {}, asHtml = true): string {
    return text.replace(/\[(.*?)\]/gi, match => {
      const formatMatch = match.replace(/^\[?|\]?$/gi, '');
      const splitMatch = formatMatch.split(':');
      const content = splitMatch[0].replace(/^\'?|\'?$/gi, '').trim();
      const title = splitMatch[1].replace(/^\"?|\"?$/gi, '').trim();

      if (asHtml) {
        if (content.includes(this.URL_PARAM)) {
          const [ , urlKey ] = content.split(this.URL_PARAM);
          const interpolationOptionsLinks = interpolationOptions && interpolationOptions.links;

          if (interpolationOptionsLinks) {
            const target = interpolationOptionsLinks[urlKey];

            if (target && !target.url) {
              // if no url, then show plain text instead of an anchor link
              return `<span data-title="${title}" data-content="${content}" data-missing-url="1">${ title }</span>`;
            }
          }
        }

        return `<a class="color-blue-link label-interpolate" data-title="${title}" data-content="${content}">${title}</a>`;
      }

      return title;
    });
  }
}
