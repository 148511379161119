/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowUtilsService, FlowTextBlockModelInterface, FlowHelpers } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowDialogsService } from '../../../../services/dialogs/dialogs.service';
import { FlowModalFieldTranslateComponent } from '../../../modals/components/modal-field-translate/modal-field-translate.component';
import { FlowTranslateConfigInterface } from '../../../../interfaces/translate-config.interface';

@Component({
  selector: 'flow-field-translate',
  templateUrl: './field-translate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowFieldTranslateComponent implements OnInit, OnChanges {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() fieldLabel: string;
  @Input() hideLabel: boolean;
  @Input() placeholder: string;
  @Input() hidePlaceholder: boolean;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() hint: string;
  @Input() translateConfig: FlowTranslateConfigInterface;

  @Output() fieldTranslated: EventEmitter<FlowTextBlockModelInterface[]> = new EventEmitter();

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService,
    private UtilsService: FlowUtilsService,
    private DialogsService: FlowDialogsService
  ) {}

  ngOnInit() {
    this.labels = this._translateLabels();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.translateConfig && !changes.translateConfig.firstChange) {
      this.translateConfig = changes.translateConfig.currentValue;
    }
  }

  openDialog(): void {
    const title = this.translateConfig && this.translateConfig['modalTitleTranslateId']
      ? this.labels[this.translateConfig['modalTitleTranslateId']]
      : undefined;

    const subtitle = this.translateConfig && this.translateConfig['modalSubTitleTranslateId']
      ? this.labels[this.translateConfig['modalSubTitleTranslateId']]
      : undefined;

    if (this.UtilsService.hasProperty(this.translateConfig, 'tieModel')) {
      this.translateConfig.tieModelJsonPath = this.controlName;
    }

    this.DialogsService.open(FlowModalFieldTranslateComponent, {
      data: {
        title: title ? title : `${this.labels['forms.modal.field_translate']} <i>${ this.fieldLabel || this.placeholder }</i>`,
        subtitle,
        fieldValue: this.form.get(this.controlName).value,
        translateConfig: this.translateConfig
      },
      disableClose: true,
      minWidth: '60%'
    })
    .afterClosed()
    .subscribe(result => {
      if (!FlowHelpers.isEmptyArray(result)) {
        const { translations } = result[0];

        if (translations) {
          this.fieldTranslated.emit(translations);
        }
      }
    });
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labelsToTranslate: any = [
      'generic.error.required',
      'forms.label.translate',
      'forms.modal.field_translate'
    ];

    // Add custom labels for modal title and subtitle
    if (this.translateConfig) {
      const { modalTitleTranslateId, modalSubTitleTranslateId } = this.translateConfig;

      if (modalTitleTranslateId && modalSubTitleTranslateId) {
        labelsToTranslate.push({
          key: modalTitleTranslateId,
          params: {
            fieldName: this.fieldLabel || this.placeholder
          }
        },
        modalSubTitleTranslateId
        );
      }
    }

    return this.TranslateService.translateSync(labelsToTranslate);
  }
}
