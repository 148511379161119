/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowUserService } from '@flow/auth';
import { FlowStorageService, FlowUtilsService } from '@flow/core';

const SKIP_CLIENTS = [
  'flow-portal-impersonator',
  'flow-portal-external-authentication',
];
const DEFAULT_CHANGE_PASSWORD_PERIOD = 90;  // 90 days  // use this when there's no "ChangePasswordEvery" extra
const PASSWORD_EXPIRED_DATE_STORAGE_KEY = 'flow.pass-exp-date';

@Injectable({
  providedIn: 'root'
})
export class FlowPasswordExpiredService {

  constructor(
    private StorageService: FlowStorageService,
    private UserService: FlowUserService,
    private UtilsService: FlowUtilsService,
  ) { }

  get hasExpiredPassword() {
    if (this._isUserSkippable) {
      return false;
    }  // early exit (to bypass password-change)

    let expired = false;

    const changePasswordEvery = this.changePasswordEvery;

    if (changePasswordEvery > 0) {  // if is 0 then the project does not force password change.
      const lastDate = this.validLastDate;

      // only proceed if we have a valid lastDate
      if (lastDate) {
        const deltaMilliseconds = this._daysToMilliseconds(changePasswordEvery);
        const now = Date.now();
        const maxDate = new Date(now - deltaMilliseconds);

        expired = (+maxDate > +lastDate);
      }
      else {  // no valid lastDate (or no extras found), so just force password change
        expired = true;

        console.error(`No (valid) "LastPasswordUpdatedDate", "LastLoginDate" or "created" extra found!`);
      }
    }

    return expired;
  }

  get changePasswordEvery() {
    const { ExtendedProperties } = this.UserService.customer;
    let customerChangePasswordEvery = this.UtilsService.getExtraValue(ExtendedProperties, 'ChangePasswordEvery');

    customerChangePasswordEvery = customerChangePasswordEvery === false
      ? DEFAULT_CHANGE_PASSWORD_PERIOD
      : +customerChangePasswordEvery;

    return customerChangePasswordEvery;
  }

  get validLastDate() {
    return this._getValidStoragePasswordExpiredDate()
      ||
      this._getValidLastPasswordUpdatedDate()
      ||
      this._getValidLastLoginDate()
      ||
      this._getValidCompanyCreationDate();
  }

  get storagePasswordExpiredDate() {
    return this.StorageService.get(PASSWORD_EXPIRED_DATE_STORAGE_KEY);
  }

  set storagePasswordExpiredDate(date) {
    this.StorageService.set(PASSWORD_EXPIRED_DATE_STORAGE_KEY, date);
  }

  removeStoragePasswordExpiredDate() {
    this.StorageService.remove(PASSWORD_EXPIRED_DATE_STORAGE_KEY);
  }

  /**
   * TRUE if current user is skippable, e.g., being impersonated or coming from external SSO.
   */
  private get _isUserSkippable() {
    return SKIP_CLIENTS.includes(this.UserService.user['client_id']);
  }

  private _daysToMilliseconds(days: number) {
    const HOURS = 24;
    const MINUTES = 60;
    const SECONDS = 60;
    const MILLISECONDS = 1000;

    return days * HOURS * MINUTES * SECONDS * MILLISECONDS;
  }

  /**
   * checks if there's a localStorage PasswordExpiredDate and if so check if its value is a valid date number. If so it
   * returns a Date obj based on the value.
   */
  private _getValidStoragePasswordExpiredDate(): Date|null {
    let value = this.storagePasswordExpiredDate;

    if (value) {
      value = +value;

      if (this._isValidDate(value)) {
        return new Date(value);
      }
      else {
        console.error(`"${ PASSWORD_EXPIRED_DATE_STORAGE_KEY }" is not valid!`);
      }
    }

    return null;
  }

  /**
   * checks if there's a 'LastPasswordUpdatedDate' extra and if so check if its value is a valid date string. If so it
   * returns a Date obj based on the value.
   */
  private _getValidLastPasswordUpdatedDate(): Date|null {
    const name = 'LastPasswordUpdatedDate';
    const value = this.UserService.getExtraValue(name);

    if (value !== false) {
      if (this._isValidDate(value)) {
        return new Date(value);
      }
      else {
        console.error(`Extra "${ name }" is not a valid date!`);
      }
    }

    return null;
  }

  /**
   * checks if there's a 'LastLoginDate' extra and if so check if its value is a valid date string. If so it
   * returns a Date obj based on the value.
   */
  private _getValidLastLoginDate(): Date|null {
    const name = 'LastLoginDate';
    const value = this.UserService.getExtraValue(name);

    if (value !== false) {
      if (this._isValidDate(value)) {
        return new Date(value);
      }
      else {
        console.error(`Extra "${ name }" is not a valid date!`);
      }
    }

    return null;
  }

  /**
   * checks if there's a (customer) 'created' extra and if so check if its value is a valid date string. If so it
   * returns a Date obj based on the value.
   */
  private _getValidCompanyCreationDate(): Date|null {
    const name = 'created';
    const value = this.UtilsService.getExtraValue(this.UserService.customer.ExtendedProperties, name);

    if (value !== false) {
      if (this._isValidDate(value)) {
        return new Date(value);
      }
      else {
        console.error(`Extra "${ name }" is not a valid date!`);
      }
    }

    return null
  }

  private _isValidDate(date: string | number | Date) {
    if (this._isString(date) || typeof date === 'number') {
      date = new Date(date);
    }

    return date instanceof Date && !isNaN(date.getTime());
  }

  private _isString(value) {
    return (typeof value === 'string' || value instanceof String);
  }
}
