/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { FlowVendorDashboardInterface } from '@flow/core';
import { FlowUserService } from '@flow/auth';
import { FlowDialogsService, FlowPortalService } from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowMarketplaceStateInterface } from '@marketplaceStore/reducers';
import { SetPrivacyAgreement } from '@marketplaceStore/actions';
import { getIfCustomerIsLoading, getIfCustomerSaved } from '@marketplaceStore/selectors';

import {
  InterpolationOptionsInterface
} from '../../../marketplace-shared/directives/interpolate-html/interpolationOptions.interface';

@Component({
  selector: 'flow-modal-terms-agreement',
  templateUrl: './modal-terms-agreement.component.html',
  styleUrls: ['./modal-terms-agreement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FlowModalTermsAgreementComponent implements OnInit, OnDestroy {

  labels: FlowTranslateLabel;

  dashboard: FlowVendorDashboardInterface;

  form: UntypedFormGroup;

  currentPortalTermsKey: string;

  isLoading$: Observable <boolean>;

  subscription$$: Subscription = new Subscription();

  interpolationOptions: InterpolationOptionsInterface;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef <FlowModalTermsAgreementComponent>,
    private store: Store <FlowMarketplaceStateInterface>,
    private UserService: FlowUserService,
    private DialogsService: FlowDialogsService,
    private PortalService: FlowPortalService,
    private TranslateService: FlowTranslateService
  ) {
    this.form = this.formBuilder.group({});
    this.currentPortalTermsKey = `${this.data.source}Terms`;
    this._setupForm();
  }

  ngOnInit() {
    this.isLoading$ = this.store.pipe(select(getIfCustomerIsLoading));

    this.subscription$$ = this.store
      .pipe(
        select(getIfCustomerSaved),
        filter(status => status === true)
      )
      .subscribe(status => this.dialogRef.close('terms-accepted'));

    this.labels = this.TranslateService.translateSync([
      'general.btn.save',
      'general.label.flow_privacy',
      'general.btn.close',
      'general.label.flow_terms_acceptance',
      'general.label.flow_privacy_acceptance',
      'general.label.marketing_policy',
      { key: 'general.label.company_terms_headline', params: { project: this.data.companyName } },
      { key: 'general.label.projectsupplier_terms_acceptance', params: { customer: this.data.companyName } },
      { key: 'general.label.terms_customer_acceptance', params: { customer: this.data.companyName } },
      { key: 'general.label.privacy_terms_customer_acceptance', params: { customer: this.data.companyName } },
      { key: 'general.label.operational_communication_acceptance', params: { customer: this.data.companyName } },
    ]);

    if (this.data.isSupplierPortal) {
      this.interpolationOptions = {
        links: {
          customer_privacy: this.PortalService.getCustomerPrivacySettings() || {},
          customer_terms: this.PortalService.getCustomerTermsSettings() || {}
        }
      };
    }
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }

  _setupForm(): void {
    if (this.data.isSupplierPortal) {   // CUSTOMER terms

      if (this.data.isPartner) {
        if (this.data.isAdmin) {
          if (!this.data.projectSupplierTerms) {
            this.form.addControl('ProjectSupplierTerms', new UntypedFormControl(false, [Validators.requiredTrue]));
          }

          if (!this.data.customerTerms) {
            this.form.addControl('CustomerTerms', new UntypedFormControl(false, [Validators.requiredTrue]));
          }
        }
      }

      if (!this.data.customerPrivacy) {
        this.form.addControl('CustomerPrivacy', new UntypedFormControl(false, [Validators.requiredTrue]));
      }

      if (!this.data.customerOperationalCommunication) {
        this.form.addControl('CustomerOperationalCommunication', new UntypedFormControl(false, [Validators.requiredTrue]));
      }
    }
    else {    // DEFAULT terms
      if (!this.data.eula) {
        this.form.addControl('EULA', new UntypedFormControl(false, [Validators.requiredTrue]));
      }

      if (!this.data.privacyPolicy) {
        this.form.addControl('FlowPrivacyPolicy', new UntypedFormControl(false, [Validators.requiredTrue]));
      }

      if (!this.data.marketingPolicy && true === this.data.showMarketingPolicy) {
        this.form.addControl('FlowMarketingPolicy',
          (this.data.isMarketingOptional)
            ? new UntypedFormControl(false, [])
            : new UntypedFormControl(false, [Validators.requiredTrue])
        );
      }

      if (
        this.data.source !== 'Flow' &&
        !this.data.currentSourceTermsAccepted &&
        this.data.currentPortalTerms &&
        Object.keys(this.data.currentPortalTerms.translations).length
      ) {
        this.form.addControl(this.currentPortalTermsKey, new UntypedFormControl(false, [Validators.requiredTrue]));
      }
    }
  }

  openCurrentPortalTerms($event: MouseEvent): void {
    $event.preventDefault();

    this.DialogsService.textModal({
      showOkButton: false,
      translateTitle: false,
      title: this.data.companyName,
      content: this.TranslateService.getTranslationFromTextBlock(this.data.currentPortalTerms, this.UserService.language),
      translateContent: false,
      contentTagsWithoutBottomMargin: true
    });
  }

  onSubmit(): void {
    const { value } = this.form;
    let data = {};

    if (this.data.isSupplierPortal) {
      const project = this.data.source;

      if (this.data.reAcceptTermsMsg) {
        data[`reAcceptTermsMsg__${ project }`] = '';  // this will set the extra (if exist) to empty string, which will
                                                      // cause the extra to be removed prior to persisting to BE.
      }

      // add the project as postfix on the extra, e.g. CustomerTerms__amsterdam
      Object.keys(value).forEach(key => data[`${ key }__${ project }`] = value[key]);
    }
    else {
      data = value;
    }

    this.store.dispatch(new SetPrivacyAgreement(data));
  }
}
