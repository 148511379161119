import { FlowHelpers } from '@flow/core';

export interface FlowExportParamsInterface {
  format?: string;
  mime?: string;
  filename?: string;
  delimiter?: string;
}

export class FlowExport {

  readonly filename: string;

  private args: FlowExportParamsInterface;

  private fileContent: string;

  constructor(params?: FlowExportParamsInterface) {
    this.args = Object.assign({
      format: 'csv',
      mime: 'application/octet-stream',
      filename: 'flow-export',
      delimiter: ','
    }, params);

    this.filename = `${this.args.filename}-${FlowHelpers.timestamp()}.${this.args.format}`;
  }

  exportToCsv(columns: {
    title: string;
    field: string;
  }[], data: {[key: string]: any }[]): Promise <boolean> {
    // Adding universal BOM at beginning of exported file content
    // to force excel to open csv files using UTF-8
    this.fileContent = '\uFEFF';

    return new Promise((resolve, reject) => {
      try {
        if (FlowHelpers.isEmptyArray(columns)) {
          throw new Error('No columns found.');
        }

        this.fileContent += columns.map(column => column.title).join(this.args.delimiter);
        this.fileContent += ' \n';

        data.forEach(row => {
          columns.forEach((column, index) => {
            this.fileContent += `"${row[ column.field ]}"`;
            if (++index < columns.length) {
              this.fileContent += this.args.delimiter;
            }
          });

          this.fileContent += ' \n';
        });

        if (!this.fileContent) {
          throw new Error('There was an error generating the csv file');
        }

        this.renderHiddenDownloadTrigger()
        .then(() => resolve(null))
        .catch((err) => {
          throw new Error(err);
        });

      }
      catch (Error) {
        console.error(Error);
        reject();
      }
    });
  }

  exportToJson(data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if (FlowHelpers.isEmptyObject(data)) {
          throw new Error('No data found.');
        }

        this.fileContent = JSON.stringify(data);

        this.renderHiddenDownloadTrigger()
        .then(() => resolve(null))
        .catch((err) => {
          throw new Error(err);
        });

      }
      catch (Error) {
        console.error(Error);
        reject();
      }
    });
  }

  renderHiddenDownloadTrigger(): Promise <void> {
    const a = document.createElement('a');
    return new Promise((resolve, reject) => {
      // IE10 is not supported anymore in ng11
      /* if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(new Blob([this.fileContent], { type: this.args.mime }), this.filename );
        resolve();
      } */
      if ('download' in a) {
        const blob = new Blob([this.fileContent], {type: this.args.mime });
        a.href = window.URL.createObjectURL(blob);
        a.download = this.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        resolve();
      }
      else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        reject('Safari is not supported');
      }
      else {
        const hiddenFrame = document.createElement('iframe');
        hiddenFrame.src = 'data:' + this.args.mime + ',' + encodeURIComponent(this.fileContent);
        document.body.appendChild(hiddenFrame);
        setTimeout(() => {
          document.body.removeChild(hiddenFrame);
          resolve();
        }, 0);
      }
    });
  }
}
