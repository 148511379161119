/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FlowEnvService } from '../env/env.service';
import { FlowHttp } from '../../classes/class.http';

@Injectable({
  providedIn: 'root'
})
export class FlowCmsService extends FlowHttp {

  constructor(
    _HttpClient: HttpClient,
    EnvService: FlowEnvService,
  ) {
    super(_HttpClient, EnvService.get('cmsUrl'));
  }
}
