/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { FlowLanguageInterface, FlowUtilsService } from '@flow/core';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-modal-language-selection',
  templateUrl: './modal-language-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowModalLanguageSelectionComponent implements OnInit {

  languagesToSelect: FlowLanguageInterface[];

  preselectedLanguage: string;

  loading$: BehaviorSubject <boolean> = new BehaviorSubject(true);

  labels: FlowTranslateLabel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService
  ) { }

  ngOnInit(): void {
    const currentLanguage = this.TranslateService.getCurrentLanguage();
    const { languagesToSelect } = this.data;

    // If languages are passed, use lcId of the first item as selectedLanguage
    if (languagesToSelect && languagesToSelect.length > 0) {
      const { lcId } = languagesToSelect[0];

      this.languagesToSelect = languagesToSelect;
      this.preselectedLanguage = lcId ? lcId : currentLanguage;
    }
    else {
      this.languagesToSelect = this.UtilsService.getLanguages();
      this.preselectedLanguage = currentLanguage;
    }

    this.TranslateService.fetchLabels([
      'generic.btn.add',
      'generic.btn.close',
      'forms.label.select_available_language',
    ])
    .subscribe(data => {
      this.loading$.next(false);
      this.labels = data;
    });
  }
}
