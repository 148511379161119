<ng-container *ngIf="layout === 'inline'">
  <mat-form-field [formGroup]="form" [ngClass]="containerClassNames">
    <mat-label>{{ fieldLabel }}</mat-label>

    <input
      [id]="controlName"
      type="text"
      matInput
      [formControlName]="controlName"
      [minlength]="minLength"
      [attr.aria-label]="fieldLabel">

      <mat-error
        [hidden]="control.valid"
        [innerHTML]="control.hasError('required')
          ? labels['generic.error.required']
          : control.hasError('minlength')
            ? labels['generic.error.minlength']
            : control.hasError('nameNotAllowedCharacters')
              ? labelNameInvalidCharsError(control.getError('nameNotAllowedCharacters'))
              : control.hasError('nameNotNumber')
                ? labels['generic.error.field_cannot_be_number']
                : null"
        role="alert"
        aria-live="assertive"
      ></mat-error>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="layout === 'column'">
  <div class="row align-items-baseline" [ngClass]="containerClassNames">

    <div [ngClass]="leftColumnClassNames">
      <label
        [attr.for]="controlName"
        class="form-group-label"
        [ngClass]="{ 'required': required }">
        {{ fieldLabel }}
      </label>
    </div>

    <div [ngClass]="rightColumnClassNames">
      <mat-form-field [formGroup]="form">
        <input
          [id]="controlName"
          type="text"
          matInput
          [formControlName]="controlName"
          [minlength]="minLength"
          [attr.aria-label]="fieldLabel">

          <mat-error
            [hidden]="control.valid"
            [innerHTML]="control.hasError('required')
              ? labels['generic.error.required']
              : control.hasError('minlength')
                ? labels['generic.error.minlength']
                : control.hasError('nameNotAllowedCharacters')
                  ? labelNameInvalidCharsError(control.getError('nameNotAllowedCharacters'))
                  : control.hasError('nameNotNumber')
                    ? labels['generic.error.field_cannot_be_number']
                    : null"
            role="alert"
            aria-live="assertive"
          ></mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>
