export const DEFAULT_COLOR_SCHEMA = {
  'white': '#fefefe',
  'black': '#212121',
  'primary': '#005eaa',
  'primary-hover': '#1e8cd2',
  'primary-bright': '#2e7ab7',
  'primary-i-30': '#2e7ab7',
  'danger': '#d90000',
  'success': '#69be46',
  'text': '#6d6d6d',
  'content-title-color': '#6d6d6d',
  'content-bg-color': '#fefefe',
  'background': '#fefefe',
  'info-text-color': '#212121',
  'menu-top-bg-color': '#ffffff',
  'badge-bg-color': '#d90000',
  'dropdown-bg-color': '#f0f0f0',
  'dropdown-bg-color-hover': '#e6e6e6',
  'dropdown-color-hover': '#6d6d6d',
  'header-bg-color-closed': '#ffffff',
  'header-bg-color-opened': '#ffffff',
  'header-color': '#005eaa',
  'help-bg-color': '#005eaa',
  'help-color': '#fefefe',
  'help-btn-bg-color': '#005eaa',
  'help-btn-border-color': '#fefefe',
  'help-btn-color': '#fefefe',
  'help-btn-bg-color-hover': '#fefefe',
  'help-btn-color-hover': '#005eaa',
  'nav-bg-color': '#f0f0f0',
  'page-nav-color': '#6d6d6d',
  'page-nav-color-active': '#005eaa',
  'page--nav-bg-color': '#f0f0f0',
  'bookmark-bg-color': '#005eaa',
  'bookmark-color': '#fefefe',
  'bookmark-color-active': '#005eaa',
  'bookmark-color-hover': '#005eaa',
  'tab-border-color': '#6d6d6d',
  'tab-color': '#6d6d6d',
  'tab-color-active': '#6d6d6d',
  'tab-bg-color-active': '#fefefe',
  'grid-bg-color': '#005eaa',
  'chart-color': '#fefefe',
  'chart-label-color': '#fefefe',
  'chart-grid-line-color': '#2e7ab7',
  'tile-border-color': '#e0e0e0',
  'tile-border-color-hover': '#e0e0e0',
  'table-hover-bg-color': '#ebebeb',
  'footer-bg-color': '#f0f0f0',
  'footer-color': '#6d6d6d',
  'breadcrumb-color': '#6d6d6d'
};

/* former scheme
'white': '#fefefe',
'black': '#111',
'primary': '#005eaa',
'primary-bright': '#2e7ab7',
'primary-i-30': '#2e7ab7',
'danger': '#cf000f',
'success': '#56af37',
'text': '#868686',
'content-title-color': '#111',
'content-bg-color': '#fefefe',
'background': '#fefefe',
'menu-top-bg-color': '#005eaa',
'badge-bg-color': 'yellow',
'header-bg-color-closed': '#005eaa',
'header-bg-color-opened': '#005eaa',
'header-color': '#fefefe',
'help-bg-color': '#005eaa',
'help-color': '#fefefe',
'help-btn-bg-color': '#005eaa',
'help-btn-border-color': '#fefefe',
'help-btn-color': '#fefefe',
'help-btn-bg-color-hover': '#fefefe',
'help-btn-color-hover': '#005eaa',
'nav-bg-color': '#ebebeb',
'bookmark-bg-color': '#2e7ab7',
'bookmark-color': '#fefefe',
'tab-border-color': '#ddd',
'tab-bg-color-active': '#fefefe',
'grid-bg-color': '#005eaa'
*/
