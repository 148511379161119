/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { FlowUtilsService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

@Component({
  selector: 'flow-modal-message-accept',
  templateUrl: './modal-message-accept.component.html',
  styleUrls: ['./modal-message-accept.component.scss']
})
export class FlowModalMessageAcceptComponent implements OnInit {

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  form: UntypedFormGroup;

  labels: FlowTranslateLabel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FlowModalMessageAcceptComponent>,
    private formBuilder: UntypedFormBuilder,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService
  ) {
    this.form = this.formBuilder.group({
      accept: new UntypedFormControl('', [Validators.requiredTrue])
    });
  }

  ngOnInit(): void {
    this.loading$.next(true);

    this.data.content = this.UtilsService.decodeHtml(this.data.content);

    this.TranslateService.fetchLabels([
      'general.label.note',
      'general.label.yes_i_agree',
      'general.btn.continue'
    ])
    .subscribe(results => {
        this.labels = results;
        this.loading$.next(false);
    });
  }

  doAccept(): void {
    this.dialogRef.close(true);
  }
}
