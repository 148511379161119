/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, from, Observable, of } from 'rxjs';
import { FlowEnvService, FlowHelpers } from '@flow/core';
import { FlowTokensService, FlowUserService } from '@flow/auth';
import { concatMap, filter, first, map } from 'rxjs/operators';
import { FlowWebsocketMessageInterface } from './websocket-message.interface';
import { FlowWebsocketNotificationMessageInterface } from './websocket-notification-message.interface';

export type WebsocketConnectionStatus = 'offline' | 'connecting' | 'connected' | 'closing' | 'closed';

@Injectable({
  providedIn: 'root'
})
export class FlowWebsocketService {

  /** WS Object */
  private ws: WebSocket;

  /** Websocket URL */
  private url: string;

  /** Subject to keep track of the connection status */
  private state$$: BehaviorSubject <WebsocketConnectionStatus> = new BehaviorSubject('offline');

  /** Raw messages stream */
  private feed$$: BehaviorSubject <FlowWebsocketMessageInterface> = new BehaviorSubject(null);

  /** Retry debouncing ratio */
  private retryDecay = 1.5;

  /** Number of reconnection attempts */
  private numberOfRetries = 0;

  /** Max number of retry attempts */
  private maxRetries = 10;

  constructor(
    private EnvService: FlowEnvService,
    private TokensService: FlowTokensService,
    private UserService: FlowUserService
  ) { }

  /** Get the full stream */
  get feed$(): Observable <FlowWebsocketMessageInterface> {
    return this.feed$$.asObservable().pipe(
        filter(msg => !!msg),
      );
  }

  /** Get a filtered stream for notifications */
  get notificationFeed$(): Observable <FlowWebsocketNotificationMessageInterface> {
    return this.feed$.pipe(
      filter(message => message.topic === 'notification'),
      concatMap(message => {
        if (message.subject.indexOf('history') > -1) {
          if (!FlowHelpers.isEmptyArray(message.message)) {
            // Reverse history messages as messages are reversed in
            // flow-ng-marketplace-app\src\app\modules\layout\components\menu-top-notifications\menu-top-notifications.component.ts
            // to keep correct order (newest message on top)
            message.message.reverse();

            return from(message.message).pipe(
              map((notification: FlowWebsocketMessageInterface) => this.convertToNotification(notification))
            );
          }

          return EMPTY;
        }
        else {
          return of(this.convertToNotification(message));
        }
      })
    );
  }

  /** Get the websocket status */
  get state$(): Observable <string> {
    return this.state$$.asObservable();
  }

  /** Get if socket is connected. (Needed before sending messages) */
  get waitForConnection$(): Observable <boolean> {
    return this.state$.pipe(
      filter(status => status === 'connected'),
      first(),
      map(() => true)
    );
  }

  /**
   * Establish a connection to the WS.
   */
  connect(url?: string): void {
    this.url = url || `${this.EnvService.get('wsUrl')}/com/channel?token=${this.TokensService.getToken()}`;

    this.setStatus('connecting');
    this.EnvService.logWarn('Trying to connect to WS url,', this.url);

    try {
      this.ws = new WebSocket(this.url);
      this.attachHandlers();
    }
    catch (Error) {
      this.EnvService.log('WS connect error.', { error: Error}, 'error');
      this.EnvService.logDebug('Failed.');
      this.setStatus('offline');
      this.ws = undefined;
    }
  }

  reconnect(): void {
    if (typeof this.ws === 'undefined') {
      return;
    }

    if (this.numberOfRetries >= this.maxRetries) {
      this.EnvService.logDebug('WS Reconnect limit reached.');
      return;
    }

    this.numberOfRetries += 1;
    this.EnvService.logDebug('WS reconnect attempt', (this.numberOfRetries * this.retryDecay));
    setTimeout(() => this.connect(), (this.numberOfRetries * this.retryDecay) * 1000);
  }

  /**
   * Disconnect from the WS server.
   */
  disconnect(): void {
    if (typeof this.ws === 'undefined') {
      return;
    }

    this.ws.close(1000);
  }

  /**
   * Attach Event listeners to the connection.
   */
  attachHandlers() {
    if (typeof this.ws === 'undefined') {
      return;
    }

    this.ws.onopen = () => {
      this.setStatus('connected');
      this.numberOfRetries = 0;
      this.EnvService.logDebug('Connected to WS.');
    };

    this.ws.onclose = event => {
      this.EnvService.logDebug('WS closed', {event});
      this.handleClose(event);
      this.setStatus('closed');
    };

    this.ws.onerror = event => {
      this.EnvService.logDebug('WS error', {event});
      this.disconnect();
    };

    this.ws.onmessage = event => {
      const message = this.parseRawMessage(event.data);
      this.feed$$.next(message);
    };
  }

  parseRawMessage(message: string): FlowWebsocketMessageInterface {
    try {
      return JSON.parse(message);
    }
    catch (Error) {
      return null;
    }
  }

  /**
   * Update the subject for the connection status change.
   */
  setStatus(status: WebsocketConnectionStatus): void {
    this.state$$.next(status);
    this.EnvService.logDebug('WS Status changed to', status);
  }

  convertToNotification(message: FlowWebsocketMessageInterface): FlowWebsocketNotificationMessageInterface {
    return {
      id: message.id,
      icon: message.message.appIcon || 'system',
      // appTitle: message.message.appIcon || 'FLOW',
      color: message.message.color || 'default',
      message: message.message.msg || null,
      messagesCount: 1,
      hideCounter: false,
      time: message.time,
      read: false,
      from: message.from,
      link: message.message.link || null,
      extras: message.message.extras || null,
      progress: false,
      progressMode: 'determinate',
      _raw: message
    };
  }

  /**
   * Connection closing handler.
   */
  handleClose(event: CloseEvent): void {
    this.setStatus('closing');
    if (event.code !== 1000) {
      this.reconnect();
      return;
    }
    this.ws = undefined;
  }

  /**
   * Sends a message
   */
  send(message: FlowWebsocketMessageInterface): void {
    const defaults = {
      id: FlowHelpers.createGuid(),
      from: this.UserService.username,
      to: [this.UserService.username],
      message: {}
    };

    this.ws.send(JSON.stringify({
      ...defaults,
      ...message
    }));
  }
}
