import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordsMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const newPasswordCtrl = control.get('newPassword');
  const repeatNewPasswordCtrl = control.get('repeatNewPassword');

  const newPasswordValue = newPasswordCtrl ? newPasswordCtrl.value : '';
  const repeatNewPasswordValue = repeatNewPasswordCtrl ? repeatNewPasswordCtrl.value : '';

  const invalid = (newPasswordValue && repeatNewPasswordValue) && !(newPasswordValue === repeatNewPasswordValue);

  if (invalid) {
    repeatNewPasswordCtrl.setErrors({ 'passwordMatch': true });
  }
  else {
    _clearControlError(repeatNewPasswordCtrl as UntypedFormControl, 'passwordMatch');
  }

  return invalid ? { 'passwordMatch': true } : null;
};

/**
 * clear specified error from the controller without clearing other errors (if any)
 **/
const _clearControlError = (ctrl: UntypedFormControl, error: string) => {
  const { errors } = ctrl;

  if (errors && errors[error]) {
    delete errors[error];

    ctrl.setErrors(Object.keys(errors).length ? errors : null);
  }
};
