/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 * https://github.com/Agranom/ngx-material-timepicker
 */

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-timepicker',
  templateUrl: './timepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowTimepickerComponent implements OnInit {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() required: boolean;

  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) {}

  ngOnInit() {
    this.labels = this._translateLabels();
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.btn.cancel',
      'general.btn.ok',
      'generic.error.required'
    ]);
  }
}
