/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const validator = control.validator && control.validator({} as AbstractControl);
    const isRequired = validator && validator.required;

    if (isRequired) {
      return !!(control && control.invalid && (control.dirty || control.touched));
    }
    else {
      return !!(control && control.invalid);
    }
  }
}

@Component({
  selector: 'flow-select-multiple-autocomplete',
  templateUrl: './select-multiple-autocomplete.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowSelectMultipleAutocompleteComponent implements OnInit {

  @Input() form: any;
  @Input() controlName: string;
  @Input() dropdownOptions: any[];
  @Input() showSelectedAsChips: boolean;
  @Input() errorMsg: string;

  @ViewChild('searchTerm') searchTerm: ElementRef;

  labels: FlowTranslateLabel;

  selectControl: UntypedFormControl;
  searchTermControl = new UntypedFormControl();
  matcher = new CustomErrorStateMatcher();

  filteredOptions$: Observable<any[]>;
  selectedOptions: any[] = [];

  constructor(
    private TranslateService: FlowTranslateService
  ) {}

  ngOnInit() {
    this.labels = this._translateLabels();
    this.selectControl = this.form.get(this.controlName) as UntypedFormControl;

    // Filter dropdown options based on input value changes
    this.filteredOptions$ = this.searchTermControl.valueChanges
      .pipe(
        startWith<string>(''),
        distinctUntilChanged(),
        map(name => this._filter(name))
      );
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTermControl.patchValue('');
  }

  /**
   * Actions triggered when opening dropdown
   */
  openedChange(event): void {
    // Set search text control value as empty while opening dropdown
    this.searchTermControl.patchValue('');

    // Focus to search text control while clicking on dropdown
    if (event) {
      this.searchTerm.nativeElement.focus();
    }
  }

  /**
   * Remove selected option from form control triggered by mat-chips
   */
  removeSelectedOption(selectedOption: string): void {
    this.selectedOptions = (this.selectControl.value as string[]).filter(option => option !== selectedOption);

    // Update form control
    this.selectControl.patchValue(this.selectedOptions);
  }

  /**
   * Remove unchecked options from selected options
   */
  selectionChange(event): void {
    if (event.isUserInput && event.source.selected === false) {
      const index = this.selectedOptions.indexOf(event.source.value);
      this.selectedOptions.splice(index, 1);
    }
  }

  /**
   * Filter dropdownOptions based on search input
   */
  private _filter(name: string): any[] {
    const charsToBeEscaped = ['-', '[', ']', '/', '{', '}', '(', ')', '*', '+', '?', '.', '^', '$', '|'];

    let filterValue = name.toLowerCase();

    // Escape backslash
    filterValue = filterValue.replace(/\\/g, '\\\\');

    // Escape other special chars
    charsToBeEscaped.forEach(char => {
      const searchRegExp = new RegExp(`\\${ char }`, 'g');

      filterValue = filterValue.replace(searchRegExp, `\\${ char }`);
    });

    // console.log(filterValue)

    const filteredOptions = this.dropdownOptions.filter(option => option.name.toLowerCase().search(filterValue) > -1);

    // Set selected values to retain the selected checkbox state
    this._setSelectedOptions();

    // Update form control
    this.selectControl.patchValue(this.selectedOptions);

    return filteredOptions;
  }

  /**
   * Set selected options to retain the state
   */
  private _setSelectedOptions() {
    if (this.selectControl.value && this.selectControl.value.length > 0) {
      this.selectControl.value.forEach((selectedOption: any) => {
        if (this.selectedOptions.indexOf(selectedOption) === -1) {
          this.selectedOptions.push(selectedOption);
        }
      });
    }
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'forms.label.search',
      'forms.label.clear_search',
      'forms.error.no_results'
    ]);
  }
}
