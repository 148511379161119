/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate'
})
export class FlowTextTruncatePipe implements PipeTransform {
  transform(value: string, num: number): any {
    if (!num || num >= value.length) {
      return value;
    }

    return value.slice(0, num) + ' ...';
  }
}
