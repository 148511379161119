/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';

import { registerLocaleData } from '@angular/common';

import locale_en_gb from '@angular/common/locales/en-GB';
import locale_nl    from '@angular/common/locales/nl';
import locale_de    from '@angular/common/locales/de';
import locale_fr    from '@angular/common/locales/fr';
import locale_es    from '@angular/common/locales/es';
import locale_it    from '@angular/common/locales/it';
import locale_hu    from '@angular/common/locales/hu';
import locale_da    from '@angular/common/locales/da';
import locale_tr    from '@angular/common/locales/tr';
import locale_zh    from '@angular/common/locales/zh';
import locale_ja    from '@angular/common/locales/ja';


@Injectable({
  providedIn: 'root'
})
export class FlowLocaleService {

  private supportedLocaleIds = [
    'en-GB', 'nl-NL', 'de-DE', 'fr-FR', 'es-ES', 'it-IT', 'hu-HU', 'da-DK', 'tr-TR', 'zh-HK', 'ja-JP'
  ];

  /**
   * Check if given localeId is a supported locale Id to prevent
   * 'Missing locale data' errors when using FlowDateLocalePipe
   */
  isSupportedLocaleId(localeId: string): boolean {
    return this.supportedLocaleIds.indexOf(localeId) > -1;
  }

  /**
   * Register locale data based on lcId
   */
  registerLocale(lcId: string): void {
    if (!lcId) {
      return;
    }

    switch (lcId) {
      case 'en-GB': {
        registerLocaleData(locale_en_gb);
        break;
      }
      case 'nl-NL': {
        registerLocaleData(locale_nl);
        break;
      }
      case 'de-DE': {
        registerLocaleData(locale_de);
        break;
      }
      case 'fr-FR': {
        registerLocaleData(locale_fr);
        break;
      }
      case 'es-ES': {
        registerLocaleData(locale_es);
        break;
      }
      case 'it-IT': {
        registerLocaleData(locale_it);
        break;
      }
      case 'hu-HU': {
        registerLocaleData(locale_hu);
        break;
      }
      case 'da-DK': {
        registerLocaleData(locale_da);
        break;
      }
      case 'tr-TR': {
        registerLocaleData(locale_tr);
        break;
      }
      case 'zh-HK': {
        registerLocaleData(locale_zh);
        break;
      }
      case 'ja-JP': {
        registerLocaleData(locale_ja);
        break;
      }
      default: {
        return;
      }
    }
  }
}
