/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowAuthService } from '@flow/auth';
import { FlowTranslateService } from '@flow/translate';
import { FlowEnvService } from '@flow/core';
import { FlowPortalService } from '../portal/portal.service';
import { FlowTrackingService } from '../tracking/tracking.service';

@Injectable({
  providedIn: 'root'
})
export class FlowInitService {

  constructor(
    private EnvService: FlowEnvService,
    private AuthService: FlowAuthService,
    private TranslateService: FlowTranslateService,
    private PortalService: FlowPortalService,
    private TrackingService: FlowTrackingService
  ) {
  }

  initialize(): Promise <void> {

    return new Promise(resolve => {

      this.EnvService.logWarn('App initialization.');

      this.TranslateService.localizeApp(this.TranslateService.getInitialLanguage());

      this.EnvService.logWarn('getting initial language from storage ..');
      this.EnvService.logDebug(this.TranslateService.getCurrentLanguage());

      Promise.all([
       this.PortalService.setPortal(),
       this.EnvService.createFingerprint()
      ]).then(() => {
        const initialVendor = this.EnvService.isVendorPortal ? this.EnvService.portalDomain : this.AuthService.getInitialVendor();
        this.AuthService.setVendor(initialVendor);

        this.EnvService.logWarn('Setting flow.vendor to ..');
        this.EnvService.logDebug(this.AuthService.vendor);

        // Initialize the GTM tracking service.
        this.TrackingService.start();

        this.EnvService.logWarn('end initialization');
        // console.log('FlowInitService ::: initialize() ::: flow-ng17-shared ::: vendor', this.AuthService.vendor, ' ::: current lang ::: ', this.TranslateService.getCurrentLanguage());
        resolve();
      });
    });
  }
}
