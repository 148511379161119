/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'flow-gravatar',
  templateUrl: './gravatar.component.html',
  styleUrls: ['./gravatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowGravatarComponent implements OnInit {

  @Input() location: string;

  @Input() width = 48;

  @Input() height = 48;

  @Input() classNames: string[];

  @Input() tooltip: string;

  @Input() set email(value: string) {
    this.updateImageSrc(value);
  }

  @HostBinding('class') get classes() {
    return !this.classNames ? '' : this.classNames.join(' ');
  }

  /*
  The styles are assigned directly to the component now
  to prevent layout issues when showing material icon as fallback

  @HostBinding('style.width.px') get elementWidth() {
    return this.width;
  }

  @HostBinding('style.height.px') get elementHeight() {
    return this.height;
  }
  */

  imageUrl: string;
  matIconAsFallback: boolean;

  ngOnInit(): void {
    this.matIconAsFallback = false;
  }

  updateImageSrc(value: string): void {
    const gravatarFallbackImage = this.location && this.location === 'menu-top'
      ? '404' // gravatar api returns a 404
      : 'mp'; // gravatat api returns image

    this.imageUrl = null;

    if (!value) {
      return;
    }

    const hash = Md5.hashStr(value.trim().toLowerCase());
    this.imageUrl = `//www.gravatar.com/avatar/${hash}?d=${gravatarFallbackImage}`;
  }

  showMatIconAsFallback($event) {
    this.matIconAsFallback = ($event === true) ? true : false;
  }
}
