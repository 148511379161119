// https://gist.github.com/ahmeti/5ca97ec41f6a48ef699ee6606560d1f7

import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[flowNumeric]',
})
export class FlowNumericFieldDirective {
  @Input() decimals = 0;

  constructor(
    private el: ElementRef,
    private control: NgControl) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown() {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('paste', ['$event'])
  onPaste() {
    this.run(this.el.nativeElement.value);
  }

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    }
    else {
      const regExpString =
            '^\\s*((\\d+(\\.\\d{0,' +
            this.decimals +
            '})?)|((\\d*(\\.\\d{1,' +
            this.decimals +
            '}))))\\s*$';

      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue: string) {
    setTimeout(() => {
      const currentValue: string = this.el.nativeElement.value;

      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
        this.control.control.patchValue(oldValue);
      }
    });
  }
}
