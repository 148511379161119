/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Inject, OnInit, HostBinding } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { FlowUtilsService } from '@flow/core';
import { FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-modal-simple-text',
  template: `
    <flow-preloader *ngIf="loading$ | async else content" [classes]="'p-3'"></flow-preloader>
    <ng-template #content>
      <div *ngIf="dialogData.title" class="p-3 modal-title bg-primary color-white">
        <h5 class="truncate" [innerHTML]="dialogData.title"></h5>
      </div>
      <mat-dialog-content>
        <div [innerHTML]="dialogData.content | safe:'html'"></div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button
          *ngIf="dialogData.showCancelButton"
          class="button button-close-dialogs"
          [mat-dialog-close]="false"
          [innerHTML]="dialogData.cancelButtonLabel"
        ></button>
        <button
          *ngIf="dialogData.showOkButton"
          class="button button-primary ml-2"
          [mat-dialog-close]="true"
          [innerHTML]="dialogData.okButtonLabel"
        ></button>
      </mat-dialog-actions>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowModalTextComponent implements OnInit {

  @HostBinding('class')
  get classes() {
    return this.dialogData.contentTagsWithoutBottomMargin ? 'no-bottom-margin' : '';
  }

  dialogData: any;

  loading$: BehaviorSubject <boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private UtilsService: FlowUtilsService,
    private TranslateService: FlowTranslateService
  ) {
    const defaults: any = {
      title: null,
      translateTitle: true,
      content: null,
      translateContent: true,
      showOkButton: true,
      showCancelButton: true,
      contentTagsWithoutBottomMargin: false,
      okButtonLabel: 'generic.btn.ok',
      cancelButtonLabel: 'generic.btn.close'
    };

    this.dialogData = Object.assign(defaults, this.data);

    // Decode content if it contains encoded HTML
    this.dialogData.content = this.UtilsService.decodeHtml(this.dialogData.content);

    this.loading$.next(true);
  }

  ngOnInit(): void {
    const toLoad = [];

    if (true === this.dialogData.translateContent) {
      toLoad.push(this.dialogData.content);
    }

    if (this.dialogData.title && true === this.dialogData.translateTitle) {
      toLoad.push(this.dialogData.title);
    }

    if (this.dialogData.showOkButton) {
      toLoad.push(this.dialogData.okButtonLabel);
    }

    if (this.dialogData.showCancelButton) {
      toLoad.push(this.dialogData.cancelButtonLabel);
    }

    this.TranslateService
      .fetchLabels(toLoad)
      .subscribe(data => {

      if (this.dialogData.showCancelButton && data[this.dialogData.cancelButtonLabel]) {
        this.dialogData.cancelButtonLabel = data[this.dialogData.cancelButtonLabel];
      }

      if (this.dialogData.showOkButton && data[this.dialogData.okButtonLabel]) {
        this.dialogData.okButtonLabel = data[this.dialogData.okButtonLabel];
      }

      if (this.dialogData.title && data[this.dialogData.title]) {
        this.dialogData.title = data[this.dialogData.title];
      }

      if (this.dialogData.content && data[this.dialogData.content]) {
        this.dialogData.content = data[this.dialogData.content];

        // Decode content if it contains encoded HTML
        this.dialogData.content = this.UtilsService.decodeHtml(this.dialogData.content);
      }

      this.loading$.next(false);
    });
  }
}
