/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnChanges, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { FlowKeyValueStringInterface, FlowHelpers } from '@flow/core';

import { FlowDialogsService } from '../../../services/dialogs/dialogs.service';
import { FlowModalFieldTranslateComponent } from '../../modals/components/modal-field-translate/modal-field-translate.component';
import { FlowTranslateConfigInterface } from '../../../interfaces/translate-config.interface';

@Component({
  selector: 'flow-translate-button',
  templateUrl: './translate-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowTranslateComponent implements OnChanges {

  @Input() buttonLabel: string;

  @Input() classNames: string;

  @Input() modalTitle: string;

  @Input() translateConfig: FlowTranslateConfigInterface;

  @Output() translated: EventEmitter<FlowKeyValueStringInterface> = new EventEmitter();

  constructor(
    private DialogsService: FlowDialogsService
  ) {}

  ngOnChanges(): void {
    this.classNames = !this.classNames
      ? 'button button-small button-primary-outline'
      : `button button-small ${this.classNames}`;
  }

  openTranslateDialog(): void {
    this.DialogsService.open(FlowModalFieldTranslateComponent, {
      data: {
        title: this.modalTitle,
        translateConfig: this.translateConfig
      },
      disableClose: true,
      minWidth: '60%'
    })
    .afterClosed()
    .subscribe(result => {
      if (!FlowHelpers.isEmptyArray(result)) {
        const { translations } = result[0];

        if (translations) {
          this.translated.emit(translations);
        }
      }
    });
  }
}
