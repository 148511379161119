/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlowPipesModule } from '../pipes/pipes.module';
import { FlowStatusMessageService } from './services/status-message.service';
import { FlowStatusMessageComponent } from './components/status-message/status-message.component';

@NgModule({
  declarations: [
    FlowStatusMessageComponent
  ],
  imports: [
    CommonModule,
    FlowPipesModule
  ],
  exports: [
    FlowStatusMessageComponent
  ],
  providers: [
    FlowStatusMessageService
  ]
})
export class FlowStatusMessageModule {
}
