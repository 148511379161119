/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FlowDialogsService } from '@flow/shared';

import { InterpolationOptionsInterface } from './interpolationOptions.interface';
import { FlowInterpolateHtmlService } from './interpolate-html.service';

@Directive({
  selector: '[flowInterpolateHtml]'
})
export class FlowInterpolateHtmlDirective implements OnInit, AfterViewInit {

  @Input() flowInterpolateHtml: string;
  @Input() interpolationOptions: InterpolationOptionsInterface;

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2,
    private domSanitizer: DomSanitizer,
    private DialogsService: FlowDialogsService,
    private InterpolateHtmlService: FlowInterpolateHtmlService,
  ) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.innerHTML = this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.domSanitizer.bypassSecurityTrustHtml(
        this.InterpolateHtmlService.parseLabel(this.flowInterpolateHtml, this.interpolationOptions)
      )
    );
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.querySelectorAll('.label-interpolate')
      .forEach(
        node => this.renderer2.listen(node, 'click', $event => this._open($event))
      );
  }

  private _open($event) {
    $event.preventDefault();

    const { title, content } = ($event.target as HTMLElement).dataset;

    if (content.includes(this.InterpolateHtmlService.URL_PARAM)) {
      const [ , urlKey ] = content.split(this.InterpolateHtmlService.URL_PARAM);
      const interpolationOptionsLinks = this.interpolationOptions && this.interpolationOptions.links;

      if (interpolationOptionsLinks) {
        const target = interpolationOptionsLinks[urlKey];

        if (target) {
          const { url, view } = target;

          if (url) {
            if (view === 'modal') {
              this.DialogsService.iframeModal({ url });
            }
            else {
              window.open(url,'_blank');
            }
          }
          else {
            console.error(`No interpolationOptionsLinks URL for: ${ urlKey }`);
          }
        }
        else {
          console.error(`interpolationOptionsLinks missing: ${ urlKey }`);
        }
      }
      else {
        console.error(`No interpolationOptionsLinks for: ${ urlKey }`);
      }
    }
    else {
      this._openModal(title, content, $event);
    }
  }

  private _openModal(title, content, $event: MouseEvent): void {
    this.DialogsService.textModal({
      showOkButton: false,
      translateTitle: false,
      title,
      content,
    });
  }
}
