/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';
import { FlowEnvService, FlowHelpers } from '@flow/core';

import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { Flow2faGoogleAuthService } from '../../services/2fa-google-auth/2fa-google-auth.service';
import { Flow2faService } from '../../../../../services/2fa/2fa.service';

@Component({
  selector: 'flow-modal-google-auth-sign',
  templateUrl: './modal-google-auth-sign.component.html',
  styleUrls: ['./modal-google-auth-sign.component.scss']
})
export class FlowModalGoogleAuthSignComponent implements OnInit, OnDestroy {

  @ViewChild('pincodeRef', {static: false}) pincodeRef: ElementRef;

  form: UntypedFormGroup;

  labels: FlowTranslateLabel;

  loading = true;

  loggingIn = false;

  regex = {
    numeric: /[0-9]/,
  };

  tfaHelpLink = this.tfaService.tfaHelpLink;

  // The qr code to scan.
  qrCode: string;

  private _destroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef <FlowModalGoogleAuthSignComponent>,
    private formBuilder: UntypedFormBuilder,
    private Google2FaService: Flow2faGoogleAuthService,
    private TranslateService: FlowTranslateService,
    private EnvService: FlowEnvService,
    private DialogsService: FlowDialogsService,
    private tfaService: Flow2faService,
  ) {
    this.form = this.formBuilder.group({
      requestid: new UntypedFormControl(this.data.requestid, [Validators.required]),
      pincode: new UntypedFormControl('', [Validators.required]),
      fid: new UntypedFormControl(this.EnvService.fingerprint, [Validators.required]),
    });
  }

  ngOnInit() {
    this.TranslateService.fetchLabels([
      'general.label.two_factor_authentication',
      'account_settings.label.pin_code',
      'general.label.validating_pin',
      'account_settings.label.validate_pin',
      'general.btn.close',
      'general.label.help',
    ]).subscribe(labels => {
      this.loading = false;
      this.labels = labels;

      // NOTE: invoking focus in ngAfterViewInit will fail as UI is still waiting for this.loading before the input ref is available.
      setTimeout(this._focusPincodeField.bind(this)); // timeout needed to ensure it runs after UI has been rendered.
    });

    this.form.get('pincode').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      takeUntil(this._destroy$) /* must be last */
    ).subscribe(this._onPincodeValueChange.bind(this));
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  doLogin(): void {
    this.loggingIn = true;
    this.Google2FaService.sign(this.form.value).subscribe(result => {
      if (FlowHelpers.isObject(result)) {
        this.matDialogRef.close(result);
      }
      else {
        this.loggingIn = false;
        this.DialogsService.textModal({
          showOkButton: false,
          content: result
        });
      }
    });
  }

  allowOnly(event: KeyboardEvent, regex: RegExp) {
    const key = String(event.key);

    return regex.test(key);
  }

  pincodeError(): string {
    return this.TranslateService.instant({
      key: 'error.maxLength',
      params: {
        number: 6
      }
    });
  }

  private _focusPincodeField() {
    if (this.pincodeRef) {
      this.pincodeRef.nativeElement.focus();
    }
  }

  private _onPincodeValueChange(value) {
    if (value.length === 6) {
      // auto-submit
      this.doLogin();
    }
  }
}
