/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { EventEmitter, Injectable } from '@angular/core';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import {
  FlowHelpers,
  FlowEnvService,
  FlowLocaleService,
  FlowModelsService,
  FlowStorageService,
  FlowUtilsService,
  FlowTextBlockModelInterface
} from '@flow/core';

import {
  FlowOnLangChangeInterface,
  FlowTranslateCollectionInterface,
  FlowTranslateLabelsStoreInterface,
  FlowTranslateLabel
} from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FlowTranslateService {

  /** Fallback language code. */
  private _defaultLanguage = 'en-US';

  /** Window storage key. **/
  private _storageKey = 'flow.lang';

  /** Current language being used in the application. */
  private _currentLanguage: string;

  /** Internal storage for the loaded labels. */
  private _labelsStore: FlowTranslateLabelsStoreInterface = {};

  /** Event emitter when the application language changes. */
  private _onLangChange: EventEmitter <FlowOnLangChangeInterface> = new EventEmitter();

  /** Label paths that have been loaded */
  private _loadedPaths: string[] = [];

  /** Allow to pass label aliases to improve readability. */
  private _labelsAlias: any = {};

  private _experimentalPrefix = '';

  constructor(
    private EnvService: FlowEnvService,
    private LocaleService: FlowLocaleService,
    private ModelsService: FlowModelsService,
    private StorageService: FlowStorageService,
    private UtilsService: FlowUtilsService,
  ) { }

  /**
   * Getter to listen the event when the application language is changed.
   */
  get onLangChange(): EventEmitter<FlowOnLangChangeInterface> {
    return this._onLangChange;
  }

  /**
   * Sets the required internal parameters to determine which language is being used in the application.
   *
   * @param code Language code
   * @param useUserLang True prevents overwriting lang by query param as user language needs to be used
   * (prevents from weird testing scenarios).
   * @returns
   */
  localizeApp(code: string, useUserLang?: boolean): void {
    // console.log('FlowTranslateService ::: localizeApp ::: flow-ng17-translate');

    const urlParams = new URLSearchParams(window.location.search);

    // If the application is reached by a link in an email, the language of the email and the application
    // needs to be the same. Solved by a language query param appended to the email link.
    // Check for "lang" query param to set language in local storage only
    // - if the user language is not to be used
    //   (after login user lang should be used of course, even if lang query param is set)
    // - for allowed language codes
    // - for certain url paths
    if (urlParams.has('lang') && !useUserLang) {
      const lang = urlParams.get('lang');

      // Check for allowed lang
      if (lang === this.UtilsService.getLanguageByCode(lang)) {
        const pathsWithLangParam = [
          '/auth/change-password',
          '/auth/forgot-password',
          '/auth/login'
        ];

        // Check for valid paths
        pathsWithLangParam.forEach(path => {
          if (location.pathname.startsWith(path)) {
            code = lang;
            this.EnvService.logWarn('Language set by query param');
            this.EnvService.logDebug(lang);
          }
        });
      }
    }
    // If onboarding application is embedded in the shops a "parent" query param is appended to the onboarding URL.
    // Check for "lang" property in this query param to set language in local storage of onboarding application.
    else if (urlParams.has('parent')) {
      const parent = urlParams.get('parent'); // parent is a JSON string

      try {
        const parentObj = JSON.parse(parent);
        const { lang } = parentObj;

        // Check for allowed lang
        if (lang && lang === this.UtilsService.getLanguageByCode(lang)) {
          code = lang;
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    else if (!code || !this.UtilsService.getLanguageByCode(code)) {
      code = this._defaultLanguage;
    }

    if (code === this._currentLanguage) {
      return;
    }

    this._currentLanguage = code;
    this._loadedPaths = [];
    this.StorageService.set(this._storageKey, code);

    // Register locale based on language code
    this.LocaleService.registerLocale(code);
  }

  /**
   * Getter for _defaultLanguage.
   */
  getDefaultLanguage(): string {
    return this._defaultLanguage;
  }

  /**
   * Getter for _currentLanguage$ to return the sync value.
   */
  getCurrentLanguage(): string {
    return this._currentLanguage;
  }

  /** Getter for the Initial language in the Application */
  getInitialLanguage(): string {
    const language = this.StorageService.get(this._storageKey);
    let checkedLanguage = this.UtilsService.getLanguageByCode(language);

    // First, check if the language has been correctly set.
    if (checkedLanguage) {
      return checkedLanguage;
    }
    // Otherwise, check the language in the browser.
    else {
      checkedLanguage = this.UtilsService.getLanguageByCode(navigator.language);

      if (checkedLanguage) {
        return checkedLanguage;
      }
    }

    // Fallback to default language.
    return this.getDefaultLanguage();
  }

  /**
   * Fetch individual labels
   * If one or more labels exists in the store, they are not fetched.
   */
  fetchLabels(labels: string[], args?: any): Observable <FlowTranslateLabel> {
    this._checkIfCurrentLanguageIsDefinedInStore();

    const labelsAfter: FlowTranslateLabel = {};
    const toLoad = [];
    const defaults = {
      platform: 'Flow',
      skipAuth: false,
      bypassCache: false
    };
    const fetchRequests = [];

    args = Object.assign(defaults, args);

    labels.forEach(label => {
      const parts = label.split('.');

      if (!this._labelsStore[this._currentLanguage][parts[0]]) {
        this._labelsStore[this._currentLanguage][parts[0]] = {};
      }

      // Prepare labelsStore with fallback en-US
      if (
        this._currentLanguage !== this.getDefaultLanguage()
        &&
        !this._labelsStore[this.getDefaultLanguage()][parts[0]]) {
        this._labelsStore[this.getDefaultLanguage()][parts[0]] = {};
      }

      if (
        this._labelsStore[this._currentLanguage][parts[0]][label]
        &&
        this._labelsStore[this._currentLanguage][parts[0]][label] !== label) {
        labelsAfter[label] = this._labelsStore[this._currentLanguage][parts[0]][label];
      }
      else {
        toLoad.push(label);
      }
    });

    if (!toLoad.length) {
      return of(labelsAfter);
    }

    const regex = toLoad.map(item => `"${this._experimentalPrefix}${item}"`);
    const requestParams = {
      platform: args.platform,
      query: `{"translations.${this._currentLanguage}": { "$exists": true }, "$or": [{ "id": { "$in": [${regex.join(',')}] } }] }`,
      filter: `translations.${this._currentLanguage},id,page,type`,
      skipAuth: args.skipAuth
    };

    // Bypass caching
    if (args.bypassCache) {
      requestParams['t'] = FlowHelpers.timestamp();
    }

    // Fetch labels for current language
    fetchRequests.push(this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', requestParams));

    // ST020766: Fetch labels also for default language as default.
    // According to David two parallel calls to the textblocks endpoints is fastest (because of the cache).
    if (this._currentLanguage !== this.getDefaultLanguage()) {
      const requestParamsDefault = {
        platform: args.platform,
        query: `{"translations.${this.getDefaultLanguage()}": { "$exists": true }, "$or": [{ "id": { "$in": [${regex.join(',')}] } }] }`,
        filter: `translations.${this.getDefaultLanguage()},id,page,type`,
        skipAuth: args.skipAuth
      };

      // Bypass caching
      if (args.bypassCache) {
        requestParamsDefault['t'] = FlowHelpers.timestamp();
      }

      fetchRequests.push(this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', requestParamsDefault));
    }

    return forkJoin(...fetchRequests)
      .pipe(
        map(result => {
          if (this._currentLanguage === this.getDefaultLanguage()) {
            if (result[0]['data'].length) {
              result[0]['data'].forEach(label => {
                if (label.translations[this._currentLanguage] !== '') {
                  this._labelsStore[this._currentLanguage][label.page][label.id] = label.translations[this._currentLanguage];
                  labelsAfter[label.id] = label.translations[this._currentLanguage];
                }
              });
            }
          }
          else {
            if (result[0]['data'].length) {
              result[0]['data'].forEach(label => {
                if (label.translations[this._currentLanguage] !== '') {
                  this._labelsStore[this._currentLanguage][label.page][label.id] = label.translations[this._currentLanguage];
                  labelsAfter[label.id] = label.translations[this._currentLanguage];
                }
              });
            }

            if (result[1]['data'].length) {
              result[1]['data'].forEach(label => {
                if (label.translations[this.getDefaultLanguage()] !== '') {
                  this._labelsStore[this.getDefaultLanguage()][label.page][label.id] = label.translations[this.getDefaultLanguage()];

                  if (!labelsAfter[label.id]) {
                    labelsAfter[label.id] = label.translations[this.getDefaultLanguage()];
                  }
                }
              });
            }
          }

          return labelsAfter;

        }),
        catchError(() => of(null))
      );


    /*
    return this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', {
      platform: args.platform,
      query: `{"translations.${this._currentLanguage}": { "$exists": true }, "$or": [{ "id": { "$in": [${regex.join(',')}] } }] }`,
      filter: `translations.${this._currentLanguage},id,page,type`,
      skipAuth: args.skipAuth
    }).pipe(
      map(result => {
        if (result.data.length) {
          result.data.forEach(label => {

            // label.page = label.page === this._experimentalPrefix ? label.id.split('.')[1] : label.page;
            // label.id = label.id.startsWith(`${this._experimentalPrefix}.`) ? label.id.replace(`${this._experimentalPrefix}.`, '') : label.id;

            if (label.translations[this._currentLanguage]) {
              this._labelsStore[this._currentLanguage][label.page][label.id] = label.translations[this._currentLanguage];
              labelsAfter[label.id] = label.translations[this._currentLanguage];
            } else {
              // I guess this won't work as translations of default language aren't fetched in query
              if (label.translations[this.getDefaultLanguage()]) {
                this._labelsStore[this._currentLanguage][label.page][label.id] = label.translations[this.getDefaultLanguage()];
                labelsAfter[label.id] = label.translations[this.getDefaultLanguage()];
              }
            }
          });
        }

        return labelsAfter;
      }),
      catchError( () => of({}))
    );
    */
  }

  /**
   * This method is used in the resolve for every container route.
   * In Angular applications, a container component encapsulates all the children components
   * that may be used in a view/path.
   */
  fetchRouteLabels(
    language: string = this.getDefaultLanguage(),
    paths: string[],
    aliases: FlowTranslateLabel = {},
    skipAuth = false
  ): Observable <boolean> {

    if (! paths.length) {
      return of(true);
    }

    this._checkIfCurrentLanguageIsDefinedInStore();

    const pathsToLoad = paths.filter(path => this._loadedPaths.indexOf(path) === -1);
    const regex = pathsToLoad.map(path => `{ "id": { "$regex": "^${this._experimentalPrefix}${path}." } }`);

    if (!pathsToLoad.length) {
      return of(true);
    }

    return this.ModelsService.list<FlowTextBlockModelInterface>('TextBlock', {
      platform: 'Flow',
      query: `{"translations.${language}": { "$exists": true }, "$or": [${regex}] }`,
      filter: `translations.${language},id,page,type`,
      skipAuth
    }).pipe(
      tap(data => {
        if (data.data.length) {
          data.data.forEach(label => {
            // label.page = label.page === this._experimentalPrefix ? label.id.split('.')[1] : label.page;
            // label.id = label.id.startsWith(`${this._experimentalPrefix}.`) ? label.id.replace(`${this._experimentalPrefix}.`, '') : label.id;

            if (!this._labelsStore[language][label.page]) {
              this._labelsStore[language][label.page] = {};
            }

            if (aliases[label.id]) {
              if (!this._labelsAlias[label.id]) {
                this._labelsAlias[label.id] = aliases[label.id];
              }

              this._labelsStore[language][label.page][aliases[label.id]] = label.translations[language] || label.id;
            }
            else {
              // Note from BL:
              // BE returns also empty translations for a language.
              // In this case the label.id will be stored.
              this._labelsStore[language][label.page][label.id] = label.translations[language] || label.id;
            }
          });
        }
      }),
      map(() => {
        this._loadedPaths = [ ...this._loadedPaths, ...pathsToLoad ];
        return true;
      }),
      catchError(() => of(false))
    );
  }

  /**
   * Sync method to translate labels.
   * This should be the default behaviour, it avoids to unsubscribe from the observable in given context(s).
   */
  translateSync(collection: (FlowTranslateCollectionInterface|string)[]): FlowTranslateLabel {
    if (
      !this._labelsStore[this._currentLanguage]
      ||
      (
        !this._labelsStore[this._currentLanguage]
        &&
        !this._labelsStore[this._defaultLanguage]
      )) {
      return {};
    }

    return collection.map((item: any) => {
      const model = {
        key: FlowHelpers.isObject(item) ? item.key : item,
        params: FlowHelpers.isObject(item) ? item.params : {},
        value: null,
        path: null,
        alias: null
      };

      model.alias = this._labelsAlias[ model.key] || null;

      const hasParts = model.key.split('.');

      if (hasParts && hasParts.length > 1) {
        model.path = hasParts.shift();

        model.value = this.isDomainPathLoaded(this._currentLanguage, model.path)
          ? this._labelsStore[this._currentLanguage][`${model.path}`][this._labelsAlias[model.key] || model.key]
          : null;

        // In case a translation for the current language was not found or the label id was stored as translation
        // look up for the translation for the default language.
        if (!model.value || model.value === model.key) {
          model.value = this.isDomainPathLoaded(this._defaultLanguage, model.path)
            ? this._labelsStore[this._defaultLanguage][`${model.path}`][this._labelsAlias[model.key] || model.key]
            : null;
        }
      }
      else {
        // Assume is plain text.
        model.value = model.key;
      }

      return model;

    }).reduce((accumulator: any, currentValue: { key: string; value: string; params: object; alias: string }) => {
      accumulator[currentValue.alias || currentValue.key] = currentValue.value
        ? this._interpolate(currentValue.value , currentValue.params) || currentValue.key
        : currentValue.alias || currentValue.key;

      return accumulator;
    }, {});
  }

  /**
   * Check if a label path exists in the store.
   */
  isDomainPathLoaded(language: string, path: string): boolean {
    return !!this._labelsStore[language][path];
  }

  /**
   * Return the translations as an Observable.
   * The downside to this is to manage the subscription, since is static data and does not need to update the values.
   *
   * This could be useful to use in route resolvers to preload some labels for certain page(s).
   */
  translate(collection: (FlowTranslateCollectionInterface|string)[]): Observable <FlowTranslateLabel> {
    return of(this.translateSync(collection));
  }

  translateModel(platform = 'flow', model: string, entity: string, property = 'name'): string {
    const kinetixModel = this.ModelsService.getLowerCaseModel(model);
    if (! kinetixModel) {
      return null;
    }

    return FlowHelpers.hasProperty(this._labelsStore[this._currentLanguage], platform)
      ? this._labelsStore[this._currentLanguage][platform][`${platform}.${kinetixModel}.${entity}.${property}`]
      : null;
  }

  /**
   * Shortcut to translate and return a single label from the labels Map.
   * The label must be a FlowTranslateCollectionInterface object.
   */
  instant(label: FlowTranslateCollectionInterface): string {
    return this.translateSync([label])[label.key] || label.key;
  }

  /**
   * Getting a single textblock
   */
  getTextBlockById(entityId: string, args?: any): Observable<FlowTextBlockModelInterface> {
    const defaultArgs = {
      platform: 'Flow',
      skipAuth: false,
      bypassCache: false,
      unescape: true
    };

    args = Object.assign(defaultArgs, args);

    const params = {
      skipAuth: args.skipAuth,
      unescape: args.unescape
    };

    // Bypass caching if necessary
    if (args.bypassCache) {
      params['t'] = FlowHelpers.timestamp();
    }

    return this.ModelsService.getOne<FlowTextBlockModelInterface>('TextBlock', entityId, params, {
      platform: args.platform
    })
      .pipe(
        catchError(() => of(null))
      );
  }

  updateTextBlock(textblock: FlowTextBlockModelInterface, platform: string): Observable <boolean> {
    return this.ModelsService.update<FlowTextBlockModelInterface>('TextBlock', textblock.id, textblock, { platform })
      .pipe(
        map(() => true),
        catchError(FlowHelpers.catchAsFalse)
    );
  }

  getTranslationFromTextBlock(text: FlowTextBlockModelInterface, languageCode?: string): string {
    if (! languageCode) {
      languageCode = this._currentLanguage;
    }

    if (!FlowHelpers.isEmptyObject(text.translations)) {
      // If the Translation object contains the selected/current language code ...
      if (FlowHelpers.hasProperty(text.translations, languageCode)) {
        return text.translations[languageCode];
      }
      else {
        // Or if it contains the default language ...
        if (FlowHelpers.hasProperty(text.translations, this._defaultLanguage)) {
          return text.translations[this._defaultLanguage];
        }
        else {
          // If not, return the first Object key.
          return text.translations[Object.keys(text.translations)[0]];
        }
      }
    }

    return null;
  }

  /**
   * Removing textblocks of given textblock ids
   */
  removeTextBlocks(entityIds: string[], args?: any): Observable<any> {
    const deleteRequests = [];
    const defaultArgs = {
      platform: 'Flow',
      skipAuth: false
    };

    args = Object.assign(defaultArgs, args);

    entityIds.forEach(entityId => {
      deleteRequests.push(
        this.ModelsService.delete<FlowTextBlockModelInterface>('TextBlock', entityId, {
          platform: args.platform,
          skipAuth: args.skipAuth
        })
      );
    });

    return forkJoin(deleteRequests)
      .pipe(
        catchError(() => of(null))
      );
  }

  /**
   * Internal method to interpolate strings and parameters.
   */
  private _interpolate(text: string, params?: any): string {
    return text.replace(/{{\s?([^{}\s]*)\s?}}/g, (match: string) => {
      match = match.replace(/[{{}}]/g, '');
      return params[match];
    });
  }

  /**
   * Checks if a given language exists in the internal store.
   */
  private _checkIfCurrentLanguageIsDefinedInStore() {
    if (!this._labelsStore[this._currentLanguage]) {
      this._labelsStore[this._currentLanguage] = {};
    }

    if (this._currentLanguage !== this._defaultLanguage && !this._labelsStore[this._defaultLanguage]) {
      this._labelsStore[this._defaultLanguage] = {};
    }
  }

}
