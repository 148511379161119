/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { FlowHelpers, FlowEnvService, FlowRouterService, FlowStorageService, FlowRegexService } from '@flow/core';
import { FlowAuthService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { FlowPortalService } from '../../../../../services/portal/portal.service';
import { FlowLayoutService } from '../../../../../services/layout/layout.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { FlowModalMessageAcceptComponent } from '../../modals/modal-message-accept/modal-message-accept.component';
import { FlowModalGoogleAuthSignComponent } from '../../../../2fa/2fa-google-auth/modals/modal-google-auth-sign/modal-google-auth-sign.component';
import { FlowModalYubicoSignComponent } from '../../../../2fa/2fa-yubico/modals/modal-yubico-sign/modal-yubico-sign.component';
import { FlowModalSmsAuthSignComponent } from '../../../../2fa/2fa-sms-auth/modals/modal-sms-auth-sign/modal-sms-auth-sign.component';

const SERVER_ERROR_STORAGE_KEY = 'flow.server-error';

@Component({
  selector: 'flow-login',
  templateUrl: './login.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowLoginComponent implements OnInit {

  form: UntypedFormGroup;

  loading$: Observable <boolean>;

  labels: FlowTranslateLabel;

  portalDescription: string;

  private _platform: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private EnvService: FlowEnvService,
    private router: Router,
    private RouterService: FlowRouterService,
    private StorageService: FlowStorageService,
    private RegexService: FlowRegexService,
    private AuthService: FlowAuthService,
    private TranslateService: FlowTranslateService,
    private LayoutService: FlowLayoutService,
    private DialogsService: FlowDialogsService,
    private PortalService: FlowPortalService
  ) {
    this._setControls();
  }

  ngOnInit(): void {
    this._platform = this.EnvService.portalDomain;

    this.loading$ = this.LayoutService.loading$;

    this.labels = this.TranslateService.translateSync([
      'generic.error.required.email',
      'generic.error.invalid.email',
      'generic.error.required.password',
      'login.login_form.label.email',
      'login.login_form.label.password',
      'general.tooltip.login'
    ]);

    this.portalDescription = this.PortalService.portalDescription;

    this._chkForServerErrorMessage();
  }

  onSubmit(): void {
    this.form.markAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.LayoutService.setLoading(true);
    this.LayoutService.setLastError(null);

    this.AuthService.login(this.form.value)
    .subscribe(loginResult => {
      const hasMessageToAccept = this.PortalService.currentPortalDashboard.acceptMessageAfterLogin;

      // Successful login without two factor authentication
      if (true === loginResult) {
        if (hasMessageToAccept) {
          this._showMessageToAccept();
        }
        else {
          this._redirectAfterLogin();
        }
      }
      // Two factor authentication
      else if (FlowHelpers.isObject(loginResult) && FlowHelpers.hasProperty(loginResult, 'twoFactor')) {
        const twoFactor = (loginResult as any).data;

        switch (twoFactor.use) {
          // TODO: all cases seem to use same logic with the exception of method. The rest can be combined into a single shared method
          case 'googleAuthenticator':
            this._signWithGoogleAuth(twoFactor).afterClosed().subscribe(result => {
              if (result) {
                this.AuthService.afterLogin(result).subscribe(() => {
                  if (hasMessageToAccept) {
                    this._showMessageToAccept();
                  }
                  else {
                    this._redirectAfterLogin();
                  }
                });
              }
              else {
                this.LayoutService.setLoading(false);
                this.LayoutService.setLastError('login.error.two_factor_auth_cancelled');
              }
            });
          break;

          case 'smsAuthenticator':
            this._signWithSmsAuth(twoFactor).afterClosed().subscribe(result => {
              if (result) {
                this.AuthService.afterLogin(result).subscribe(() => {
                  if (hasMessageToAccept) {
                    this._showMessageToAccept();
                  }
                  else {
                    this._redirectAfterLogin();
                  }
                });
              }
              else {
                this.LayoutService.setLoading(false);
                this.LayoutService.setLastError('login.error.two_factor_auth_cancelled');
              }
            });
          break;

          /*  // TODO[Yubico]:
          case 'yubico':
            this._signWithYubicoAuth(twoFactor).afterClosed().subscribe(result => {
              if (result) {
                this.AuthService.afterLogin(result).subscribe(() => {
                  const afterLoginUrl = this.PortalService.getCustomRedirectUrl();
                  this.RouterService.navigate(afterLoginUrl);
                });
              } else {
                this.LayoutService.setLoading(false);
                this.LayoutService.setLastError('login.error.two_factor_auth_cancelled');
              }
            });
          break;*/
        }
      }

      // Error on login
      else {
        this.LayoutService.setLoading(false);
        this.LayoutService.setLastError(FlowHelpers.flatHttpErrorResponse(loginResult as HttpErrorResponse, 'login'));
      }
    });
  }

  _setControls(): void {
    this.form = this.formBuilder.group({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern(this.RegexService.getRegex('email'))]),
      password: new UntypedFormControl('', [Validators.required]),
      rememberMe: new UntypedFormControl(false, [Validators.required]),
      skipAuth: new UntypedFormControl(true, [Validators.required])
    });
  }

  _signWithGoogleAuth(data: {requestid: string; use: string }): MatDialogRef <FlowModalGoogleAuthSignComponent> {
    return this.DialogsService.open(FlowModalGoogleAuthSignComponent, {
      maxWidth: '400px',
      minWidth: '330px',
      closeOnNavigation: true,
      disableClose: true,
      data
    });
  }

  _signWithSmsAuth(data: {requestid: string; use: string }): MatDialogRef <FlowModalSmsAuthSignComponent> {
    return this.DialogsService.open(FlowModalSmsAuthSignComponent, {
      maxWidth: '400px',
      minWidth: '330px',
      closeOnNavigation: true,
      disableClose: true,
      data
    });
  }

  // TODO[Yubico]:
  _signWithYubicoAuth(data: {requestid: string; use: string }): MatDialogRef <FlowModalYubicoSignComponent> {
    return this.DialogsService.open(FlowModalYubicoSignComponent, {
      maxWidth: '330px',
      closeOnNavigation: true,
      disableClose: true,
      data
    });
  }

  /**
   * Redirect to custom redirect url after login
   */
  private _redirectAfterLogin(): void {
    const afterLoginUrl = this.PortalService.getCustomRedirectUrl();
    const [ url ] = afterLoginUrl;

    if (url.includes('?')) {
      /*
        In case of a '?' we must call .navigateByUrl, because otherwise ng .navigate will strip out the last part of the path including any
        query params. E.g. /some/example/path?param1=value1&param2=value2 will become /some/example/ instead of the original url.
      */
      this.router.navigateByUrl(url);
    }
    else {
      this.RouterService.navigate(afterLoginUrl);
    }
  }

  /**
   * Check if there's a "server-error" msg that we need to show
   */
  private _chkForServerErrorMessage() {
    const serverError = this.StorageService.get(SERVER_ERROR_STORAGE_KEY);

    if (serverError) {
      // show the server-error
      setTimeout(() => this.LayoutService.setLastError(serverError)); // timeout to trigger change detection

      // remove to prevent re-show
      this.StorageService.remove(SERVER_ERROR_STORAGE_KEY);
    }
    else {
      // clear error otherwise will re-show on refresh
      this.LayoutService.setLastError(null);
    }
  }

  /**
   * Show a modal containing a customer message each user has to accept
   */
  private _showMessageToAccept(): void {
    const messageTranslationId = (`${this._platform}.portalsettings.accept_message_after_login.message`).toLowerCase();
    const currentLanguage = this.TranslateService.getCurrentLanguage();
    const defaultLanguage = this.TranslateService.getDefaultLanguage();

    this.TranslateService.getTextBlockById(messageTranslationId, { platform: this._platform })
    .subscribe(textBlockResult => {
      // Only show message if translation in current or default language is available
      if (
        textBlockResult
        &&
        textBlockResult.translations
        &&
        (
          textBlockResult.translations[currentLanguage]
          ||
          textBlockResult.translations[defaultLanguage]
        )
      ) {
        const content = textBlockResult.translations[currentLanguage]
                        ?
                        textBlockResult.translations[currentLanguage]
                        :
                        textBlockResult.translations[defaultLanguage];

        this.DialogsService.open(FlowModalMessageAcceptComponent, {
          data: { content },
          maxWidth: '600px',
          minWidth: '280px',
          closeOnNavigation: true,
          disableClose: true
        })
        .afterClosed()
        .subscribe(accepted => {
          // Customer message accepted by user
          if (accepted) {
            this._redirectAfterLogin();
          }
          // Show error (this case should not happen, but just in case)
          else {
            this.LayoutService.setLoading(false);
            this.LayoutService.setLastError('login.error.customer_message_not_accepted');
          }
        });
      }
      else {
        this._redirectAfterLogin();
      }
    });
  }
}
