/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { FlowTurnstileComponent } from './turnstile.component';

@NgModule({
  imports: [],
  declarations: [
    FlowTurnstileComponent
  ],
  exports: [
    FlowTurnstileComponent
  ],
  providers: []
})
export class FlowTurnstileModule {
}
