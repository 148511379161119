/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import {
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';

import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';

import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FlowUserService } from '@flow/auth';
import {
  FlowLayoutService,
  FlowDialogsService,
  FlowWebsocketService,
  FlowWebsocketNotificationMessageInterface
} from '@flow/shared';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';
import { ToggleHelpPanel, FlowMarketplaceStateInterface, SetIfChatIsOpen } from '@marketplaceStore';

import { FlowUserlaneService } from '../../../marketplace-shared/services/userlane/userlane.service';
import { FlowProfileComponent } from '../../../profile/containers/profile/profile.component';

export interface FlowTopMenuItemInterface {
  [key: string]: {
    name: string;
    link: string[];
    icon?: string;
    class?: string;
    trigger?: MatMenu;
    disabled?: boolean;
  }[];
}

@Component({
  selector: 'flow-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowMenuTopComponent implements OnInit, OnDestroy {

  @HostBinding('class') classNames = 'px-3 d-flex flex-row align-items-center justify-content-between position-relative';

  @ViewChild('notificationsTrigger') trigger: MatMenuTrigger;

  @ViewChild('customersMenu', {static: true}) customersMenu: MatMenu;

  @ViewChild('contentMenu', {static: true}) contentMenu: MatMenu;

  @ViewChild('sitesMenu', {static: true}) sitesMenu: MatMenu;

  @ViewChild('cmsMenu', {static: true}) cmsMenu: MatMenu;

  @ViewChild('settingsMenu', {static: true}) settingsMenu: MatMenu;

  @ViewChild('productsMenu', {static: true}) productsMenu: MatMenu;

  @Input() helpPanelVisible: string;

  notifications$: Observable<FlowWebsocketNotificationMessageInterface>;

  showNotificationBadge: boolean;

  showAccessLimitedWarning = false;

  subscription$: Subscription = new Subscription();

  labels: FlowTranslateLabel;

  isMobile: boolean;

  isPortalAdminOrSuperAdmin: boolean;
  isChatBought: boolean;

  tooltipAccount: string;
  profileHeader: { fullName: string; email: string; company: string };

  menu: FlowTopMenuItemInterface = {};

  private _sidenavRef: any;

  private _destroy$$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<FlowMarketplaceStateInterface>,
    private UserService: FlowUserService,
    private LayoutService: FlowLayoutService,
    private DialogsService: FlowDialogsService,
    private WebsocketService: FlowWebsocketService,
    private TranslateService: FlowTranslateService,
    private UserlaneService: FlowUserlaneService
  ) { }

  ngOnInit(): void {
    this.labels = this._translateLabels();

    // Check device
    this.LayoutService.isMobile$.pipe(
      takeUntil(this._destroy$$)
    ).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.changeDetectorRef.markForCheck();
    });

    this.notifications$ = this.WebsocketService.notificationFeed$;

    this.subscription$.add(this.WebsocketService.waitForConnection$.subscribe(() => {
      // Load history.
      this.WebsocketService.send({
        topic: 'notification',
        subject: 'history'
      });
    }));

    this.isPortalAdminOrSuperAdmin = this.UserService.hasPermission(['portal:super-admin', 'portal:admin']);
    this.isChatBought = this.UserService.isBoughtProduct('chat');

    const {
      fullName,
      user: { email },
      customer: { Description, Name }
    } = this.UserService;

    this.tooltipAccount = `${ fullName.trim() }\n${ email }\n${ Description || Name }`;
    this.profileHeader = {
      fullName: fullName.trim(),
      email: email.trim(),
      company: Description || Name,
    };

    const projectAccess = this.UserService.user['projectAccess'];
    this.showAccessLimitedWarning = projectAccess === 'blocked';

    this.setMenu();
  }

  ngOnDestroy(): void {
    // Avoid maintaining the sidenav if something like a 401 happens.
    if (this._sidenavRef) {
      this._sidenavRef.close();
    }
    this.subscription$.unsubscribe();

    this._destroy$$.next();
    this._destroy$$.complete();
  }

  toggleHelp(): void {
    this.store.dispatch(new ToggleHelpPanel());
  }

  openProfile(): void {
    this._sidenavRef = this.DialogsService.openSidenavFor(FlowProfileComponent, {});
  }

  openUserlanes(): void {
    this.UserlaneService.openAssistant();
  }

  onHasNotifications(): void {
    this.showNotificationBadge = !this.trigger.menuOpen;
  }

  updateNotifications(): void {
    this.showNotificationBadge = false;
  }

  toggleChat(): void {
    this.store.dispatch(new SetIfChatIsOpen());
  }

  setMenu(): void {
    this.menu = {
      main: [],
      products: [],
      customers: [],
      content: [],
      cms: [],
      sites: [],
      settings: []
    };

    if (!this.LayoutService.isMobileDevice
        &&
        this.UserService.hasPermission(['portal:super-admin', 'portal:projectadmin', 'portal:admin', 'portal:writer'])
       ) {
      this.menu.main.push({
        name: this.labels['general.tooltip.admin'],
        link: ['/administration']
      });

      // My company.
      /* this.menu.main.push({
        name: this.labels['general.label.my_company'],
        link: ['/administration', 'company'],
        class: 'indent'
      }); */

      // Portal settings.
      /* if (!this.UserService.isActingAsPartner() && !this.PortalService.exclusiveMode) {
        this.menu.main.push({
          name: this.labels['general.label.portal'],
          link: ['/administration', 'portal'],
          class: 'indent'
        });
      } */

      // Integration.
      /* if (this.UserService.isBoughtProduct('edi') || this.UserService.isBoughtProduct('einvo')) {
        this.menu.main.push({
          name: this.labels['general.label.integration'],
          link: ['/administration', 'integration'],
          class: 'indent'
        });
      } */

      // DG
      /* if (this.UserService.isActingAsPartner()) {
        this.menu.main.push({
          name: this.labels['account_settings.tab.dg'],
          link: ['/administration', 'dg'],
          class: 'indent'
        });
      } */
    }

    // Administration menu.
    /* if (this.UserService.hasPermission(['portal:super-admin', 'portal:projectadmin'])) {
      // Products
      this.menu.main.push({
        name: this.labels['general.label.products'],
        link: null,
        trigger: this.productsMenu,
        class: 'indent',
        disabled: true
      });

      this.menu.products.push({
        name: this.labels['general.label.categories'],
        link: null,
      });

      this.menu.products.push({
        name: this.labels['general.label.products'],
        link: null,
      });

      this.menu.products.push({
        name: this.labels['general.label.product_rules'],
        link: null,
      });

      this.menu.products.push({
        name: this.labels['general.label.bulk_operations'],
        link: null,
      });

      // Customers
      this.menu.main.push({
        name: this.labels['general.menu.customers'],
        link: null,
        trigger: this.customersMenu,
        class: 'indent'
      });

      this.menu.customers.push({
        name: this.labels['general.label.users'],
        link: ['/administration', 'customers']
      });

      this.menu.customers.push({
        name: this.labels['general.label.orders'],
        link: ['/administration', 'orders'],
        disabled: true
      });

      // Content
      this.menu.main.push({
        name: this.labels['general.label.content'],
        link: null,
        trigger: this.contentMenu,
        class: 'indent',
        disabled: true
      });

      this.menu.content.push({
        name: this.labels['general.label.text'],
        link: ['/administration', 'texts']
      });

      this.menu.content.push({
        name: this.labels['general.label.widgets'],
        link: ['/administration', 'widgets']
      });

      // CMS.
      this.menu.content.push({
        name: this.labels['general.label.cms'],
        link: null,
        trigger: this.cmsMenu
      });

        this.menu.cms.push({
          name: this.labels['general.label.articles'],
          link: ['/administration', 'articles']
        });

        this.menu.cms.push({
          name: this.labels['general.label.categories'],
          link: ['/administration', 'articles', 'categories']
        });

      // Sites.
      this.menu.content.push({
        name: this.labels['general.label.sites'],
        link: null,
        trigger: this.sitesMenu
      });

        this.menu.sites.push({
          name: this.labels['general.label.sites'],
          link: ['/administration', 'sites']
        });

        this.menu.sites.push({
          name: this.labels['general.label.page_types'],
          link: ['/administration', 'page_types']
        });

      this.menu.content.push({
        name: this.labels['general.label.media_manager'],
        link: ['/administration', 'media_manager']
      });

      this.menu.content.push({
        name: this.labels['general.label.portal_themes'],
        link: ['/administration', 'portal_themes']
      });

      this.menu.main.push({
        name: this.labels['general.label.settings'],
        link: null,
        trigger: this.settingsMenu,
        class: 'indent',
        disabled: true
      });

      this.menu.settings.push({
        name: this.labels['general.label.languages'],
        link: ['/administration', 'languages']
      });

      this.menu.settings.push({
        name: this.labels['general.label.currencies'],
        link: ['/administration', 'currencies']
      });
    } else {
      if (this.UserService.hasPermission(['portal:admin', 'portal:projectadmin'])) {

        this.menu.main.push({
          name: this.labels['general.label.users'],
          link: ['/administration', 'customers', this.UserService.company],
          class: 'indent'
        });
      }

      if (this.UserService.hasPermission(['portal:admin', 'portal:projectadmin', 'portal:writer'])) {
        this.menu.main.push({
          name: this.labels['general.label.content'],
          link: null,
          trigger: this.contentMenu,
          class: 'indent',
          disabled: true
        });

        this.menu.content.push({
          name: this.labels['general.label.widgets'],
          link: ['/administration', 'widgets']
        });

        this.menu.content.push({
          name: this.labels['general.label.cms'],
          link: null,
          trigger: this.cmsMenu
        });

        this.menu.cms.push({
          name: this.labels['general.label.articles'],
          link: ['/administration', 'articles']
        });

        this.menu.cms.push({
          name: this.labels['general.label.categories'],
          link: ['/administration', 'articles', 'categories']
        });
      }
    } */

    // Logout
    this.menu.main.push({
      name: this.labels['general.label.logout'],
      class: 'logoutLink',
      icon: 'power_settings_new',
      link: ['/auth', 'logout'],
    });
  }

  /**
   * Translate the labels
   */
   private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.integration',
      'account_settings.tab.dg',
      'general.label.chat',
      'general.label.userlane',
      'general.label.notifications',
      'general.footer.link.account',
      'general.tooltip.admin',
      'general.label.my_company',
      'general.label.portal',
      'general.menu.customers',
      'general.label.content',
      'general.label.logout',
      'general.label.text',
      'general.label.widgets',
      'general.label.cms',
      'general.label.sites',
      'general.label.media_manager',
      'general.label.users',
      'general.label.orders',
      'general.label.invoices',
      'general.label.page_types',
      'general.label.portal_themes',
      'general.label.settings',
      'general.label.languages',
      'general.label.currencies',
      'general.label.articles',
      'general.label.categories',
      'general.label.products',
      'general.label.product_rules',
      'general.label.bulk_operations',
      'general.label.currencies',
      'general.label.help',
      'general.label.access_limited',
    ]);
  }
}
