/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';
import { FlowHelpers } from '@flow/core';

import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';
import { Flow2faYubicoService } from '../../services/2fa-yubico/2fa-yubico.service';
import { Flow2faService } from '../../../../../services/2fa/2fa.service';

@Component({
  selector: 'flow-modal-yubico-sign',
  templateUrl: './modal-yubico-sign.component.html',
  styleUrls: ['./modal-yubico-sign.component.scss']
})
export class FlowModalYubicoSignComponent implements OnInit {

  labels: FlowTranslateLabel;

  // modal display content message
  msgLabel: string;

  loading = true;

  loggingIn = false;

  tfaHelpLink = this.tfaService.tfaHelpLink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef <FlowModalYubicoSignComponent>,
    private Yubico2FaService: Flow2faYubicoService,
    private TranslateService: FlowTranslateService,
    private DialogsService: FlowDialogsService,
    private tfaService: Flow2faService,
  ) {
  }

  ngOnInit() {
    console.warn('ngOnInit',);
    console.log(this);
    this.TranslateService.fetchLabels([
      'general.label.two_factor_authentication',
      'account_settings.two_factor_auth.press_yubico_key',
      'general.btn.cancel',
      'error.two_factor_auth.device_ineligible',
      'error.two_factor_auth.timeout',
      'error.two_factor_auth.request_error',
      'general.label.help',
    ]).subscribe(labels => {
      this.loading = false;
      this.labels = labels;

      this.msgLabel = 'account_settings.two_factor_auth.press_yubico_key';

      this._authenticate();
    });
  }

  private _authenticate() {
    this.Yubico2FaService.sign(this.data).subscribe(result => {
      console.warn('Y result', result);

      if (FlowHelpers.isObject(result)) {
        this.matDialogRef.close(result);
      }
      else {
        this.loggingIn = false;
        this.DialogsService.textModal({
          showOkButton: false,
          content: result
        });
      }
    });
  }
}
