/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { FlowAuthService } from '../services/auth/auth.service';
import { FlowRouterService } from '@flow/core';

@Injectable({
  providedIn: 'root'
})
export class FlowLoginGuard  {
  constructor(
    private AuthService: FlowAuthService,
    private router: FlowRouterService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    /**
     * Since this guard applies to the "auth" path
     * We need to avoid triggering it when a logged in user goes to the logout page.
     *
     * The logout url should be public.
     */
    if (state.url.indexOf('logout') > -1) {
      return true;
    }

    if (true === this.AuthService.isLoggedIn) {
      this.router.navigate(this.AuthService.afterLoginUrl);
    }

    return !this.AuthService.isLoggedIn;
  }
}
