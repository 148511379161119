import { Injectable } from '@angular/core';

import { COUNTRY_ZIPCODES } from '../../constants/country-zipcodes';

@Injectable({
  providedIn: 'root'
})
export class FlowZipCodeValidationService {

  isValid(value: string, countryCode: string) {
    const regex = this.getRegex(countryCode);

    return (regex && regex.test(value)) || !regex;
  }

  prettifyValue(value: string, countryCode: string): string {
    value = value.toUpperCase();

    if (this.isValid(value, countryCode)) {
      // Insert a space on 4th position
      if (countryCode.toLowerCase() === 'nl') {
        if (value.charAt(4) !== ' ') {
          value = value.substring(0, 4) + ' ' + value.substring(4, value.length);
        }
      }
    }

    return value;
  }

  getRegex(countryCode: string): RegExp|undefined {
    const foundCountry = COUNTRY_ZIPCODES.find(country => country.abbrev === countryCode);

    if (foundCountry && foundCountry.postal) {
      return new RegExp(`^${foundCountry.postal}$`);
    }

    return undefined;
  }
}
