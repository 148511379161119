/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FlowEnvService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import { Flow2faSmsAuthService } from '../../services/2fa-sms-auth/2fa-sms-auth.service';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';

@Component({
  selector: 'flow-modal-sms-auth-register',
  templateUrl: './modal-sms-auth-register.component.html',
  styleUrls: ['./modal-sms-auth-register.component.scss']
})
export class FlowModalSmsAuthRegisterComponent implements OnInit {

  // The form.
  form: UntypedFormGroup;

  // Needed labels.
  labels: FlowTranslateLabel;

  // IF it's loadeing.
  loading: boolean;

  // IF it's saving.
  saving: boolean;

  // The qr code to scan.
  qrCode: string;

  // If the registration has succeeded.
  registered = false;

  // If SMS auth is selected on enforce 2Fa page
  isEnforce2FaPage = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private TranslateService: FlowTranslateService,
    private Sms2FaService: Flow2faSmsAuthService,
    private EnvService: FlowEnvService,
    private DialogsService: FlowDialogsService
  ) {
    this.form = this.formBuilder.group({
      pincode: new UntypedFormControl(null, [Validators.required, Validators.maxLength(6)]),
      requestid: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    const { isEnforce2FaPage } = this.data;

    if (isEnforce2FaPage) {
      this.isEnforce2FaPage = isEnforce2FaPage;
    }

    this.loading = true;

    this.TranslateService.fetchLabels([
      'error.maxLength',
      'error.two_factor_auth.invalid_phone_number',
      'error.two_factor_auth.invalid_phone_number.enforced',
      'general.btn.close',
      'general.btn.close_reload',
      'account_settings.label.validate_pin',
      'general.label.validating_pin',
      'account_settings.label.pin_code',
      'account_settings.two_factor_auth.verify_sms',
      'account_settings.two_factor_auth.enter_verification_sms_pin',
      'account_settings.two_factor_auth.sms_registered',
    ]).subscribe(results => {
      this.labels = results;
      setTimeout(this._init.bind(this), 5000); /* 5s delay to allow the profile-panel to finish saving */
    });
  }

  doRegister(): void {
    this.saving = true;
    this.Sms2FaService
      .registrationFinish(this.form.value)
      .subscribe(result => {
      this.saving = false;
      if (result) {
        this.DialogsService.textModal({
          showOkButton: false,
          content: result
        });
      }
      else {
        // Registration went ok.
        this.registered = true;
      }
    });
  }

  pincodeError(): string {
    return this.TranslateService.instant({
      key: 'error.maxLength',
      params: {
        number: 6
      }
    });
  }

  private _init() {
    this.Sms2FaService.registrationStart()
      .subscribe(result => {
        this.form.get('requestid').setValue(result);

        this.loading = false;
      });
  }
}
