/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range'
})
export class FlowRangePipe implements PipeTransform {

  transform(value: any, size = 0, start = 1, step = 1): any {
    const range: number[] = [];

    for (let length = 0; length < size; ++length) {
      range.push(start);
      start += step;
    }

    return range;
  }
}
