import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nameNotNumberValidator(): ValidatorFn {

  return (control: AbstractControl): {[key: string]: any} | null => {
    const { value } = control;

    if (!value) {
      return null;
    }

    const valid = isNaN(+value);

    return !valid
      ? {'nameNotNumber': {value}}
      : null;
  };
}
