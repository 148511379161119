import { NgModule } from '@angular/core';

import { FlowTranslateDirective } from './directives/translate/translate.directive';

@NgModule({
  declarations: [FlowTranslateDirective],
  imports: [],
  exports: [FlowTranslateDirective]
})
export class FlowTranslateModule {
}
