<mat-form-field [formGroup]="form">
  <mat-label *ngIf="inlineLabel" [innerHTML]="labels[inlineLabel]"></mat-label>
  <mat-select [formControlName]="controlName" [required]="required">
    <mat-option
      *ngFor="let language of languages | async"
      [value]="language.lcId">
      {{ language.description }}
    </mat-option>
  </mat-select>
</mat-form-field>
