/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlowComponentsModule } from '../../components/components.module';
import { FlowDirectivesModule } from '../../directives/directives.module';
import { FlowMaterialModule } from '../../material/material.module';
import { FlowModalsModule } from '../../modals/modals.module';
import { FlowGoogleAuthRegisterComponent } from './components/google-auth-register/google-auth-register.component';
import { FlowModalGoogleAuthRegisterComponent } from './modals/modal-google-auth-register/modal-google-auth-register.component';
import { FlowModalGoogleAuthSignComponent } from './modals/modal-google-auth-sign/modal-google-auth-sign.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlowMaterialModule,
        FlowModalsModule,
        FlowComponentsModule,
        FlowDirectivesModule
    ],
    declarations: [
        FlowGoogleAuthRegisterComponent,
        FlowModalGoogleAuthSignComponent,
        FlowModalGoogleAuthRegisterComponent
    ],
    exports: [
        FlowGoogleAuthRegisterComponent
    ]
})
export class Flow2FaGoogleAuthModule {
}
