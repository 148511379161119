/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable, Injector, TemplateRef } from '@angular/core';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { FlowModalTextComponent } from '../../modules/modals/components/modal-text/modal-text.component';
import { FlowModalQuillComponent } from '../../modules/modals/components/modal-quill/modal-quill.component';
import { FlowModalPrivacyComponent } from '../../modules/views/auth-views/modals/modal-privacy/modal-privacy.component';
import { FlowModalIframeComponent } from '../../modules/modals/components/modal-iframe/modal-iframe.component';

import { DialogsOverlayRef } from './dialogs.overlay-ref';

@Injectable({
  providedIn: 'root'
})
export class FlowDialogsService {

  constructor(
    private _Dialog: MatDialog,
    private overlay: Overlay,
    private injector: Injector
  ) { }

  open <T, D = any, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, config?: MatDialogConfig<D>): MatDialogRef<T, R> {
    return this._Dialog.open(componentOrTemplateRef, config);
  }

  openTextDialog
  <T, R = any>(componentOrTemplateRef: ComponentType<T> | TemplateRef<T>, params?: any): MatDialogRef <T, R> {
    return this._Dialog.open(componentOrTemplateRef, {
      data: params,
      disableClose: true,
      autoFocus: false,
      hasBackdrop: (params && params.hasBackdrop !== undefined) ? params.hasBackdrop : true
    });
  }

  iframeModal(params: any): MatDialogRef <FlowModalIframeComponent> {
    return this.open(FlowModalIframeComponent, {
      data: {
        params
      },
      maxWidth: '991px',
      minWidth: '767px',
      panelClass: 'no-inner-padding-modal'
    });
  }

  /**
   * Open a centered simple native popup window that shows the content of an url.
   * Used in cases where external urls are being blocked from showing in an iframe.
   */
  simpleUrlPopup(url: string, windowName: string, width: number, height: number) {
    const top = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const left = window.top.outerWidth / 2 + window.top.screenX - (width / 2);

    return window.open(
      url,
      windowName,
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,' +
      'width=' +
      width +
      ',height=' +
      height +
      ',top=' +
      top +
      ',left=' +
      left
    );
}

  textModal(params: any): MatDialogRef <FlowModalTextComponent> {
    return this.openTextDialog(FlowModalTextComponent, params);
  }

  textModalPrivacy(): MatDialogRef <FlowModalPrivacyComponent> {
    return this.openTextDialog(FlowModalPrivacyComponent);
  }

  createInjector(ref: DialogsOverlayRef, injector: Injector) {
    const tokens = new WeakMap([[DialogsOverlayRef, ref]]);
    return new PortalInjector(injector, tokens);
  }

  textblockTranslationModal(html: string): MatDialogRef <FlowModalQuillComponent> {
    return this.open(FlowModalQuillComponent, {
      data: {
        html
      },
      disableClose: true, // This option needs to be kept, otherwhise textblocks will be overwritten with "undefined" after closing this modal.
      autoFocus: false,
      panelClass: 'flow-quill-dialog',
      maxWidth: '90%',
      width: '90%',
      height: '90%',
      maxHeight: '90%'
    });
  }

  attachSidenav(config: any): OverlayRef {
    return this.overlay.create({
      ...config,
      disposeOnNavigation: true,
      hasBackdrop: true,
      width: '99%',
      height: '100%',
      maxWidth: 390,
      panelClass: 'flow-sidenav-modal',
      backdropClass: 'flow-modal-backdrop',
      positionStrategy: new GlobalPositionStrategy().top('0').right('0').bottom('0'),
      scrollStrategy: this.overlay.scrollStrategies.block()
    });
  }

  openSidenavFor<T>(
    modalElement: ComponentType <T>,
    data: any,
    config: OverlayConfig = {},
    closeOnBackDropClick = false
  ): DialogsOverlayRef <T> {

    const overlay = this.attachSidenav(config);
    const dialogRef = new DialogsOverlayRef(overlay, data, closeOnBackDropClick);

    overlay.attach(
      new ComponentPortal(
        modalElement,
        null,
        this.createInjector(dialogRef, this.injector)
      )
    );

    return dialogRef;
  }
}
