import { NgModule } from '@angular/core';
import { FlowAuthViewsModule } from './auth-views/auth-views.module';

@NgModule({
  declarations: [],
  imports: [
    FlowAuthViewsModule
  ]
})
export class FlowViewsModule { }
