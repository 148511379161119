import { FlowLanguageInterface } from '../interfaces/language.interface';

export const LANGUAGES: FlowLanguageInterface[] = [
  {
    'lc': 'EN',
    'description': 'English',
    'lcId': 'en'
  },
  {
    'lc': 'DE',
    'description': 'Deutsch',
    'lcId': 'de-DE'
  },
  {
    'lc': 'DES',
    'description': 'Deutsch (de-CH)',
    'lcId': 'de-CH'
  },
  {
    'lc': 'FR',
    'description': 'Français',
    'lcId': 'fr-FR'
  },
  {
    'lc': 'FRB',
    'description': 'Français (fr-BE)',
    'lcId': 'fr-BE'
  },
  {
    'lc': 'ES',
    'description': 'Español',
    'lcId': 'es-ES'
  },
  {
    'lc': 'IT',
    'description': 'Italiano',
    'lcId': 'it-IT'
  },
  {
    'lc': 'PT',
    'description': 'Portuguese',
    'lcId': 'pt-PT'
  },
  {
    'lc': 'JPN',
    'description': 'Japanese',
    'lcId': 'ja-JP'
  },
  {
    'lc': 'TR',
    'description': 'Turkish',
    'lcId': 'tr-TR'
  },
  {
    'lc': 'NL',
    'description': 'Nederlands',
    'lcId': 'nl-NL'
  },
  {
    'lc': 'NLB',
    'description': 'Nederlands (nl-BE)',
    'lcId': 'nl-BE'
  },
  {
    'lc': 'DA',
    'description': 'Dansk',
    'lcId': 'da-DK'
  },
  {
    'lc': 'NO',
    'description': 'Norsk',
    'lcId': 'nn-NO'
  },
  {
    'lc': 'SV',
    'description': 'Svenska',
    'lcId': 'sv-SV'
  },
  {
    'lc': 'RUS',
    'description': 'Russian',
    'lcId': 'ru-RU'
  },
  {
    'lc': 'KO',
    'description': 'Korean',
    'lcId': 'ko-KR'
  },
  {
    'lc': 'EZA',
    'description': 'English - Southern Africa',
    'lcId': 'en-ZA'
  },
  {
    'lc': 'UK',
    'description': 'English - Great Britain',
    'lcId': 'en-GB'
  },
  {
    'lc': 'CA',
    'description': 'English - Canada',
    'lcId': 'en-CA'
  },
  {
    'lc': 'EIE',
    'description': 'English - Ireland',
    'lcId': 'en-IE'
  },
  {
    'lc': 'EAU',
    'description': 'English - Australia',
    'lcId': 'en-AU'
  },
  {
    'lc': 'PTB',
    'description': 'Portuguese - Brazil',
    'lcId': 'pt-BR'
  },
  {
    'lc': 'ESL',
    'description': 'Spanish - Latin America',
    'lcId': 'es-MX'
  },
  {
    'lc': 'CS',
    'description': 'Czech',
    'lcId': 'cs-CZ'
  },
  {
    'lc': 'CHS',
    'description': 'Chinese - Simplified',
    'lcId': 'zh-CN'
  },
  {
    'lc': 'CHT',
    'description': 'Chinese - Traditional',
    'lcId': 'zh-HK'
  },
  {
    'lc': 'ENU',
    'description': 'English - US',
    'lcId': 'en-US'
  },
  {
    'lc': 'PLK',
    'description': 'Polish',
    'lcId': 'pl-PL'
  },
  {
    'lc': 'ENZ',
    'description': 'English - New Zealand',
    'lcId': 'en-NZ'
  },
  {
    'lc': 'BR',
    'description': 'Bulgarian',
    'lcId': 'bg-BG'
  },
  {
    'lc': 'HR',
    'description': 'Croatian',
    'lcId': 'hr-BA'
  },
  {
    'lc': 'EE',
    'description': 'Estonian',
    'lcId': 'et-EE'
  },
  {
    'lc': 'GR',
    'description': 'Greek',
    'lcId': 'el-GR'
  },
  {
    'lc': 'HU',
    'description': 'Hungarian',
    'lcId': 'hu-HU'
  },
  {
    'lc': 'LV',
    'description': 'Latvian',
    'lcId': 'lv-LV'
  },
  {
    'lc': 'LT',
    'description': 'Lithuanian',
    'lcId': 'lt-LT'
  },
  {
    'lc': 'RO',
    'description': 'Romanian',
    'lcId': 'ro-RO'
  },
  {
    'lc': 'RS',
    'description': 'Serbian',
    'lcId': 'sr-Latn-RS'
  },
  {
    'lc': 'SK',
    'description': 'Slovakian',
    'lcId': 'sk-SK'
  },
  {
    'lc': 'SI',
    'description': 'Slovenian',
    'lcId': 'sl-SI'
  },
  {
    'lc': 'UA',
    'description': 'Ukrainian',
    'lcId': 'uk-UA'
  },
  {
    'lc': 'HEB',
    'description': 'Hebrew',
    'lcId': 'he-IL'
  }
];
