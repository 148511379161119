import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

export class DialogsOverlayRef <T = any> {

  private afterClosed: Subject <{ data: any }> = new Subject();

  afterClosed$ = this.afterClosed.asObservable();

  constructor(
    public overlay: OverlayRef,
    public data: T,
    public closeOnBackdropClick: boolean
  ) {
    if (true === this.closeOnBackdropClick) {
      overlay.backdropClick().subscribe(() => this.close(data));
    }
  }

  close(data?: T): void {
    this.overlay.addPanelClass('closing');
    setTimeout(() => {
      this.overlay.dispose();
      this.afterClosed.next({ data });
      this.afterClosed.complete();
    }, 500);
  }
}
