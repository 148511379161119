/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FlowRegexService } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';

import {
  formFieldLayoutType,
  FORM_FIELD_LAYOUT_TYPES,
  DEFAULT_FORM_FIELD_LAYOUT_TYPE
} from '../constants/form-field-layout';

@Component({
  selector: 'flow-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowInputPhoneNumberComponent implements OnInit, DoCheck {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() fieldLabel: string;

  @Input() required?: boolean;

  @Input() layout?: formFieldLayoutType;

  @Input() containerClassNames?: string;

  @Input() leftColumnClassNames?: string;

  @Input() rightColumnClassNames?: string;

  labels: FlowTranslateLabel;

  private readonly _phoneNumberRegex = this.RegexService.getRegex('phone_number');

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private RegexService: FlowRegexService,
    private TranslateService: FlowTranslateService
  ) { }

  get control(): UntypedFormControl {
    return this.form.get(this.controlName) as UntypedFormControl;
  }

  ngOnInit(): void {
    this.layout = this.layout && FORM_FIELD_LAYOUT_TYPES.includes(this.layout)
      ? this.layout
      : DEFAULT_FORM_FIELD_LAYOUT_TYPE;

    this.labels = this._translateLabels();

    this._setValidators();
  }

  ngDoCheck(): void {
    // Trigger error messages, if user has touched form control
    // or form was marked as touched by parent component.
    // This allows showing error messages, if existing control value is invalid.
    if (this.control.invalid && this.control.touched) {
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Set field validators
   */
  private _setValidators(): void {
    const validators = [
      Validators.pattern(this._phoneNumberRegex)
    ];

    // Set required validator based on input
    if (this.required === true) {
      validators.push(Validators.required);
    }

    this.control.setValidators(validators);
  }

  /**
   * Translate the labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'generic.error.required',
      'account_settings.label.error.invalid.phone'
    ]);
  }
}
