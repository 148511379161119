/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, AfterContentInit, ChangeDetectionStrategy, Input, Output, ContentChild, TemplateRef, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-repeated-collapsible-form',
  templateUrl: './repeated-collapsible.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FlowRepeatedCollapsibleFormComponent implements OnInit, AfterContentInit {

  @Input() form: UntypedFormGroup;
  @Input() controlName: string;
  @Input() panelHeader: string;
  @Input() buttonLabel: string;
  @Output() addedRepeatedCollapsibleControls: EventEmitter <any>  = new EventEmitter();
  @ContentChild('projectedRepeatedCollapsibleForm') projectedForm: TemplateRef<any>;

  projectedContent: TemplateRef<any>;
  labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) { }

  get arrayControls(): UntypedFormArray {
    return this.form.get(this.controlName) as UntypedFormArray;
  }

  ngOnInit() {
    this.labels = this._translateLabels();
  }

  ngAfterContentInit() {
    this.projectedContent = this.projectedForm;
  }

  /**
   * Add item controls
   */
  addControls(): void {
    this.addedRepeatedCollapsibleControls.emit();
  }

  /**
   * Remove item controls
   */
  removeControls(index: number): void {
    this.arrayControls.removeAt(index);
    this.form.markAsDirty();
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.btn.remove'
    ]);
  }
}
