/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BehaviorSubject } from 'rxjs';

import { FlowTranslateService } from '@flow/translate';

@Component({
  selector: 'flow-modal-iframe',
  template: `
    <flow-preloader [classes]="'p-3'" *ngIf="loading$ | async else content"></flow-preloader>
    <ng-template #content>
      <!-- <flow-preloader *ngIf="isIframeLoading"></flow-preloader> -->
      <div *ngIf="dialogData.title" class="p-3 modal-title bg-primary color-white">
        <h5 class="truncate" [innerHTML]="dialogData.title"></h5>
      </div>
      <mat-dialog-content>
        <iframe [src]="dialogData.url | safe:'resourceUrl'" frameborder="0" width="100%" height="500px"></iframe>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button
          class="button button-close-dialogs"
          [mat-dialog-close]="false"
          [innerHTML]="dialogData.closeButtonLabel"
        ></button>
      </mat-dialog-actions>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowModalIframeComponent implements OnInit {

  dialogData: any;

  loading$: BehaviorSubject <boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private TranslateService: FlowTranslateService
  ) {
    const defaults: any = {
      title: null,
      translatedTitle: null,
      closeButtonLabel: 'generic.btn.close'
    };

    this.dialogData = Object.assign(defaults, this.data.params);
    this.loading$.next(true);
  }

  ngOnInit(): void {
    const toLoad = [];

    if (this.dialogData.title) {
      toLoad.push(this.dialogData.title);
    }

    toLoad.push(this.dialogData.closeButtonLabel);

    this.TranslateService.fetchLabels(toLoad)
      .subscribe(data => {
        if (this.dialogData.title && data[this.dialogData.title]) {
          this.dialogData.title = data[this.dialogData.title];
        }

        if (data[this.dialogData.closeButtonLabel]) {
          this.dialogData.closeButtonLabel = data[this.dialogData.closeButtonLabel];
        }

      this.loading$.next(false);
    });

    if (this.dialogData.translatedTitle) {
      this.dialogData.title = this.dialogData.translatedTitle;
    }
  }
}
