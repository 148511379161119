import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordSameAsOldValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const currentPasswordCtrl = control.get('currentPassword');
  const newPasswordCtrl = control.get('newPassword');

  const currentPasswordValue = currentPasswordCtrl ? currentPasswordCtrl.value : '';
  const newPasswordValue = newPasswordCtrl ? newPasswordCtrl.value : '';

  const invalid = (currentPasswordValue && newPasswordValue) && (currentPasswordValue === newPasswordValue);

  if (invalid) {
    newPasswordCtrl.setErrors({ 'sameNewPassword': true });
  }
  else {
    _clearControlError(newPasswordCtrl as UntypedFormControl, 'sameNewPassword');
  }

  return invalid ? { 'sameNewPassword': true } : null;
};

/**
 * clear specified error from the controller without clearing other errors (if any)
 * */
const _clearControlError = (ctrl: UntypedFormControl, error: string) => {
  const { errors } = ctrl;

  if (errors && errors[error]) {
    delete errors[error];

    ctrl.setErrors(Object.keys(errors).length ? errors : null);
  }
};
