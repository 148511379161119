import { HttpEvent, HttpEventType } from '@angular/common/http';

export const requestEventMessageType = (event: HttpEvent <any>) => {
  switch (event.type) {
    // case HttpEventType.Sent:
    //   return 'start';

    case HttpEventType.UploadProgress: {
      // Compute and show the % done:
      const percentDone = Math.round(100 * event.loaded / event.total);
      return percentDone;
    }

    case HttpEventType.Response:
      return event.body;

    default:
      return event;
  }
};
