
<div class="datatable__filters">
  <ng-content></ng-content>
</div>

<table
  datatable
  [dtOptions]="dtOptions"
  class="row-border hover"
  style="width:100%">
</table>
