<flow-preloader *ngIf="loading$ | async else modal"></flow-preloader>

<ng-template #modal>
  <ng-container *ngIf="errorMsg$ | async as errorMsg else modalContent">
    <div class="p-3 modal-title bg-primary color-white">
      <h5 [innerHTML]="dialogData['error.modal.error.title']"></h5>
    </div>

    <mat-dialog-content class="mb-3">
      <div class="alert danger" [innerHTML]="errorMsg"></div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        class="button button-primary"
        (click)="cancel()"
        [innerHTML]="dialogData.cancelButtonLabel"
      ></button>
    </mat-dialog-actions>
  </ng-container>

  <ng-template #modalContent>
    <div *ngIf="dialogData.title" class="p-3 modal-title bg-primary color-white">
      <h5 class="truncate" [innerHTML]="dialogData.title"></h5>
    </div>

    <flow-preloader *ngIf="deleting$ | async else content" [classes]="'mt-4 mb-4'"></flow-preloader>

    <ng-template #content>
      <mat-dialog-content *ngIf="dialogData.content">
        <div [innerHTML]="dialogData.content | safe:'html'"></div>
      </mat-dialog-content>

      <mat-dialog-actions>
        <button
          class="button button-close-dialogs"
          (click)="cancel()"
          [innerHTML]="dialogData.cancelButtonLabel"
        ></button>
        <button
          class="button button-primary ml-2"
          (click)="deleteEntity()"
          [innerHTML]="dialogData.okButtonLabel"
        ></button>
      </mat-dialog-actions>
    </ng-template>
  </ng-template>
</ng-template>
