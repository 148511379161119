/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { FlowLocaleService } from '@flow/core';
import { FlowUserService } from '@flow/auth';

@Pipe({
  name: 'dateLocale'
})
export class FlowDateLocalePipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe,
    private LocaleService: FlowLocaleService,
    private UserService: FlowUserService
  ) {}

  transform(value: any, format: string, timezone?: string): any {
    const userLang = this.UserService.language;
    const localeId = this.LocaleService.isSupportedLocaleId(userLang) ? userLang : 'en-US';

    return this.datePipe.transform(value, format, timezone, localeId);
  }
}
