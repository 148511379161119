<svg [attr.viewBox]="viewbox" [attr.width]="width" [attr.height]="height" [ngClass]="classes">
  <style type="text/css">
    .st0{fill:#858585;}
    .st1{fill:#D2051E;}
    .st2{fill:#1E8CD2;}
    .st3{fill:#69BE46;}
    .st4{fill:#F59B2D;}
    .st5{fill:#669ECC;}
  </style>
  <g>
    <path class="st0" d="M103.8,6.3c-5,0-9,4.1-8.9,9.1c0.1,4.6,4,8.5,8.6,8.6c5,0.1,9.1-3.9,9.1-8.9C112.7,10.3,108.7,6.3,103.8,6.3z
     M109.3,16.4c-0.4,2.1-2.2,3.8-4.3,4.3c-3.6,0.8-6.8-2-6.8-5.5c0-3.1,2.5-5.6,5.6-5.6C107.3,9.6,110.1,12.8,109.3,16.4z"></path>
    <path class="st0" d="M93.7,20.6h-3.2c-1,0-1.7-0.8-1.7-1.7V8.2c0-0.9-0.7-1.6-1.6-1.6c-0.9,0-1.6,0.7-1.6,1.6v10.7
    c0,2.7,2.2,4.9,4.9,4.9h3.2c0.9,0,1.6-0.7,1.6-1.6C95.3,21.3,94.6,20.6,93.7,20.6z"></path>
    <path class="st0" d="M82,13.6h-5v-2c0-1,0.8-1.7,1.7-1.7h3.2c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6h-3.2
    c-2.7,0-4.9,2.2-4.9,4.9v10.7c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6v-5.4h5c0.9,0,1.6-0.7,1.6-1.6S82.9,13.6,82,13.6z"></path>
    <path class="st0" d="M133.4,6.6c-0.8-0.3-1.8,0.2-2,1l-3.7,11.3l-3.7-11.3c-0.3-0.8-1.2-1.3-2-1c-0.8,0.3-1.3,1.2-1,2l1.2,3.6
    l-2.2,6.7l-3.7-11.3c-0.3-0.8-1.2-1.3-2-1c-0.8,0.3-1.3,1.2-1,2l4.4,13.5c0.3,1,1.3,1.7,2.3,1.7c0,0,0,0,0,0c1.1,0,2-0.7,2.3-1.7
    l1.6-4.8l1.6,4.7c0.3,1,1.3,1.7,2.3,1.7c0,0,0,0,0,0c1.1,0,2-0.7,2.3-1.7l4.5-13.5C134.7,7.8,134.2,6.9,133.4,6.6z"></path>
  </g>
  <g class="vertical_line">
    <path class="st0" d="M147.6,24c-0.1,0-0.2-0.1-0.2-0.2V6.6c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v17.2
    C147.7,23.9,147.6,24,147.6,24z"></path>
  </g>
  <g class="logo_brand">
    <path class="st0" d="M160.7,13V6.7h2.3c0.7,0,1.3,0.2,1.6,0.5c0.4,0.3,0.6,0.8,0.6,1.5c0,0.6-0.2,1.1-0.6,1.5
    c-0.4,0.3-0.9,0.5-1.6,0.5h-1.4V13H160.7z M161.6,9.9h1.3c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.9c0-0.4-0.1-0.7-0.3-0.9
    s-0.6-0.3-1.1-0.3h-1.3V9.9z"></path>
    <path class="st0" d="M165.2,13l2.5-6.4h0.9l2.5,6.4h-1l-0.7-1.8h-2.7l-0.7,1.8H165.2z M167.2,10.4h2l-1-2.6L167.2,10.4z"></path>
    <path class="st0" d="M171.9,13V6.7h2.3c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4c0,0.5-0.1,0.9-0.4,1.2
    c-0.3,0.3-0.6,0.5-1,0.6l1.7,2.6h-1l-1.5-2.4h-1.4V13H171.9z M172.8,9.8h1.4c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.8
    c0-0.8-0.5-1.2-1.5-1.2h-1.4V9.8z"></path>
    <path class="st0" d="M177.1,7.5V6.7h5.2v0.8h-2.1V13h-0.9V7.5H177.1z"></path>
    <path class="st0" d="M183,13V6.7h1l3.6,5.1V6.7h0.9V13h-1l-3.6-5.1V13H183z"></path>
    <path class="st0" d="M189.9,13V6.7h4.2v0.8h-3.3v1.9h2.9v0.8h-2.9v1.9h3.3V13H189.9z"></path>
    <path class="st0" d="M195.1,13V6.7h2.3c0.7,0,1.3,0.2,1.7,0.5c0.4,0.3,0.6,0.8,0.6,1.4c0,0.5-0.1,0.9-0.4,1.2
    c-0.3,0.3-0.6,0.5-1,0.6L200,13h-1l-1.5-2.4H196V13H195.1z M196,9.8h1.4c0.6,0,0.9-0.1,1.1-0.3c0.2-0.2,0.3-0.5,0.3-0.8
    c0-0.8-0.5-1.2-1.5-1.2H196V9.8z"></path>
    <path class="st0" d="M160.1,23.8l2.5-6.4h0.9l2.5,6.4h-1l-0.7-1.8h-2.7l-0.7,1.8H160.1z M162,21.2h2l-1-2.6L162,21.2z"></path>
    <path class="st0" d="M166.7,21.2v-3.7h0.9v3.8c0,0.6,0.1,1.1,0.4,1.4c0.3,0.3,0.7,0.4,1.3,0.4c0.6,0,1-0.1,1.3-0.4
    c0.3-0.3,0.4-0.8,0.4-1.4v-3.8h0.9v3.7c0,0.9-0.2,1.5-0.7,2s-1.1,0.7-1.9,0.7c-0.8,0-1.5-0.2-1.9-0.7S166.7,22.1,166.7,21.2z"></path>
    <path class="st0" d="M172.7,18.3v-0.8h5.2v0.8h-2.1v5.5h-0.9v-5.5H172.7z"></path>
    <path class="st0" d="M178.3,22c-0.1-0.4-0.2-0.9-0.2-1.4c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.4-0.4,0.7-0.6
    c0.3-0.1,0.5-0.2,0.8-0.3c0.3,0,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.3c0.3,0.1,0.5,0.3,0.7,0.6
    c0.2,0.2,0.3,0.5,0.5,0.9c0.1,0.4,0.2,0.9,0.2,1.4c0,0.5-0.1,1-0.2,1.4c-0.1,0.4-0.3,0.7-0.5,0.9c-0.2,0.2-0.4,0.4-0.7,0.6
    s-0.5,0.2-0.8,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.1-0.5-0.3-0.7-0.6
    C178.6,22.7,178.5,22.4,178.3,22z M179.2,19.6c-0.1,0.3-0.1,0.7-0.1,1.1s0,0.8,0.1,1.1c0.1,0.3,0.2,0.5,0.3,0.7
    c0.1,0.2,0.3,0.3,0.5,0.4s0.4,0.2,0.5,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.5-0.4
    c0.1-0.2,0.2-0.4,0.3-0.7s0.1-0.7,0.1-1.1s0-0.8-0.1-1.1c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
    c-0.2-0.1-0.4-0.2-0.5-0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0s-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
    C179.4,19,179.3,19.3,179.2,19.6z"></path>
    <path class="st0" d="M185.3,23.8v-6.4h1l2.1,2.7l2.1-2.7h1v6.4h-0.9v-5l-2.2,2.9l-2.2-2.9v5H185.3z"></path>
    <path class="st0" d="M192.2,23.8l2.5-6.4h0.9l2.5,6.4h-1l-0.7-1.8h-2.7l-0.7,1.8H192.2z M194.2,21.2h2l-1-2.6L194.2,21.2z"></path>
    <path class="st0" d="M197.6,18.3v-0.8h5.2v0.8h-2.1v5.5h-0.9v-5.5H197.6z"></path>
    <path class="st0" d="M203.6,23.8v-6.4h0.9v6.4H203.6z"></path>
    <path class="st0" d="M205.9,22c-0.1-0.4-0.2-0.9-0.2-1.4c0-0.5,0.1-1,0.2-1.4c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.4-0.4,0.7-0.6
    c0.3-0.1,0.5-0.2,0.8-0.3c0.3,0,0.5-0.1,0.8-0.1c0.3,0,0.6,0,0.8,0.1c0.3,0,0.5,0.1,0.8,0.3c0.3,0.1,0.5,0.3,0.7,0.6
    c0.2,0.2,0.3,0.5,0.5,0.9c0.1,0.4,0.2,0.9,0.2,1.4c0,0.5-0.1,1-0.2,1.4c-0.1,0.4-0.3,0.7-0.5,0.9c-0.2,0.2-0.4,0.4-0.7,0.6
    c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0-0.5,0.1-0.8,0.1c-0.3,0-0.6,0-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.1-0.5-0.3-0.7-0.6
    C206.2,22.7,206,22.4,205.9,22z M206.7,19.6c-0.1,0.3-0.1,0.7-0.1,1.1s0,0.8,0.1,1.1c0.1,0.3,0.2,0.5,0.3,0.7
    c0.1,0.2,0.3,0.3,0.5,0.4s0.4,0.2,0.5,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.2,0,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.5-0.4
    c0.1-0.2,0.2-0.4,0.3-0.7s0.1-0.7,0.1-1.1s0-0.8-0.1-1.1c-0.1-0.3-0.2-0.5-0.3-0.7c-0.1-0.2-0.3-0.3-0.5-0.4
    c-0.2-0.1-0.4-0.2-0.5-0.2c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0s-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.2-0.5,0.4
    C206.9,19,206.8,19.3,206.7,19.6z"></path>
    <path class="st0" d="M212.8,23.8v-6.4h1l3.6,5.1v-5.1h0.9v6.4h-1l-3.6-5.1v5.1H212.8z"></path>
  </g>
  <g>
    <g>
      <path class="st1" d="M33.1,13.4c0.4,0.6,0.8,1.2,1.2,1.8c4.2-6.1,7.9-8.7,12-8.7c4.3,0,7.9,3.9,7.9,8.7c0,0,0,0,0,0
      c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3c0,0,0,0,0,0C60.6,6.8,54.2,0,46.2,0c-3.7,0-7.2,1.2-10.4,3.8
      c-1.9,1.5-3.7,3.3-5.5,5.7C31.2,10.7,32.1,12,33.1,13.4C33,13.4,33.1,13.4,33.1,13.4z"></path>
    </g>
  </g>
  <path class="st2" d="M60.6,15.2c0,8.4-6.5,15.2-14.4,15.2c-3.7,0-7.2-1.2-10.4-3.8c-2.7-2.1-5.3-5.2-8.2-9.6
  c0.6,0.9,1.6,1.4,2.7,1.4c0.6,0,1.2-0.2,1.8-0.5c1.5-1,1.9-3,1-4.5c0,0,0,0,0,0c0,0,0,0,0,0c4.8,7.3,8.7,10.5,13.2,10.5
  c4.3,0,7.9-3.9,7.9-8.7c0-1.8,1.5-3.3,3.3-3.3S60.6,13.4,60.6,15.2z"></path>
  <path class="st3" d="M33.1,13.4C33,13.4,33,13.4,33.1,13.4c-2.9-4.4-5.5-7.5-8.2-9.6C21.6,1.2,18.1,0,14.4,0C6.5,0,0,6.8,0,15.2
  c0,0,0,0,0,0C0,13.4,1.5,12,3.3,12c1.8,0,3.3,1.5,3.3,3.3c0,0,0,0,0,0c0-4.8,3.5-8.7,7.9-8.7c4.2,0,8,2.8,12.5,9.4
  c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6c0,0,0,0,0.1,0.1c0.6,0.9,1.6,1.4,2.7,1.4
  c0.6,0,1.2-0.2,1.8-0.5C33.6,17,34,14.9,33.1,13.4z"></path>
  <path class="st4" d="M30.3,20.9c-1.9,2.4-3.7,4.3-5.5,5.7c-3.3,2.6-6.7,3.8-10.4,3.8C6.5,30.4,0,23.6,0,15.2v0C0,13.4,1.5,12,3.3,12
  c1.8,0,3.3,1.5,3.3,3.3v0c0,4.8,3.5,8.7,7.9,8.7c4.1,0,7.7-2.6,12-8.7c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.1,0.1,0.2,0.2,0.2
  c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.4,0.4,0.6c0,0,0,0,0.1,0.1C28.5,18.5,29.4,19.7,30.3,20.9z"></path>
  <g>
    <path class="st0" d="M220.4,17.7v1.8h-0.2v-1.8h-0.6v-0.2h1.4v0.2H220.4z M221.6,17.8v1.7h-0.2v-2h0.3l0.8,1.4l0.8-1.4h0.3v2h-0.2
    v-1.7l-0.7,1.4h-0.2L221.6,17.8z"></path>
  </g>
  <g class="company">
    <path class="st5" d="M74.7,34.5v-5.1h1.7c0.6,0,1,0.1,1.3,0.3c0.3,0.2,0.4,0.6,0.4,1c0,0.2-0.1,0.5-0.2,0.6
    c-0.1,0.2-0.3,0.3-0.6,0.4c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.2,0.5,0.2,0.8c0,0.5-0.1,0.8-0.4,1.1c-0.3,0.3-0.7,0.4-1.3,0.4H74.7z
     M75.4,31.5h1c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.3-0.1-0.5-0.2-0.6c-0.2-0.1-0.4-0.2-0.8-0.2h-1V31.5z M75.4,32.1
    v1.8h1.1c0.3,0,0.6-0.1,0.7-0.2c0.2-0.2,0.3-0.4,0.3-0.7c0-0.6-0.3-0.9-1-0.9H75.4z"></path>
    <path class="st5" d="M80.5,33.5l0.9-2.9h0.7L80.6,35c-0.2,0.6-0.6,1-1.1,1l-0.1,0l-0.2,0v-0.5l0.2,0c0.2,0,0.4,0,0.5-0.1
    c0.1-0.1,0.2-0.3,0.3-0.5l0.1-0.4l-1.4-3.8h0.7L80.5,33.5z"></path>
    <path class="st5" d="M88.3,29.9h-1.7v4.6H86v-4.6h-1.6v-0.6h4V29.9z"></path>
    <path class="st5" d="M89.9,34.5h-0.7v-5.1h0.7V34.5z"></path>
    <path class="st5" d="M94.2,32.1H92v1.8h2.6v0.6h-3.3v-5.1h3.2v0.6H92v1.7h2.2V32.1z"></path>
    <path class="st5" d="M98.8,32.1l-0.6,0.7v1.7h-0.7v-5.1h0.7v2.5l2.3-2.5h0.8l-2,2.3l2.2,2.9h-0.8L98.8,32.1z"></path>
    <path class="st5" d="M102.1,29.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0.1,0.3
    c0,0.1,0,0.2-0.1,0.3s-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1C102.1,29.8,102.1,29.7,102.1,29.6z M102.8,34.5h-0.7v-3.8h0.7V34.5z"></path>
    <path class="st5" d="M104.6,30.6l0,0.5c0.3-0.4,0.7-0.6,1.1-0.6c0.8,0,1.2,0.5,1.2,1.4v2.5h-0.7v-2.5c0-0.3-0.1-0.5-0.2-0.6
    c-0.1-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0.1-0.6,0.2s-0.3,0.3-0.4,0.4v2.7H104v-3.8H104.6z"></path>
    <path class="st5" d="M109.7,34.5c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.4v-0.1c0-0.4,0.1-0.7,0.2-1
    c0.1-0.3,0.3-0.5,0.6-0.7c0.3-0.2,0.5-0.3,0.8-0.3c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.4,0.8,0.4,1.4v0.3h-2.6c0,0.4,0.1,0.7,0.3,0.9
    c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4,0,0.6-0.1s0.3-0.2,0.4-0.4l0.4,0.3C110.8,34.3,110.3,34.5,109.7,34.5z M109.6,31.1
    c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.8h1.9v0c0-0.3-0.1-0.6-0.3-0.8C110.1,31.2,109.9,31.1,109.6,31.1z"></path>
    <path class="st5" d="M113,29.7v0.9h0.7v0.5H113v2.4c0,0.2,0,0.3,0.1,0.3s0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0v0.5
    c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5-0.1-0.7-0.3s-0.2-0.4-0.2-0.8v-2.4h-0.7v-0.5h0.7v-0.9H113z"></path>
    <path class="st5" d="M114.6,29.6c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.3-0.1s0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3
    c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.2,0.1-0.3,0.1s-0.2,0-0.3-0.1C114.6,29.8,114.6,29.7,114.6,29.6z M115.3,34.5h-0.7v-3.8h0.7V34.5z
    "></path>
    <path class="st5" d="M117.8,32l0.8-1.4h0.8l-1.2,1.9l1.3,1.9h-0.8l-0.9-1.4l-0.9,1.4h-0.8l1.3-1.9l-1.2-1.9h0.8L117.8,32z"></path>
  </g>
</svg>
