import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DataTablesModule } from 'angular-datatables';

import { FlowDatatableService } from './services/datatable.service';
import { FlowDatatableComponent } from './containers/datatable/datatable.component';
import { FlowRawDatatableComponent } from './containers/raw-datatable/raw-datatable.component';

@NgModule({
  declarations: [
    FlowDatatableComponent,
    FlowRawDatatableComponent
  ],
  imports: [
    CommonModule,
    DataTablesModule
  ],
  exports: [
    FlowDatatableComponent,
    FlowRawDatatableComponent
  ],
  providers: [
    DatePipe
  ]
})

export class FlowDatatableModule {
  static forRoot(): ModuleWithProviders<FlowDatatableModule> {
    return {
      ngModule: FlowDatatableModule,
      providers: [
        FlowDatatableService
      ]
    };
  }
}
