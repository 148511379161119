<mat-form-field [formGroup]="form">
  <mat-label>{{ placeholder }} </mat-label>
  <input
    matInput
    [ngxMatTimepicker]="picker"
    [formControlName]="controlName"
    [attr.aria-label]="placeholder"
    [required]="required ? required : false"
    [format]="24"
    [value]="form.get(controlName).value || '00:00'"
    readonly>
  <ngx-mat-timepicker
    #picker
    [cancelBtnTmpl]="cancelBtn"
    [confirmBtnTmpl]="confirmBtn"
    [enableKeyboardInput]="true"
    [editableHintTmpl]="dialHint">
  </ngx-mat-timepicker>
  <mat-error
    *ngIf="required && form.get(controlName).hasError('required')"
    [innerHTML]="labels['generic.error.required']">
  </mat-error>
</mat-form-field>

<ng-template #dialHint></ng-template>

<ng-template #cancelBtn>
  <button class="button" [innerHTML]="labels['general.btn.cancel']"></button>
</ng-template>

<ng-template #confirmBtn>
  <button class="button button-primary" [innerHTML]="labels['general.btn.ok']"></button>
</ng-template>
