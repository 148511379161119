import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, fromEvent, BehaviorSubject, Subscription } from 'rxjs';
import { filter, pluck } from 'rxjs/operators';

import { FlowEnvService, FlowRouterService } from '@flow/core';
import { FlowAuthService, FlowTokensService } from '@flow/auth';

import { FlowPortalService } from '../../../../../services/portal/portal.service';

@Component({
  selector: 'flow-sso',
  templateUrl: './sso.component.html',
})
export class FlowSsoComponent implements OnInit, OnDestroy {

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  private _queryParams$$: Subscription;
  private _iframeMessage$$: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private EnvService: FlowEnvService,
    private RouterService: FlowRouterService,
    private AuthService: FlowAuthService,
    private TokensService: FlowTokensService,
    private PortalService: FlowPortalService
  ) { }

  ngOnInit(): void {
    this._queryParams$$ = this.activatedRoute.queryParams.subscribe(params => {
      const { token, refreshToken, productId } = params;

      const currentTokens = this.TokensService.getTokens();

      // only logout old user if the tokens are not the same
      if (token
          &&
          token !== currentTokens.token
          &&
          refreshToken
          &&
          refreshToken !== currentTokens.refreshToken) {

        // clear old user/tokens if any
        this.AuthService.logout();
      }

      // recieve tokens by query params
      if (token && refreshToken) {
        this._doAuthentication({ ...params, checkForCustomAfterLoginUrl: true });
      }
      // recieve tokens by hidden iframe
      else if (productId) {
        // create hidden iframe
        this.createHiddenIframe(productId);

        // listen for message sent from hidden iframe
        this._iframeMessage$$ = this._listenToIframeMessage().subscribe(
          data => {
            // parse JSON
            try {
              const msgObj = JSON.parse(data);
              const { token, refresh, status } = msgObj;

              if (status && status === '200' && token && refresh) {
                this._doAuthentication({ ...params, token, refreshToken: refresh });
              }
              else {
                this.RouterService.navigate(this.AuthService.loginUrl);
              }
            }
            catch {
              this.RouterService.navigate(this.AuthService.loginUrl);
            }
          }
        );
      }
      // fallback: redirect to login page
      else {
        this.RouterService.navigate(this.AuthService.loginUrl);
      }
    });
  }

  ngOnDestroy(): void {
    this.isLoading$.next(false);
    this.isLoading$.complete();

    this._queryParams$$.unsubscribe();

    if (this._iframeMessage$$) {
      this._iframeMessage$$.unsubscribe();
    }
  }

  /**
   * Authenticate user
   */
  private _doAuthentication(params): void {
    const { token, refreshToken, redirect, project, config, pid, parent, checkForCustomAfterLoginUrl } = params;

    // set tokens
    this.TokensService.setTokens(token, refreshToken);

    if (project) {
      this.AuthService.setVendor(project);

      // Set onboarding source used on final submit in new onboarding
      this.AuthService.setOnboardingSource(project);
    }

    // authenticate user
    this.AuthService.getMe().subscribe(result => {
      if (result) { // auth success
        if (redirect) { // redirect to specific route
          const routeParams: {
            project?: string;
            config?: string;
            id?: string;
            parent?: string;
          } = {};

          if (project) {
            routeParams.project = project;
          }
          else if (config) {
            routeParams.config = config;
          }
          else if (pid) {
            routeParams.id = pid;
          }

          // Allows to implement different logic based on where app is embedded (e.g. for onboarding)
          if (parent) {
            routeParams.parent = parent;
          }

          this.RouterService.navigate([redirect, routeParams]);
        }
        else {  // redirect to main/root route or custom redirect url
          if (checkForCustomAfterLoginUrl) {
            this.RouterService.navigate(this.PortalService.getCustomRedirectUrl());
          }
          else {
            this.RouterService.navigate(this.AuthService.afterLoginUrl);
          }
        }
      }
      else {  // auth failed, so redirect to login page
        this.RouterService.navigate(this.AuthService.loginUrl);
      }
    });
  }

  /**
   * Create hidden iframe
   */
  private createHiddenIframe(productId: string): void {
    const hiddenFrame = document.createElement('iframe');

    hiddenFrame.setAttribute('src', `${this.EnvService.get('cmsUrl')}/authorize/${productId}/init`);

    hiddenFrame.style.width = '1px';
    hiddenFrame.style.height = '1px';
    hiddenFrame.style.border = 'none';

    document.body.appendChild(hiddenFrame);
  }

  /**
   * Event handler for listening to
   */
  private _listenToIframeMessage(): Observable<string> {
    return fromEvent(window, 'message').pipe(
      // we only trust messages from the cms url (e.g. https://backend-cms-test.tiekinetix.net)
      filter((msg: MessageEvent) => msg.origin === this.EnvService.get('cmsUrl')),
      // message will be like
      // {"token": "xxx", "refresh": "xxx", "status": "200"}
      pluck('data')
    );
  }
}
