/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FlowIframeMessageService } from '../services/iframe-message/iframe-message.service';
import { FlowEventService } from '../services/event/event.service';


const RESPONSE_ERROR_500_EVENT = 'response.error.500';

@Injectable()
export class FlowRequestsInterceptor implements HttpInterceptor {

  constructor(
    private EventService: FlowEventService,
    private IframeMessageService: FlowIframeMessageService,
  ) { }

  private get isInIframe() {
    // try/catch to prevent possible cross-origin SecurityError exception
    try {
      return window.self !== window.top;
    }
    catch (e) {
      return true;
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        /*map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            console.log('event--->>>', event);
          }
          return event;
        }),*/
        catchError(err => {
          if (
            err instanceof HttpErrorResponse
            &&
            err.status === 500
          ) {
            if (this.isInIframe) {
              this.IframeMessageService.send({action: RESPONSE_ERROR_500_EVENT});
            }
            else {
              this.EventService.send(RESPONSE_ERROR_500_EVENT, err);
            }
          }

          return throwError(err);
        })
      );
  }
}
