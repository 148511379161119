/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FlowModalSmsAuthRegisterComponent } from '../../modals/modal-sms-auth-register/modal-sms-auth-register.component';
import { FlowDialogsService } from '../../../../../services/dialogs/dialogs.service';

@Component({
  selector: 'flow-sms-auth-register',
  templateUrl: './sms-auth-register.component.html',
  styleUrls: ['./sms-auth-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowSmsAuthRegisterComponent {

  @Input() isEnforce2FaPage: boolean;

  @Output() registrationResult: EventEmitter <boolean> = new EventEmitter<any>();

  constructor(
    private ModalsService: FlowDialogsService
  ) {}

  register(): void {
    this.ModalsService.open(FlowModalSmsAuthRegisterComponent, {
      data: {
        isEnforce2FaPage: this.isEnforce2FaPage
      },
      maxWidth: '330px',
      closeOnNavigation: true,
      disableClose: true
    }).afterClosed().subscribe(result => {
      this.registrationResult.emit(result);
    });
  }
}
