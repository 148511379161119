/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FlowProjectService } from '@flow/auth';
import { FlowLanguageInterface } from '@flow/core';
import { FlowTranslateService, FlowTranslateLabel } from '@flow/translate';
import { Observable } from 'rxjs';

@Component({
  selector: 'flow-languages-dropdown',
  templateUrl: './languages-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowLanguagesDropdownComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  @Input() controlName: string;

  @Input() inlineLabel?: string;

  @Input() required?: boolean;

  labels: FlowTranslateLabel;

  languages: Observable<FlowLanguageInterface[]>;

  constructor(
    private ProjectService: FlowProjectService,
    private TranslateService: FlowTranslateService,
  ) { }

  ngOnInit() {
    this.labels = this._translateLabels();

    this.languages = this.ProjectService.getProjectLanguages();
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    const labels = [];

    if (this.inlineLabel) {
      labels.push(this.inlineLabel);
    }

    return this.TranslateService.translateSync(labels);
  }
}
