import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { FlowCustomerService, FlowUserService } from '@flow/auth';

import { FlowMarketplaceStateInterface } from '@marketplaceStore';

import * as fromCompanyActions from '../actions/company.actions';
import * as fromCompanySelectors from '../selectors/company.selectors';

@Injectable()
export class CompanyEffect {

  Load$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromCompanyActions.FlowCompanyActions>(fromCompanyActions.CompanyActionTypes.LoadCompany),
      withLatestFrom(this.store.pipe(select(fromCompanySelectors.getIfCompanyIsLoaded))),
      filter(([state, loaded]) => !loaded),
      switchMap(() => this.CustomerService.getCustomer(this.UserService.company).pipe(
        map(result => new fromCompanyActions.LoadCompanySuccess(result)),
        catchError(err => of(new fromCompanyActions.LoadCompanyFail(err))),
      ))
    );
  });

  Update$ = createEffect((): Observable<Action> => {
    return this.actions$.pipe(
      ofType<fromCompanyActions.FlowCompanyActions>(fromCompanyActions.CompanyActionTypes.UpdateCompany),
      withLatestFrom(this.store.pipe(select(fromCompanySelectors.getCurrentCompany))),
      switchMap(([state, data]) => this.CustomerService.updateSsoCustomer(data, this.UserService.company).pipe(
        map(() => new fromCompanyActions.UpdateCompanyResult(true)),
        catchError(() => of(new fromCompanyActions.UpdateCompanyResult(false)))
      ))
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store <FlowMarketplaceStateInterface>,
    private CustomerService: FlowCustomerService,
    private UserService: FlowUserService,
  ) {}
}
