/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {

  private _labels: FlowTranslateLabel;

  constructor(
    private TranslateService: FlowTranslateService
  ) {
    super();
    this._labels = this._translateLabels();
    this._setPaginatorLabels();
  }

  /**
   * Set range label
   */
  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this._labels['general.label.paginator.of']} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length
                     ? Math.min(startIndex + pageSize, length)
                     : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this._labels['general.label.paginator.of']} ${length}`;
  };

  /**
   * Set paginator labels
   */
  private _setPaginatorLabels(): void {
    this.firstPageLabel = this._labels['general.label.paginator.first_page'];
    this.lastPageLabel = this._labels['general.label.paginator.last_page'];
    this.nextPageLabel = this._labels['general.label.paginator.next_page'];
    this.previousPageLabel = this._labels['general.label.paginator.previous_page'];
  }

  /**
   * Translate labels
   */
   private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'general.label.paginator.first_page',
      'general.label.paginator.last_page',
      'general.label.paginator.next_page',
      'general.label.paginator.previous_page',
      'general.label.paginator.of'
    ]);
  }
}
