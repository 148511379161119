/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { FlowTranslateService } from '../../services/translate/translate.service';

@Directive({
  selector: '[flowTranslate]'
})
export class FlowTranslateDirective implements AfterViewInit {

  /** the Key to translate */
  @Input() flowTranslate: string;

  /** Optional parameters */
  @Input() translateParams: any;

  constructor(
    private element: ElementRef,
    private TranslateService: FlowTranslateService
  ) { }

  ngAfterViewInit() {
    this.update();
  }

  /**
   * Updates the HTML.
   */
  update() {
    if (this.flowTranslate) {
      let translation;

      // Check for textblock interpolation
      const regex = /\{{(.*?)\}}/gi;
      const matches = this.flowTranslate.search(regex) >= 0;

      if (matches) {
        translation = this.flowTranslate.replace(regex, (match) => {
          match = match.replace('{{', '');
          match = match.replace('}}', '');

          return this.TranslateService.instant({key: match});
        });
      }
      else {
        translation = this.TranslateService.instant({ key: this.flowTranslate, params: this.translateParams });
      }

      this.element.nativeElement.innerHTML = translation;
    }
  }
}
