/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlowHelpers, FlowRouterService } from '@flow/core';
import { FlowAuthService, FlowSsoService } from '@flow/auth';
import { FlowTranslateLabel, FlowTranslateService } from '@flow/translate';

import { passwordStrengthValidator, rulesRegex } from '../../../../../validators/password-strenght.validator';
import { passwordsMatchValidator } from '../../../../../validators/passwords-match.validator';
import { FlowLayoutService } from '../../../../../services/layout/layout.service';

@Component({
  selector: 'flow-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowChangePasswordComponent implements OnInit {

  labels: FlowTranslateLabel;

  form: UntypedFormGroup;

  loading$: Observable<boolean>;

  validHash$: Observable<boolean>;

  errorMsg: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private AuthService: FlowAuthService,
    private TranslateService: FlowTranslateService,
    private RouterService: FlowRouterService,
    private LayoutService: FlowLayoutService,
    private SsoService: FlowSsoService
  ) {
    this.form = this.formBuilder.group(
      {
        customerId: new UntypedFormControl('Flow', [Validators.required]),
        userId: new UntypedFormControl('', [Validators.required]),
        newPassword: new UntypedFormControl('', [
          Validators.required,
          passwordStrengthValidator(),
        ]),
        repeatNewPassword: new UntypedFormControl('', [
          Validators.required,
        ]),
        token: new UntypedFormControl('', [Validators.required])
      },
      {
        validators: [
          passwordsMatchValidator
        ]
      });
  }

  ngOnInit(): void {
    this.labels = this._translateLabels();

    this.loading$ = this.LayoutService.loading$;

    this.validHash$ = this.activatedRoute.data.pipe(
      map(params => {
        if (!FlowHelpers.isObject(params.hash) ||
            !FlowHelpers.hasProperty(params.hash, 'user') ||
            !FlowHelpers.hasProperty(params.hash, 'password')) {
              this.errorMsg = params.hash.error;
              return false;
        }
        else {
          this.form.get('token').setValue(params.hash.password);
          this.form.get('userId').setValue(params.hash.user);
          this.errorMsg = '';
          return true;
        }
      })
    );
  }

  passwordConformsTo(rule): boolean {
    let valid = false;
    const password = this.form.get('newPassword').value;

    if (password) {
      valid = rulesRegex[rule].test(password);
    }

    return valid;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.LayoutService.setLoading(true);

    this.SsoService.changePassword(
      { ...this.form.value, currentPassword: this.form.get('newPassword').value },
      {
        recoveryToken: this.activatedRoute.snapshot.params.hash,
        skipAuth: true
      }
    )
      .subscribe(changed => {
        if (changed === true) {
          this.errorMsg = '';

          this.AuthService.login({
            email: this.form.get('userId').value,
            password: this.form.get('newPassword').value,
            rememberMe: false,
            skipAuth: true
          })
          .subscribe(result => {
            if (result === true) {
              this.RouterService.navigate(this.AuthService.afterLoginUrl);
            }
            else {
              // on fail, logout and move the user to the login page.
              this.RouterService.navigate(this.AuthService.logoutUrl);
            }
          });
        }
        else {
          const changedError = changed['error'];
          let errorMsg = this.labels['error.invalid.password_request'];   // default fallback

          if (changedError) {
            if (changedError.includes('reset_link_expired') || changedError.includes('find in cache')) {
              errorMsg = this.labels['generic.error.reset_link_expired'];
            }
            else if (changedError.includes('password_not_secure_enough')) {
              errorMsg = this.labels['generic.error.password_strength'];
            }
          }

          this.errorMsg = errorMsg;
          this.LayoutService.setLoading(false);
        }
      }
    );
  }

  /**
   * Translate necessary labels
   */
  private _translateLabels(): FlowTranslateLabel {
    return this.TranslateService.translateSync([
      'login.password_form.label.new_password',
      'login.password_form.label.repeat_password',
      'generic.error.required.new_password',
      'generic.error.required.repeat_password',
      'generic.error.password_strength',
      'generic.error.reset_link_expired',
      'generic.error.password_mismatch',
      'general.password_requirements',
      'general.password_requirements.title',
      'general.password_requirements.rules.length',
      'general.password_requirements.rules.upper_lower_chars',
      'general.password_requirements.rules.numbers',
      'general.password_requirements.rules.special_chars',
      'general.password_requirements.rules.no_personal_info',
      'error.invalid.password_request',
      'login.password_form.headline.set_password',
      'login.aria.region.password_requirements'
    ]);
  }
}
