<!--
  <div class="datatable__actions">
    <button type="button" (click)="setViewType('list')">List view</button>
    <button type="button" (click)="setViewType('tile')">Tile view</button>
    <input type="text" name="search" id="search" placeholder="Search ..." [(ngModel)]="searchText" (keyup)="onSearch($event)">
  </div>
-->

<div class="datatable__filters">
  <ng-content></ng-content>
  <!--
    <div>
      <form (submit)="filterByType()">
        <input type="text" name="type" id="type" [(ngModel)]="type">
        <button class="btn btn-primary" type="submit">Filter by type</button>
      </form>
    </div>
  -->
</div>

<!-- To enable striped tabels add class="stripe" -->
<table datatable
       [dtOptions]="dtOptions"
       [ngClass]="viewType === 'list' ? 'list-view row-border hover' : 'tile-view hover'"
       style="width:100%">
</table>