<flow-preloader *ngIf="loading$ | async else content"></flow-preloader>
<ng-template #content>
  <flow-alert-message
    *ngIf="errorOnSave$ | async"
    [type]="'danger'"
    [messageId]="'error.general.error_loading'"
    [extraClasses]="'mb-3'">
  </flow-alert-message>

  <form [formGroup]="translateForm" (ngSubmit)="onSubmit()" novalidate>
    <h6
      *ngIf="dialogData.title"
      mat-dialog-title
      [innerHTML]="dialogData.title"
      class="with-description"
    ></h6>
    <div
      class="mb-3"
      [innerHTML]="dialogData.subtitle"
    ></div>

    <mat-dialog-content>
      <ng-container *ngFor="let language of selectedLanguages">
        <div class="row align-items-baseline">
          <div class="col-12 col-lg-4 col-xl-3">
            <label
              [attr.for]="language.id"
              [ngClass]="dialogData.required !== false && language.id === defaultLanguage
                ? 'form-group-label required'
                : 'form-group-label'"
              [innerHTML]="language.label">
            </label>
          </div>
          <div
            [ngClass]="{ 'mb-4': textBlockType === 'htmlarea' }"
            class="col-12 col-lg-8 col-xl-9">
            <mat-form-field *ngIf="textBlockType === 'input'">
              <input
                matInput
                [id]="language.id"
                [formControlName]="language.id"
                [attr.aria-label]="language.label">
              <mat-error
                [hidden]="translateForm.get(language.id).valid"
                [innerHTML]="translateForm.get(language.id).hasError('required')
                  ? errorMessages['required']
                  : translateForm.get(language.id).hasError('pattern')
                    ? errorMessages['pattern']
                    : null"
                role="alert"
                aria-live="assertive">
              </mat-error>
            </mat-form-field>

            <quill-editor
              *ngIf="textBlockType === 'htmlarea'"
              [formControlName]="language.id"
              [sanitize]="true"
              (onContentChanged)="onContentChanged()">
            </quill-editor>
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        class="button button-close-dialogs mr-2"
        type="button"
        (click)="cancel()"
        [innerHTML]="labels['generic.btn.cancel']">
      </button>
      <button
        *ngIf="this.dialogData.showResetButton"
        class="button button-danger-outline mr-2"
        type="button"
        (click)="translateForm.reset()"
        [innerHTML]="labels['general.btn.remove']">
      </button>
      <button
        class="button button-primary"
        type="submit"
        [disabled]="translateForm.invalid"
        [innerHTML]="labels['generic.btn.save']">
      </button>
    </mat-dialog-actions>
  </form>
</ng-template>
