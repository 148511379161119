/*
* Include here all the allowed actions/methods the (child) iframes are allowed to execute thru the IframeMessageService.
* Try to keep the method name same as the action name for easier reference.
* */

import { Injectable } from '@angular/core';

import { FlowAuthService } from '@flow/auth';
import { FlowRouterService } from '@flow/core';
import { FlowDialogsService } from '@flow/shared';

import { FlowProjectDomainService } from './project-domain.service';

@Injectable({
  providedIn: 'root'
})
export class FlowIframeActionsService {

  constructor(
    private AuthService: FlowAuthService,
    private RouterService: FlowRouterService,
    private DialogsService: FlowDialogsService,
    private ProjectDomainService: FlowProjectDomainService,
  ) { }

  openNewWindow(data) {
    const { url } = data;

    if (url) {
      window.open(url,'_blank');
    }
  }

  openIframeModal(data) {
    const { url } = data;

    if (url) {
      this.DialogsService.iframeModal({ url });
    }
  }

  logout(data) {
    if (this.AuthService.isLoggedIn) {
      this.RouterService.navigate(['/auth', 'logout']);
    }
  }

  impersonate(data) {
    const { project, token } = data;
    const tokens = {
      token: token.access,
      refreshToken: token.refresh,
    };

    this.ProjectDomainService.redirectToVendorDomain(project, tokens);
  }
}
