/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Injectable } from '@angular/core';
import { FlowCmsService } from '../cms/cms.service';
import { Observable } from 'rxjs';
import { MODELS, MODELS_WITH_BULK } from '../../constants/models';
import { FlowHelpers } from '../../classes/class.helpers';

@Injectable({
  providedIn: 'root'
})
export class FlowModelsService {

  constructor(
    private CmsService: FlowCmsService
  ) { }

  list<T>(model: string, params?: any, headers?: any): Observable<{ total: number; data: T[] }> {
    return this.CmsService.getWithHeaders(this.getModel(model), params, headers);
  }

  getModel(predicate: string): string {
    if (FlowHelpers.hasProperty(MODELS, predicate)) {
      return MODELS[predicate];
    }
    throw new TypeError(`model ${predicate} does not exist in our collection. Check models in @flow/core/constants/models.ts`);
  }

  getBulkModel(predicate: string): string {
    if (FlowHelpers.hasProperty(MODELS_WITH_BULK, predicate)) {
      return MODELS_WITH_BULK[predicate];
    }
    throw new TypeError(
      `model ${predicate} does not exist in our collection of models with bulk support. Check models in @flow/core/constants/models.ts`
    );
  }

  getOne<T>(model: string, entityId: string, params: any = {}, headers: any = {}): Observable<T> {
    return this.CmsService.get(`${this.getModel(model)}/${entityId}`, params, headers);
  }

  delete<T>(model: string, entityId: string, params: any = {}, headers: any = {}): Observable<T> {
    return this.CmsService.delete(`${this.getModel(model)}/${entityId}`, params, headers);
  }

  bulkDelete<T>(model: string, params: any = {}, headers: any = {}, extras: any = {}): Observable<T> {
    return this.CmsService.bulkDelete(`${this.getBulkModel(model)}`, params, headers, extras);
  }

  update<T>(model: string, entityId: string, params: any = {}, headers: any = {}): Observable<T> {
    return this.CmsService.put(`${this.getModel(model)}/${entityId}`, params, headers);
  }

  bulkPost<T>(model: string, params: any = {}, headers: any = {}): Observable<T> {
    return this.CmsService.bulkPost(`${this.getBulkModel(model)}`, params, headers);
  }

  getLowerCaseModel(model: string): string {
    return this.getModel(model).split('.').pop().toLowerCase();
  }
}

