/**
 * @license
 * Copyright TIE Kinetix. All Rights Reserved.
 */

import { Component, ChangeDetectionStrategy, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'flow-abs-preloader',
  templateUrl: './abs-preloader.component.html',
  styles: [
    `
           :host {
             z-index: 100;
             background: rgba(255,255,255, .83);
           }
         `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlowAbsPreloaderComponent {

  @Input() completed: boolean;
  @Input() positionFixed: boolean;

  @HostBinding('class')
  get classNames() {
    return this.positionFixed
      ? 'absolute-center-fixed d-flex align-items-center justify-content-center'
      : 'absolute-center d-flex align-items-center justify-content-center';
  }

}
