import {
  trigger,
  animate,
  transition,
  style,
  group,
  animateChild,
  query
} from '@angular/animations';

export const FlowRouterSlideTransition = trigger('routerSlideTransition', [
  transition('AuthState <=> AppState', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ]),
    query(':enter', [
      style({ opacity: 0 })
    ]),
    query(':leave', animateChild()),
    group([
      query(':leave', [
        animate('200ms ease-out', style({ opacity: 0}))
      ]),
      query(':enter', [
        animate('300ms ease-out', style({ opacity: 1}))
      ])
    ]),
    query(':enter', animateChild()),
  ]),
  transition('AppState => AuthState', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], {optional: true}),
    query(':enter', [
      style({left: '-100%'})
    ], {optional: true}),
    query(':leave', animateChild(), {optional: true}),
    group([
      query(':leave', [
        animate('200ms ease-out', style({opacity: 0}))
      ], {optional: true}),
      query(':enter', [
        animate('300ms ease-out', style({left: '0%'}))
      ], {optional: true})
    ]),
    query(':enter', animateChild(), {optional: true}),
  ])
]);
